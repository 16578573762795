import { postMessage } from '../nativeBridge';
import { LOGIN } from '../nativeBridge/messageTypes';

/**
 * user has logged in, notify native so they can talk to Airship
 * @param {string} userID the customer id of the mosaic user
 */
export const notifyLogin = (userID) => {
	let message = {
		messageType: LOGIN,
		data: {
			userID
		}
	};
	postMessage(message);
};
