import React from 'react';
import { navigateToExternal } from '../../services/navigate/navigate';
import config from '../../services/config/config';


//////////////////


const PreAuthDisclaimer = React.createClass({
    buildDefault() {
        const { checkout } = this.props;

        if (checkout.paymentMethod.type) {
            switch (checkout.paymentMethod.type) {
                case 'KLARNA':
                    switch (checkout.paymentMethod.klarnaType) {
                        case 'pay_over_time':
                            return `Once you've have selected to pay via Klarna Pay in 3, you will be redirected to Klarna to confirm your details and accept Klarna’s T&Cs – you must be 18+. Once you have placed your order, you will be redirected back to ${config.appDisplayName} and payment for your first instalment will be held by Klarna. You will have committed to purchasing the product if you win. You'll receive an email to confirm your entry and also after the draw closes to confirm if you have been successful or unsuccessful. If successful, payment will be taken via the chosen payment method and the product will be sent via your chosen delivery method. If unsuccessful, your held payment will be released, after the draw has ended which may take 3-5 days depending on your bank. `
                        case 'pay_later':
                            return `Once you've confirmed your payment via Klarna Pay in 30 days, you will be redirected to Klarna to confirm your details and accept Klarna’s T&Cs – you must be 18+. Once you have placed your order, you will be redirected back to ${config.appDisplayName} and you will have committed to purchasing the product if you win. Neither Klarna nor us will place a pre-authorisation hold on your payment card. You'll receive an email to confirm your entry and also after the draw closes to confirm if you have been successful or unsuccessful. If you win, the product will be sent via your chosen delivery method and payment will be taken by Klarna 30 days after dispatch. If unsuccessful, the commitment will be cancelled through Klarna after the draw has ended.  `
                        default:
                            return ``
                    }
                case 'CARD':
                case 'RECURRING':
                    return `Once you've clicked “Enter”, your payment will be held and you have committed to purchasing the product if you win. You'll receive an email to confirm your entry and also after the draw closes to confirm if you have been successful or unsuccessful. If you win, payment will be taken and the product will be sent via your chosen delivery method, after the draw has closed. If unsuccessful, your held payment will be released after the draw has ended which may take 3-5 days depending on your bank. `
                default:
                    return ``
            }
        }
    },
    render() {
        const { product, checkout, toggleCheckoutConfirmation } = this.props;
        return (
            <div className="check-option no-bold preAuthConfirm">
                <span>{
                    product.charity ?
                        <span>
                            {product.tandc ? product.tandc + " " : null}
                            <span>
                                See full T&Cs <span className='link' onClick={() => navigateToExternal(`${config.storeUrl}${config.raffleDisclaimerPath}`)}>{`here`}.</span>
                            </span>
                            <span> We will use your information in accordance with our <span className='link' onClick={() => navigateToExternal(`${config.storeUrl}customer-service/privacy/`)}>privacy policy</span>.</span>
                        </span> : checkout.paymentMethod.type ?
                            <span>
                                {this.buildDefault()}
                                See full T&Cs <span className='link' onClick={() => navigateToExternal(`${config.storeUrl}${config.raffleDisclaimerPath}`)}>{`here`}.</span>
                                <span> We will use your information in accordance with our <span className='link' onClick={() => navigateToExternal(`${config.storeUrl}customer-service/privacy/`)}>privacy policy</span>.</span>
                                {checkout.paymentMethod.klarnaType === 'pay_over_time' || checkout.paymentMethod.klarnaType === 'pay_later' ? <div className="disclaimer">18+, T&Cs apply, Late payment fees apply, Credit subject to status.</div> : ''}
                                {config.supportsMembership ?
                                    <div>
                                        <br />
                                        All entries have an equal chance of winning but size?access members automatically have: 3 entries for each draw; or 5 entries for each draw where members have size?access+ status. Entrants can only enter each draw once and only ever have a maximum of 5 entries in each draw. size?access membership is free, <span className='link' onClick={() => navigateToExternal(`https://m.size.co.uk/customer-service/sizeaccess/`)}>click here</span> for details.
                                        All purchases are subject to the size? <span className='link' onClick={() => navigateToExternal(`https://www.size.co.uk/customer-service/terms/`)}>Terms and Conditions: Online Sale of Goods</span>.
                                        You must be 18+ and can only win one prize per draw.
                                    </div> : null}
                            </span> : null
                }
                </span>
                {checkout.paymentMethod.type ?
                    <span>
                        <input type="checkbox" id="preAuthConfirm" checked={checkout.checkoutConfirmation} onChange={toggleCheckoutConfirmation} />
                        <label htmlFor="preAuthConfirm">
                            <span className="check-box tick"></span>
                            <span className="name">Accept</span>
                        </label>
                    </span> : null
                }
            </div>
        );
    }
});

export default PreAuthDisclaimer