import React from 'react';
import Slider from './slider/Slider'
import Page from './page/Page'



const PageWrapper = ({ page, pageIndex }) => {
    if (!page) {
        console.warn('Page is missing');
        return null;
    }

    switch (page.type) {
        case 'page-slider':
            return <Slider content={page.content} pageID={page.id} loop={page.loop} />;
        case 'page-page':
            return <Page name={page.name} content={page.content} pageID={page.id} />;
        default:
            console.error('Unknown content type: ' + page.type);
            return null;
    }
}


export default PageWrapper