import { hasDatePassed } from "../time/time";

/**
 * getProductFromProducts
 * 
 * @description searches an array of products for a product by SKU
 * 
 * @param [string] sku 
 * @param [array] products
 * @return [object] product
 */
export function getProductFromProducts(ID, products){
	return products.find((el) => el.ID === ID);
}

/**
 * getProductFromProductsByOptionID
 * 
 * @description searches an arrary of products for a product that matches the optionID (child SKU)
 * provided. Might sound a bit mad but saves an API call
 * 
 * @param [string] optionID
 * @param [array] products
 * @return [object] product
 */
export function getProductFromProductsByOptionID(optionID, products){
	return products.find((product) => {
    let isProduct = false;
    product.options.forEach((option, i) => {
      if(option.optionID === optionID ){
        isProduct = true;
      }
    });
    return isProduct;
  });
}


/**
 * getOptionByOptionID
 * 
 * @description searchs a product's options for an option
 * 
 * @param [string] optionID
 * @param [object] product
 * @return [object] option
 */
export function getOptionByOptionID(optionID, product){
    return product.options.find((option, i) => {
        return option.optionID === optionID;
    });
}


/**
 * isStatusAvailable
 * 
 * @description checks the status of a product to see if its
 * available or not
 * 
 * @param [string] status 
 * @return boolean
 */
export function isStatusAvailable(status){
  let isAvailable;
  switch(status) {
    case 'available':
        isAvailable = true;
        break;
    default:
        isAvailable = false;
  } 
  
  return isAvailable;
}

/**
 * canEnterProduct
 * 
 * @description can we enter/buy a product
 * 
 * @param object product 
 * @return boolean
 */
export function canEnterProduct(product){
  if(!isStatusAvailable(product.status)){
    return false
  }
  if(product.launchType === 'raffle'){
    return hasDatePassed(product.launchStartDate) && !hasDatePassed(product.launchDate)
  }else{
    return hasDatePassed(product.launchDate)
  }
}

/**
 * isOutOfStock
 * 
 * @description checks the status of a product to see if its
 * out of stock or not
 * 
 * @param [string] status 
 * @return boolean
 */
export function isOutOfStock(status){
  let isOutOfStock = false;

  if(status === 'out-of-stock' || status === 'complete') {
    isOutOfStock = true;
  }
  
  return isOutOfStock;
}
