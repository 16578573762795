import storage from '../storage/storage';

const BOOKMARKS_KEY = 'bookmarks';


//////////////////
// public


/**
 * Adds a bookmark
 * @param {string} productID ID of product to bookmark
 */
export function addBookmark(productID) {
	let bookmarks = getBookmarks();
	if (bookmarks.indexOf(productID) === -1) {
		bookmarks.push(productID);
		setBookmarks(bookmarks);
	}
	return bookmarks;
};

/**
 * Deletes all bookmarks
 */
export function clearBookmarks() {
	storage.removeItem(BOOKMARKS_KEY);
};

/**
 * Gets all of the users bookmarks
 * @returns {Array.<String>}
 */
export function getBookmarks() {
	let bookmarks = storage.getItemNoExpiry(BOOKMARKS_KEY);
	bookmarks = bookmarks ? bookmarks.split(',') : [];
	return bookmarks;
}

/**
 * Determines if the user has a product bookmark
 * @param {string} productID ID of product to check
 * @returns {boolean}
 */
export function hasBookmark(productID) {
	let bookmarks = getBookmarks();
	return (bookmarks.indexOf(productID) > -1);
};

/**
 * Removes a bookmark
 * @param {string} productID ID of product to unbookmark
 */
export function removeBookmark(productID) {
	let bookmarks = getBookmarks();
	let ind = bookmarks.indexOf(productID);
	if (ind !== -1) {
		bookmarks.splice(ind, 1);
		setBookmarks(bookmarks);
	}
};


//////////////////
// private


/**
 * Sets the users bookmarks
 * @param {Array.<String>} bookmarks
 */
function setBookmarks(bookmarks) {
	let str = bookmarks.length ? bookmarks.join(',') : '';
	storage.setItemNoExpiry(BOOKMARKS_KEY, str);
}
