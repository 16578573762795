/**
 * Header Actions
 * 
 * Here you'll find all the action creators & actions for managing the Header state. Async
 * actions (using redux-thunk) are at the bottom. 
 * 
 */

export const TOGGLE_MINI_CART = 'TOGGLE_MINI_CART';
/**
 * toggleMiniCart
 * 
 * @param [boolean] display
 * @return [object] header action
 */
export function toggleMiniCart(display){
    return {
        type: TOGGLE_MINI_CART,
        display
    }
}

