import React, { PropTypes } from 'react';
import config from '../../services/config/config'


import './Sidebar.scss';


//////////////////


const SidebarItem = ({ clickHandler, label, itemType, path, active, row, image }) => (
	<span className={`row-${row}`}>
		<button onClick={clickHandler ? () => clickHandler() : null}>
			<div className={`nav-item-${itemType} ${active ? "active" : "inactive"}`}>
				{image ? <img alt={label} src={image} /> : null}
				{config.layout.nav.showLableForImage || !image  ? <lable className="lable">{mapLable(label)}</lable>: null}

			</div>
		</button>
	</span>
);

function mapLable(lable){
	if(!config.layout.nav.lableMap){
		return lable;
	}
	return config.layout.nav.lableMap[lable] || lable

}

SidebarItem.defaultProps = {
	label: '',
	active: false,
	path: '',
};

SidebarItem.propTypes = {
	label: PropTypes.string.isRequired,
	active: PropTypes.bool.isRequired,
	path: PropTypes.string.isRequired,
};


//////////////////


export default SidebarItem;
