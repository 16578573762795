import { UPDATE_SESSION, SET_SESSION, CLEAR_SESSION } from '../actions/session';
import { PAYMENT_START, PAYMENT_SUCCESS, PAYMENT_FAILURE } from '../actions/payment';
import { ADD_TO_BASKET_SUCCESS } from '../actions/product';
import { SELECT_DELIVERY_METHOD, PRE_ORDER_SUCCESS, KLARNA_START_SUCCESS } from '../actions/checkout';

const initialState = () => ({
	stock: [],
	subTotal: {},
	preOrder: null
});

export const session = (state = initialState(), action) => {
	switch (action.type) {
		case UPDATE_SESSION:
			return Object.assign({}, state, action.session)
		case ADD_TO_BASKET_SUCCESS:
		case SET_SESSION:
			return Object.assign({}, initialState(), { ID: action.sessionID })
		case SELECT_DELIVERY_METHOD:
			let total = {
				currency: state.subTotal.currency,
				amount: (parseFloat(state.subTotal.amount) + parseFloat(action.deliveryMethod.price.amount)).toFixed(2).toString()
			}
			return Object.assign({}, state, { total });
		case CLEAR_SESSION:
			return Object.assign({}, initialState(), { preOrder: state.preOrder});
		case PAYMENT_START:
			return Object.assign({}, state, { pause: true });
		case PAYMENT_SUCCESS:
		case PAYMENT_FAILURE:
			return Object.assign({}, state, { pause: false });
		case PRE_ORDER_SUCCESS:
			return Object.assign({}, state, { preOrder: action.order });
		case KLARNA_START_SUCCESS:
			return Object.assign({}, state, { klarna: action.klarnaPaymentResponse });
		default:
			return state;
	}
}

export default session;