/**
 * androidPostMessage
 * 
 * @param {object} payload
 * @return {Promise}
 */
function androidPostMessage(payload){
     return new Promise(function(resolve, reject){   
        let response = window.Android.postMessage(JSON.stringify(payload));
        resolve(response);
    });
}

export default androidPostMessage;