import config from '../config/config';
import { EVTS } from './analytics'


/**
 *
 */
export function analyticsInit() {
	includeHeroChat();
}

export function trackProductAction(action, product) {
	if (typeof action === 'object') {
		action = action.hero
	}
	if (window.hero) {
		window.hero("track", {
			type: action,
			products: [
				{
					id: product.ID,
					name: product.name,
					image: product.mainImage.original,
					price: parseFloat(product.price.amount),
					currency: product.price.currency,
					quanity: 1, //Ignored by PRODUCT_VIEW
					basketQuanity: action === EVTS.ACT.PRODUCT_ADD.hero ? 1 : 0 //Ignored by PRODUCT_VIEW
				}
			]
		});
	}
}

/**
 *
 * @param {*} order
 */
export function trackOrder(order) {
	//Hero Chat tracking
	if (window.hero) {
		window.hero("track", {
			type: "ecommerce:purchase",
			purchase: {
				id: order.sessionID,
				subtotal: parseFloat(order.subTotal.amount),
				currency: order.total.currency,
				total: parseFloat(order.total.amount),
				shippingCost: parseFloat(order.deliveryMethod.price.amount),
				shippingMethod: order.deliveryMethod.clientID,
			},
			products: order.stock.map((product) => {
				return {
					id: product.ID,
					quantity: 1,
					name: product.product.name,
					image: product.product.mainImage.original,
					price: parseFloat(product.product.price.amount),
					currency: product.product.price.currency,
				}
			})
		});
	}
}


/**
 *
 * @param {*} order
 */
export function trackPreAuth(product, order) {
	//Hero Chat tracking
	if (window.hero) {
		window.hero("track", {
			type: "ecommerce:purchase",
			purchase: {
				id: order.orderID,
				subtotal: parseFloat(order.subTotal.amount),
				currency: order.total.currency,
				total: parseFloat(order.total.amount),
				shippingCost: parseFloat(order.deliveryMethod.price.amount),
				shippingMethod: order.deliveryMethod.clientID,
			},
			products: [
				{
					id: product.ID,
					quantity: 1,
					name: product.name,
					image: product.mainImage.original,
					price: parseFloat(product.price.amount),
					currency: product.price.currency,
				}
			]
		});
	}
}

function includeHeroChat() {
	if(!config.heroChatID){
		return;
	}
	window.HeroWebPluginSettings = {
		applicationId: config.heroChatID,
		targetName: "speak-to-stylist"
	};
	//eslint-disable-next-line
	(function (i, a, m, h, e, r, o) { i.HeroObject = e; i[e] = i[e] || function () { (i[e].q = i[e].q || []).push(arguments) }, i[e].l = 1 * new Date; r = a.createElement(m), o = a.getElementsByTagName(m)[0]; r.async = 1; r.src = h; o.parentNode.insertBefore(r, o) })(window, document, "script", "https://cdn.usehero.com/loader.js", "hero");
}
