import React, { Component } from "react";


export default class Stickers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            badges: null
        };
    }
    collectedBadges = this.props.loyalty.badges;

    componentDidMount() {
        const collectedBadges = this.props.loyalty.badges;
        const badgesArray = this.props.content.badges;
        const badges = badgesArray.filter((item, index) => {
            const arrayy = Object.keys(collectedBadges)
            const truthy = arrayy.find(badge => badge === item.id);
            if (truthy) {
                return item
            } else {
                return "none"
            }
        })
        this.setState({ badges: badges })
    }

    render() {

        return (
            <div>
                <div className="achievements_title">
                    Stickers
                    <div className="title_divider" />
                </div>
                <div className="stickers_container">
                    {this.state.badges && this.state.badges.map((item, index) => (
                        <div className="sticker_container" key={index} style={this.collectedBadges[item.id] ? {} : { display: "none" }}>
                            <div key={index} className="sticker">
                                <img src={item.image} alt={item.id} />
                            </div>
                            <div className="sticker_win_count">x{this.collectedBadges[item.id] || "0"}</div>
                        </div>
                    ))
                    }
                    {
                        Object.keys(this.collectedBadges).length < 1 &&
                        <div className="win_counter">0</div>
                    }
                </div>
            </div>
        );
    }
}

