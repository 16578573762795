/**
 * AddressForm
 *
 * Presentational component for address forms. This is used on the checkout flow for both delivery and billing.
 * Inputs are controlled, so initial state should be passed in via props.
 *
 */

import React, { PropTypes } from 'react';
import LocaleSelector from '../localeSelector/LocaleSelector';
import TextField from '../textInput/TextInput';
import { noPostcodeLocales } from '../../services/checkout/checkout';
import config from "../../services/config/config";

const AddressForm = ({ address, collapsed, locales, updateAddress }) => {
	const handleSubmit = (e) => e.preventDefault();

	const handleAddressOneChange = (e) => updateAddress({ address1: e.target.value });
	const handleAddressTwoChange = (e) => updateAddress({ address2: e.target.value });
	const handlePostcodeChange = (e) => updateAddress({ postcode: e.target.value });
	const handleFirstNameChange = (e) => updateAddress({ firstName: e.target.value });
	const handleLastNameChange = (e) => updateAddress({ lastName: e.target.value });
	const handleLocaleChange = function(locale){
		updateAddress({ postcode: '' });
		updateAddress({ locale })
	};
	const handleCountyChange = (e) => updateAddress({ county: e.target.value });
	const handleTownChange = (e) => updateAddress({ town: e.target.value });

	let countryLabel = address.locale === 'gb' || !address.locale ? 'County': 'State';
	let postcodeLabel = address.locale === 'us' ? 'Zip code' : 'Postcode';

	if (collapsed && address) {
		let country;
		if (address.locale && locales && locales.length) {
			for (let i = 0, len = locales.length; i < len; i++) {
				if (locales[i].locale === address.locale) country = locales[i].name;
			}
		}

		return (
			<form className="form-horizontal" onSubmit={evt => evt.preventDefault()}>
				<p>
					{address.firstName}&nbsp;{address.lastName}
					{address.address1 ? <span>,<br />{address.address1}</span> : null}
					{address.address2 ? <span>,<br />{address.address2}</span> : null}
					{address.town ? <span>,<br />{address.town}</span> : null}
					{address.county ? <span>,<br />{address.county}</span> : null}
					{address.postcode ? <span>,<br />{address.postcode}</span> : null}
					{country ? <span>,<br />{country}</span> : null}
				</p>
			</form>
		);
	} else {
		return (
			<form className="form-horizontal" onSubmit={handleSubmit}>
				{locales ? <LocaleSelector locale={address.locale} locales={locales} updateLocale={handleLocaleChange} /> : null}
				<p>(Please use Latin alphabet, any other character may cause delays in delivery)</p>
				<div className="form-group">
					<TextField
						type="text"
						name="name"
						floatingLabelText="First name"
						value={address.firstName}
						onChange={handleFirstNameChange}
						required={true}
						fullWidth={true}
						placeholder="Your first name"
					/>
				</div>
				<div className="form-group">
					<TextField
						type="text"
						name="name"
						floatingLabelText="Surname"
						value={address.lastName}
						onChange={handleLastNameChange}
						required={true}
						hintText="Please enter your  surname"
						fullWidth={true}
					/>
				</div>
				<div className='form-group'>
					<TextField
						type="text"
						floatingLabelText="Address Line 1"
						name="address1"
						value={address.address1}
						onChange={handleAddressOneChange}
						required={true}
						hintText="Please provide your address"
						fullWidth={true}
						maxLength={config.validation.address.maxLength}
					/>
				</div>
				<div className='form-group'>
					<TextField
						type="text"
						name="address2"
						floatingLabelText="Address Line 2"
						value={address.address2}
						onChange={handleAddressTwoChange}
						hintText="Optionally supply more address details"
						fullWidth={true}
						maxLength={config.validation.address.maxLength}
					/>
				</div>
				<div className='form-group'>
					<TextField
						type="text"
						name="town"
						floatingLabelText="Town"
						value={address.town}
						onChange={handleTownChange}
						hintText="Optionally supply your town"
						fullWidth={true}
						required={true}
						maxLength={config.validation.address.maxLength}
					/>
				</div>
				<div className='form-group'>
					<TextField
						type="text"
						name="county"
						floatingLabelText={countryLabel}
						value={address.county}
						onChange={handleCountyChange}
						hintText="Please provide your county"
						fullWidth={true}
						maxLength={config.validation.address.maxLength}
					/>
				</div>
				<div className='form-group postcode'>
					<TextField
						type="text"
						name="postcode"
						floatingLabelText={postcodeLabel}
						value={address.postcode}
						onChange={handlePostcodeChange}
						required={noPostcodeLocales.indexOf(address.locale) === -1}
						hintText="Please provide your postcode"
						locale={address.locale}
						maxLength={config.validation.address.maxLength}
					/>
				</div>
			</form>
		);
	}
};

AddressForm.propTypes = {
	address: PropTypes.object.isRequired
};

AddressForm.defaultProps = {
	address: {
		address1: "",
		address2: "",
		postcode: "",
		phone: ""
	}
};

export default AddressForm;
