/**
 * localPostMessage
 * 
 * @param {object} payload
 * @return {Promise}
 */
function localPostMessage(payload) {
    return new Promise(function (resolve, reject) {
        switch (payload.messageType) {
            case "navigate-to-external":
                window.open(payload.data.url, '_blank').focus();
                break;
            default:
                break;
        }
        return resolve(true);
    });
}

export default localPostMessage;