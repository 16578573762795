import React from 'react';
import ReactDOM from 'react-dom';
import isMobilePhone from 'validator/lib/isMobilePhone';
import isEmail from 'validator/lib/isEmail';
import isNumeric from 'validator/lib/isNumeric';
import isLength from 'validator/lib/isLength';
import { getScrollElement, resetInlineStyles, scrollElementIntoView } from '../../services/ui/ui';
import './TextInput.scss';
import config from '../../services/config/config';
import { validatePostcode } from '../../services/checkout/checkout.js';

let userAgent = window.navigator.userAgent.toLowerCase();
let ios = /iphone|ipod|ipad/.test(userAgent);
let android = /Android/i.test(userAgent);
let deviceClass = android ? 'android' : ios ? 'ios' : '';

/**
 * validateInput
 * @param {string|number} input value
 * @param {string} input name
 * @param {boolean} required by form
 * @param {string|number} input maxEntries
 * @returns {boolean}
 */
const validateInput = (value, name, required, locale = null, maxEntries = null) => {
	let valid;
	if (!required && !value) {
		return undefined;
	}
	else if (required && (!value || 0 === value.length)) {
		return false;
	}
	let latinRegex = /^[A-z\u00C0-\u00ff\s'.,-/#!@"+$%^&*;:{}=\-_`~()0-9]+$/;
	let alphabetRegex = /^[a-zA-Z\s]*$/;
	let addressRegex = /^(?!.*[-/]{2,})[a-zA-Z0-9\s/-]*$/;

	if (!latinRegex.test(value)) {
		return false;
	}

	switch (name) {
		case 'email':
			valid = isEmail(value);
			break;
		case 'phone':
			valid = isMobilePhone(value) || (isNumeric(value) && isLength(value, { max: 14, min: 7 }));
			break;
		case 'number':
			break;
		case 'name':
		case 'county':
			valid = alphabetRegex.test(value) && isLength(value, { min: 1 });
			break;
		case 'address1':
		case 'address2':
		case 'address3':
		case 'town':
			valid = (
				addressRegex.test(value) &&
				typeof config.validation.address.minLength !== 'undefined' &&
				typeof config.validation.address.maxLength !== 'undefined' &&
				isLength(value, { min: config.validation.address.minLength, max: config.validation.address.maxLength })
			);
			break;
		case 'postcode':
			if (locale != null) {
				valid = validatePostcode(value, locale)
			} else {
				//Without a locale, the postcode can never be valid
				valid = false;
			}
			break;
		case 'quantity':
			valid = false;
			if (isNumeric(value) && maxEntries !== null && maxEntries !== '') {
				if (maxEntries >= value) {
					valid = true;
				}
			}
			break;
		default:
			if (value) valid = true;
			break;
	}

	return valid;
};

const TextInput = React.createClass({
	getInitialState() {
		return {
		}
	},

	shouldComponentUpdate(nextProps, nextState) {
		return true;
	},

	componentDidMount() {
		// if theres an initial value passed in e.g. pre-filled input or default value
		// then we need to validate this when the component is first mounted
		if (this.props.value) {
			this.setState({
				valid: validateInput(this.props.value, this.props.name, this.props.required, this.props.locale, this.props.maxEntries)
			});
		}

		//Add an event listener to trigger when the window is resized
		window.addEventListener("resize", this.onWindowResize);
	},

	componentDidUpdate(prevProps, prevState) {
		// Sends the changed validation status to the parent component.

		if (this.props.isFieldValid && (prevState.valid !== this.state.valid)) {
			// console.log("validating")
			this.props.isFieldValid(this.state.valid);
		}
	},

	componentWillUnmount() {
		window.removeEventListener("resize", this.onWindowResize);
	},

	onWindowResize(e) {
		//This check is brittle because it depends on the input being the first child of the inputWrapper.
		//Done this way because the active element is the input box, and not the wrapper. 
		if (document.activeElement === ReactDOM.findDOMNode(this.inputWrapper).firstChild) {
			scrollElementIntoView(this.inputWrapper);
		}
	},

	onChange(e) {
		if (this.typingTimeout) clearTimeout(this.typingTimeout);
		let value = e.target.value;
		let { onChange } = this.props;
		// console.log("changed")
		// console.log(value + " ||| " + this.props.name + " ||| " + this.props.required)
		if (typeof this.state.valid === 'undefined') {
			// console.log("undefined validity")
			this.typingTimeobhut = setTimeout(() => {
				this.setState({
					valid: validateInput(value, this.props.name, this.props.required, this.props.locale, this.props.maxEntries)
				});
			}, 700)
		} else {
			// console.log("validity defined")
			this.setState({
				valid: validateInput(value, this.props.name, this.props.required, this.props.locale, this.props.maxEntries)
			});
		}
		// As the value changes run any onChange function that was passed in and
		// validate the input
		if (onChange && typeof (onChange) === 'function' && this.props.type !== 'number') {
			onChange(e);
		}
	},

	onFocus(e) {
		let { onFocus } = this.props;
		if (this.props.forceScrollOnFocus || (!this.props.forceScrollOnFocus && deviceClass === 'android')) scrollElementIntoView(this.inputWrapper);
		if (onFocus && typeof (onFocus) === 'function') {
			onFocus(e);
		}
		if (this.props.isFieldFocused) {
			this.props.isFieldFocused(true)
		}
	},
	onBlur(e) {
		// when the input loses focus, remove our inline style on the content-scroll element
		let value = e.target.value;
		let { onBlur } = this.props;

		if (onBlur && typeof (onBlur) === 'function') {
			onBlur(value);
		}

		let scrollElem = getScrollElement();
		resetInlineStyles(scrollElem);
		if (this.props.isFieldFocused) {
			this.props.isFieldFocused(false)
		}
	},

	render() {
		let { id, floatingLabelText, value, required, type, disabled, size, autoComplete, dataEncryptedName, maxLength, inputClass, fieldName } = this.props;
		value = (value === null) ? '' : value;
		fieldName = (fieldName === null) ? '' : fieldName;
		let wrapperClass = this.state.valid === false ? 'group invalid' : 'group';
		let inputClassWithError = this.state.valid === false && fieldName === 'quantity' ? inputClass + ' invalid' : inputClass;
		type = type || 'text'; //default value
		return (
			<div className={wrapperClass} ref={(inputWrapper) => { this.inputWrapper = inputWrapper; }}>
				<input id={id}
					size={size}
					autoComplete={autoComplete}
					type={type}
					required={required}
					value={value}
					onChange={this.onChange}
					onFocus={this.onFocus}
					onBlur={this.onBlur}
					disabled={disabled}
					placeholder={floatingLabelText}
					data-encrypted-name={dataEncryptedName}
					maxLength={maxLength}
					className={inputClassWithError}
					name={fieldName}
				/>
				<span className="highlight"></span>
				<span className="bar"></span>
				<label>{floatingLabelText}</label>
			</div>
		)
	}
});

export default TextInput;
