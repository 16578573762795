import { hashHistory } from 'react-router';
import { clearPreferencesFromState, getUserPreferences, updateUserPreferences } from '../../../actions/preferences';
import { isUserLoggedIn } from '../../../services/user';
import { connect } from 'react-redux';
import React, { PropTypes } from 'react';
import TitleBlock from '../../titleBlock/TitleBlock';
import TextField from '../../textInput/TextInput';
import './PersonalPreferences.scss';

import { trackPage } from '../../../services/analytics/analytics';

const preferencesKey = 'personal';


//////////////////


const PersonalPreferences = React.createClass({
	defaultProps: {
		preferences: {},
		isFirstNameValid: undefined,
		isLastNameValid: undefined,
	},
	PropTypes: {
		preferences: PropTypes.object.isRequired
	},
	getInitialState: () => ({
		preferences: {}
	}),
	componentWillMount() {
		let { preferences } = this.props;
		this.setState({ preferences: preferences });
		trackPage('preferences/personal');
	},
	backToDashboard() {
		hashHistory.push('/preferences')
	},
	onCancel() {
		this.backToDashboard();
	},
	onSave() {
		let preferences = this.state.preferences;
		this.props.savePreferences(preferencesKey, preferences);
		this.backToDashboard();
	},
	changeProperty(val, property) {
		this.updatePreference(property, val);
	},
	updateValidationStates(isFieldValid, fieldName) {
		// Validation state of individual fields
		if (this.state[fieldName] !== isFieldValid) {
			const stateUpdate = {};
			stateUpdate[fieldName] = isFieldValid;
			this.setState(stateUpdate);
		}
	},
	updatePreferences(newPreferences) {
		let preferences = { ...this.state.preferences }
		Object.assign(preferences, newPreferences)
		this.setState({ preferences: preferences });
	},
	updatePreference(property, val) {
		let newPreferences = {}
		newPreferences[property] = val
		this.updatePreferences(newPreferences)
	},
	isSaveDisabled() {
		let { isFirstNameValid, isLastNameValid } = this.state;

		return !(isFirstNameValid && isLastNameValid);
	},
	render() {
		let { hideButtons, theme } = this.props;
		let { preferences } = this.state

		return (
			<div className="Preferences Personal-Preferences">

				<div className="content-scroll">
					<TitleBlock title="Personal Preferences" />
					<h4>Keep your details up to date</h4>
					<div className="form-horizontal">
						<p className="intro">
							Manage your personal details to access your account and help us contact you about upcoming trainers releases.
						</p>
					</div>
					<form className="form-horizontal" onSubmit={e => e.preventDefault()}>
						<div className="form-group">
							<TextField
								type="text"
								name="name"
								floatingLabelText="First name"
								value={preferences.firstName}
								onChange={evt => this.changeProperty(evt.target.value, 'firstName')}
								required={true}
								hintText="Please enter your first name"
								fullWidth={true}
								isFieldValid={isValid => this.updateValidationStates(isValid, 'isFirstNameValid')}
							/>
						</div>

						<div className="form-group">
							<TextField
								type="text"
								name="name"
								floatingLabelText="Surname"
								value={preferences.lastName}
								onChange={evt => this.changeProperty(evt.target.value, 'lastName')}
								required={true}
								hintText="Please enter your surname"
								fullWidth={true}
								isFieldValid={isValid => this.updateValidationStates(isValid, 'isLastNameValid')}
							/>
						</div>

						<div className="form-group">
							<TextField
								type="text"
								name="email"
								floatingLabelText="Email Address"
								value={preferences.email}
								onChange={evt => this.changeProperty(evt.target.value, 'email')}
								required={true}
								hintText="Please enter email address"
								fullWidth={true}
								disabled={this.props.isLoggedIn}
							/>
						</div>

						<div className="form-group telephone">
							<TextField
								type="tel"
								name="tel"
								floatingLabelText="Mobile Number"
								value={preferences.phone}
								onChange={evt => this.changeProperty(evt.target.value, 'phone')}
								hintText="Please enter your mobile number"
								fullWidth={true} />
						</div>
					</form>
				</div>
				{
					hideButtons
						? null
						: <div className="form-action fixed">
							<div className="inline">
								<div className="action-split">
									<button className="cancel" onClick={this.onCancel}>Cancel</button>
								</div>
								<div className="action-split">
									<button className="action" onClick={this.onSave} style={theme.primaryButton} disabled={this.isSaveDisabled()}>
										<div className="button-contents">Save</div>
									</button>
								</div>
							</div>
						</div>
				}
			</div>
		);
	}
});

const mapStateToProps = state => {
	const { user, content, loading } = state;
	return { preferences: user.preferences.personal, theme: content.theme, loading, isLoggedIn: isUserLoggedIn() };
};

const mapDispatchToProps = dispatch => {
	return {
		clearPreferences: (key) => dispatch(clearPreferencesFromState(key)),
		getPreferences: (key) => dispatch(getUserPreferences(key)),
		savePreferences: (key, data) => dispatch(updateUserPreferences(key, data)),
	};
};


//////////////////


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PersonalPreferences);
