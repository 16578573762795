import React from 'react';
import config from '../../services/config/config';

function withRecaptchaV3(WrappedComponent, action) {
  // ...and returns another component...
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        token: ''
      };

      this.action = action;
      this.componentDidMount = this.componentDidMount.bind(this);
      this.refreshReCaptchaToken = this.refreshReCaptchaToken.bind(this);
    }

    componentDidMount() {
      if (!window.grecaptcha){
        const script = document.createElement("script");
        script.src = `https://www.google.com/recaptcha/api.js?render=${config.reCaptchaKey}`;
        script.async = true;

        script.onload = () => {
          window.grecaptcha.ready(() => {
            this.refreshReCaptchaToken();
          });
        }

        document.body.appendChild(script);
      }
    }

    refreshReCaptchaToken(){
      return window.grecaptcha.execute(config.reCaptchaKey, { action: this.action })
        .then((token) => {  
          //token only lasts 2 minutes, so lets refresh this every
          //90 seconds if the user is waiting around
          if (this.refreshInterval) clearInterval(this.refreshInterval);
          this.refreshInterval = setInterval(() => this.refreshReCaptchaToken(), 90 * 1000);

          return new Promise((resolve) => {
            this.setState({
              token: token
            }, () => resolve(this.state.token));
          });
          
          
        });
    }

    componentWillUnmount(){
      if (this.refreshInterval) clearInterval(this.refreshInterval);
    }


    render() {
      return <WrappedComponent refreshReCaptchaToken={this.refreshReCaptchaToken} reCaptchaToken={this.state.token} {...this.props} />;
    }
  };
}

export default withRecaptchaV3;