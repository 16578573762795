import React from 'react';
import { hashHistory } from 'react-router';
import './Order.scss';
import Price from '../price/Price';
import TitleBlock from '../titleBlock/TitleBlock';
import SocialLinks from './../socialLinks/SocialLinks'

import { trackPage, trackOrder } from '../../services/analytics/analytics';

const Order = React.createClass({
	componentWillMount(){
		trackPage();
		trackOrder(this.props.order);
	},
	componentDidMount(){
		if (this.props.session.ID){
			this.props.clearSession()
		}
	},
	render() {
		let { order } = this.props;
		let orderLines = null;
		if (order.stock) {
			orderLines = order.stock.map((stock, i) =>
				<li key={i}>
					<div className="order-item-image">
					<img src={stock.product.mainImage.original} srcSet={stock.product.mainImage.original.srcSet} alt={stock.product.name} />
                            </div>
                            <div className="order-item-info">
                                <h4>{stock.product.name}</h4>
                                <h4>{stock.product.subTitle}</h4>
                                <div className="bottom-block">
                                    <span>
                                        {stock.product.option.name}
                                    </span>
                                    <span className="price">
                                        <Price amount={stock.product.price.amount} currency={stock.product.price.currency} />
                                    </span>
                                </div>
                            </div>
				</li>
			);
		}

		return (
			<div className="Checkout Order">

				<div className="content-scroll">

					<TitleBlock title="Order Confirmed" />
					<div className="form-horizontal">
						<p>
							Congratulations. Your order has been placed. Please find the details of your order below.
						</p>
					</div>
                    <TitleBlock title={`Order Reference: ${order.sessionID.substring(0,6)}`} />
					<div className="form-horizontal">
						<div className="order-details">
							<h4>Your Details</h4>
							<p>{order.customer.firstName} {order.customer.lastName}</p>
							<p>{order.customer.email}</p>
							<p>{order.customer.phone}</p>
						</div>
						<div className="order-details order-lines">
							<h4>Your Items</h4>
							<ul>
								{orderLines}
							</ul>
						</div>
						<div className="order-details order-totals">
							<h3>Total: <Price amount={order.total.amount} currency={order.total.currency} /></h3>
						</div>
					</div>

				</div>				
				<div className="form-action">
					<SocialLinks />
                        <button className="action" onClick={() => hashHistory.push('/')}>
                            <div className="button-contents">Done</div></button>
                </div>
			</div>


		);
	}
})

export default Order;
