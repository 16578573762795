import React from 'react';
import Price from '../price/Price';
import TitleBlock from '../titleBlock/TitleBlock';
import { getProductFromProductsByOptionID } from '../../services/product/product';

import './CartTotals.scss';

const CartTotals = ({ delivery, products, stock }) => {
	let subtotal = { amount: 0, currency: '' };

	let total = { amount: parseFloat(delivery.amount), currency: delivery.currency };

	for (let i = 0, len = stock.length; i < len; i++) {
		let product = getProductFromProductsByOptionID(stock[i].optionID, products);
		if (!subtotal.currency) subtotal.currency = product.price.currency;
		let quantity = product.charity === true ? stock[i].product.quantityOfEntries : 1;
		subtotal.amount = parseFloat(subtotal.amount) + (parseFloat(product.price.amount) * quantity);
	}
	subtotal.amount = subtotal.amount.toFixed(2);
	total.amount += +subtotal.amount;
	total.amount = parseFloat(total.amount).toFixed(2);

	return (
		<div className="cart-totals">
			<div className="">
				<TitleBlock title="Your Order:" />
				<ul>
					<li className="totals-container">
						<h5 className="totals-split">Sub-total</h5>
						<h5 className="totals-split">
							<Price amount={subtotal.amount} currency={subtotal.currency} />
						</h5>
					</li>
					<li className="totals-container">
						<h5 className="totals-split">Delivery</h5>
						<h5 className="totals-split">
							<Price amount={delivery.amount} currency={delivery.currency} />
						</h5>
					</li>
					<li className="totals-container">
						<h5 className="totals-split">Order Total</h5>
						<h5 className="totals-split">
							<Price amount={total.amount} currency={total.currency} />
						</h5>
					</li>
				</ul>
			</div>
		</div>
	);
};

export default CartTotals;
