import {SESSION_EXPIRED, PAYMENT_FAILED, UNKNOWN_ERROR} from './paymentStatuses';
const PAYMENT_ERROR_CODE_BASE = 'paymentError-';

function createPaymentError(error){
    let paymentError = {
        errorCode: "",
        errorInfo: "",
        paymentErrorCode: ""
    };
    switch(error.status){
        case SESSION_EXPIRED:
        case PAYMENT_FAILED:
        case UNKNOWN_ERROR:
            paymentError.errorCode = `${PAYMENT_ERROR_CODE_BASE}${error.status}`;
            paymentError.errorInfo = `${error.refusalReason}`;
            paymentError.paymentErrorCode = error.errorCode
            return paymentError;
        default: 
            return paymentError;
    }
}

export default createPaymentError;