/**
 * Delivery Types Actions
 *
 * Here you'll find all the action creators & actions for managing the delivery types in the checkout flow. Async
 * actions (using redux-thunk) are at the bottom.
 *
 */
import {getDeliveryMethods} from '../services/api';

export const UPDATE_DELIVERY_TYPES = 'UPDATE_DELIVERY_TYPES';
/**
 * updateDeliveryTypes
 *
 * @param [array] locales
 * @return [object] locales action
 */
export function updateDeliveryTypes(deliveryTypes){
    return {
        type: UPDATE_DELIVERY_TYPES,
        deliveryTypes
    }
}

/****************************************
 * ASYNC ACTION CREATORS
 * (dependent on redux thunk middleware)
 *
 ***************************************/


export const LOAD_DELIVERY_METHODS = 'LOAD_DELIVERY_METHODS';
/**
 * loadDeliveryMethods
 *
 * @param [array] stores
 * @return [object] stores action
 */
export function loadDeliveryMethods(locale){
    return (dispatch) => {
        getDeliveryMethods(locale)
        .then(data => dispatch(updateDeliveryTypes(data.deliverytypes)))
    }
}
