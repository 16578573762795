/**
 * Home Content presentational component
 *
 * This is a presentational component which wraps the home page.
 *
 * Receives props from the container component in containers/HomePage
 *
 */
import React from 'react';
import { connect } from 'react-redux';
import Sidebar from './Sidebar';

import { trackPage } from '../../services/analytics/analytics';
import { filterCampaigns, setIndex } from '../../actions/content';

import { hashHistory } from 'react-router'

import config from '../../services/config/config'

import icnBlog from '../../assets/img/sidebar/icn-blog.png'
import icnPast from '../../assets/img/sidebar/icn-past.png'
import icnBookmarks from '../../assets/img/sidebar/icn-bookmarks.png'
import icnCalendar from '../../assets/img/sidebar/icn-calendar.png'
import icnLaunches from '../../assets/img/sidebar/icn-launches.png'


const Content = React.createClass({
	getInitialState() {
		return {
			expand: false
		}
	},
	componentWillMount() {
		const { sliderIndex } = this.props.content;
		if (this.props.trackPageChanges) {
			this.handleChangeIndex(sliderIndex)
		}
	},
	trackChangeIndex(index, campaignName) {
		const { bookmarkedProducts } = this.props.bookmarks;
		let page = campaignName || 'calendar';

		if (index === 0 && bookmarkedProducts && bookmarkedProducts.length) {
			page = 'bookmark';
		} else if (index === 0) {
			page = 'intro video';
		}

		trackPage(page);
	},
	handleChangeIndex(index) {
		let { content } = this.props;
		let invert = false;
		let campaignName = null;
		let pageHeaderLogo = "";
		let pageTransperentHeader = false;
		this.setState({ expand: false });
		if (index >= 5) {
			try {
				let campaigns = filterCampaigns(content.campaigns.campaigns, this.props.loyalty)
				// console.log(campaigns)
				invert = campaigns[index - 5].invert;

				let homePage = content.campaigns.pages.find((page) => (page.id === campaigns[index - 5].homePage))

				if (homePage) {
					pageHeaderLogo = homePage.image;
					pageTransperentHeader = homePage.transparentHead;
					campaignName = campaigns[index - 5].name;
				} else {
					console.warn('Home page not found for campaign index', index - 5);
					index = 0;
				}
			} catch (e) {
				console.error(e)
				index = 0;
			}
		}
		this.trackChangeIndex(index, campaignName);
		this.props.setIndex(index, invert, index >= 5, pageHeaderLogo, pageTransperentHeader);

		//If were not on the hompage...
		//we want to redirect to it if we are not tracking the users index changes
		if (!this.props.trackPageChanges) {
			hashHistory.push('/');
		}

	},
	toggleExpand() {
		let { expand } = this.state;
		this.setState({ expand: !expand });
	},
	render() {
		let { sliderIndex, invertHeader, campaigns } = this.props.content;
		let content = campaigns;

		let navImages = config.layout.nav.position === "bottom"

		let sidebarItems = [
			{ label: "Blog", image: navImages ? icnBlog : undefined, active: sliderIndex === 4, clickHandler: function () { _this.handleChangeIndex(4) } },
			{ label: "Previous Launches", image: navImages ? icnPast : undefined, active: sliderIndex === 2, clickHandler: function () { _this.handleChangeIndex(2) } },
			{ label: "Calendar", image: navImages ? icnCalendar : undefined, active: sliderIndex === 3, clickHandler: function () { _this.handleChangeIndex(3) } },
			{ label: "Bookmarks", image: navImages ? icnBookmarks : undefined, active: sliderIndex === 1, clickHandler: function () { _this.handleChangeIndex(1) } },
			{ label: "Launches", image: navImages ? icnLaunches : undefined, active: sliderIndex === 0, clickHandler: function () { _this.handleChangeIndex(0) } }
		];

		//Inject Campaign Content
		filterCampaigns(content.campaigns, this.props.loyalty).forEach(campaign => {
			let index = sidebarItems.length;
			sidebarItems.push({ image: campaign.image, label: campaign.displayName, itemType: "Featured", active: sliderIndex === index, clickHandler: function () { _this.handleChangeIndex(index) } });
		});

		//If we're on a campaign that no longer exsists, go home.
		if (sidebarItems.length <= sliderIndex) {
			this.handleChangeIndex(0);
		}

		const _this = this;
		return (
			<div className="Home">
				<Sidebar
					label='Sidebar'
					index={sliderIndex}
					invert={invertHeader}
					items={sidebarItems}
					expand={this.state.expand}
					toggleExpand={this.toggleExpand}
				/>
			</div>
		);
	}
});

const mapStateToProps = state => {
	const { content, bookmarks, user } = state;
	return { content, bookmarks, loyalty: user.preferences.loyalty || { isPremium: false } };
};

const mapDispatchToProps = dispatch => {
	return {
		setIndex: (index, invert, isCampaignSlide, pageHeaderLogo, pageTransperentHeader) => dispatch(setIndex(index, invert, isCampaignSlide, pageHeaderLogo, pageTransperentHeader)),
	}
};

//////////////////

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Content);
