import moment from 'moment';

/**
 * formatTimeForCountdown
 * 
 * Formats a moment durations object into friendly formats and returns
 * days, hours, minutes, seconds 
 * 
 * @param [object] moment Duration
 * @return [object] 
 * 
 * @author Aidan Gee
 */
export function formatTimeForCountdown(momentDuration, showDaysInHours) {

	var seconds = momentDuration.seconds();
	var minutes = momentDuration.minutes();
	var hours = momentDuration.hours();
	var days = momentDuration.days();

	if (showDaysInHours) {
		hours+= days * 24
	}

	seconds = seconds < 10 ? '0' + seconds : seconds;
	minutes = minutes < 10 ? '0' + minutes : minutes;
	hours = hours < (showDaysInHours ? 100 : 10) ? '0' + hours : hours;
	days = days < 10 ? '0' + days : days;

	return {
		days,
		hours,
		minutes,
		seconds
	}
}

/**
 * getTimeRemaining
 * 
 * gets the time in milliseconds between 2 dates. The params can
 * be moment date objects or date strings
 * 
 * @param [object, string] endDate
 * @param [object, string] currentDate
 * @return [object] moment Duration
 * 
 * @author Aidan Gee
 */
export function getTimeRemaining(endDate, currentDate = moment()) {
	if (!moment.isMoment(endDate)) endDate = moment.utc(endDate);
	if (!moment.isMoment(currentDate)) currentDate = moment.utc(currentDate);

	// get the time difference in milliseconds 
	return moment.duration(endDate.diff(currentDate));

}



/**
 * hasDatePassed
 * 
 * @description checks if a date has passed
 * 
 * @param [string] date 
 * @return boolean
 */
export function hasDatePassed(date) {
	let now = +new Date();
	let productDate = +new Date(date);
	return (now > productDate);
	//	let currentDate = moment();
	//	date = moment(date);

	//	return currentDate.diff(date) > 0;
}

/**
 * isLessThanSecondsLeft
 * 
 * @description checks if a time between currentDate and data provides is less than the 
 * seconds provided. For example you could use this function to check if theres less than 
 * 20 seconds left until a data
 * 
 * @param [string] date 
 * @return boolean
 */
export function isLessThanSecondsLeft(date, secondsLeft) {

	let currentDate = moment();
	date = moment(date);
	return currentDate.diff(date) > -(secondsLeft * 1000);
}