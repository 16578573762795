import React from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import { EVTS, trackEvent } from "../../services/analytics/analytics";
import "./Video.scss";

export default class Video extends React.Component {
	componentDidMount() {
		this.player = videojs(this.videoNode, this.props);
		const player = this.player;

		player.on("pause", function () {
			player.bigPlayButton.show();
		});
		player.on("play", function () {
			setTimeout(function () {
				player.bigPlayButton.hide();
			}, 300);
		});
	}
	sendEvent(event) {
		let content = this.props || {};
		let { src } = content;
		trackEvent(EVTS.CAT.INTERACT, event, src)
	}
	componentWillUnmount() {
		if (this.player) {
			this.player.dispose();
		}
	}

	render() {
		let content = this.props || {};
		let { autoPlay, thumbnail, src, preload } = content;
		return (
			<div>
				<div data-vjs-player className="vjs-layout-tiny">
					<video
						ref={node => (this.videoNode = node)}
						autoPlay={autoPlay || false}
						muted={autoPlay || false}
						controls
						poster={thumbnail}
						preload={preload || "none"}
						className="video-js vjs-default-skin vjs-big-play-centered vjs-big-paused-centered"
						data-setup='{"fluid":true, "fullscreenToggle": true}'
						onPause={() => this.sendEvent(EVTS.ACT.PAUSE)} onPlay={() => this.sendEvent(EVTS.ACT.PLAY)} onEnded={() => this.sendEvent(EVTS.ACT.END)}>
						<source src={src} type="video/mp4" />
					</video>
				</div>
			</div>
		);
	}
}
