import React from "react";
import VideoJs from "../../../videoJS/Video.js";
const Video = React.createClass({
	render() {
		let { content } = this.props;
		const videoJsOptions = {
			autoplay: content.autoPlay,
			muted: content.autoPlay,
			poster: content.thumbnail,
			src: content.src,
			preload: "metadata"
		};
		return (
			<div
				className={`video-wrapper ${content.fullwidth ? "full" : "borders"
					}`}
			>
				<VideoJs {...videoJsOptions} />
			</div>
		);
	}
});

export default Video;
