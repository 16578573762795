import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import './MoreInfoPopup.scss'
import logo from '../preferences/membership/logo.svg'
import { hashHistory } from 'react-router'

//////////////////


export default ({ showLearnMore, hideLearnMore, text, closeText }) => {
    return (
        <ReactCSSTransitionGroup
            transitionName="loyalty-info"
            transitionEnterTimeout={200}
            transitionLeaveTimeout={200}
        >
            {showLearnMore ?
                <div className="learnMoreBg">
                    <style>{`.header,.sidebar {display: none;}`}</style>
                    <div className="learnMore">
                        {text === 'SIGNUP' ? <div>
                            <img alt="logo" src={logo} />
                            <span>
                                <p>Did you know you could have 3 entries into this draw instead of 1?</p>
                                <p>Sign up to size?access now to become a member, its FREE and you’ll get 
                                    3 entries for every draw you enter plus loads of other member benefits.</p> 
                                    </span></div>
                            : <span dangerouslySetInnerHTML={{__html: text}}/>}
                        {text === 'SIGNUP' ? <div className="form-action">
                            <button onClick={() => { hashHistory.push('preferences/membership') }} className="action boosted">
                                <div className="button-contents">Sign Up</div>
                            </button>
                        </div> : null}
                        <div className="form-action">
                            <button onClick={hideLearnMore} className={`action${text === 'SIGNUP' ? ' secondery' : ''}`}>
                                <div className="button-contents">{closeText || 'Close'}</div>
                            </button>
                        </div>
                    </div>
                </div> : null
            }
        </ReactCSSTransitionGroup >
    )
};