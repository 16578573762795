/**
 * Product presentational component
 * This is a presentational component which wraps the product defailts page.
 * Receives props from the container component in containers/productPage
 */

// libraries
import React, { PropTypes } from 'react';
import { hashHistory } from 'react-router';
import withRecaptchaV3 from '../recaptcha/recaptcha';


// components
import Price from '../price/Price';
import ProductGallery from '../productGallery/ProductGallery';
import ProductInteractions from './ProductInteractions';
import ProductLaunchInformation from './ProductLaunchInformation';
import VideoJs from '../videoJS/Video.js'
import AudioJs from '../videoJS/Audio.js'


import Collapse, { Panel } from 'rc-collapse';
import BookmarkProductButton from '../../containers/BookmarkButtonContainer';
import ShareButton from '../shareButton/ShareButton';
import LaunchDateMini from '../launchDateMini/LaunchDateMini'
import KlarnaExclusive from '../klarnaExclusive/KlarnaExclusive'

import PreOrder from '../preferences/preOrders/PreOrder';

// utilities
import { hasDatePassed } from '../../services/time/time';
import { isLessThanSecondsLeft } from '../../services/time/time';
import config from '../../services/config/config';
import { trackPage, trackEvent, EVTS, trackProductAction } from '../../services/analytics/analytics';
import { isStatusAvailable, isOutOfStock } from '../../services/product';
import isKlarnaExclusive from '../../services/payment/isKlarnaExclusive'
import getCardActivationDate from '../../services/payment/getCardActivationDate'


// css
import './Product.scss';
import 'rc-collapse/assets/index.css';
import ProductLaunchCountDown from './ProductLaunchCountDown';


/**
 *
 * Component
 *
 */

const Product = React.createClass({

	shouldLoadStock() {
		// If the product is less than 15 seconds away from launching then load stock and
		// start polling
		if (isLessThanSecondsLeft(this.props.product.launchDate, 15) && this.props.product.status === 'available' && this.props.product.launchType !== 'raffle') {
			if (this.lauchInterval) clearInterval(this.lauchInterval);
			this.props.getCurrentStock(this.props.product.ID);
			this.pollForStock();
			return true;
		}
		return false;
	},
	getInitialState() {
		return {
			loading: true
		}
	},

	pollForStock() {
		let { product } = this.props;
		this.stockInterval = setInterval(() => {
			this.props.getCurrentStock(product.ID);
		}, 5000);
	},

	componentWillMount() {
		this.webkitOverflowFix = true;

		const { name, ID, price } = this.props.product;
		const trackProduct = [
			{
				action: 'ec:addProduct',
				args: {
					id: ID,
					name: name || '',
					price: price.amount || 0
				}
			},
			{
				action: 'ec:setAction',
				args: 'detail'
			}
		];
		trackPage(`/product/${name}/${ID}`, trackProduct);
		trackPage(`/product/${name}/${ID}`);
		loadProductData(this.props)
			.then((product) => {
				this.setState({ loading: false })
				trackProductAction(EVTS.ACT.PRODUCT_VIEW, product.data)
				if (!this.shouldLoadStock() && product && this.props.product.status === 'available') {
					this.lauchInterval = setInterval(() => {
						this.shouldLoadStock();
					}, 1000);
				}
				this.props.selectQuantityOfEntries(1);
			})
			.catch((error) => {
				console.log(error)
				console.log('no product found!!');
				hashHistory.push('/');
			});
		//If were deep linking into the app (from the widget etc.) Then there will be no history.
		//So when users click the back button, nothing happens, to fix this we create a fake history for the user. 
		if (window.history.length === 1) {
			let location = window.location.href
			//Replace the current history entry with the home page
			window.history.replaceState({}, 'Home', '/')
			//Create a new event at the top of the history stack for the current page.
			window.history.pushState({}, 'Product', location)
			//We now have a history with 2 pages in it ['home','product']
		}
	},

	componentWillUnmount() {
		if (this.lauchInterval) clearInterval(this.lauchInterval);
		if (this.stockInterval) clearInterval(this.stockInterval);
	},

	addToBasket() {
		let { product, handleAddToBasket, session } = this.props;
		let { loading } = this.props.loading;

		const trackProduct = [
			{
				action: 'ec:addProduct',
				args: {
					id: product.ID,
					name: product.name || '',
					price: product.price.amount || 0,
					quantity: 1
				}
			},
			{
				action: 'ec:setAction',
				args: 'add'
			}
		];

		function track() {
			trackEvent(EVTS.CAT.INTERACT, 'click', EVTS.ACT.PRODUCT_ADD, null, trackProduct);
			trackProductAction(EVTS.ACT.PRODUCT_ADD, product)
		}
		// refresh the token and submit
		this.props.refreshReCaptchaToken()
			.then((verification) => {
				if (!loading) handleAddToBasket(product.ID, product.sizeSelected, verification, session, track);
			});


	},

	goToPreAuth(sku, answer = false, isBoosted = false) {
		//Clear session of any full launch sessions so avoid visual glitches
		this.props.clearSession();
		if (isBoosted) {
			localStorage.setItem('isBoosted', 'true');
		} else {
			localStorage.removeItem('isBoosted')
		}
		if (answer === false) {
			hashHistory.push(`/checkout/${sku}/preAuth`);
			return
		} else {
			hashHistory.push(`/checkout/${sku}/preAuth/${answer}`);
			return
		}
	},

	getInfoBlocks(informationBlocks) {
		let informationBlockPanels = informationBlocks.map((block, i) => {
			return (
				<Panel header={block.displayName} key={i}>
					<p dangerouslySetInnerHTML={createMarkup(block.content)}></p>
				</Panel>
			)
		});

		return informationBlockPanels;
	},
	findSizeGuide(id) {
		let { content } = this.props;
		return content.sizeGuides.find(sizeGuide => sizeGuide.id === id)
	},
	safariWebkitOverflowFix(product) {

		this.launchReload = (product.launchType !== 'monthOnly' && hasDatePassed(product.launchDate) && this.webkitOverflowFix) ? 'reload' : '';
		if (this.webkitOverflowFix) {
			setTimeout(() => {
				if (this.launchReload) {
					this.launchReload = '';
					this.webkitOverflowFix = false;
					this.forceUpdate();
				}
			}, 100)
		}
	},

	render() {
		let { product, selectSize, selectQuantityOfEntries, launchProduct, verificationRecieved, theme } = this.props;
		let { loading } = this.state;
		if (loading) {
			return <div></div>
		}
		// console.log("🚀 ~ file: Product.js:217 ~ render ~ product:", product)

		let dateFormat = getDateFormatForLaunchType(product.launchType),
			infoBlocks = this.getInfoBlocks(product.informationBlocks);

		const videoContent = product.video || {};
		const videoJsOptions = {
			poster: videoContent.thumbnail,
			src: videoContent.original,
			preload: "metadata"
		};
		// prevents a layout issue in safari webview
		this.safariWebkitOverflowFix(product);

		let sizeGuide = product.sizeGuide ? this.findSizeGuide(product.sizeGuide) : null;
		return (
			<div className={`Product ${this.launchReload}`}>
				<style>{`#hero-iframe-container{ display: initial !important; }`}</style>
				<div className="content-scroll">
					<div className="content-scroll-fix">
						<div className="imageWrapper">
							{product.images.length && product.loaded ? <ProductGallery images={product.images} /> : null}
							{product.launchDate && config.layout.pdp.miniLaunchDate ? <LaunchDateMini launchDate={product.launchDate}></LaunchDateMini> : null}
							<KlarnaExclusive product={product} />
						</div>
						<div className="product-details">
							<div className="inlineContainer">
								<div className="left">
									<div className="product-details-header">
										<div className="titleBlock">
											<span className="title">{product.name}</span>
											<span className="subTitle">{product.subTitle}</span>
										</div>
										<span className="priceBlock">
											<Price className='price' currency={product.price.currency} amount={product.price.amount} />
										</span>
									</div>
									<ProductLaunchInformation
										isAvailable={isStatusAvailable(product.status)}
										launchDate={product.launchDate}
										launchStartDate={product.launchStartDate}
										launchProduct={launchProduct}
										dateFormat={dateFormat}
										launchType={product.launchType}
									></ProductLaunchInformation>
								</div>
								<div className="right">
									<div className="buttons">
										<span className="actions">
											<ShareButton product={product} type="product" />
											<BookmarkProductButton productID={product.ID} name={product.name} launchDate={product.launchDate} />
										</span>
									</div>
								</div>
							</div>
							<ProductLaunchCountDown
								isAvailable={isStatusAvailable(product.status)}
								launchDate={product.launchDate}
								launchStartDate={product.launchStartDate}
								launchProduct={launchProduct}
								dateFormat={dateFormat}
								launchType={product.launchType}
							></ProductLaunchCountDown>
							{
								product.status === 'archived' || (product.entryData && !product.entryData.canEnter) ?
									product.charity ?
										<div className='charity-out-of-entries'>
											<h3>You have reached the maximum number of entries for this draw.</h3>
											Check back when the draw closes to see if you're a winner!
											<br />
										</div> : null
									: <div>
										<ProductInteractions
											addToBasket={this.addToBasket}
											isAvailable={isStatusAvailable(product.status)}
											buttonStyles={theme.primaryButton}
											launchDate={product.launchDate}
											launchStartDate={product.launchStartDate}
											options={product.options.options}
											recaptchaKey={config.recaptchaKey}
											selectSize={selectSize}
											quantityOfEntries={selectQuantityOfEntries}
											verification={product.verification}
											verificationRecieved={verificationRecieved}
											launchType={product.launchType}
											outOfStock={isOutOfStock(product.status)}
											sizeSelected={product.sizeSelected}
											goToPreAuth={this.goToPreAuth}
											question={product.question}
											charity={product.charity}
											answers={product.answers}
											isTimedKlarnaExclusive={isKlarnaExclusive(product) && product.paymentGateways.includes('Adyen')}
											timeToCard={getCardActivationDate(product)}
											price={product.price}
											paymentGateways={product.paymentGateways}
											maxQuantity={product.entryLimit}
											quantity={product.quantityOfEntries}
											quantityOfPreviousEntries={product.entryData.quantityOfPreviousEntries}
										/>
									</div>
							}
							{product.entryData && product.entryData.status ? <div>
								<div className="underlinedTitle">Draw Entry</div>
								<PreOrder PreOrder={product.entryData} />
							</div> : null}
							<div>
								<div className="underlinedTitle">Details</div>
								<div className="description" dangerouslySetInnerHTML={createMarkup(product.description)}></div>
							</div>
							{
								product.blogCopy ? <div><div className="underlinedTitle">Blog</div><div className="blogCopy" dangerouslySetInnerHTML={createMarkup(product.blogCopy)}></div></div>
									: null
							}
							{
								product.audio
									? <span className="productAudio">
										<div className="underlinedTitle">Audio</div>
										<AudioJs {...{
											src: product.audio.original,
											preload: "metadata",
											controls: true,
											inactivityTimeout: 0,
											controlBar: {
												fullscreenToggle: false,
												pictureInPictureToggle: false
											}
										}} />
									</span>
									: null
							}
							{
								product.video
									? <span className="productVideo">
										<div className="underlinedTitle">Watch the Video</div>
										<VideoJs {...videoJsOptions} />
									</span>
									: null
							}
							<Collapse accordion={true} className="infoBlocks">
								{sizeGuide ?
									<Panel header={"Size Guide"} key={"size-guide"}>
										<div className="sizeGuide" dangerouslySetInnerHTML={createMarkup(sizeGuide.value)} />
									</Panel>
									: null
								}
								{infoBlocks}
							</Collapse>
						</div>
					</div>
				</div>
			</div>
		);
	}
});

Product.propTypes = {
	params: PropTypes.shape({
		sku: PropTypes.string.isRequired
	})
}

/**
 *
 * Helper functions
 *
 */

/**
 * Returns the date format based on the type of launch
 *
 * @param {String} launchType
 * @return {String} dataFormat
 */
function getDateFormatForLaunchType(launchType) {
	let dateFormat;

	switch (launchType) {
		case 'monthOnly':
			dateFormat = "MMMM YYYY"
			break;
		case 'raffle':
			dateFormat = "Do MMM / HH:mm"
			break;
		default:
			break;
	}
	return dateFormat;
}

/**
 *
 * @param {Object} props
 * @return {Promise}
 */
function loadProductData({ params, loadProduct }) {
	return loadProduct(params.sku);
}

/**
 * Returns html in the correct format to set dangerously
 * with react
 *
 * @param {String} html
 * @return {Object}
 */
function createMarkup(html) {
	return { __html: html };
}

/**
 *
 * Exports
 *
 */

export default withRecaptchaV3(Product, 'Product');
