import { OVERRIDDEN_POSTCODE_REGEX } from '../../services/config/config';
import isPostalCode from 'validator/lib/isPostalCode';
import isNumeric from 'validator/lib/isNumeric';
import isEmail from 'validator/lib/isEmail';


let checkoutPreferencesMapping; //defined in private block to keep exports tidy

/**
 * Locales that don't require a postcode - copied from instore-core (which was itself copied from website-app)
 * so might not be complete
 */
export const noPostcodeLocales = ['ae', 'ag', 'ao', 'aw', 'bf', 'bi', 'bs', 'bv', 'bw', 'bz', 'cg', 'ck', 'cm', 'cy', 'dj', 'dm', 'eh', 'er', 'fj', 'ga', 'gm', 'gq', 'gw', 'gy', 'hk', 'ie', 'ki', 'km', 'kn', 'lc', 'mr', 'ms', 'mu', 'mw', 'nr', 'nu', 'qa', 'rw', 'sb', 'sc', 'sj', 'sl', 'so', 'sr', 'st', 'tf', 'tg', 'tk', 'to', 'tv', 'tz', 'ug', 'vu', 'ye', 'zw'];

/**
 * Locales that require the county field
 */
// seem to remember something about it being necessary for the US for paypal
// so even though we don't have paypal in the app, there's a chance this might be needed for something in the
// future, hence why it is here
export const requiredCountyLocales = [];

export function validatePostcode(postcode, locale) {

	//If the postcode ends with a space, its definaly invalid
	if (!postcode || postcode.endsWith(" ")) {
		return false;
	}

	//Some locales such as gb are too lenient in there validation when using 'isPostalCode' method of validate.js,
	//letting through postcode like 'WF2' for example.
	//Here we can override them.
	if (locale.toUpperCase() in OVERRIDDEN_POSTCODE_REGEX) {
		return OVERRIDDEN_POSTCODE_REGEX[locale.toUpperCase()].test(postcode);
	} else {
		try {
			return isPostalCode(postcode, locale.toUpperCase());
		} catch {
			return true;
		}
	}
}

/**
 * Determines if an object is prefilled, based on whether the properties in the equivalent mapping are filled
 * @param {string} key key to get from the mapping
 * @param {Object} data object to check
 * @returns {boolean}
 */
export function isObjectFull(key, data, product) {
	if (!key || !data) return;
	let fields = checkoutPreferencesMapping[key];
	if (fields && fields.mapping) return; //the property we're checking is not an object (e.g. locale is str)

	let isFull = true;
	for (let prop in fields) {
		if (isFull) { //no need to keep going once one property is missed
			if (data.hasOwnProperty(prop)) {
				let req = fields[prop].required;
				let val = data[prop];
				// Check for latin alphabet characters
				let latinRegex = /^[A-z\u00C0-\u00ff\s'.,-/#!@"+$%^&*;:{}=\-_`~()0-9]+$/
				if (val && !latinRegex.test(val)) {
					isFull = false
				}

				// special cases for addresses - county, postcode
				if (prop === 'postcode' && noPostcodeLocales.indexOf(data['locale']) !== -1) req = false;
				if (prop === 'postcode' && !validatePostcode(data[prop], data['locale'])) isFull = false;
				if (prop === 'county' && requiredCountyLocales.indexOf(data['locale']) !== -1) req = true;

				if (prop === 'email' && !isEmail(data[prop])) isFull = false;
				if (req && (data[prop] == null || data[prop] === '')) isFull = false
			} else {
				isFull = false;
			}
		}
	}

	//specific things we can't do with property names
	if (key === 'deliveryMethod') {
		// if delivery method is C&C, we need store location ID and name
		if (data.type === 'clickAndCollect' && isFull) {
			if (!data.storelocationID || !data.storelocationName) isFull = false;
		}
		if (product && data && !product.charity !== !data.charity) isFull = false;
	}
	if (key === 'customer') {
		isFull = isFull && data.phone && data.phone.length > 4 && isNumeric(data.phone);
	}

	return isFull;
};

/**
 * Uses values from user preferences to fill out checkout properties
 * @param {Object} checkout the checkout object as it is on the state
 * @param {Object} preferences the user preferences
 * @returns {Object}
 */
export function setPreferencesOnCheckout(checkout, preferences) {
	if (!preferences) return checkout;
	let prefilled = JSON.parse(JSON.stringify(checkout)); //so we're not assigning directly on param

	// iterate over checkout object, so we're only trying to prefill on properties that exist
	// assumes we're replacing val for {key:val} or {key:{prop:val}}
	for (let key in checkout) {
		if (checkout.hasOwnProperty(key)) {
			if (checkoutPreferencesMapping[key]) { //if prop in checkout is in our prefill map
				let map = checkoutPreferencesMapping[key]; //get relevant part of prefill map
				if (typeof map === 'object' && !map.mapping) { //{key:{prop:val}}
					for (let prop in checkout[key]) {
						if (checkout[key].hasOwnProperty(prop)) {
							// try and prefill from preferences
							prefilled[key][prop] = getPrefsValue(prop, map, checkout[key][prop], preferences);
						}
					}
				} else { //{key:val}
					// try and prefill from preferences
					prefilled[key] = getPrefsValue(key, map, checkout[key], preferences);
				}
			}
		}
	}

	// do specific changes that we can't define with strings for a reduce function
	if (prefilled.delivery) prefilled.delivery.locale = prefilled.locale; //want locale on delivery for isFull
	if (preferences && preferences.delivery && preferences.delivery.hasOwnProperty('useAsBilling')) {
		// explicitly set useBillingAsDelivery
		prefilled.useBillingAsDelivery = preferences.delivery.useAsBilling;
	}
	if (preferences && preferences.delivery && preferences.delivery.useAsBilling) { //use delivery as billing
		prefilled.billing = prefilled.delivery;
	}
	if (prefilled.deliveryMethod && prefilled.deliveryMethod.type === 'clickAndCollect') { //set C&C props
		if (preferences.delivery && preferences.delivery.method) {
			let method = preferences.delivery.method;
			if (method.storelocationID) prefilled.deliveryMethod.storelocationID = method.storelocationID;
			if (method.storelocationName) prefilled.deliveryMethod.storelocationName = method.storelocationName;
		}
	}

	// determine if each object is now prefilled - uses required fields on mapping
	for (let key in checkoutPreferencesMapping) {
		if (prefilled.hasOwnProperty(key)) {
			let obj = prefilled[key];
			if (obj && typeof obj === 'object' && !Array.isArray(obj)) {
				prefilled[key].isPrefilled = !!isObjectFull(key, obj);
			}
		}
	}

	return prefilled;
};


//////////////////
// private

/*
 * Mapping of preferences fields to checkout fields
 * 	mapping: ['property on preferences object as dot string']
 * 	required: is this a required property for isPrefilled to be true?
 */
checkoutPreferencesMapping = {
	billing: {
		address1: { mapping: ['billing.address1'], required: true },
		address2: { mapping: ['billing.address2'], required: false },
		locale: { mapping: ['billing.locale'], required: true },
		county: { mapping: ['billing.county'], required: false },
		firstName: { mapping: ['billing.firstName', 'personal.firstName'], required: true },
		lastName: { mapping: ['billing.lastName', 'personal.lastName'], required: true },
		postcode: { mapping: ['billing.postcode'], required: true },
		town: { mapping: ['billing.town'], required: true }
	},
	customer: {
		email: { mapping: ['personal.email'], required: true },
		firstName: { mapping: ['personal.firstName'], required: true },
		lastName: { mapping: ['personal.lastName'], required: true },
		phone: { mapping: ['delivery.phone'], required: true }
	},
	delivery: {
		address1: { mapping: ['delivery.address1'], required: true },
		address2: { mapping: ['delivery.address2'], required: false },
		county: { mapping: ['delivery.county'], required: false },
		firstName: { mapping: ['delivery.firstName', 'personal.firstName'], required: true },
		lastName: { mapping: ['delivery.lastName', 'personal.lastName'], required: true },
		postcode: { mapping: ['delivery.postcode'], required: true },
		town: { mapping: ['delivery.town'], required: true }
	},
	deliveryMethod: {
		ID: { mapping: ['delivery.method.ID'], required: true },
		name: { mapping: ['delivery.method.name'], required: true },
		price: { mapping: ['delivery.method.price'], required: true },
		type: { mapping: ['delivery.method.type'], required: true }
	},
	locale: { mapping: ['delivery.locale'], required: true },
	useBillingAsDelivery: { mapping: ['delivery.useAsBilling'], required: false }
};

/**
 * Uses reduce to copy a value from preferences, based on the preferences to checkout value mappings
 * Will always return the preference value (even if null/undefined), if the property exists
 * If the preference property does not exist, the existing value on checkout is returned
 * @param {string} key key we want to change
 * @param {Object|Array.<String>} map from the mapping object - string if we're getting the value for a top-level
 * property (i.e checkout.property), object if it's a deep property (i.e. checkout.parent.property)
 * @param {string} orig original value of the key, so we can return if nothing for it in preferences
 * @param {Object} preferences the full preferences object
 * @returns {any}
 */
function getPrefsValue(key, map, orig, preferences) {
	let keyArr = map.mapping ? map : map[key]; //array from prefill map
	let res = -1;

	if (keyArr) {
		// go through array of mappings and get value from preferences
		// if the property exists (i.e. reduce() does not return -1), use it!
		// if the mappings array has more than one element, these will act as fallback values, in order
		let mapping = keyArr.mapping;
		for (let i = 0, len = mapping.length; i < len; i++) {
			if (res === -1) res = mapping[i].trim().split('.').reduce((o, x) => o ? o[x] : -1, preferences);
		}
	}
	return res !== -1 ? res : orig;
};
