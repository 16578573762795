import { ADD_NOTIFICATION_PRODUCT, HAVE_NATIVE_RESPONSE, REBUILD_NOTIFICATIONS, REMOVE_NOTIFICATION_PRODUCT } from '../actions/notifications';

/**
 * Builds an initial state object
 * Done as a function, rather than an object on a const, as an object gets updated (even with Object.assign())
 * @returns {Object}
 */
const getInitialState = () => {
	return {
		notifications: [],
		showErrors: true,
		updateWaiting: []
	};
};

export const notifications = (state = getInitialState(), action) => {
	switch (action.type) {
		// updates the state to store the new array of notifications when one is added or removed
		case ADD_NOTIFICATION_PRODUCT:
		case REMOVE_NOTIFICATION_PRODUCT:
			return Object.assign({}, state, { updateWaiting: action.notifications, showErrors: true });

		case REBUILD_NOTIFICATIONS:
			return Object.assign({}, state, { updateWaiting: action.notifications, showErrors: false });

		// updates the state when we get a native response
		case HAVE_NATIVE_RESPONSE:
			let response = action.response;
			if (response.success) { //successful - set notifications from temporarily stored array (waiting)
				let update = {
					notifications: state.updateWaiting,
					showErrors: true,
					updateWaiting: []
				};
				return Object.assign({}, state, update)
			} else { //error
				return state;
			}

		default:
			return state;
	}
};
