// Container component for the error bar
import { connect } from 'react-redux';
import { clearError } from '../actions/error';
import ErrorBar from '../components/errorBar/ErrorBar';


/**
 * mapStateToProps
 * 
 * @description maps the error state from the application state to props for the 
 * error bar component
 * 
 * @param [object] state
 * @return [object] error bar props
 */
const mapStateToProps = (state) => {
  const {error} = state;
  const {show, message, title, dismissButton} = error;
  return {
      show, 
      message,
      title,
      dismissButton
  }
}

/**
 * mapDispatchToProps
 * 
 * @description maps the error actions to props for the ErrorBar component
 * 
 * @param [function] dispatch
 * @return [object] ErrorBar props
 */
const mapDispatchToProps = (dispatch) => {
  return {
      close: () => dispatch(clearError())
  }
}

// map state and dispatch methods to props for error handling
const Error = connect(
    mapStateToProps,
    mapDispatchToProps
)(ErrorBar);

export default Error;