import { UPDATE_DELIVERY_TYPES } from '../actions/deliveryTypes';


export const deliveryTypes = (state = [], action) => {

  switch (action.type) {
    case 'SELECT_DELIVERY_METHOD':
        let deliveryTypes = state.map((deliveryMethod) => {
            let newDeliveryMethodOptions = deliveryMethod.options.map((deliveryOption) => {
                return deliveryOption.ID === action.deliveryMethod.ID ? Object.assign({}, deliveryOption, {selected: true})
                    : Object.assign({}, deliveryOption, {selected: false});
            })
            let newDeliveryMethod = Object.assign({}, deliveryMethod, {
                options: newDeliveryMethodOptions
            })
            return Object.assign({}, deliveryMethod, newDeliveryMethod);
        });
        return deliveryTypes;
    case UPDATE_DELIVERY_TYPES:
       return  action.deliveryTypes || [];
    default:
      return state;
  }
}

export default deliveryTypes;