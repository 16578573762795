import React from 'react';
import { connect } from 'react-redux';
import contentActions from '../../../../services/contentService/actions'

import HtmlContentElement from '../../compontents/html/HTML';
import ImageContentElement from '../../compontents/image/Image';
import VideoContentElement from '../../compontents/video/Video';
import ImageGaleryContentElement from '../../compontents/imageGalery/ImageGalery';
import OptIn from '../../compontents/optIn/OptIn';
import ProductList from '../../compontents/productList/productList/ProductList';

import './Page.scss';

const Page = React.createClass({
	render() {
		let { content, handelAction, name } = this.props;
		let contentItems = []
		content.forEach((contentItem, index) => {
			contentItems.push(buildPage(contentItem, index, handelAction))
		});
		return (
			<div className={`ContentPage ${name}`}>
				<div className="content-scroll">
					{contentItems}
				</div>
			</div>
		);
	}
})

function buildPage(contentItem, index, handelAction) {
	//Skip hidden components
	if(contentItem.disable){
		return null;
	}
	switch (contentItem.type) {
		case 'html':
			return <HtmlContentElement key={index} name={contentItem.name} content={contentItem.data} handelAction={handelAction} />
		case 'image':
			return <ImageContentElement key={index} name={contentItem.name} content={contentItem.data} handelAction={handelAction} />
		case 'video':
			return <VideoContentElement key={index} name={contentItem.name} content={contentItem.data} handelAction={handelAction} />
		case 'image-gallery':
			return <ImageGaleryContentElement key={index} name={contentItem.name} content={contentItem.data} handelAction={handelAction} />
		case 'opt-in':
			return <OptIn key={index} content={contentItem.data} name={contentItem.name} handelAction={handelAction} />
		case 'product-list':
			return <ProductList key={index} content={contentItem.data} name={contentItem.name}/>
		default:
			console.log('Unknown element')
	}
}

const mapStateToProps = (state) => {
	return {}
};

const mapDispatchToProps = (dispatch) => {
	return {
		handelAction: (action) => contentActions(dispatch, action),
	}
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Page);