import { hashHistory } from 'react-router';
import { updateUserPreferences, updateMarketingPreferencesPlatform } from '../../../actions/preferences';
import { connect } from 'react-redux';
import React from 'react';
import TitleBlock from '../../titleBlock/TitleBlock';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem'
import './Marketing.scss'

const preferencesKey = 'marketing';

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
const brands = ['Nike', 'Adidas', 'Jordans', 'Reebok', 'New Balance', 'Clarks Original', 'Puma', 'Fila'];
const shoeSizes = ['3', '3.5', '4', '4.5', '5', '5.5', '6', '6.5', '7', '7.5', '8', '8.5', '9', '9.5', '10', '10.5', '11', '11.5', '12', '12.5', '13', '13.5', '14'];
const genders = ['male', 'female', 'other', 'prefer not to say'];

const MarketingPreferences = React.createClass({
    getInitialState: () => ({
        preferences: {
            optedIn: false,
            birthday: {
                day: '',
                month: '',
                year: ''
            }
        }
    }),
    onCancel() {
        this.backToDashboard();
    },
    backToDashboard() {
        hashHistory.push('/preferences');
    },
    updatePreferences(newPreferences) {
        let preferences = { ...this.state.preferences }
        Object.assign(preferences, newPreferences)
        this.setState({ preferences: preferences });
    },
    updatePreference(property, val) {
        let newPreferences = {}
        newPreferences[property] = val
        this.updatePreferences(newPreferences)
    },
    componentWillMount() {
        let { preferences } = this.props;
        let mergedPreferences = Object.assign({}, this.state.preferences, preferences)
        this.setState({ preferences: mergedPreferences });
        // No validation restriction on this page
        if (this.props.isPageValid) {
            this.props.isPageValid(true);
        }
    },
    onSave() {
        let preferences = this.state.preferences;
        this.props.savePreferences(preferencesKey, preferences);
        this.props.updatePlatform(preferences);
        this.backToDashboard();
    },
    updateBrand(evt, index, val, property) {
        this.updatePreference('favoriteBrand', val);
    },
    updateCheckbox() {
        this.updatePreference('optedIn', !this.state.preferences.optedIn);
    },
    updateGender(evt, index, val) {
        this.updatePreference('gender', val);
    },

    updateShoeSize(evt, index, val) {
        this.updatePreference('shoeSize', val);
    },
    updateDay(evt, index, val) {
        let birthday = {
            ...this.state.preferences.birthday,
            day: val
        }
        this.updatePreference('birthday', birthday);
    },
    updateMonth(evt, index, val) {
        let birthday = {
            ...this.state.preferences.birthday,
            month: index + 1
        }
        this.updatePreference('birthday', birthday);
    },
    updateYear(evt, index, val) {
        let birthday = {
            ...this.state.preferences.birthday,
            year: val
        }
        this.updatePreference('birthday', birthday);
    },
    componentDidUpdate() {
        if (this.props.onFormChange) {
            this.props.onFormChange(this.state);
        }
    },
    
    render() {
        const handleSubmit = e => e.preventDefault();

        let years = [];
        var now = new Date();
        var yearsAgo = now.getFullYear() - 18;

        let days = [];
        let chosenYear = this.state.preferences.birthday.year || yearsAgo;
        let chosenMonth = this.state.preferences.birthday.month || 1;
        let totalDays = new Date(chosenYear, chosenMonth, 0).getDate();

        if (this.state.preferences.birthday && this.state.preferences.birthday.day > totalDays) {
            this.updateDay(undefined, undefined, undefined)
        }

        for (let i = 1; i <= totalDays; i++) {
            days.push(<MenuItem key={i} value={i} primaryText={i} />);
        }

        for (let i = yearsAgo; i >= 1920; i--) {
            years.push(<MenuItem key={i} value={i} primaryText={i} />);
        }

        let { birthday } = this.state.preferences;

        let dateInvalid = birthday && (!birthday.year || !birthday.day || !birthday.month)
            && (birthday.year || birthday.day || birthday.month);

        return (
            <div className="Preferences Marketing-Preferences">
                <div className="content-scroll">
                    <TitleBlock title="Marketing Preferences" />
                    <div className="form-horizontal">
                        <p className="intro">
                            Would you like to receive marketing communications?
                        </p>
                        <div className="form-group check-option useBilling">
                            <input type="checkbox" id="useBilling" checked={this.state.preferences.optedIn} onChange={this.updateCheckbox} />
                            <label htmlFor="useBilling">
                                <span className="check-box tick"></span>
                                <span className="name">Yes, sign me up!</span>
                            </label>
                        </div>
                    </div>
                    <form className="form-horizontal dropdowns" onSubmit={handleSubmit}>
                        <div>
                            <SelectField
                                value={this.state.preferences.gender}
                                onChange={(evt, index, val) => this.updateGender(evt, index, val)}
                                underlineStyle={{ borderColor: '#000000', borderWidth: '2px' }}
                                iconStyle={{ fill: '#000000' }}
                                labelStyle={{ color: '#000000' }}
                                autoWidth={true}
                                floatingLabelText="Gender"
                            >
                                {genders.map((val, index) => {
                                    return (<MenuItem key={index} value={val} primaryText={val.charAt(0).toUpperCase() + val.slice(1)} />)
                                }
                                )}
                            </SelectField>
                        </div>
                        <div>
                            <SelectField
                                value={this.state.preferences.favoriteBrand}
                                onChange={(evt, two, val) => this.updateBrand(evt, two, val)}
                                underlineStyle={{ borderColor: '#000000', borderWidth: '2px' }}
                                iconStyle={{ fill: '#000000' }}
                                labelStyle={{ color: '#000000' }}
                                autoWidth={true}
                                floatingLabelText="What is your favorite brand?"
                            >
                                {brands.map((val, index) => {
                                    return (<MenuItem key={index} value={val} primaryText={val} />)
                                }
                                )}
                            </SelectField>
                        </div>
                        <div>
                            <SelectField
                                value={this.state.preferences.shoeSize}
                                onChange={(evt, index, val) => this.updateShoeSize(evt, index, val)}
                                underlineStyle={{ borderColor: '#000000', borderWidth: '2px' }}
                                iconStyle={{ fill: '#000000' }}
                                labelStyle={{ color: '#000000' }}
                                autoWidth={true}
                                floatingLabelText="What is your shoe size?"

                            >
                                {
                                    shoeSizes.map((val, index) => {
                                        return (<MenuItem key={index} value={val} primaryText={val} />)
                                    }
                                    )
                                }
                            </SelectField>
                        </div>
                        <br />
                        <div>
                            <p>Date of birth</p>
                            <div className="dateInput">
                                <span className={birthday.day ? 'active' : ''}>
                                    <SelectField
                                        value={birthday.day}
                                        onChange={(evt, index, val) => this.updateDay(evt, index, val)}
                                        style={{ width: "65px" }}
                                        underlineStyle={{ borderColor: dateInvalid && !birthday.day ? '#C91515' : '#000000', borderWidth: '2px' }}
                                        iconStyle={{ fill: '#000000' }}
                                        labelStyle={{ color: '#000000' }}
                                        autoWidth={true}
                                        floatingLabelText="31"
                                    >
                                        {days}
                                    </SelectField>
                                </span>
                                <span className={`mounth ${birthday.month ? 'active' : ''}`}>
                                    <SelectField
                                        value={birthday.month}
                                        onChange={(evt, index, val) => this.updateMonth(evt, index, val)}
                                        style={{ width: "100%" }}
                                        underlineStyle={{ borderColor: dateInvalid && !birthday.month ? '#C91515' : '#000000', borderWidth: '2px' }}
                                        iconStyle={{ fill: '#000000' }}
                                        labelStyle={{ color: '#000000' }}
                                        autoWidth={true}
                                        floatingLabelText="January"
                                    >
                                        {months.map((val, index) => {
                                            return (<MenuItem key={index} value={index + 1} primaryText={val} />)
                                        })}
                                    </SelectField>
                                </span>
                                <span className={birthday.year ? 'active' : ''}>
                                    <SelectField
                                        value={birthday.year}
                                        onChange={(evt, index, val) => this.updateYear(evt, index, val)}
                                        style={{ width: "85px" }}
                                        underlineStyle={{ borderColor: dateInvalid && !birthday.year ? '#C91515' : '#000000', borderWidth: '2px' }}
                                        iconStyle={{ fill: '#000000' }}
                                        labelStyle={{ color: '#000000' }}
                                        autoWidth={true}
                                        floatingLabelText="2000"
                                    >
                                        {years}
                                    </SelectField>
                                </span>
                            </div>
                        </div>
                        {this.props.hideButtons ? null :
                            <div className="form-action fixed">
                                <div className="inline">
                                    <div className="action-split">
                                        <button className="cancel" onClick={this.onCancel}>Cancel</button>
                                    </div>
                                    <div className="action-split">
                                        <button disabled={dateInvalid} onClick={this.onSave} className="action">
                                            <div className="button-contents">Save</div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        }
                    </form>

                </div>
            </div>
        )
    }
});

const mapStateToProps = state => {
    const { user } = state;
    return { preferences: user.preferences.marketing };
};

const mapDispatchToProps = dispatch => {
    return {
        savePreferences: (key, data) => dispatch(updateUserPreferences(key, data)),
        updatePlatform: (data) => dispatch(updateMarketingPreferencesPlatform(data)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MarketingPreferences);

