import config from "../../../services/config/config";

let mapPreOrderStatus =  (status)=>{
    let statuses ={
        ongoing:{
            progress: 25,
            barBackground: config.layout.raffleEntries.ongoingColour,
            finalStepName:"?"
        },
        processing:{  
            progress: 50,
            barBackground: config.layout.raffleEntries.ongoingColour,  
            finalStepName:"?"
        },
        winner: {
            progress: 100,
            barBackground: config.layout.raffleEntries.winColour,  
            finalStepName: "Winner",
        },
        donated: {
            progress: 100,
            barBackground: config.layout.raffleEntries.winColour,  
            finalStepName: "Donated",
        },
        looser_unprocessed:{
            progress: 100,
            barBackground: config.layout.raffleEntries.lossColour, 
            finalStepName: "Unlucky",
            firstDescription: "Your held payment will be released in 3-5 days depending on your bank."
        },
        looser_processed:{  
            progress: 100,
            barBackground: config.layout.raffleEntries.lossColour, 
            finalStepName: "Unlucky",
            firstDescription: "Your held payment will be released in 3-5 days depending on your bank."
        }
    }
    return statuses[status];
}

export default mapPreOrderStatus;