import React from 'react';

import { trackPage } from '../../services/analytics/analytics';

const NotFound = () => {

  trackPage('*');

  return(
    <div className="App">
      <div id="no-app">
          <div className="content">
              <div className="sad">
                  <div className="four">4</div>
                  <div className="container">
                      <div>:(</div>
                  </div>
                  <div className="four">4</div>
              </div>
              <h1>OOPS!</h1>
              <p>Sorry, not found</p>
          </div>
      </div>
    </div>
  )
};

export default NotFound;
