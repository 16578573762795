import { connect } from 'react-redux';
import React from 'react';
import SidebarWrapper from '../../sidebar/SidebarWrapper';
import './MembershipPreferences.scss'
import MembershipSignup from './nonMember/MembershipSignup';
import MembershipHub from './MembershipHub';
import { loadContent } from '../../../actions/content';


const MembershipPreferences = React.createClass({
    componentWillMount() {
        let { updateNextButtonStatus } = this.props;
        if (updateNextButtonStatus) {
            updateNextButtonStatus(true);
        }
        this.props.loadContent();
    },
    render() {
        let { loyalty, step } = this.props;
        return (
            <div className={`Preferences Membership-Preferences ${loyalty.isPremium ? 'member' : 'non-member'}`}>
                <SidebarWrapper />
                <div className="content-scroll">
                    {loyalty.isPremium ? <MembershipHub /> : <MembershipSignup step={step} />}
                </div>
            </div>
        )
    }
});

const mapStateToProps = state => {
    const { user } = state;
    return { loyalty: user.preferences.loyalty };
};

const mapDispatchToProps = dispatch => {
    return {
        loadContent: () => dispatch(loadContent(true))
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MembershipPreferences);

