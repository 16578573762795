// Container component for the Payment page
import { connect } from 'react-redux';
import Payment from '../components/payment/Payment';
import { makeEncryptedPayment } from '../actions/payment';
import { clearSession } from '../actions/session';

/**
 * mapStateToProps
 *
 * @description maps the state from the application state to props for the
 * Payment component
 *
 * @param [object] state
 * @return [object] payment state
 */
const mapStateToProps = (state, currentProps) => {
	const { order, session } = state;
	let newOrder;
	if(session.preOrder){
		newOrder = { ...session.preOrder }; 
	} else {
		newOrder = { ...order }; 
	}
	
	return { order: newOrder, session };
}

/**
 * mapDispatchToProps
 *
 * @description maps the actions to props for the Payment component
 *
 * @param [function] dispatch
 * @return [object] payment props
 */
const mapDispatchToProps = (dispatch) => {
	return {
		sendEncryptedPaymentData: (sessionID, data) => dispatch(makeEncryptedPayment(sessionID, data)),
		clearSession: () => dispatch(clearSession())
	}
}

// map state and dispatch methods to props for Payment component
const PaymentPage = connect(
	mapStateToProps,
	mapDispatchToProps
)(Payment);

export default PaymentPage;
