import React from 'react';
import { connect } from 'react-redux';
import { hidePromoPopup } from '../../actions/content';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import CalendarItemColumns from '../calendarItem/columns/CalendarItem';
import CalendarItemFull from '../calendarItem/full/CalendarItem';

import config from '../../services/config/config'

import './PromoProduct.scss';


//////////////////


const PromoPopup = React.createClass({
    render() {
        let { products, hidePromoPopup, showBannerPopup, allProducts } = this.props;
        const CalendarItem = config.layout.listStyle === "full" ? CalendarItemFull : CalendarItemColumns;
        return <ReactCSSTransitionGroup
            transitionName="promo-modal"
            transitionEnterTimeout={200}
            transitionLeaveTimeout={200}>
            {showBannerPopup ? <div className="promoPopupWrapper">
                <div className="promoModal">
                    <div className="modalHeader">
                        <span onClick={hidePromoPopup} className="close"></span>
                    </div>
                    <div className="productList">
                        {products
                            .map((product) => {
                                return allProducts.find((fullProduct) => fullProduct.ID === product)
                            })
                            .map((product, i) => {
                                if (product) {
                                    return <CalendarItem
                                                key={product.ID || i}
                                                image={product.mainImage}
                                                launchDate={product.launchDate}
                                                launchStartDate={product.launchStartDate}
                                                launchType={product.launchType}
                                                sku={product.ID}
                                                title={product.name}
                                                product={product}
                                                price={product.price}
                                                subtitle={product.subTitle}
                                                soldOut={product.status === 'out-of-stock' || product.status === 'complete'}
                                                trackScroll={hidePromoPopup}
                                            />
                                }
                                return null;
                            })}
                    </div>
                </div>
            </div> : null}
        </ReactCSSTransitionGroup>
    }
});

//////////////////

const mapStateToProps = state => {
    const { content } = state;
    return { showBannerPopup: content.showBannerPopup, products: content.bannerProducts, allProducts: content.products };
};

const mapDispatchToProps = dispatch => {
    return {
        hidePromoPopup: () => dispatch(hidePromoPopup())
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PromoPopup);
