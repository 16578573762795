let mapOrderStatus = (status, deliveryMethodType) => {
    let statuses = {
        PLACED: {
            progress: 0,
            barBackground: "#FF600B",
            description: "Your order is currently being reviewed, once your order has been dispatched we will send you an email and SMS message informing you that your order is on its way.",
            name : "Placed"
        },
        ACCEPTED: {
            progress: 25,
            barBackground: "#FF600B",
            description: "Your order has been accepted, once your order has been dispatched we will send you an email and SMS message informing you that your order is on its way.",
            name: "Accepted"
        },
        WAREHOUSE: {
            progress: 50,
            barBackground: '#FF600B',
            description : "Your order is in our warehouse being prepared, once your order has been dispatched we will send you an email and SMS message informing you that your order is on its way.",
            name: "Warehouse"
        },
        ON_THE_WAY: {
            progress: deliveryMethodType === "delivery" ? 100 : 75,
            barBackground: '#FF600B',
            description: "Your order is on its way.",
            name: "On the way",

        },
        READY_FOR_COLLECTION: {
            progress: 90,
            barBackground: '#FF600B',
            description: "Your order is now ready to collect from your chosen store.",
            name: "Ready for Collection"
        },
        COLLECTED: {
            progress: 100,
            barBackground: '#FF600B',
            description: "Your order has been collected, thank you for shopping with us.",
            name: "Collected"

        }
    }
    return statuses[status];
}

export default mapOrderStatus;