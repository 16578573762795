// Container component for the Payment page
import { connect } from 'react-redux';
import Klarna from '../components/klarna/Klarna';
import { makeKlarnaPayment } from '../actions/payment';
import { hideKlarna } from '../actions/checkout';
import { clearSession, updateSession } from '../actions/session';

import { showError } from '../actions/error';

/**
 * mapStateToProps
 *
 * @description maps the state from the application state to props for the
 * Klarna component
 *
 * @param [object] state
 * @return [object] payment state
 */
const mapStateToProps = (state, currentProps) => {
	const { order, session, checkout } = state;
	let newOrder;
	if (session.preOrder) {
		newOrder = { ...session.preOrder };
	} else {
		newOrder = { ...order };
	}

	return { order: newOrder, session, checkout: checkout };
}

/**
 * mapDispatchToProps
 *
 * @description maps the actions to props for the Klarna component
 *
 * @param [function] dispatch
 * @return [object] payment props
 */
const mapDispatchToProps = (dispatch) => {
	return {
		sendKlarnaPaymentData: (sessionID, data) => dispatch(makeKlarnaPayment(sessionID, data)),
		clearSession: () => dispatch(clearSession()),
		updateSession: (session) => dispatch(updateSession(session)),
		hideKlarna: () => dispatch(hideKlarna()),
		showError: (errorInfo) => dispatch(showError({ errorInfo: errorInfo })),
	}
}

// map state and dispatch methods to props for Payment component
const KlarnaPage = connect(
	mapStateToProps,
	mapDispatchToProps
)(Klarna);

export default KlarnaPage;
