import React from 'react';

import './Category.scss';

import sizeCaptures from './size-captures.png';
import sizePopping from './size-popping.png';
import sizePreviews from './size-previews.png';
import sizeSeries from './size-series.png';
import sizeSessions from './size-sessions.png';
import sizeSpaces from './size-spaces.png';


//////////////////


const Category = ({ title, slug }) => (
    <li className="blog-category">
        {getCategory(title, slug)}
    </li >
);


function getCategory(title, slug) {
    switch (slug) {
        case 'size-captures':
            return <img alt={title} src={sizeCaptures} ></img>
        case 'size-popping':
            return <img alt={title} src={sizePopping} ></img>
        case 'size-previews':
            return <img alt={title} src={sizePreviews} ></img>
        case 'size-series':
            return <img alt={title} src={sizeSeries} ></img>
        case 'size-sessions':
            return <img alt={title} src={sizeSessions} ></img>
        case 'size-spaces':
            return <img alt={title} src={sizeSpaces} ></img>
        default:
            return title
    }
}


//////////////////


export default Category;
