/**
 * Content (Home Page) Actions
 *
 * Here you'll find all the action creators & actions for the home page of the application. Async
 * actions (using redux-thunk) are at the bottom.
 *
 */
import { prefillCheckout } from './checkout';
import { showErrorFromResponse } from './error';
import { getHomeContent, getRaffleEntries, withdrawPreauth } from '../services/api';
import { getPreferences, getUserData } from '../services/user';
import { processProducts as processBookmarks, getProductsForBookmarks, haveBookmarkedProducts } from './bookmarks';
import { processProducts as processCalendar, receivedProductsByMonth as receivedProductsByMonthCalendar } from './calendar';
import { processProducts as processPast, receivedProductsByMonth as receivedProductsByMonthPast } from './pastLaunches';
import { updateLoyalty, updateMarketing } from './preferences';
import { loaded, loaded3DS, loading, loading3DS } from './loading';
import { hashHistory } from 'react-router';


//////////////////


export const CLOSE_SPLASH_LOGIN = 'CLOSE_SPLASH_LOGIN';
export const CONTINUE_AS_GUEST = 'CONTINUE_AS_GUEST';
export const END_LOGIN_JOURNEY = 'END_LOGIN_JOURNEY';
export const ERROR_CONTENT = 'ERROR_CONTENT';
export const GET_USER_FROM_STORAGE = 'GET_USER_FROM_STORAGE';
export const HOMEPAGE_SLIDER_INDEX = "HOMEPAGE_SLIDER_INDEX";
export const CONTENT_SLIDER_INDEX = "CONTENT_SLIDER_INDEX";
export const LOAD_CONTENT = 'LOAD_CONTENT';
export const LOAD_RAFFLE_ENTRIES = 'LOAD_RAFFLE_ENTRIES';
export const UPDATE_RAFFLE_ENTRIES = 'UPDATE_RAFFLE_ENTRIES';
export const RECIEVED_RAFFLE_ENTRIES = 'RECIEVED_RAFFLE_ENTRIES';
export const RECIEVED_CONTENT = 'RECIEVED_CONTENT';
export const SHOW_SPLASH_LOGIN = 'SHOW_SPLASH_LOGIN';
export const TRACK_SCROLL = 'TRACK_SCROLL';
export const UPDATE_CONTENT = 'UPDATE_CONTENT';

export const COMPLETE_SCRATCHCARD = 'COMPLETE_SCRATCHCARD';
export const VISIT_PAGE = 'VISIT_PAGE';

export const HIDE_PROMO_POPUP = 'HIDE_PROMO_POPUP';
export const SHOW_PROMO_POPUP = 'SHOW_PROMO_POPUP';

/**
 * Loads the app content
 * @returns {function} thunk
 */

export function loadContent(showLoadingScreen = false, forceLoadHomeContent = false) {
	return (dispatch) => {
		dispatch({
			type: LOAD_CONTENT
		})
		if (showLoadingScreen) {
			dispatch(loading3DS('Checking Loyalty Enrollment'))
		}
		let homeContent;
		getHomeContent(forceLoadHomeContent)
			.then(data => { homeContent = data; })
			.then(data => dispatch(updateContent(homeContent)))
			.then(data => dispatch(recievedContent()))
			//Bookmarks
			.then(data => dispatch(processBookmarks(homeContent.products)))
			.then(data => dispatch(getProductsForBookmarks(data)))
			.then(data => dispatch(haveBookmarkedProducts()))
			//Calendar
			.then(data => dispatch(processCalendar(homeContent.products)))
			.then(data => dispatch(receivedProductsByMonthCalendar()))
			//Upcoming
			.then(data => dispatch(processPast(homeContent.products)))
			.then(data => dispatch(receivedProductsByMonthPast()))
			//Get Loyalty
			.then(data => dispatch(updateLoyalty(homeContent.achievements)))
			// Get Marketing
			.then(data => dispatch(updateMarketing()))
			.catch((error) => {
				console.log(error)
				dispatch(showErrorFromResponse(error))
				dispatch(recievedContent())
			}).finally(() => {
				if (showLoadingScreen) {
					dispatch(loaded3DS())
				}
			}
			);
	}
};

/**
 * Loads the app content
 * @returns {function} thunk
 */
export function loadRaffleEntries() {
	let userData = getUserData();
	let userID = userData.userID

	return (dispatch) => {
		dispatch({
			type: LOAD_RAFFLE_ENTRIES
		})
		getRaffleEntries(userID)
			.then(data => dispatch(updateRaffleEntries(data)))
			.then(data => dispatch(recievedRaffleEntries()))
			.catch((error) => {
				dispatch(showErrorFromResponse(error))
				dispatch(recievedRaffleEntries())
			})
	}
};

/**
 * Withdraw a customer
 * @returns {function} thunk
 */
export function withdraw(preauthID) {
	let userData = getUserData();
	let userID = userData.userID

	return (dispatch) => {
		dispatch(loading())
		withdrawPreauth(userID, preauthID)
			.then(data => { hashHistory.push('/preferences/raffle/withdraw/done') })
			.catch((error) => {
				dispatch(showErrorFromResponse(error))
			}).finally(() => { dispatch(loaded()) })
	}
};

/**
 * Gets the user from storage
 * @returns {function} thunk
 */
export function getUserFromStorage() {
	let preferences = getPreferences();
	return (dispatch, getState) => {
		dispatch({
			preferences,
			type: GET_USER_FROM_STORAGE
		});

		let { checkout } = getState();
		dispatch(prefillCheckout(checkout, preferences));
	};
};


//////////////////


/**
 * Hide the splash login
 * @returns {Object}
 */
export function closeSplashLogin(path) {
	return {
		onFinish: path,
		type: CLOSE_SPLASH_LOGIN
	}
};

/**
 * Use the app as a guest
 * @returns {Object}
 */
export function continueAsGuest() {
	return {
		type: CONTINUE_AS_GUEST
	}
};

/**
 * Ends the login/signup journey
 * @returns {Object}
 */
export function endLoginJourney() {
	return {
		type: END_LOGIN_JOURNEY
	};
}

/**
 * Called when we have an error getting content
 * @param {Object} response
 * @returns {Object}
 */
export function errorContent(response) {
	return {
		type: ERROR_CONTENT,
		response
	}
};

/**
 * Called when we'rve received content
 * @returns {Object}
 */
export function recievedContent() {
	return {
		type: RECIEVED_CONTENT
	}
};

/**
 * Called when we'rve received raffle entries
 * @returns {Object}
 */
export function recievedRaffleEntries() {
	return {
		type: RECIEVED_RAFFLE_ENTRIES
	}
};

/**
 * Keeps track of the homepage slider index, so we can go back to that page
 * @param {number} index slider page index
 * @returns {Object}
 */
export function setIndex(index, invert, isCampaignSlide, pageHeaderLogo, pageTransperentHeader) {
	return {
		index,
		invert,
		isCampaignSlide,
		pageHeaderLogo,
		pageTransperentHeader,
		type: HOMEPAGE_SLIDER_INDEX
	};
}

/**
 * Keeps track of the current content slider index, so we can go back to that page
 * NB 
 * @param {number} index slider page index
 * @returns {Object}
 */
export function setContentIndex(id, index) {
	return {
		index,
		id,
		type: CONTENT_SLIDER_INDEX
	};
}

/**
 * Show the splash login
 * @returns {Object}
 */
export function showSplashLogin() {
	return {
		type: SHOW_SPLASH_LOGIN
	}
};

/**
 * Tracks the scroll distance of the component
 * @param {number} scrollTop scroll distance in pixels from top
 */
export function trackScroll(scrollTop) {
	return {
		scrollTop,
		type: TRACK_SCROLL
	};
}

/**
 * Update content
 * @param {Object} content
 * @returns {Object}
 */
export function updateContent(content) {
	return {
		type: UPDATE_CONTENT,
		content
	}
};

/**
 * Update raffle entries
 * @param {Object} entries
 * @returns {Object}
 */
export function updateRaffleEntries(entries) {
	return {
		type: UPDATE_RAFFLE_ENTRIES,
		entries
	}
};

/**
 * Add a completed scratchcard
 * @param {Object} entries
 * @returns {Object}
 */
export function addCompletedScratchCard(img) {
	return {
		type: COMPLETE_SCRATCHCARD,
		img
	}
};

/**
 * Logs that a page has been visted and sets up the header state.
 * @param {Object} entries
 * @returns {Object}
 */
export function visitPage(page) {
	return {
		type: VISIT_PAGE,
		page
	}
};

export function filterCampaigns(campaigns = [], loyalty, location = 'sidebar') {
	return campaigns.filter((campaign) => ((!campaign.location && location === 'sidebar') || campaign.location === location)
		&& (!campaign.showTo || campaign.showTo === 'everyone' || (campaign.showTo === 'members' && loyalty.isPremium) || (campaign.showTo === 'non-members' && !loyalty.isPremium)))
}

/**
 * Hide a promo popup
 * @returns {Object}
 */
 export function hidePromoPopup() {
	return {
		type: HIDE_PROMO_POPUP,
	}
};

/**
 * Show a promo popup
 * @param {Object} products
 * @returns {Object}
 */
 export function showPromoPopup(products) {
	return {
		type: SHOW_PROMO_POPUP,
		products
	}
};