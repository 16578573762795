import CalendarItemColumns from '../calendarItem/columns/CalendarItem';
import CalendarItemFull from '../calendarItem/full/CalendarItem';import moment from 'moment';
import React, { PropTypes } from 'react';
import config from '../../services/config/config'

import './PastLaunches.scss';


//////////////////

const PastLaunches = React.createClass({
	componentWillMount() {
		//this.props.getPastLaunches()
	},
	componentWillUnmount() {
		// this.props.clearPastLaunches();
	},
	componentDidMount() {
		let el = document.getElementsByClassName('PastLaunches')[0].getElementsByClassName('content-scroll')[0];
		el.scrollTop = this.props.scrollTop || 0;
	},
	render() {
		const PastLaunchesItem = config.layout.listStyle === "full" ? CalendarItemFull : CalendarItemColumns;

		let { productsByMonth, theme } = this.props;
		let keys = Object.keys(productsByMonth);
		let items = [];
		keys.sort((a, b ) => a > b ? -1 : a < b ? 1 : 0);
		for (let i = 0, len = keys.length; i < len; i++) {
			if (productsByMonth.hasOwnProperty(keys[i])) {
				let splitKey = keys[i].split('-');
				let groupLabel = `${moment.months([splitKey[1]] - 1)} ${splitKey[0]}`;
				let monthList = productsByMonth[keys[i]].map((product, i) => {
					// create markup for each product in the calendar
					return <PastLaunchesItem
						key={product.sku || i}
						buttonStyles={theme.primaryButton}
						image={product.mainImage}
						launchDate={product.launchDate}
						launchStartDate={product.launchStartDate}
						launchType={product.launchType}
						price={product.price}
						sku={product.ID}
						title={product.name}
						product={product}
						subtitle={product.subTitle}
						soldOut={product.status === 'out-of-stock' || product.status === 'complete' || product.status === 'archived'}
					/>;
				});

				// create markup for each month in the calendar
				items.push(
					<li key={keys[i]}>
						<ul className="calendarMonth">
							<li className="calendarMonthLabel">
								<h4>{groupLabel}</h4>
							</li>
							{monthList}
						</ul>
					</li>
				);
			}
		}
		// return full calendar markup
		return (
			<div className={`PastLaunches  ${config.layout.listStyle} nav-${config.layout.nav.position}`}>
				<div className="content-scroll" id="content-scroll">
					<ul>
						{items}
					</ul>
				</div>
			</div>
		);
	}
});

PastLaunches.defaultProps = {
	products: []
};

PastLaunches.propTypes = {
	products: PropTypes.array.isRequired
};


//////////////////


export default PastLaunches;
