/**
 *
 * Actions
 *
 */
import {
	RECIEVED_PRODUCT,
	LOAD_PRODUCT,
	LAUNCH_PRODUCT,
	SELECT_SIZE,
	QUANTITY_OF_ENTRIES,
	BOT_VERIFICATION,
	UPDATE_STOCK,
	BOT_VERIFICATION_FAILED,
	ADD_TO_BASKET_SUCCESS,
	CLEAR_PRODUCT,
	SELECT_SIZE_PREFERENCE
} from '../actions/product';


/**
 *
 * inital product state
 *
 */
export const initialState = {
	displayName: "",
	images: [],
	price: {},
	decription: "",
	launchDate: "",
	options: {
		options: []
	},
	loaded: false,
	sizeSelected: false,
	verification: false,
	informationBlocks: [],
	quantityOfEntries: 1,
};


/**
 *
 * Reducer
 *
 */
export const product = (state = initialState, action) => {
	let newOptions;
	switch (action.type) {
		case CLEAR_PRODUCT:
			return Object.assign({}, initialState);
		case LOAD_PRODUCT:
			return Object.assign({}, state, {
				loaded: false
			});
		case RECIEVED_PRODUCT:
			return Object.assign({}, state, action.data, {
				sizeSelected: false,
				verification: false,
				loaded: true
			});
		case LAUNCH_PRODUCT:
			return Object.assign({}, state, {
				status: 'available'
			});
		case SELECT_SIZE_PREFERENCE:
		case SELECT_SIZE:
			let currentOptions = state.options.options;
			newOptions = currentOptions.map((el, i) => {
				let newOption = Object.assign({}, el);
				if (newOption.optionID === action.sku) {
					newOption.selected = true;
				} else {
					newOption.selected = false;
				}
				return newOption;
			});
			return Object.assign({}, state, {
				options: {
					options: newOptions
				},
				sizeSelected: action.sku
			});
		case QUANTITY_OF_ENTRIES:
			return Object.assign({}, state, {
				quantityOfEntries: action.quantityOfEntries
			})
		case BOT_VERIFICATION:
			return Object.assign({}, state, {
				verification: action.verification
			})
		case BOT_VERIFICATION_FAILED:
			return Object.assign({}, state, {
				verification: ""
			})
		case UPDATE_STOCK:
			var sizeSelected,
				options = action.options.options,
				status = state.status;

			if (state.sizeSelected) {
				sizeSelected = state.sizeSelected;
				options = options.map((el, i) => {
					let newOption = Object.assign({}, el);
					if (newOption.optionID === state.sizeSelected && el.isAvailable) {
						newOption.selected = true;
					} else {
						newOption.selected = false;
					}
					return newOption;
				});
			} else if (options.length === 1 && options[0].isAvailable) {
				options[0].selected = true;
				sizeSelected = options[0].optionID;
			}

			return Object.assign({}, state, {
				options: {
					options: options
				},
				sizeSelected: sizeSelected || undefined,
				status
			})
		case ADD_TO_BASKET_SUCCESS:
			newOptions = state.options.options.map((el, i) => {
				let newOption = Object.assign({}, el);
				newOption.selected = false;
				return newOption;
			});
			return Object.assign({}, state, {
				sizeSelected: false,
				options: {
					options: newOptions
				},
				verification: false
			})
		default:
			return state;
	}
}

export default product;