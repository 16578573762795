import {APPLE_PAY_ENABLED,
        APPLE_PAY_START,
        APPLE_PAY_EVENT} from '../services/nativeBridge/messageTypes';

import {startApplePaySession} from '../services/payment';
import { paymentSuccess } from './payment';
import {showError} from './error';
import { hashHistory } from 'react-router';
import { sendEncryptedToken, createOrder } from '../services/api';
import { postMessage } from '../services/nativeBridge';
import { clearSession } from './session';
import { checkPaymentStatus } from '../services/payment';
import {orderSuccess} from './checkout';

export function applePayEventHandler(getState, payload, dispatch){
    let state = getState();
    let sessionID = state.session.ID;
    let checkoutState = state.checkout;
    switch (payload.data.type) {
        case 'onpaymentauthorized':
            createOrder(checkoutState, sessionID)
            .then((data) => {
				dispatch(orderSuccess(data));
                return sendEncryptedToken(sessionID, payload.data.payment.token)
                .then( (response) => {
                    // throw error if payment was not successfull
                    checkPaymentStatus(response);
                    // apple pay success
                    dispatch(completePayment(true));
                    // payment was successfull to reach this point
                    dispatch(paymentSuccess(sessionID));
                    hashHistory.push(`/order-confirmation/${sessionID}`);
                    dispatch(clearSession());
                })
            })
            .catch((error) => {
				dispatch(showError({errorInfo:`Unable to complete Apple Pay payment. ${error.refusalReason}`}));
                dispatch(completePayment(false));
            });
            break;
        default:
            break;
    }
}

/**
 *
 * @param {object} payload
 */
export function enableApplePayIfSupported(){

    let message = {
        messageType: APPLE_PAY_ENABLED,
        data: {
            supportedNetworks: ['Visa', 'MasterCard', 'Amex', 'Discover']
        }
    };

    return (dispatch) => postMessage(message);
}

/**
 * @description send message to native app to start applepay
 *
 */
export function startApplePay(){
    return (dispatch, getState) => {
        let {session, checkout, deliveryTypes} = getState();
        let message = {
            messageType: APPLE_PAY_START,
            data: startApplePaySession(session, checkout, deliveryTypes)
        };
        return postMessage(message);
    }
}

/**
 * @description Send message to native apple to complete apple pay payment
 *
 * @param {boolean} success
 *
 * return {function} redux thunk
 */
export function completePayment(success){
    let message = {
        messageType: APPLE_PAY_EVENT,
        data: {
            type: "completePayment",
            success
        }
    };
    return (dispatch) => postMessage(message);
}


export const ENABLE_APPLE_PAY = 'ENABLE_APPLE_PAY';
/**
 * enableApplePay
 *
 * @return [object] paymentMethod action
 */
export function enableApplePay(){
    return {
        type: ENABLE_APPLE_PAY
    }
}