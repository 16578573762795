// libraries
import React from 'react';
import config from '../../services/config/config';
import { navigateToExternal } from '../../services/navigate/navigate';
import Price from '../price/Price'
//images
import klarnaBadge from './klarnaBadge.png'

//CSS
import './KlarnaCalculator.scss'


/**
 *
 * Component
 *
 */

const KlarnaCalculator = React.createClass({
    render() {
        let { price, instalments } = this.props;
        return (<div className="KlarnaCalculator" onClick={()=>navigateToExternal(`${config.storeUrl}page/klarna/`)}>
            <img className="klarna-badge" src={klarnaBadge} alt="Klarna Badge" />
            <div className="copy">
                <div>Pay <Price amount={(parseFloat(price.amount) / instalments).toFixed(2).toString()} currency={price.currency} /> in {instalments} with Klarna</div>
                <div className="disclaimer">18+, Late payment fees apply, T&Cs apply, Credit subject to status.</div>
            </div>
        </div>)
    }
});

export default KlarnaCalculator
