import { connect } from 'react-redux';
import React from 'react';
import { Link } from 'react-router';


const Help = React.createClass({
	render() {
		let { campaign } = this.props;

		return (

			<li className="form-option-type">
				<Link to={`/page/${campaign.homePage}`} >
					<div className="check-option">
						<label>
							<h4>{campaign.name}</h4>
							<p>{campaign.subtitle}</p>
						</label>
					</div>
				</Link>
			</li>
		);
	}
});


//////////////////


export default connect(	
)(Help);
