import { Router, Route, hashHistory, IndexRoute } from 'react-router';
import CalendarPage from './containers/CalendarPage';
import CheckoutPage from './containers/CheckoutPage';
import HomePage from './containers/HomePage';
import LoginPage from './containers/LoginPage';
import LoginSignupJourneyPage from './components/loginSignupJourney/LoginSignupJourneyPage';
import NotFound from './components/notFound/NotFound';
import OrderPage from './containers/OrderPage';
import PreOrderPage from './containers/PreOrderPage';
import PaymentPage from './containers/PaymentPage';
import PreferencesPage from './containers/PreferencesPage';
import PreAuthPage from './containers/PreAuthPage';
import TutorialOnboarding from './containers/OnboardingTutorialPage';
import ProductPage from './containers/ProductPage';
import ContentPageContainer from './containers/ContentPageContainer';
import React from 'react';
import RootContainer from './containers/RootContainer';
import SignUpPage from './containers/SignUpPage';
import Adyen3DsecureRedirectForm from './components/adyenPaymentForm/Adyen3DsecureRedirectForm';
import KlarnaPaymentForm from './containers/KlarnaPage';
import SearchPage from './containers/SearchPage';
import MembershipPreferences from './components/preferences/membership/MembershipPreferences';
import SignupForm from './components/preferences/membership/SignupForm';
import AccountDeletion from './components/preferences/accountDeletion/AccountDeletion';
import Confirm from './components/preferences/accountDeletion/Confirm';
import Withdraw from './components/preferences/preOrders/Withdraw/Withdraw';
import Compleate from './components/preferences/preOrders/Withdraw/Compleate';

import './App.scss';

const onRouterUpdate = () => {
	let pageWrapper = document.getElementsByClassName('page-wrapper')
	if (pageWrapper[0]) {
		document.getElementsByClassName('page-wrapper')[0].scrollTop = 0
	}
};

const App = () => (
	<Router onUpdate={onRouterUpdate} history={hashHistory}>
		<Route path="/" component={RootContainer}>
			<IndexRoute component={HomePage} />
			<Route path="calendar" component={CalendarPage} />
			<Route path="product/:sku" component={ProductPage} />
			<Route path="page/:id" component={ContentPageContainer} />
			<Route path="preferences" component={PreferencesPage} />
			<Route path="preferences/raffle/:id/withdraw" component={Withdraw} />
			<Route path="preferences/raffle/withdraw/done" component={Compleate} />
			<Route path="preferences/membership" component={MembershipPreferences} />
			<Route path="preferences/delete" component={AccountDeletion} />
			<Route path="preferences/delete/confirm" component={Confirm} />
			<Route path="preferences/membership/signup" component={SignupForm} />
			<Route path="preferences/*" component={PreferencesPage} />
			<Route path="onboarding/tutorial" component={TutorialOnboarding} />
			<Route path="login" component={LoginPage} />
			<Route path="signup" component={SignUpPage} />
			<Route path="journey/:step" component={LoginSignupJourneyPage} />
			<Route path="checkout" component={CheckoutPage} />
			<Route path="checkout/:sku/preAuth(/:answer)" component={PreAuthPage} />
			<Route path="order-confirmation/:id" component={OrderPage} />
			<Route path="preorder-confirmation/:id" component={PreOrderPage} />
			<Route path="payment" component={PaymentPage} />
			<Route path="klarna" component={KlarnaPaymentForm} />
			<Route path="payment/3dsecure" component={Adyen3DsecureRedirectForm} />
			<Route path="search" component={SearchPage} />
			<Route path="*" component={NotFound} />
		</Route>
	</Router>
);

export default App;
