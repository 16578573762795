import React from 'react';
import TitleBlock from '../titleBlock/TitleBlock';
import { isObjectFull } from '../../services/checkout/checkout';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import './CollapsibleSection.scss';

const CollapsibleSection = React.createClass({
	getInitialState() {
		return {
			// if object is prefilled, then set editing state to false (so it shows collapsed)
			editing: !isObjectFull(this.props.propName, this.props.checkoutData, this.props.product)
		};
	},
	componentWillMount() {
		this.setState({ editing: !isObjectFull(this.props.propName, this.props.checkoutData, this.props.product) });
	},
	toggleEdit() {
		if(this.state.editing && this.props.trackSaveSection) this.props.trackSaveSection();
		this.setState({ editing: !this.state.editing });
	},
	render() {
		let { checkoutData, collapsedBlock, editBlock, propName, theme, title, product } = this.props;
		let { editing } = this.state;
		let canSave = isObjectFull(propName, checkoutData, product); //can only save if the object is full
		return (
			<div className="collapsibleSection">
				<div className="title">
					<TitleBlock title={title} />
					<button
						onClick={this.toggleEdit}
						disabled={!canSave}
						style={theme.primaryButton} >
						{editing ? 'save' : 'edit'}
					</button>
				</div>
				<ReactCSSTransitionGroup
					transitionName="checkout-block"
					transitionEnterTimeout={300}
					transitionLeaveTimeout={300} >
					{
						editing
							? editBlock
							: collapsedBlock
					}
				</ReactCSSTransitionGroup>
			</div>
		);
	}
});

export default CollapsibleSection;
