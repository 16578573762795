// Container component for the Root of the application. This container can
// be used for 'top heirarchy' logic e.g. session control, page authentification
import { connect } from 'react-redux';
import { withRouter } from 'react-router'
import Root from '../Root';
import { clearSession } from '../actions/session';
import { closeSplashLogin, continueAsGuest } from '../actions/content';

/**
 * Maps the state from the application state to props for the component
 * @param {Object} state
 * @return {Object}
 */
const mapStateToProps = (state) => {
	const { auth, session, content, startup } = state;
	return { auth, session, content, startup };
};

/**
 * Maps the actions to props for the Root component
 * @param {function} dispatch
 * @return {Object}
 */
const mapDispatchToProps = (dispatch) => {
	return {
		clearSession: () => dispatch(clearSession()),
		closeSplashLogin: path => dispatch(closeSplashLogin(path)),
		continueAsGuest: () => dispatch(continueAsGuest())
	}
};

// map state and dispatch methods to props for Root
const RootContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(Root));

export default RootContainer;
