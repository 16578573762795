import React, { PropTypes } from 'react';
import { hasDatePassed } from '../../services/time/time';
import './SizeList.scss';

const SizeList = ({ launchDate, sizes, onSizeSelection, productIsAvailable }) => {
	const handleSizeSelection = (size) => {
		onSizeSelection(size.optionID);
	}

	let list = sizes.map((size, i) => {
		return (
			<li key={size.optionID}>
				<button
					className={(size.selected ? "size selected" : "size") + " " + (size.isAvailable === true ? 'loading' : '')}
					onClick={(e) => handleSizeSelection(size)}
					disabled={ size.isAvailable === false }
				>
					{size.name}
				</button>
			</li>
		)
	});

	return (
		<ul className="size-list">
			{
			hasDatePassed(launchDate) && !list.length ?
				<h4>No sizes available</h4>: null
			}
			{list}
		</ul>
	);
};

SizeList.defaultProps = {
	sizes: []
};

SizeList.propTypes = {
	sizes: PropTypes.array,
	onSizeSelection: PropTypes.func
};

export default SizeList;