import { getQueryStringAsObject } from '../url';

export const OVERRIDDEN_POSTCODE_REGEX = {
	'GB': /^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$/i,
	'IL': /^(\d{5}|\d{7})$/
}

export const DEFAULT_CHANNEL = 'web-mosaic';

const STORE_CONFIG = {

	// SIZE LIVE CONFIG
	size: {
		is_app: true,
		store: 'size',
		storeDisplayName: 'size?',
		appDisplayName: 'size?launches',
		locale: 'gb',
		klarnaLocals: ['gb'],
		api: { api_key: '0ce5f6f477676d95569067180bc4d46d' },
		raffleDisclaimerPath: 'customer-service/size-launches',
		adyenLibraryKey: '1214343660265799', //TODO: NEED TO GET THE LIVE KEY
		adyenLibraryUrl: 'https://live.adyen.com/hpp/cse/js/',
		adyenClientKey: 'live_DEZCNOZTORAM5E4UMJ775QOBVMUI4NCN',
		adyenRaffleLength: 6,
		adyen: {
			adyenClientKey: 'live_DEZCNOZTORAM5E4UMJ775QOBVMUI4NCN',
			environment: 'live',
			locale: 'en_US',
		},
		shareUrl: 'https://www.sizelaunches.com/',
		analyticsUrl: 'https://www.sizelaunches.com/',
		storeUrl: 'https://www.size.co.uk/',
		calendarEventPrefix: 'size?launch Alert: ',
		usesCombinedFAQServie: false,
		launchLocations: {
			"website": "size.co.uk",
			"app": "size?launches"
		},
		googleAnalytics: [
			{ ID: 'UA-54989704-15', name: 'Size' }
		],
		trendingNow: ['Dunk', 'Air Jordan 1', 'Yeezy', 'Nike', 'adidas Originals'],
		//heroChatID: '77f1cfaf-aecd-4689-a9e9-ba7cf24edc68',
		reCaptchaKey: '6LcHpaoUAAAAANZV81xLosUR8pDqGnpTWUZw59hN',
		validation: {
			address: {
				minLength: 1,
				maxLength: 30
			}
		},
		social: {
			twitter: "https://twitter.com/sizeofficial",
			instagram: "https://www.instagram.com/sizeofficial",
			facebook: "https://www.facebook.com/sizeOfficial",
			youtube: "https://www.youtube.com/channel/UCL8PmfxreOZmaZt8-k-7Zqw",
			tiktok: "https://www.tiktok.com/@sizeofficial",
		},
		debug: false,
		supportsMembership: true,
		supportsKlarnaExclusive: false,
		redeye: {
			site: "SZUK",
			baseURL: "https://reporting.size.co.uk/",
			currency: 'GBP'
		},
		//heroChatID: '90ad826a-7880-4e70-8e01-2817b82a1060',
		layout: {
			nav: {
				position: "left",
				showLableForImage: false
			},
			pdp: {
				miniLaunchDate: true
			},
			raffleEntries: {
				ongoingColour: "#FF600B",
				winColour: "#2ECC7F",
				lossColour: "#C91515"
			},
			listStyle: "columns"
		},
	},

	// FOOTPATROL LIVE CONFIG
	footpatrolgb: {
		store: 'footpatrolgb',
		is_app: true,
		storeDisplayName: 'footpatrol',
		appDisplayName: 'footpatrol launches',
		locale: 'gb',
		klarnaLocals: ['gb'],
		api: { api_key: '6048110e2d7e087082d9a8d1c07b9e2c' },
		raffleDisclaimerPath: 'customer-service/footpatrol-launches',
		adyenLibraryKey: '1214343660265799',
		adyenLibraryUrl: 'https://live.adyen.com/hpp/cse/js/',
		adyenClientKey: 'live_DEZCNOZTORAM5E4UMJ775QOBVMUI4NCN',
		adyenRaffleLength: 5,
		adyen: {
			adyenClientKey: 'live_DEZCNOZTORAM5E4UMJ775QOBVMUI4NCN',
			environment: 'live',
			locale: 'en_US',
		},
		shareUrl: 'https://launches.footpatrol.com/',
		analyticsUrl: 'https://launches.footpatrol.com/',
		storeUrl: 'https://www.footpatrol.com/',
		calendarEventPrefix: 'footpatrol launch Alert: ',
		launchLocations: {
			"website": "footpatrol.com",
			"app": "footpatrol launches"
		},
		supportsMembership: false,
		usesCombinedFAQServie: true,
		googleAnalytics: [
			{ ID: 'UA-54989704-10', name: 'Footpatrol' }
		],
		trendingNow: ['Dunk', 'Air Jordan 1', 'Yeezy', 'Air Jordan 4', 'Air Max 1'],

		reCaptchaKey: '6LcHpaoUAAAAANZV81xLosUR8pDqGnpTWUZw59hN',
		validation: {
			address: {
				minLength: 1,
				maxLength: 30
			}
		},
		social: {
			twitter: "https://twitter.com/Footpatrol_ldn",
			instagram: "https://instagram.com/footpatrol_ldn",
			facebook: "https://www.facebook.com/footpatrol.london",
			youtube: "https://www.youtube.com/channel/UCiarPGhGGuzmigfspwQ0VAA",
		},
		debug: false,
		supportsKlarnaExclusive: false,
		//heroChatID: '90ad826a-7880-4e70-8e01-2817b82a1060',
		layout: {
			nav: {
				position: "bottom",
				showLableForImage: true,
				lableMap: {
					"Launches": "Feed",
					"Bookmarks": "Favorites",
					"Previous Launches": 'Previous'
				}
			},
			pdp: {
				miniLaunchDate: false
			},
			raffleEntries: {
				ongoingColour: "#3f3f3f",
				winColour: "#2ECC7F",
				lossColour: "#C91515"
			},
			listStyle: "full"
		}
	},

	// HIP LIVE CONFIG
	hipstoregb: {
		store: 'hipstoregb',
		is_app: false,
		storeDisplayName: 'HIP',
		appDisplayName: 'hipstore launches',
		locale: 'gb',
		klarnaLocals: ['gb'],
		api: { api_key: '09667273333ddbfd1e6b055ae783a89f' },
		raffleDisclaimerPath: 'customer-service/hip-launches',
		adyenLibraryKey: '1214343660265799',
		adyenLibraryUrl: 'https://live.adyen.com/hpp/cse/js/',
		adyenClientKey: 'live_DEZCNOZTORAM5E4UMJ775QOBVMUI4NCN',
		adyenRaffleLength: 5,
		adyen: {
			adyenClientKey: 'live_DEZCNOZTORAM5E4UMJ775QOBVMUI4NCN',
			environment: 'live',
			locale: 'en_US',
		},
		shareUrl: 'https://launches.thehipstore.co.uk/',
		analyticsUrl: 'https://launches.thehipstore.co.uk/',
		storeUrl: 'https://www.thehipstore.co.uk/',
		calendarEventPrefix: 'hipstore launch Alert: ',
		launchLocations: {
			"website": "thehipstore.co.uk",
			"app": "hipstore launches"
		},
		supportsMembership: false,
		usesCombinedFAQServie: true,
		googleAnalytics: [
			{ ID: 'UA-65962570-1', name: 'HipStore' }
		],
		trendingNow: ['Dunk', 'Air Jordan 1', 'Yeezy', 'Air Jordan 4', 'Air Max 1'],
		hawkDisable: true,
		reCaptchaKey: '6LcHpaoUAAAAANZV81xLosUR8pDqGnpTWUZw59hN',
		validation: {
			address: {
				minLength: 1,
				maxLength: 30
			}
		},
		social: {
			twitter: "https://twitter.com/thehipstore",
			instagram: "https://www.instagram.com/hip",
			facebook: "https://www.facebook.com/thehipstore",
		},
		debug: false,
		supportsKlarnaExclusive: false,
		headerLinks: [
			{ displayName: 'Mens', href: 'page/mens/' },
			{ displayName: 'Womens', href: 'page/womens/' },
			{ displayName: 'Living', href: 'page/hip-living/' },
			{ displayName: 'Brands', href: 'brands/' },
			{ displayName: 'Blog', href: 'https://blog.thehipstore.co.uk/' }
		],
		layout: {
			useDesktopHeader: true,
			nav: {
				position: "none",
			},
			pdp: {
				miniLaunchDate: true
			},
			raffleEntries: {
				ongoingColour: "#3f3f3f",
				winColour: "#2ECC7F",
				lossColour: "#C91515"
			},

			listStyle: "columns"
		}
	},

	oneiota: {
		store: 'oneiota',
		locale: 'gb',
		adyenLibraryKey: '8714313625623861',
		adyenLibraryUrl: 'https://test.adyen.com/hpp/cse/js/',
		debug: false,
	},

	localhost: {
		store: 'size',
		locale: 'gb',
		//adyenLibraryKey: '8714313625623861'
	}
};

const UAT_STORE_CONFIG = {

	size: {
		adyenLibraryKey: '8214907202630663',
		adyen: {
			adyenClientKey: 'test_NH6ZWZE4BFEALKGI4YKYMW5SP4PX4VRA',
			environment: 'test',
			locale: 'en_US',
		},
		shareUrl: 'https://uat-size-mosaic-share.mesh.mx/',
		analyticsUrl: 'https://dev-size-mosaic-share.mesh.mx/',
		storeUrl: 'https://uat-uatmesh-size-mobile.nonprod.jdmesh.co/',
		adyenLibraryUrl: 'https://test.adyen.com/hpp/cse/js/',
		googleAnalytics: [
			{ ID: 'UA-54989704-15', name: 'Size' }
		],
		debug: true,
	},

	footpatrolgb: {
		adyenLibraryKey: '8214907202630663',
		adyen: {
			adyenClientKey: 'test_NH6ZWZE4BFEALKGI4YKYMW5SP4PX4VRA',
			environment: 'test',
			locale: 'en_US',
		},
		storeUrl: 'https://uat-uatmesh-footpatrolgb-mobile.nonprod.jdmesh.co/',
		adyenLibraryUrl: 'https://test.adyen.com/hpp/cse/js/',
		googleAnalytics: [
			{ ID: 'UA-54989704-10', name: 'Footpatrol' }
		],
		debug: true,
	},

	hipstoregb: {
		adyenLibraryKey: '8214907202630663',
		adyen: {
			adyenClientKey: 'test_NH6ZWZE4BFEALKGI4YKYMW5SP4PX4VRA',
			environment: 'test',
			locale: 'en_US',
		},
		storeUrl: 'https://uat-uatmesh-hipstoregb-desktop.nonprod.jdmesh.co/',
		adyenLibraryUrl: 'https://test.adyen.com/hpp/cse/js/',
		googleAnalytics: [
			{ ID: 'UA-65962570-1', name: 'HipStore' }
		],
		debug: true,
	}
};

const DEV_STORE_CONFIG = {

	size: {
		shareUrl: 'https://dev-size-mosaic-share.mesh.mx/',
		analyticsUrl: 'https://dev-size-mosaic-share.mesh.mx/',
		storeUrl: 'https://dev-uatmesh-size-mobile.nonprod.jdmesh.co/',
		adyenLibraryKey: '8214907202630663', //local key,
		adyen: {
			adyenClientKey: 'test_NH6ZWZE4BFEALKGI4YKYMW5SP4PX4VRA',
			environment: 'test',
			locale: 'en_US',
		},
		debug: true,
	},

	footpatrolgb: {
		storeUrl: 'https://dev-uatmesh-footpatrolgb-mobile.nonprod.jdmesh.co/',
		adyenLibraryKey: '8214907202630663', //local key,
		adyen: {
			adyenClientKey: 'test_NH6ZWZE4BFEALKGI4YKYMW5SP4PX4VRA',
			environment: 'test',
			locale: 'en_US',
		},
		debug: true,
	},
	
	hipstoregb: {
		storeUrl: 'https://dev-uatmesh-hipstoregb-desktop.nonprod.jdmesh.co/',
		adyenLibraryKey: '8214907202630663', //local key,
		adyen: {
			adyenClientKey: 'test_NH6ZWZE4BFEALKGI4YKYMW5SP4PX4VRA',
			environment: 'test',
			locale: 'en_US',
		},
		debug: false,
	}
};


export const DEV = 'dev';
export const UAT = 'uat';
export const LIVE = 'live';
export const LOCAL = 'localhost';

/************************************************************
	* Helpers
	***********************************************************/

function extractStoreFromHost(host) {
	return process.env.REACT_APP_STORE_NAME
}

function extractEnvironmentFromHost(host) {
	return process.env.REACT_APP_ENV
}

export function isInNativeApp() {
	//If We have the oauth token key, then we know were in native mode
	if (localStorage.getItem('isNativeExperience')) {
		return true;
	}

	let qPrams = new URLSearchParams(window.location.search);
	if (qPrams.get('isNativeExperience')) {
		localStorage.setItem('isNativeExperience', 'true')
		return true;
	}
	return false
}

/************************************************************
   * Properties
   ***********************************************************/

const calendarEventPrefix = '';
const host = window.location.host;
const env = extractEnvironmentFromHost(host);
const googleAnalytics = [];
const queryOverides = getQueryStringAsObject();
const shareUrl = '/';
const store = extractStoreFromHost(host);
const launchLocations = [];

let config = {
	calendarEventPrefix,
	launchLocations,
	env,
	googleAnalytics,
	queryOverides,
	shareUrl,
	store
};
config = Object.assign(config,
	STORE_CONFIG[store]
);

if (env === UAT) {
	config = Object.assign(config, UAT_STORE_CONFIG[store]);
} else if (env === DEV || env === LOCAL) {
	config = Object.assign(config, UAT_STORE_CONFIG[store], DEV_STORE_CONFIG[store]);
}

export default config;
