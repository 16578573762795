import { postMessage } from '../nativeBridge';
import { CLOSE_NATIVE } from '../nativeBridge/messageTypes';

/**
 * Show Review Lightbox
 * 
 */
export const nativeClose = (fatal = false) => {
	let message = {
		messageType: CLOSE_NATIVE,
		data: {
			fatal
		}
	};
	postMessage(message);
};

