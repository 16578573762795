// Container component for the product page
import { connect } from 'react-redux';
import Product from '../components/product/Product';
import {launchProduct,
        selectSize,
        selectQuantityOfEntries,
        loadProduct,
        handleAddToBasket,
        verificationRecieved,
        getCurrentStock
       } from '../actions/product';
import { clearSession } from '../actions/session';



/**
 * mapStateToProps
 *
 * @description maps the product state from the application state to props for the
 * product component
 *
 * @param [object] state
 * @return [object] product state
 */
const mapStateToProps = (state) => {
  const {product, loading, content, session} = state;
  return {product, loading, theme : content.theme, session, content};
}

/**
 * mapDispatchToProps
 *
 * @description maps the product actions to props for the Product component
 *
 * @param [function] dispatch
 * @return [object] product props
 */
const mapDispatchToProps = (dispatch) => {
  return {
      clearSession: () => dispatch(clearSession()),

      loadProduct: (sku) => dispatch(loadProduct(sku)),

      selectSize: (sku) => dispatch(selectSize(sku)),

      selectQuantityOfEntries: (quantityOfEntries) => dispatch(selectQuantityOfEntries(quantityOfEntries)),

      launchProduct: () => dispatch(launchProduct()),

      handleAddToBasket : (ID, option, verification, session, track) => dispatch(handleAddToBasket(ID, option, verification, session, track)),

      verificationRecieved : (verification) => dispatch(verificationRecieved(verification)),

      getCurrentStock: (productID) => dispatch(getCurrentStock(productID))
  }
}

// map state and dispatch methods to props for Product
const ProductPage = connect(
    mapStateToProps,
    mapDispatchToProps
)(Product);

export default ProductPage;
