import { connect } from 'react-redux';
import Calendar from '../components/upcomingLaunches/UpcomingLaunches';

const mapStateToProps = state => {
	const { loading, content } = state;
	return { products: content.upcoming, loading, theme: content.theme, brands: content.brands };
};

const mapDispatchToProps = dispatch => {
	return {};
};

const UpcomingLaunchesPage = connect(
	mapStateToProps,
	mapDispatchToProps
)(Calendar);

export default UpcomingLaunchesPage;
