import React, { Component } from 'react'
import AchievementBadges from './AchievementBadges';
import AnnualWins from './AnnualWins';
import Stickers from './Stickers';

export default class UserTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
    };
  }
  openMenu() {
    const active = !this.state.active
    this.setState({
      active: active
    });
  }

  render() {
    const { userData } = this.props;

    if (userData) {
      const userInitials = userData.personal.firstName.charAt(0) + userData.personal.lastName.charAt(0);
      const userID = this.props.userID.substr(this.props.userID.length - 5)
      const userName = userData.personal.firstName + "#" + userID
      return (
        <div className={this.state.active ? "userTabContainer active" : "userTabContainer inactive"} >
          <div className="user_name">
            <div className={this.state.active ? "plusminus active" : "plusminus"} onClick={() => this.openMenu()} />
            <div className='leaderboard_initials'>
              {userInitials}
            </div>
            <div>{userName}</div>
          </div>
          <AnnualWins totalWins={this.props.loyalty.wins} />
          <AchievementBadges totalWins={this.props.loyalty.wins} content={this.props.content} />
          <Stickers loyalty={this.props.loyalty} content={this.props.content} />
        </div>
      )
    } else {
      return null;
    }
  }
}