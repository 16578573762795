import config from '../config/config';
import storage from '../storage/storage';

let _endSessionOnNextPageview = false;

/**
 *
 */
export function analyticsInit() {
	includeGA(); //includes the GA script on the page

	// init for each account in config
	for (let account of config.googleAnalytics) {
		window.ga('create', account.ID, 'auto', { name: account.name });
		window.ga(account.name + '.require', 'ec');
		// window.ga(account.name + '.set', 'location', config.analyticsUrl)
	}
	window.ga(function () {
		if (window.ga.getAll().length) {
			let clientId = window.ga.getAll()[0].get('clientId');
			if (clientId) {
				storage.setItemNoExpiry('gaClientID', clientId);
			}
		}
	});
}

export function trackEvent(category, action, opt_label, opt_value, additions = null, params = null) {
	if (typeof action === 'object') {
		action = action.ga
	}
	for (let account of config.googleAnalytics) {

		if (additions != null && additions.length) {
			for (let option of additions) {
				window.ga(account.name + '.' + option.action, option.args);
			}
		}

		window.ga(account.name + '.send', 'event', category, action, opt_label, opt_value || 0);
	}
}

/**
 *
 */
export function endSessionOnNextPageview() {
	_endSessionOnNextPageview = true;
}


/**
 *
 * @param {*} order
 */
export function trackOrder(order) {

	for (let account of config.googleAnalytics) {

		const currency = order.subTotal.currency;

		window.ga(account.name + '.set', 'currencyCode', currency);

		for (let item of order.stock) {

			if (item.product.PLU) {
				window.ga(account.name + '.ec:addProduct', {
					id: item.product.PLU,
					name: item.product.name || '',
					category: '',
					price: item.product.price.amount || 0,
					quantity: 1
				})
			}

		}

		window.ga(account.name + '.ec:setAction', 'purchase', {
			id: order.sessionID,
			affiliation: config.name,
			revenue: order.total.amount || 0,
			tax: '',
			shipping: order.deliveryMethod.price.amount || 0
		});

		window.ga(account.name + '.send', 'event', 'Ecommerce', 'purchase', { 'nonInteraction': 1 });
	}
}

/**
 *
 * @param {*} order
 */
export function trackPreAuth(product, order) {
	for (let account of config.googleAnalytics) {
		window.ga(account.name + '.send', 'event', 'ecommerce', 'preauth', product.name);
	}
}

/**
 *
 * @param {*} path
 * @param {*} ec_options
 */
export function trackPage(path = null, overrides = null) {
	if (!path) path = window.location.hash.split('?')[0].replace('#', '');

	let options = {};
	if (_endSessionOnNextPageview) {
		options.sessionControl = 'end';
		_endSessionOnNextPageview = false;
	}

	// send for each ga account
	for (let account of config.googleAnalytics) {
		if (overrides != null && overrides.length) {
			for (let option of overrides) {
				window.ga(account.name + '.' + option.action, option.args);
			}
		} else {
			window.ga(account.name + '.set', 'page', path);
		}

		window.ga(account.name + '.send', 'pageview', options);
	}
}


//////////////////
// private

/**
 * Does the call to include the GA script
 */
function includeGA() {
	// eslint-disable-next-line
	(function (i, s, o, g, r, a, m) { i['GoogleAnalyticsObject'] = r; i[r] = i[r] || function () { (i[r].q = i[r].q || []).push(arguments) }, i[r].l = 1 * new Date(); a = s.createElement(o), m = s.getElementsByTagName(o)[0]; a.async = 1; a.src = g; m.parentNode.insertBefore(a, m) })(window, document, 'script', '//www.google-analytics.com/analytics.js', 'ga');
}
