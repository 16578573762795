import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import './ErrorBar.scss'

const ErrorBar = ({message, title, show, close, dismissButton}) => (
    <ReactCSSTransitionGroup
        transitionName="error-bar"
        transitionEnterTimeout={200}
        transitionLeaveTimeout={200}
    >
        { show ? <div key="error" className="error">
            <div className="errorContent">
                {title ? <h2>{title}</h2> : <h2>Oops, <br></br> something's gone wrong there - please try again</h2> }
                <h4>{message}</h4>
                <button onClick={close}><div className="button-contents">{dismissButton}</div></button>
            </div>
        </div> : null }
    </ReactCSSTransitionGroup>
);

export default ErrorBar;