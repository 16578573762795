import { hashHistory } from 'react-router';
import { loadRaffleEntries, withdraw } from '../../../../actions/content';
import { connect } from 'react-redux';
import React from 'react';
import TitleBlock from '../../../titleBlock/TitleBlock';
import './PreOrderWithdraw.scss';
import { trackPage } from '../../../../services/analytics/analytics';

//////////////////


const Withdraw = React.createClass({
	componentWillMount() {
		this.props.loadRaffleEntries();
		trackPage('preferences/preOrders/withdraw/done');
	},
	backToDashboard() {
		hashHistory.push(`/preferences`)
	},
	render() {
		return (
			<div className="Preferences PreOrders Withdraw">
				<div className="content-scroll">
					<TitleBlock title="Draw Removed" />
					<div className="form-horizontal">
						Your entry has been cancelled, If you wish to re-enter you will have to start the process again.
					</div>

					<TitleBlock title="Refund" />
					<div className="form-horizontal">
						Although your draw has been refunded, the funds can take up to <b>3-5 days</b> to show up in your account.
					</div>
					<div>
						<button className="action level-2" onClick={() => this.backToDashboard()}>
							<div className="button-contents">Go Back</div></button>
					</div>
				</div>
			</div>
		);
	}
});

const mapStateToProps = state => {
	const { content } = state;
	return { orders: content.orders };
};

const mapDispatchToProps = dispatch => {
	return {
		loadRaffleEntries: () => dispatch(loadRaffleEntries()),
		withdraw: (id) => dispatch(withdraw(id))
	};
};


//////////////////


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Withdraw);
