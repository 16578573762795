import React, { PropTypes } from 'react';
import TextField from '../textInput/TextInput';
import './LocaleSelector.scss';

const LocaleListItem = ({ onClick, locale, children, closed, selected }) => (
	<li className={closed ? "form-option-type close" : "form-option-type"} onClick={evt => onClick(evt, locale)}>
		<div className="check-option">
			{closed ? <input type="radio" checked selected readOnly /> : null}
			<label>
				{locale.locale ? <span className="check-box"></span> : null}
				<span className="name">{locale.name}</span>
				{children}
			</label>
		</div>
	</li>
);

const LocaleSelector = React.createClass({
	getInitialState() {
		return {
			search: "",
			value: this.getCountryByLocale(this.props.locale, this.props.locales).name,
			visibleLocales: []
		}
	},

	getCountryByLocale(locale, locales) {
		return locales.find((value, i) => locale === value.locale) || {};
	},

	shouldComponentUpdate(nextProps, nextState) {
		return (nextProps.locale !== this.props.locale || nextState !== this.state);
	},

	componentWillReceiveProps(nextProps) {
		if (nextProps.locale !== this.props.locale || nextProps.locales.length !== this.props.locales.length) {
			this.setState({
				value: this.getCountryByLocale(nextProps.locale, nextProps.locales).name
			})
		}
	},

	onSelection(e, value) {
		let { updateLocale } = this.props;
		if (value.locale) {
			updateLocale(value.locale);
			this.setState({ value: value.name });
		}
	},

	deselectLocale() {
		let { updateLocale } = this.props;
		updateLocale(null);
		this.setState({ value: "" });
	},

	fetchLocales(search) {
		this.setState({
			search,
			visibleLocales: getSuggestions(search, this.props.locales)
		})
	},

	getFilteredLocales() {
		return this.state.visibleLocales.map((locale, i) => {
			return <LocaleListItem key={i} onClick={this.onSelection} locale={locale} />
		})
	},

	render() {
		let filteredLocales = this.getFilteredLocales();

		return (
			<div className="form-horizontal form-options locale-selector">
				{
					this.state.value
						? <div className="form-group form-suggestion">
				            <label className="selected-country">Country</label>
							<ul>
								<LocaleListItem onClick={this.deselectLocale} locale={{ name: this.state.value, locale: this.props.locale }} closed={true}>
									<span className="price"><span></span></span>
								</LocaleListItem>
							</ul>
						</div>
						: <div className="form-group form-suggestion">
							<TextField
								type="text"
								floatingLabelText="Enter Country"
								value={this.state.search}
								onChange={evt => this.fetchLocales(evt.target.value)}
								hintText="Search by store name"
								fullWidth={true} />
							<ul className={filteredLocales.length ? null : 'empty'}>
								{filteredLocales}
							</ul>
						</div>
				}
			</div>
		);
	}
});


LocaleSelector.propTypes = {
	locales: PropTypes.arrayOf(PropTypes.shape({
		locale: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired
	})),
	locale: PropTypes.string.isRequired
};

LocaleSelector.defaultProps = {
	locales: [{ locale: '', name: '' }],
	locale: ''
};

export default LocaleSelector;


/**
 *
 * @param {*} value
 * @param {*} param1
 */
function getSuggestions(value, locales) { //autosuggest filter function
	const inputValue = value.trim().toLowerCase();
	const inputLength = inputValue.length;

	let suggestions = inputLength === 0 ? locales.slice(0, 10) : locales.filter(locale =>
		locale.name.toLowerCase().startsWith(inputValue.toLowerCase())
	).slice(0, 10);

	if (suggestions.length === 0) suggestions.push({ name: "No countries found" });
	return suggestions;
}