import React from 'react'

function LeaderBoardItem({ props, placement, content }) {
  const collectedBadges = props.badges;
  const badgesArray = content.badges;
  const badges = badgesArray.filter((item, index) => {
    const arrayy = Object.keys(collectedBadges)
    const truthy = arrayy.find(badge => badge === item.id);
    if (truthy) {
      return item
    } else {
      return null
    }
  })
  return (
    <div className='leaderboard_item'>
      <div className='leaderboard_item_left'>
        {placement === 0 && (
          <div className='leaderboard_1st'>
            <div>1</div>
            <div className='username_container'>
              <div className='leaderboard_username'>{props.firstName}#{props.userID.slice(-5)}</div>
            </div>
          </div>
        )}
        {placement === 1 && (
          <div className='leaderboard_2nd'>
            <div>2</div>
            <div className='username_container'>
              <div className='leaderboard_username'>{props.firstName}#{props.userID.slice(-5)}</div>
            </div>
          </div>
        )}
        {placement === 2 && (
          <div className='leaderboard_3rd'>
            <div>3</div>
            <div className='username_container'>
              <div className='leaderboard_username'>{props.firstName}#{props.userID.slice(-5)}</div>
            </div>
          </div>
        )}
        {placement >= 3 && (
          <div className='leaderboard_regular_placement'>
            <div className="regular_placemenet">
              <div>{placement + 1}</div>
            </div>
            <div className='username_container'>
              <div className='leaderboard_username'>{props.firstName}#{props.userID.slice(-5)}</div>
            </div>
          </div>
        )}
        {placement === 0 && (
          <div className='leaderboard_1st_wins'>
            <div style={{ fontSize: "7px" }}>WINS</div>
            {props.wins}
          </div>
        )}
        {placement === 1 && (
          <div className='leaderboard_2nd_wins'>
            <div style={{ fontSize: "7px" }}>WINS</div>
            {props.wins}
          </div>
        )}
        {placement === 2 && (
          <div className='leaderboard_3rd_wins'>
            <div style={{ fontSize: "7px" }}>WINS</div>
            {props.wins}
          </div>
        )}
        {placement >= 3 && (
          <div className='leaderboard_regular_placement_wins'>
            <div style={{ fontSize: "7px", fontWeight: "regular" }}>WINS</div>
            {props.wins}
          </div>
        )}

        <div className='leaderboard_item_line'></div>
      </div>
      <div className='leaderboard_item_right'>
        {badges && badges.map((item, index) => (
          <div className="sticker_container" key={index}>
            <div key={index} className="sticker">
              <img src={item.image} alt={item.id} />
            </div>
            <div className="stickerCount">x{collectedBadges[item.id]}</div>
          </div>
        ))
        }
      </div>
    </div>
  )
}

export default LeaderBoardItem