import React from 'react';
import { navigateToExternal } from '../../services/navigate/navigate';
import config from '../../services/config/config';


//////////////////


const PreAuthDisclaimer = React.createClass({
    buildDefault() {
        const { checkout } = this.props;

        if (checkout.paymentMethod.type) {
            switch (checkout.paymentMethod.type) {
                case 'KLARNA':
                    switch (checkout.paymentMethod.klarnaType) {
                        case 'pay_over_time':
                            return `Once you've confirmed your payment via Klarna Pay in 3, you will be redirected to Klarna to confirm your details and accept Klarna’s T&Cs – you must be 18+. Once you have placed your order, you will be redirected back to ${config.appDisplayName} and payment for your first instalment will be taken by Klarna - you will have committed to purchasing the product. You'll receive an email to confirm your order, the product will be sent via your chosen delivery method. `
                        case 'pay_later':
                            return `Once you've confirmed your payment via Klarna Pay in 30 days, you will be redirected to Klarna to confirm your details and accept Klarna’s T&Cs – you must be 18+. Once you have placed your order, you will be redirected back to ${config.appDisplayName} and you will have committed to purchasing the product. You'll receive an email to confirm your order, the product will be sent via your chosen delivery method and payment will be taken by Klarna 30 days after dispatch. `
                        default:
                            return ``
                    }
                default:
                    return false
            }
        }
    },
    render() {
        const { checkout, toggleCheckoutConfirmation } = this.props;
        return checkout.paymentMethod.type && checkout.paymentMethod.type === 'KLARNA' ? (
            <div className="check-option no-bold preAuthConfirm">
                <span>
                    <span>
                        {this.buildDefault()}
                        See details on Klarna <span className='link' onClick={() => navigateToExternal(`${config.storeUrl}page/klarna/`)}>{`here`}.</span>
                    </span>
                </span>
                <span>
                    <input type="checkbox" id="preAuthConfirm" checked={checkout.checkoutConfirmation} onChange={toggleCheckoutConfirmation} />
                    <label htmlFor="preAuthConfirm">
                        <span className="check-box tick"></span>
                        <span className="name">Accept</span>
                    </label>
                </span>
            </div>
        ) : null;
    }
});

export default PreAuthDisclaimer