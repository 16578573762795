import MenuItem from 'material-ui/MenuItem';
import React from 'react';
import FlatButton from 'material-ui/FlatButton';
import SelectField from 'material-ui/SelectField';


//////////////////


const NotifyTimeSelector = ({ changeProperty, ind, intervals, model, remove, canRemove }) => {
	// intercepts the changeProperty call and makes sure the time value isn't greater than a possible max
	let changeHandler = (evt, ind, val, property) => {
		// prevents any elements under the select window being clicked
		evt.preventDefault();

		if (property === 'time') {
			// make sure the selected time is not greater than the interval max, less than 1, or isNaN
			// but do allow empty string, as user may have deleted content to type new stuff in
			if (!val && val !== 0) {
				val = '';
			} else if (isNaN(val) || val < 1) {
				val = 1;
			} else if (val > intervals[model.interval].max) {
				val = intervals[model.interval].max;
			}
		}
		changeProperty(ind, val, property);
	};

	let options = Object.keys(intervals).map((key, ndx) => <MenuItem key={ndx} value={+key} primaryText={intervals[key].label} />);

	return (
		<div className="notifyTimeBefore">
			<div className="time form-group">
				<FlatButton className="decrement"
					onClick={(evt) => changeHandler(evt, ind, +model.time - 1, 'time')}
					label="-" />
				<div className="group">
					<div className="input">
						{model.time}
					</div>
					<span className="highlight"></span>
					<span className="bar"></span>
					<label></label>
				</div>
				<FlatButton className="increment"
					onClick={(evt) => changeHandler(evt, ind, +model.time + 1, 'time')}
					label="+" />
			</div>
			<div className="interval form-group">
				<SelectField
					value={+model.interval}
					onChange={(evt, ndx, val) => changeHandler(evt, ind, +val, 'interval')}
					underlineStyle={{ borderColor: '#000000', borderWidth: '2px' }}
					iconStyle={{ fill: '#000000' }}
					labelStyle={{ color: '#000000' }}
					autoWidth={true}>
					{options}
				</SelectField>
			</div>
			{canRemove?<div className="remove" onClick={() => remove(ind)}><div><span>X</span></div></div>:null}
		</div>
	);
};


//////////////////


export default NotifyTimeSelector;
