import { connect } from 'react-redux';
import { addNotifiedProduct } from '../../actions/notifications';
import { bookmarkProduct } from '../../actions/bookmarks';
import { hasNotification } from '../../services/notifications/notifications';
import { hasBookmark } from '../../services/bookmarks/bookmarks';
import DateTime from '../dateTime/DateTime';
import React from 'react';

import './NotifyAndBookmarkButton.scss';

//////////////////


const NotifyAndBookmarkButton = ({ disabled, className, style, notifyAndBookmark, productID, name, launchDate, launchType, children, added}) => (
	<button disabled={added ? true : disabled} className={`${className} notifyBookmarkButton`} style={style} onClick={e => notifyAndBookmark(productID, name, launchDate, launchType)}>
		{added ? <DateTime date={launchDate}/> : children}
	</button>
)

/**
 * Maps the state from the application state to props
 * Bookmark components
 * @param {Object} state app state
 * @param {Object} ownProps properties passed into component
 * @return {Object}
 */
const mapStateToProps = (state, ownProps) => {
	const { disabled, className, style, dateOffset } = ownProps;
	const {product} = state;
	const {launchDate, name, ID} = product;
	let modifiedLaunchDate = new Date(launchDate);
	if(dateOffset){
		modifiedLaunchDate.setDate(modifiedLaunchDate.getDate() + dateOffset)
	}
	const added = hasNotification(ID) && hasBookmark(ID);
	return { launchDate: modifiedLaunchDate.toISOString(), name, productID: ID, disabled, added, className, style };
};

/**
 * Maps actions to props
 * @param {function} dispatch
 * @return {Object}
 */
const mapDispatchToProps = dispatch => {
	return {
		notifyAndBookmark: (productID, name, launchDate, launchType) => {
			dispatch(addNotifiedProduct(productID, name, launchDate, launchType));
			dispatch(bookmarkProduct(productID, name));
		}
	};
};


//////////////////


// map state and dispatch methods to component props and export the full thing
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(NotifyAndBookmarkButton);
