import { hasDatePassed } from "../../../services/time/time";

import { Link } from "react-router";
import BookmarkProductButton from "../../../containers/BookmarkButtonContainer";
import DateTime from "../../dateTime/DateTime";
import LaunchDateMini from "../../launchDateMini/LaunchDateMini";
import KlarnaExclusive from "../../klarnaExclusive/KlarnaExclusive";

import React, { PropTypes } from "react";
import Price from "../../price/Price";

import "./CalendarItem.scss";

//////////////////

const CalendarItem = ({
	buttonStyles,
	image,
	launchDate,
	launchStartDate,
	launchType,
	sku,
	title,
	subtitle,
	soldOut,
	trackScroll,
	type,
	product,
	price,
	noRedirect
}) => (
	<li
		className={`product ${launchType} ${isProductClosed({
			launchType,
			launchDate,
			soldOut,
			product
		})}`}
	>
		<Link
			to={!noRedirect ? `/product/${sku}` : null}
			onClick={trackScroll ? () => trackScroll() : null}
		>
			<div
				className="image"
				style={{ backgroundImage: `url(${image.original})` }}
			>
				<LaunchDateMini launchDate={launchDate} />
				<KlarnaExclusive product={product} />
			</div>
			<div className="productInfo">
				<div className="productDetails">
					<h3 className="title">{title}</h3>
					<div className="subtitle-price">
						<h3 className="title">{subtitle} </h3>
						{price ? (
							<Price
								className="price"
								amount={price.amount}
								currency={price.currency}
							/>
						) : null}
					</div>
				</div>
				<hr />
				<div className="itemFooter">
					<span className="launchInfo">
						{launchType === "monthOnly" ? (
							soldOut ? (
								<h4 className="soldout">Sold Out</h4>
							) : (
								<DateTime date={launchDate} format="MMMM" />
							)
						) : launchType === "raffle" ? (
							launchStartDate &&
							!hasDatePassed(launchStartDate) ? (
								<DateTime date={launchDate} format="DD MMMM" />
							) : launchDate && !hasDatePassed(launchDate) ? (
								<h4 className="outnow"> Draw Open</h4>
							) : (
								<h4 className="soldout">Draw Closed</h4>
							)
						) : launchDate && !hasDatePassed(launchDate) ? (
							<DateTime date={launchDate} format="DD MMMM" />
						) : soldOut ? (
							<h4 className="soldout">Sold Out</h4>
						) : (
							<h4 className="outnow">Out Now!</h4>
						)}
					</span>
				</div>
			</div>
		</Link>
		<span className="actions">
			<BookmarkProductButton
				productID={sku}
				name={title}
				launchDate={launchDate}
				launchType={launchType}
			/>
		</span>
	</li>
);

CalendarItem.defaultProps = {
	image: {},
	launchType: "full",
	title: ""
};

CalendarItem.propTypes = {
	image: PropTypes.object.isRequired,
	launchDate: PropTypes.string,
	launchStartDate: PropTypes.string,
	launchType: PropTypes.string,
	sku: PropTypes.string.isRequired,
	title: PropTypes.string
};

function isProductClosed({ launchType, launchDate, soldOut, product }) {
	const isRaffleClosed = launchType === "raffle" && hasDatePassed(launchDate);
	const isFullAndSoldOut = launchType === "full" && soldOut;
	const isHipstoreProduct = product.storeName === "hipstoregb";
	return `${(isRaffleClosed || isFullAndSoldOut) && isHipstoreProduct ? "draw-closed" : ""}`;
}

//////////////////

export default CalendarItem;
