import React, { PropTypes } from 'react';
import './PaymentMethods.scss';
import AdyenCVCOnlyPaymentForm from '../adyenPaymentForm/AdyenCVCOnlyPaymentForm';
import Countdown from '../countdownTimer/Countdown';
import NotifyAndBookmarkButton from '../notifyAndBookmarkButton/NotifyAndBookmarkButton';

import isKlarnaExclusive from '../../services/payment/isKlarnaExclusive';
import getCardActivationDate from '../../services/payment/getCardActivationDate';
import config from '../../services/config/config';

const PaymentMethod = ({ option, updatePaymentMethod, selectedPaymentMethod, show, params, refreshToken, loyalty, product, isFormValid, session, context }) => (
	<li className={`form-option-type ${option.type}`}>
		{show ?
			<div className="check-option">
				<input id={`paymentMethod${option.ID}`} type="radio" name="paymentMethod" value={option.ID} onChange={updatePaymentMethod} checked={selectedPaymentMethod.ID === option.ID} />
				<label htmlFor={`paymentMethod${option.ID}`} className={option.type}>
					<span className="check-box"></span>
					<span className="name">{option.name}
						<span>{option.description}</span>
					</span>
					{
						option.type === 'RECURRING' ?
							<AdyenCVCOnlyPaymentForm
								selectedPaymentMethod={selectedPaymentMethod}
								generatedTime={new Date().toISOString()}
								submitText={`Enter${loyalty.isPremium && !product.charity ? ' with size?access' + (loyalty.isBoostWeek ? '+' : '') + (localStorage.getItem('isBoosted') ? '+' : '') : ''}`}
								params={params}
								refreshToken={refreshToken}
								isFormValid={isFormValid}
								storePaymentMethod={false}
								session={session}
								context={context}
							/> : null
					}
					<span className={`logo ${option.paymentMethodVariant === 'klarna' ? 'klarna' : `card${option.paymentMethodVariant}`}`}>
					</span>
				</label>
			</div>
			: null
		}
	</li>
)

const PaymentMethods = ({ options, updatePaymentMethod, selectedPaymentMethod, product, params, refreshToken, loyalty, isFormValid, session, context }) => {
	let paymentMethodsList = options

	//If the product is (a timed) klarna exclusive, filter out all payment methods that are not klarna,
	if (isKlarnaExclusive(product)) {
		paymentMethodsList = paymentMethodsList.filter((el) => { return el.type === 'KLARNA' })
	}

	//Dont map hidden elements, so its easier to work out if there are no payment methods to show
	paymentMethodsList = paymentMethodsList.filter((el) => { return el.show && !el.hidden }).map((el, i) => <PaymentMethod key={i} option={el} updatePaymentMethod={(e) => updatePaymentMethod(el)} selectedPaymentMethod={selectedPaymentMethod} show={el.show && !el.hidden} params={params} refreshToken={refreshToken} loyalty={loyalty} product={product} isFormValid={isFormValid} session={session} context={context} />);


	return (
		<div className="form-horizontal form-options form-payments">
			{paymentMethodsList.length ?
				<ul>
					{paymentMethodsList}
				</ul> : isKlarnaExclusive(product) ?
					<div className="noPayments">We can currently only offer Klarna payments to eligible customers in the UK.</div> : null
			}
			{
				//If the product has a card payment option AND its still klarna exclusive, show the countdown
				(!product.paymentGateways || product.paymentGateways.includes('Adyen')) && isKlarnaExclusive(product) ?
					<div className="cardUnlock">
						<b>Card payment unlocks in...</b>
						<Countdown end={getCardActivationDate(product)} />
						<NotifyAndBookmarkButton disabled={false} dateOffset={-config.adyenRaffleLength} className='action'>
							<div className="button-contents">
								<span>Notify me & add to Bookmarks</span>
							</div>
						</NotifyAndBookmarkButton>
					</div> : null
			}
		</div>
	);
}


PaymentMethods.defaultProps = {
	options: []
}

PaymentMethods.propTypes = {
	options: PropTypes.array,
	updatePaymentMethod: PropTypes.func
}

export default PaymentMethods;