/**
 * iosPostMessage
 * 
 * @param {object} payload
 * @return {Promise}
 */
function iosPostMessage(payload){
    return new Promise(function(resolve, reject){   
        let response = window.webkit.messageHandlers.mosaicBridge.postMessage(payload);
        resolve(response);
    });
}

export default iosPostMessage;