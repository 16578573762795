// Container component for the order confirmation page
import React from 'react'
import { connect } from 'react-redux';

import { visitPage } from '../actions/content'
import { trackPage } from '../services/analytics/analytics';

import PageWrapper from '../components/contentService/pageTypes/PageWrapper';
import { hashHistory } from 'react-router';
import SidebarWrapper from '../components/sidebar/SidebarWrapper';

const PageParamExtractor = React.createClass({
    getInitialState() {
        return {
            page: null
        };
    },
    componentWillMount() {
        let { params, pageVisted, campaigns } = this.props;
        let page = campaigns.pages.find((page) => (page.id === params.id));
        if (page) {
            //Log Page as visited
            trackPage(page.name);
            pageVisted(page);
            this.setState({ page: page })
        } else {
            //If we cant find the page, go home
            hashHistory.push('/');
        }

    },
    render() {
        let { page } = this.state;
        if (page) {
            return (
                <div>
                    {
                        page.includeNav ? <SidebarWrapper trackPageChanges={false} /> : null
                    }
                    <PageWrapper page={this.state.page} />
                </div>);
        } else {
            return (null);
        }
    }
})


const mapStateToProps = (state, currentProps) => {
    let { content } = state
    return { campaigns: content.campaigns };
}
const mapDispatchToProps = (dispatch) => {
    return {
        pageVisted: (page) => dispatch(visitPage(page))
    }
}

const ContentPageContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(PageParamExtractor);

export default ContentPageContainer;