/**
 * Rest Client Factory Module
 */
import cachedFetch from './cachedFetch';
import config, { isInNativeApp } from '../config/config';
import { hawkRestClient } from './hawkRestClient';
import { basicRestClient } from './basicRestClient';
import { oauthRestClient } from './oauthRestClient';
import { addQueryToURL, getCookie } from '../url';

const jsonHeaders = {
	'Accept': 'application/json',
	'Content-Type': 'application/json'
};
const defaultOptions = {
	headers: jsonHeaders,
	hawkEnabled: config.hawkDisable ? false : undefined
};

//////////////////
// public


/**
 * Factory function to create a rest client object
 * @param {string} base base domain for requests
 * @param {Object} globalOptions object of global options for all requests
 * @returns {Object}
 */
export function createRestClient(base = '', globalOptions = {}) {


	if (window.location.hostname === 'localhost') {
		defaultOptions.hawkEnabled = false
	}

	const requestOptions = Object.assign({}, defaultOptions, globalOptions);

	const combineOptionObjects = options => {
		// If we have query parameters in the options object, the Object.assign
		// that brings in the global values will overwrite them. So the following
		// uses the options object as the authoritive source if any key are duplicated.
		let localRequestOptions = {};
		localRequestOptions = JSON.parse(JSON.stringify(requestOptions));
		if (typeof options == 'object' && options.query) {
			Object.keys(options.query).forEach(key => {
				localRequestOptions.query[key] = options.query[key];
			});
		}
		Object.assign(options, localRequestOptions);

		if (options.sendSession && !config.is_app && !isInNativeApp()) {
			options.query.session = getCookie('session.ID') || undefined
		}
		return options;
	};

	function doRequest(method, ...args) {
		let options;
		switch (method) {
			case 'get':
			case 'delete':
				//Get and del dont have payloads
				options = arguments[2] || {};
				break;
			case 'put':
			case 'post':
				//Put and post dont have payloads
				options = arguments[3] || {};
				break;
			default:
				break;
		}
		options = combineOptionObjects(options);
		if(typeof options.hawkEnabled === 'undefined'){
			options.hawkEnabled = true;
		}
		let restClient = getRestClient(options)

		args[0] = addQueryToURL(base + args[0], options.query);

		switch (method) {
			case 'get':
			case 'delete':
				//Get and del dont have payloads
				args[1] = options
				break;
			case 'put':
			case 'post':
				//Put and post dont have payloads
				args[2] = options
				break;
			default:
				break;
		}
		return restClient[method](...args)
	}
	return {
		get: (...args) => doRequest('get', ...args),
		put: (...args) => doRequest('put', ...args),
		post: (...args) => doRequest('post', ...args),
		delete: (...args) => doRequest('delete', ...args),
	}
};

/**
 * Based on the response provided, return the rest client to use.
 * reponse data if it is not
 * @param {Object} options
 * @returns {Object}
 */
function getRestClient(options) {
	// console.log(options)
	if (options.hawkEnabled) {
		return hawkRestClient
	} else if (options.sendSession && options.query && isInNativeApp()) {
		return oauthRestClient;
	} else {
		return basicRestClient;
	}
}


/**
 * Checks whether the response was 'ok' and will throw an error object with the
 * reponse data if it is not
 * @param {Object} response
 * @returns {q}
 */
function checkStatus(response) {
	if (response.ok) {
		return Promise.resolve(response);
	} else {
		const error = new Error(response.statusText);
		return Promise.reject(Object.assign(error, { response }));
	}
}

/**
 * Make a GET request
 * @param {string} url
 * @param {Object} key value pairs of headers
 * @param {number} [expiry] time, lentgh of time in seconds to cache the response
 * @returns {q}
 */
export function get(url, options, expiry = 15 * 60) {
	options = {
		method: 'GET',
		mode: 'cors',
		cache: 'default',
		headers: new Headers(options.headers),
		expiry,
		bypassCache: options.bypassCache
	};

	return cachedFetch(url, options)
		.then(checkStatus)
		.then(parseJSON);
}

/**
 * Uses the native json function to parse a json string
 * @param {Object} response
 * @returns {Object}
 */
function parseJSON(response) {
	return response.json();
}

/**
 * Make a POST request with a json payload
 * @param {string} url
 * @param {Object} payload
 * @param {Object} key value pairs of headers
 * @param {number} [expiry] time, lentgh of time in seconds to cache the response
 * @returns {q}
 */
export function post(url, payload, options, expiry = 15 * 60) {
	options = {
		method: 'POST',
		headers: new Headers(options.headers),
		body: JSON.stringify(payload),
		expiry
	};

	return cachedFetch(url, options)
		.then(checkStatus)
		.then(parseJSON);
}

/**
 * Make a PUT request with a json payload
 * @param {string} url
 * @param {Object} payload
 * @param {Object} key value pairs of headers
 * @param {number} [expiry] time, lentgh of time in seconds to cache the response
 * @returns {q}
 */
export function put(url, payload, options, expiry = 15 * 60) {
	options = {
		method: 'PUT',
		headers: new Headers(options.headers),
		body: JSON.stringify(payload),
		expiry
	};

	return cachedFetch(url, options)
		.then(checkStatus)
		.then(parseJSON);
}

/**
 * Make a PUT request with a json payload
 * @param {string} url
 * @param {Object} payload
 * @param {Object} key value pairs of headers
 * @param {number} [expiry] time, lentgh of time in seconds to cache the response
 * @returns {q}
 */
export function deleteRequest(url, options, expiry = 15 * 60) {
	options = {
		method: 'DELETE',
		headers: new Headers(options.headers),
		expiry
	};

	return cachedFetch(url, options)
		.then(checkStatus)
		.then(parseJSON);
}
