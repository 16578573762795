/**
 * Product Actions
 *
 * Here you'll find all the action creators & actions for product pages. Async
 * actions (using redux-thunk) are at the bottom.
 *
 */

import { getProductBySku, addToBasket, getStock, removeItemByStockID, getRaffleEntriesByProduct } from '../services/api';
import { showError } from './error';
import { hashHistory } from 'react-router';
import { loading, loaded } from './loading';
import { saveSizePreference, getSizePreferenceForProduct } from '../services/product';
import { updateSession, clearSession } from './session';

import { EVTS, trackEvent, trackProductAction } from '../services/analytics/analytics';
import { getUserData } from '../services/user';

export const RECIEVED_PRODUCT = 'RECIEVED_PRODUCT';
/**
 * Action creator when product data has been successfully
 * recieved
 *
 * @param [object] data
 * @return [object] action
 */
export function recievedProduct(data) {
    return {
        type: RECIEVED_PRODUCT,
        data

    }
}

export const ADD_TO_BASKET_SUCCESS = 'ADD_TO_BASKET_SUCCESS';

/**
 * Action creator a successfull add to basket
 *
 * @param [object] data
 * @return [object] action
 */
export function addToBasketSuccess(sessionID) {
    return {
        type: ADD_TO_BASKET_SUCCESS,
        sessionID
    }
}

export const ADD_TO_BASKET_FAILURE = 'ADD_TO_BASKET_FAILURE';

/**
 * Action creator an add to basket error
 *
 * @param [string] error
 * @return [object] action
 */
export function addToBasketFailure(error) {
    return {
        type: ADD_TO_BASKET_FAILURE,
        error
    }
}


export const LAUNCH_PRODUCT = 'LAUNCH_PRODUCT';

/**
 * launchProduct
 *
 * @return [object] action
 */
export function launchProduct() {
    return {
        type: LAUNCH_PRODUCT
    }
}

export const SELECT_SIZE = 'SELECT_SIZE';

/**
 * selectSize
 *
 * @param [string] sku
 * @return [object] action
 */
export function selectSize(sku) {
    saveSizePreference(sku);
    return {
        type: SELECT_SIZE,
        sku
    }
}

export const QUANTITY_OF_ENTRIES = 'QUANTITY_OF_ENTRIES';
/**
 * selectQuantityOfEntries
 *
 * @param [string] quantityOfEntries
 * @return [object] action
 */
 export function selectQuantityOfEntries(quantityOfEntries) {
    return {
        type: QUANTITY_OF_ENTRIES,
        quantityOfEntries
    }
}


export const BOT_VERIFICATION = 'BOT_VERIFICATION';
/**
 * verificationRecieved
 *
 * @param [string] verification
 * @return [object] action
 */
export function verificationRecieved(verification) {
    return {
        type: BOT_VERIFICATION,
        verification
    }
}

export const BOT_VERIFICATION_FAILED = 'BOT_VERIFICATION_FAILED';
/**
 * verificationFailed
 *
 * @return [object] action
 */
export function verificationFailed() {
    return {
        type: BOT_VERIFICATION_FAILED
    }
}

export const UPDATE_STOCK = 'UPDATE_STOCK';
/**
 * updateStock
 *
 * @param [object] array
 * @param [string] selected
 * @return [object] action
 */
export function updateStock(options, selected) {
    return {
        type: UPDATE_STOCK,
        options,
        selected
    }
}

export const CLEAR_PRODUCT = 'CLEAR_PRODUCT';

export function clearProduct() {
    return {
        type: CLEAR_PRODUCT
    }
}

export const SELECT_SIZE_PREFERENCE = 'SELECT_SIZE_PREFERENCE';

export function selectSizePreference(sku) {

    return {
        type: SELECT_SIZE_PREFERENCE,
        sku
    }
}


/****************************************
 * ASYNC ACTION CREATORS
 * (dependent on redux thunk middleware)
 *
 ***************************************/

export const LOAD_PRODUCT = 'LOAD_PRODUCT';

/**
 * Action creator for loading product data
 * dependent on redux thunk middleware
 *
 * @param [string] sku
 * @return [function] thunk
 */
export function loadProduct(sku) {
    return async (dispatch) =>  {
        dispatch({
            type: LOAD_PRODUCT
        });
        try {
            let product = await getProductBySku(sku);
            let userData = getUserData();
            if (product.launchType === 'raffle' && userData.userID) {
                product.entryData = await getRaffleEntriesByProduct(sku, userData.userID)
            }else{
                product.entryData = { canEnter: true }
            }
            let data = await dispatch(recievedProduct(product));
            let option = getSizePreferenceForProduct(product);
            dispatch(selectSizePreference(option));

            return data;
        } catch (error) {
            dispatch(loaded());
            throw error
        }

    }
}

/**
 * Action creator for loading product data
 * dependent on redux thunk middleware
 *
 * @param [string] productID
 * @return [function] thunk
 */
export function getCurrentStock(productID, selected) {
    return (dispatch) => {
        getStock(productID)
            .then(data => {
                dispatch(updateStock(data, selected));
            })
            .catch(err => {
                console.log(err)
            })
    }
}

/**
 * Action creator for adding a product to basket
 * dependent on redux thunk middleware
 *
 * @param [string] ID
 * @param [string] option
 * @param [string] verification
 * @return [function] thunk
 */
export function handleAddToBasket(productID, optionID, verification, session, track) {
    return (dispatch) => {

        if (!optionID) {
            let error = new Error('size not selected');
            error.errorCode = 'sizeNotSelected';
            return dispatch(showError(error));
        }

        let product = {
            productID,
            optionID,
            verification,
            session
        };

        dispatch(loading('Adding to basket'));
        addToBasket(product)
            .then((data) => {
                track();
                dispatch(addToBasketSuccess(data.sessionID));
                dispatch(showError(
                    { errorInfo: `You now have 5 minutes to complete your order.`, dismissButton: 'Ok' },
                    () => { },
                    `They're in the basket!`));
                hashHistory.push('/checkout');
            })
            .catch((error) => {
                dispatch(loaded());
                let errorTitle = "title-unable-to-add-to-basket";
                if (error && error.response) {
                    switch (error.response.status) {
                        case 403:
                            error.response.json().then((data) => {
                                dispatch(verificationFailed(data));
                                dispatch(showError(data, () => { }, errorTitle));
                            });
                            break;
                        default:
                            error.response.json().then((data) => {
                                dispatch(addToBasketFailure(data));
                                dispatch(showError(data, () => { }, errorTitle));
                            });
                    }
                } else {
                    // no response so we've either been rate limited or ecountered 
                    // a serious error, in either case lets return something friendly
                    dispatch(addToBasketFailure());
                    dispatch(showError({ errorCode: "unable-to-add-to-basket" }, () => { }, errorTitle));
                }
            });
    }
}

/**
 * Action creator deleting a piece of stock
 *
 * @param [string] stockID
 * @return [function] thunk
 */
export function removeItem(stockID) {
    return (dispatch, getState) => {
        let { session } = getState();
        dispatch(loading('Removing item from basket'));
        trackProductAction(EVTS.ACT.PRODUCT_REMOVE, Object.assign({}, { ID: session.stock[0].productID }, session.stock[0].product))
        removeItemByStockID(session.ID, stockID)
            .then((session) => {
                trackEvent(EVTS.CAT.PRODUCT, EVTS.ACT.PRODUCT_REMOVE);
                if (session.stock && session.stock.length) {
                    dispatch(updateSession(session));
                } else {
                    dispatch(clearSession());
                }

            }).catch(() => {

            })
    }
}
