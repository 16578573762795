import { hashHistory } from 'react-router';
import { clearPreferencesFromState, getUserPreferences, updateUserPreferences } from '../../../actions/preferences';
import { triggerRebuildNotifications } from '../../../actions/notifications';
import { connect } from 'react-redux';
import { showError } from '../../../actions/error';
import React, { PropTypes } from 'react';
import TitleBlock from '../../titleBlock/TitleBlock';
import NotifyTimeSelector from '../../notifyTimeSelector/NotifyTimeSelector';
import './NotificationsPreferences.scss';
import notificationIcon from '../../../assets/img/notifications/icon-notify.svg';
import calendarIcon from '../../../assets/img/calendar/icon-calendar.svg';
import config from '../../../services/config/config'

import { trackPage } from '../../../services/analytics/analytics';

const preferencesKey = 'notifications';


//////////////////


const NotificationsPreferences = React.createClass({
	defaultProps: {
		preferences: {}
	},
	PropTypes: {
		preferences: PropTypes.object.isRequired
	},
	getInitialState: () => ({
		preferences: {}
	}),
	componentWillMount() {
		trackPage('preferences/notifications');
		let { preferences } = this.props;
		this.setState({ preferences: preferences });
		// No validation restriction on this page
		if (this.props.isPageValid) {
			this.props.isPageValid(true);
		}
	},

	backToDashboard() {
		hashHistory.push('/preferences')
	},
	onCancel() {
		this.backToDashboard();
	},
	onSave() {
		let canSave = true;
		let preferences = this.state.preferences;

		// check to	see if can save - do in here so we don't have to write multiple functions in the
		// preferences service (one for each type of preference)
		for (let i = 0, len = preferences.notifyTimeBefore.length; i < len; i++) {
			if (!preferences.notifyTimeBefore[i].time) canSave = false;
		}

		if (canSave) {
			this.props.savePreferences(preferencesKey, preferences);
			this.props.rebuildNotifications(); //trigger a notifications rebuild, in case the user's notify before times have changed
			this.backToDashboard();
		} else {
			this.props.showSaveError({ errorCode: 'preferences-missing-required' });
		}
	},
	updatePreferences(newPreferences) {
		let preferences = { ...this.state.preferences }
		Object.assign(preferences, newPreferences)
		this.setState({ preferences: preferences });
	},
	updatePreference(property, val) {
		let newPreferences = {}
		newPreferences[property] = val
		this.updatePreferences(newPreferences)
	},
	componentDidUpdate() {
		if (this.props.onFormChange) {
			this.props.onFormChange(this.state);
		}
	},
	componentDidMount(){
		if (this.props.onFormChange) {
			//Notification preferences has default values that are wiped if no calls to
			//onFormChange are made
			this.props.onFormChange(this.state);
		}
	},
	render() {
		let { hideButtons, theme } = this.props;
		let preferences = { ...this.state.preferences };
		let canSave = true;
		let intervals = {
			'60': { label: 'Minute(s)', max: 59 },
			'3600': { label: 'Hour(s)', max: 23 },
			'86400': { label: 'Day(s)', max: 30 }
		};

		// onClick handlers
		const addNotification = () => {
			let notifyTimeBeforeObj = { time: 10, interval: 60 };
			let notification = [...preferences.notifyTimeBefore];
			notification.push(notifyTimeBeforeObj);
			this.updatePreference('notifyTimeBefore', notification);
		};
		const changeProperty = (ind, val, property) => {
			/* slightly different to other preference page changeProperty function as we know we're editing a
			   specific property, which is an array of values. If we create more properties on notification
			   properties then we'll need to change this function to be the same as the other preference
			   pages, and create a specific notifyTimeBefore change function, which takes the index, updates
			   the .notifyTimeBefore property and sends it to this function */

			let newPrefs = JSON.parse(JSON.stringify(preferences));
			newPrefs.notifyTimeBefore[ind][property] = val;
			if (property === 'interval') {
				if (newPrefs.notifyTimeBefore[ind].time > intervals[val].max) {
					// if the time value the user has entered is > the max for the chosen interval, change it
					newPrefs.notifyTimeBefore[ind].time = intervals[val].max;
				}
			}
			this.updatePreference('notifyTimeBefore', newPrefs.notifyTimeBefore);
		};
		const handleSubmit = e => e.preventDefault();
		const removeNotification = (ind) => {
			if (preferences.notifyTimeBefore.length > 1) {
				let notification = [...preferences.notifyTimeBefore];
				notification.splice(ind, 1);
				this.updatePreference('notifyTimeBefore', notification);
			}
		};

		// determine if we can save
		for (let i = 0, len = preferences.notifyTimeBefore.length; i < len; i++) {
			if (!preferences.notifyTimeBefore[i].time) canSave = false;
		}

		return (
			<div className="Preferences Notification-Preferences">
				<div className="content-scroll">
					<TitleBlock title="Notification Preferences" />
					<div className="form-horizontal">
						<p className="intro">
							Never want to miss out on a launch? Select the notification icon on any upcoming launch product to choose how and when you want to be notified.
						</p>
					</div>

					<div className="form-header-split"><img src={notificationIcon} alt="notification" /><TitleBlock title="Push Notifications" /></div>
					<div className="form-horizontal">
						<p className="intro">
							Choose to get push notifications days, hours or minutes before launch. Just select the notification icon on your chosen launch product and head to this page to choose when you want to be notified.
                        </p>
					</div>

					<form className="form-horizontal" onSubmit={handleSubmit}>
						<div>
							<p>When do you want to be notified?</p>
							{
								preferences.notifyTimeBefore.map((obj, i) => {
									return (
										<NotifyTimeSelector key={`${i}-${obj.time}${obj.interval}`}
											ind={i}
											changeProperty={changeProperty}
											model={obj}
											intervals={intervals}
											canRemove={preferences.notifyTimeBefore.length > 1}
											remove={removeNotification} />
									);
								})
							}
						</div>
					</form>
					<div className="addNotification" onClick={addNotification}>Add another</div>

					<div className="form-header-split"><img src={calendarIcon} alt="calendar" /><TitleBlock title="Calendar" /></div>
					<div className="form-horizontal">
						<p className="intro">
							Use the calendar icon to allow the {config.appDisplayName} app access to add to your device calendar, you can then select and edit when you want to be notified.
						</p>
					</div>

				</div>
				{
					hideButtons
						? null
						: <div className="form-action fixed">
							<div className="inline">
								<div className="action-split">
									<button className="cancel" onClick={this.onCancel}>Cancel</button>
								</div>
								<div className="action-split">
									<button className="action" onClick={this.onSave} style={theme.primaryButton} disabled={!canSave}>
										<div className="button-contents">Save</div>
									</button>
								</div>
							</div>
						</div>
				}
			</div>
		);
	}
});

const mapStateToProps = state => {
	const { user, content, loading } = state;
	return { preferences: user.preferences.notifications, theme: content.theme, loading };
};

const mapDispatchToProps = dispatch => {
	return {
		clearPreferences: (key) => dispatch(clearPreferencesFromState(key)),
		getPreferences: (key) => dispatch(getUserPreferences(key)),
		rebuildNotifications: () => dispatch(triggerRebuildNotifications()),
		savePreferences: (key, data) => dispatch(updateUserPreferences(key, data)),
		showSaveError: error => dispatch(showError(error)),
	};
};


//////////////////


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(NotificationsPreferences);
