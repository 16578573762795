// Container component for the order confirmation page
import { connect } from 'react-redux';
import PreOrder from '../components/order/PreOrder';
import { getProductFromProducts, getOptionByOptionID } from '../services/product/product';

/**
 * mapStateToProps
 * 
 * @description maps the order state from the application state to props for the 
 * Order component
 * 
 * @param [object] state
 * @return [object] order state
 */
const mapStateToProps = (state, currentProps) => {
  const { content, session } = state;
  let productID = session.preOrder && session.preOrder.productID;
  let product = {...getProductFromProducts(productID, content.products)};
  let optionID = session.preOrder && session.preOrder.optionID;
  let option = getOptionByOptionID(optionID, product);
  product.option = option;
 
  return { product, order: session.preOrder, session };
}

/**
 * mapDispatchToProps
 * 
 * @description maps the order actions to props for the Order component
 * 
 * @param [function] dispatch
 * @return [object] checkout props
 */
const mapDispatchToProps = (dispatch) => {
  return {

  }
}

// map state and dispatch methods to props for Order
const PreOrderPage = connect(
    mapStateToProps,
    mapDispatchToProps
)(PreOrder);

export default PreOrderPage;