import React from 'react';
import MembershipBenefit from './MembershipBenefit';
import badges from './badges.png'


//////////////////


export default () => {
    return (
        <div>
            <MembershipBenefit title="Closed draws & launches">
                With your membership, you will be invited to exclusive events where you can gain access to selected closed draws and launches.
            </MembershipBenefit>
            <MembershipBenefit title="Higher chance of winning draws">
            Never miss out on a draw again; as a size?access member you will automatically be given three entries to boost your chance of winning each draw that you enter for a better shot at buying your latest must-haves. Please note that you are only eligible to win <u>once</u> per draw and all charity draws are excluded.
            </MembershipBenefit>
            <MembershipBenefit title="Birthday & membership anniversary perks">
            On your birthday and membership anniversary, you’ll gain size?access+ status for a week. During this period, each draw entry will give you five entries for a far greater shot of buying your latest must-haves. You’ll be able to enter as many draws as you want with more chance of winning each to secure your purchase – good luck! Please note that you are only eligible to win <u>once</u> per draw and all charity draws are excluded.
            </MembershipBenefit>
            <MembershipBenefit title="Achievement tokens & sticker collection">
                <img src={badges} alt="badges" />There will be the opportunity to collect a range of electronic stickers and gain achievement tokens for each draw you win. When you unlock these – you’ll gain a size access+ boost. This means, that each draw you enter - with a boost – you get five entries, giving you a far greater shot of buying your latest must-haves. What’s more, if you don’t win, your boosts are returned for you to use again! Please note that you are only eligible to win <u>once</u> per draw and all charity draws are excluded.
            </MembershipBenefit>
            <MembershipBenefit title="Early access to events">
                Get early access to all your favourite events!
            </MembershipBenefit>
            <MembershipBenefit title="Exclusive content">
                Get access to a range of exclusive content including previews and much more.
            </MembershipBenefit>
        </div >
    )
};