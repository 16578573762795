/**
 * AddressPreferenceForm
 *
 * Component for address forms. This is used in the DeliveryPreferences and BillingPreferences screens
 * which are used both for the sign up workflow (register new users) and in the Account Preferences screens
 *
 */

// react
import React, { PropTypes } from 'react';
import { connect } from 'react-redux';
// components
import LocaleSelector from '../localeSelector/LocaleSelector';
import TextField from '../textInput/TextInput';
import TitleBlock from '../titleBlock/TitleBlock';
// actions
import { loadLocales, updateStores } from '../../actions/checkout';
import { onUserPreferencesChange } from '../../actions/preferences';
// services
import { noPostcodeLocales } from '../../services/checkout/checkout';

import config from "../../services/config/config";


//////////////////


const AddressPreferenceForm = React.createClass({
	defaultProps: {
		preferences: {}
	},
	PropTypes: {
		preferences: PropTypes.object.isRequired
	},
	getInitialState() {
		let { useCase } = this.props;

		return {
			isFirstNameValid: useCase === 'delivery' ? true : undefined,
			isLastNameValid: useCase === 'delivery' ? true : undefined,
			isAddress1Valid: undefined,
			isAddress2Valid: undefined,
			isTownValid: undefined,
			isCountyValid: undefined,
			isPostcodeValid: undefined,
			isPhoneValid: undefined,
		}
	},
	componentWillMount() {
		let { getLocales } = this.props;
		getLocales();
	},
	updateValidationStates(isFieldValid, fieldName) {
		// Validation state of individual fields
		if (this.state[fieldName] !== isFieldValid) {
			const stateUpdate = {};
			stateUpdate[fieldName] = isFieldValid;
			this.setState(stateUpdate);
		}
	},
	componentDidUpdate(prevProps, prevState) {
		// If the state has changed, we send the form validation status to the parent component
		if (JSON.stringify(prevState) !== JSON.stringify(this.state)) {
			const { isFirstNameValid, isLastNameValid, isAddress1Valid, isAddress2Valid,
				isTownValid, isCountyValid, isPostcodeValid, isPhoneValid } = this.state;
			let isFormValid = false;
			// Non-required fileds can have a validation of 'undefined'; required fields must be 'true'
			if (isFirstNameValid && isLastNameValid && isAddress1Valid && (isAddress2Valid !== false)
				&& isTownValid && (isCountyValid !== false) && isPostcodeValid && (isPhoneValid !== false)) {
				isFormValid = true;
			}
			if (this.props.isFormValid) {
				this.props.isFormValid(isFormValid);
			}
		}
	},
	handleSubmit(event) {
		event.preventDefault();
	},
	isFieldFocused(value) {
		if (this.props.isFieldFocused) {
			this.props.isFieldFocused(value)
		}
	},

	render() {
		let { checkout, preferences, useCase, onLocaleChange, postcodeRef } = this.props;
		let countryLabel = preferences.locale === 'gb' || !preferences.locale ? 'County' : 'State';
		let postcodeLabel = preferences.locale === 'us' ? 'Zip code' : 'Postcode';

		return (
			<div>
				{useCase === 'delivery' ? <TitleBlock title="Delivery Address" /> : null}
				{useCase === 'billing' ? <TitleBlock title="Billing Address" /> : null}
				{useCase === 'billing' ?
					<form className="form-horizontal" onSubmit={this.handleSubmit}>
						<div className="form-group">
							<TextField
								type="text"
								floatingLabelText="First name"
								value={preferences.firstName}
								onChange={evt => this.props.onFormChange(evt.target.value, 'firstName')}
								hintText="Please enter your first name"
								required={true}
								fullWidth={true}
								isFieldFocused={this.isFieldFocused}
								name="name"
								isFieldValid={isValid => this.updateValidationStates(isValid, 'isFirstNameValid')}
							/>
						</div>
						<div className="form-group">
							<TextField
								type="text"
								floatingLabelText="Surname"
								value={preferences.lastName}
								onChange={evt => this.props.onFormChange(evt.target.value, 'lastName')}
								hintText="Please enter your surname"
								required={true}
								fullWidth={true}
								name="name"
								isFieldFocused={this.isFieldFocused}
								isFieldValid={isValid => this.updateValidationStates(isValid, 'isLastNameValid')}
							/>
						</div>
					</form>
					: null}
				{/* Address */}
				<p>(Please use Latin alphabet, any other character may cause delays in delivery)</p>
				<form className="form-horizontal" onSubmit={this.handleSubmit}>
					<LocaleSelector
						updateLocale={onLocaleChange}
						locale={preferences.locale}
						locales={checkout.locales} />
					<div className="form-group">
						<TextField
							type="text"
							floatingLabelText="Address Line 1"
							value={preferences.address1}
							onChange={evt => this.props.onFormChange(evt.target.value, 'address1')}
							hintText="Please enter your house/street name"
							required={true}
							fullWidth={true}
							isFieldFocused={this.isFieldFocused}
							maxLength={config.validation.address.maxLength}
							name="address1"
							isFieldValid={isValid => this.updateValidationStates(isValid, 'isAddress1Valid')}
						/>
					</div>
					<div className="form-group">
						<TextField
							type="text"
							floatingLabelText="Address Line 2"
							value={preferences.address2}
							onChange={evt => this.props.onFormChange(evt.target.value, 'address2')}
							hintText="Please enter your house/street name"
							fullWidth={true}
							maxLength={config.validation.address.maxLength}
							name="address2"
							isFieldFocused={this.isFieldFocused}
							isFieldValid={isValid => this.updateValidationStates(isValid, 'isAddress2Valid')}
						/>
					</div>
					<div className="form-group">
						<TextField
							type="text"
							floatingLabelText="Town/City"
							value={preferences.town}
							onChange={evt => this.props.onFormChange(evt.target.value, 'town')}
							hintText="Please enter your town/city"
							required={true}
							fullWidth={true}
							maxLength={config.validation.address.maxLength}
							name="town"
							isFieldFocused={this.isFieldFocused}
							isFieldValid={isValid => this.updateValidationStates(isValid, 'isTownValid')}
						/>
					</div>
					<div className="form-group">
						<TextField
							type="text"
							floatingLabelText={countryLabel}
							value={preferences.county}
							onChange={evt => this.props.onFormChange(evt.target.value, 'county')}
							hintText="Please enter your county"
							fullWidth={true}
							maxLength={config.validation.address.maxLength}
							name="county"
							isFieldFocused={this.isFieldFocused}
							isFieldValid={isValid => this.updateValidationStates(isValid, 'isCountyValid')}
						/>
					</div>
					<div className="form-group postcode">
						<TextField
							inputRef={(input) => this.myinput = input}
							type="text"
							ref={postcodeRef}
							floatingLabelText={postcodeLabel}
							value={preferences.postcode}
							onChange={evt => this.props.onFormChange(evt.target.value, 'postcode')}
							hintText="Please enter your postcode"
							required={noPostcodeLocales.indexOf(preferences.locale) === -1}
							fullWidth={true}
							maxLength={config.validation.address.maxLength}
							name="postcode"
							isFieldFocused={this.isFieldFocused}
							locale={preferences.locale}
							isFieldValid={isValid => (this.updateValidationStates(isValid, 'isPostcodeValid') && preferences.locals)}
						/>
					</div>
					<div className="form-group telephone">
						<TextField
							type="tel"
							floatingLabelText="Mobile Number"
							value={preferences.phone}
							onChange={evt => this.props.onFormChange(evt.target.value, 'phone')}
							hintText="Please enter your mobile number"
							fullWidth={true}
							name="phone"
							isFieldFocused={this.isFieldFocused}
							isFieldValid={isValid => this.updateValidationStates(isValid, 'isPhoneValid')}
						/>
					</div>
					{
						useCase === 'delivery'
							? <div className="form-group check-option useBilling">
								<input type="checkbox" id="useBilling" checked={preferences.useAsBilling} onChange={evt => this.props.onFormChange(!preferences.useAsBilling, 'useAsBilling')} />
								<label htmlFor="useBilling">
									<span className="check-box"></span>
									<span className="name">Use delivery address as billing address</span>
								</label>
							</div>
							: null
					}
				</form>
			</div>
		);
	}
});

const mapStateToProps = (state) => {
	const { checkout, deliveryTypes, loading } = state;
	return { checkout, deliveryTypes, loading };
};

const mapDispatchToProps = dispatch => {
	return {
		getLocales: () => dispatch(loadLocales()),
		updatePreferences: (key, property, data) => dispatch(onUserPreferencesChange(key, property, data)),
		updateStores: stores => dispatch(updateStores(stores))
	};
};


//////////////////


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AddressPreferenceForm);
