import {
	CLOSE_SPLASH_LOGIN, CONTINUE_AS_GUEST, END_LOGIN_JOURNEY, HOMEPAGE_SLIDER_INDEX,
	CONTENT_SLIDER_INDEX, SHOW_SPLASH_LOGIN, TRACK_SCROLL, UPDATE_CONTENT, UPDATE_RAFFLE_ENTRIES,
	COMPLETE_SCRATCHCARD, VISIT_PAGE, HIDE_PROMO_POPUP, SHOW_PROMO_POPUP
} from '../actions/content';
import { SHOW_PRODUCT } from '../services/contentService/actions';

const initialState = () => ({
	banner: {},
	products: [],
	theme: {},
	splashLogin: false,
	loginSignupJourneyRedirect: null,
	sliderIndex: 0,
	contentSliderIndex: [],
	scrollTop: 0,
	orders: [],
	productsToShow: [],
	visitedPages: [],
	completedScratches: [],
	invertHeader: false,
	pageHeaderLogo: "",
	pageTransperentHeader: false,
	isCampaignSlide: false,
	campaigns: { campaigns: [], pages: [] },
	bannerProducts : [],
	showBannerPopup: false
});

export const content = (state = initialState(), action) => {
	switch (action.type) {
		case CLOSE_SPLASH_LOGIN:
			return Object.assign({}, state, { loginSignupJourneyRedirect: action.onFinish, splashLogin: false, sliderIndex: 0, invertHeader: false, isCampaignSlide: false });

		case CONTINUE_AS_GUEST:
			return Object.assign({}, state, { splashLogin: false });

		case END_LOGIN_JOURNEY:
			return Object.assign({}, state, { loginSignupJourneyRedirect: null });

		case HOMEPAGE_SLIDER_INDEX:
			return Object.assign({}, state, { sliderIndex: action.index, invertHeader: action.invert, isCampaignSlide: action.isCampaignSlide, scrollTop: 0, pageHeaderLogo: action.pageHeaderLogo, pageTransperentHeader: action.pageTransperentHeader });

		case CONTENT_SLIDER_INDEX:
			let contentSliderIndex = []
			if (state.contentSliderIndex && Array.isArray(state.contentSliderIndex)) {
				//Hacky way of deep coppying objects
				contentSliderIndex = JSON.parse(JSON.stringify(state.contentSliderIndex))
			}
			//Try and find the slider to update the index.
			let found = false;
			contentSliderIndex.forEach(element => {
				if (element.id === action.id && !found) {
					element.index = action.index;
					found = true;
				}
			});
			if (!found) {
				contentSliderIndex.push({ id: action.id, index: action.index })
			}
			return Object.assign({}, state, { contentSliderIndex: contentSliderIndex, scrollTop: 0 });

		case SHOW_SPLASH_LOGIN:
			return Object.assign({}, state, { splashLogin: true });

		case TRACK_SCROLL:
			return Object.assign({}, state, { scrollTop: action.scrollTop });

		case UPDATE_CONTENT:
			return Object.assign({}, state, action.content);

		case UPDATE_RAFFLE_ENTRIES:
			return Object.assign({}, state, action.entries);

		case SHOW_PRODUCT:
			let productsToShow = []
			if (state.productsToShow) {
				productsToShow = [...state.productsToShow]
			}
			if (productsToShow.indexOf(action.productID) === -1) {
				productsToShow.push(action.productID);
			}
			return Object.assign({}, state, { productsToShow: productsToShow });

		case COMPLETE_SCRATCHCARD:
			let completedScratches = []
			if (state.completedScratches) {
				completedScratches = [...state.completedScratches]
			}
			if (completedScratches.indexOf(action.img) === -1) {
				completedScratches.push(action.img);
			}
			return Object.assign({}, state, { completedScratches: completedScratches });

		case VISIT_PAGE:
			let visitedPages = []
			if (state.visitedPages) {
				visitedPages = [...state.visitedPages]
			}
			if (visitedPages.indexOf(action.page.id) === -1) {
				visitedPages.push(action.page.id);
			}
			return Object.assign({}, state, { visitedPages: visitedPages, pageHeaderLogo: action.page.image, pageTransperentHeader: action.page.transparentHead });
		case HIDE_PROMO_POPUP:
			return Object.assign({}, state, { showBannerPopup: false, bannerProducts: [] });
		
		case SHOW_PROMO_POPUP:
			return Object.assign({}, state, { showBannerPopup: true, bannerProducts: action.products });
		default:
			return state;
	}
};
