import { GET_USER_FROM_STORAGE } from '../actions/content';
import { CHANGE_PREFERENCES, CLEAR_PREFERENCES, SAVE_USER_TO_DEVICE, SAVED_CARD_UPDATE, UPDATE_PREFERENCES, LOYALTY_UPDATE, MARKETING_UPDATE, REMOVE_NOTIFICATION } from '../actions/preferences';
import { LOGOUT } from '../actions/auth';
import storage from '../services/storage/storage';

/**
 * Builds an initial state object
 * Done as a function, rather than an object on a const, as an object gets update (even with Object.assign())
 * @returns {Object}
 */
export const getInitialState = () => {
	return {
		loaded: false,

		// default values for each preferences object
		billing: {
			firstName: '',
			lastName: '',
			address1: '',
			address2: '',
			town: '',
			county: '',
			postcode: '',
			locale: '',
			phone: ''
		},
		delivery: {
			firstName: '',
			lastName: '',
			address1: '',
			address2: '',
			town: '',
			county: '',
			postcode: '',
			locale: 'gb',
			phone: '',
			method: {},
			useAsBilling: true
		},
		personal: {
			firstName: '',
			lastName: '',
			email: '',
			phone: ''
		},
		notifications: {
			notifyTimeBefore: [
				{
					interval: 60,
					time: 10
				}
			]
		},
		payment: {
			saved: false
		},
		marketing: {
			optedIn: false,
			favoriteBrand: '',
			gender: '',
			birthday: {
				day: '',
				month: '',
				year: ''
			},
			shoeSize: ''
		},
		seenTutorial: false,
		loyalty: {
			isPremium: false,
			notifications: [],
		},

	};
};

export const preferences = (state = getInitialState(), action = {}) => {
	let prefs = {};
	switch (action.type) {
		case CHANGE_PREFERENCES:
			// create new object with the preferences key, and get existing from state
			let update = {};
			update[action.key] = {};
			update[action.key] = Object.assign({}, state[action.key]);
			// delete the property we're updating, and set it new from action, then merge into state
			delete update[action.key][action.propertyKey];
			update[action.key][action.propertyKey] = action.data;
			return Object.assign({}, state, update);

		case CLEAR_PREFERENCES:
			let initialState = getInitialState();
			return Object.assign({}, initialState);

		case GET_USER_FROM_STORAGE:
		case SAVE_USER_TO_DEVICE:
			prefs = {
				personal: { ...state.personal, ...action.preferences.personal },
				delivery: { ...state.delivery, ...action.preferences.delivery },
				billing: { ...state.billing, ...action.preferences.billing },
				notifications: { ...state.notifications, ...action.preferences.notifications },
			};

			// handle no names, phone on address preferences - set from personal
			prefs.delivery.firstName = prefs.delivery.firstName || prefs.personal.firstName;
			prefs.delivery.lastName = prefs.delivery.lastName || prefs.personal.lastName;
			prefs.delivery.phone = prefs.delivery.phone || prefs.personal.phone;
			prefs.billing.firstName = prefs.billing.firstName || prefs.personal.firstName;
			prefs.billing.lastName = prefs.billing.lastName || prefs.personal.lastName;
			prefs.billing.phone = prefs.billing.phone || prefs.personal.phone;
			return { ...getInitialState(), ...prefs };

		case SAVED_CARD_UPDATE:
			return Object.assign({}, state, {
				payment: {
					saved: !!action.cards.justCards.length,
					card: action.cards.justCards[0] ? action.cards.justCards[0] : {}
				}
			});

		case LOYALTY_UPDATE:
			let loyalty = { ...action.loyalty }
			loyalty.notifications = [];
			if (state.loyalty && state.loyalty.badges) {
				loyalty.notifications = [...new Set([...getLoyaltyNotifications(loyalty, state.loyalty, action.achievements), ...state.loyalty.notifications])]
			}
			return Object.assign({}, state, {
				loyalty: loyalty
			});

		case MARKETING_UPDATE:
			let marketing = { ...state.marketing }
			return Object.assign({}, state, {
				marketing: { ...marketing }
			});

		case UPDATE_PREFERENCES:
			// prefill various things if we've saved them in delivery/personal - we want to reduce the
			// duplicate data entry as much as possible
			prefs = {
				personal: { ...state.personal },
				delivery: { ...state.delivery },
				billing: { ...state.billing }
			};

			prefs[action.key] = action.value

			if (action.key === 'personal') {
				// handle no names, phone on address preferences - set from personal
				prefs.delivery.firstName = prefs.delivery.firstName || prefs.personal.firstName;
				prefs.delivery.lastName = prefs.delivery.lastName || prefs.personal.lastName;
				prefs.delivery.phone = prefs.delivery.phone || prefs.personal.phone;
				prefs.billing.firstName = prefs.billing.firstName || prefs.personal.firstName;
				prefs.billing.lastName = prefs.billing.lastName || prefs.personal.lastName;
				prefs.billing.phone = prefs.billing.phone || prefs.personal.phone;
			} else if (action.key === 'delivery') {
				if (prefs.delivery.phone && !prefs.billing.phone) prefs.billing.phone = prefs.delivery.phone;
				if (prefs.delivery.useAsBilling) {
					let newBilling = { ...prefs.delivery };
					delete newBilling.method;
					delete newBilling.useAsBilling;
					prefs.billing = newBilling;
					return { ...state, ...prefs };
				}
			} else if (action.key === 'marketing') {
				if (!action.value) {
					return state;
				}
			}
			return { ...state, ...prefs };

		case REMOVE_NOTIFICATION:
			let index = action.index;
			prefs.loyalty = {
				...state.loyalty
			}
			prefs.loyalty.notifications = [...state.loyalty.notifications];
			prefs.loyalty.notifications.splice(index, 1)
			return { ...state, ...prefs };
		case LOGOUT:
			// MOSAIC-65: notifications should persist when logged out
			// ...however, there's an issue that the notifications then persist into a new users preferences
			// Need a better way of storing notification preferences, so that they go from guest to user, but
			// not from user to guest to new user (i.e. set globally for guest, per user for user)
			return Object.assign({}, getInitialState()/*, { notifications: state.notifications }*/);

		default:
			return state;
	}
};


function getLoyaltyNotifications(newLoyalty, oldLoyalty, achievements) {
	let notification = []
	if (!newLoyalty.isPremium) {
		return notification;
	}

	//Its the birthday week
	if (newLoyalty.isDobWeek && !oldLoyalty.isDobWeek) {
		notification.push({ type: 'birthday' })
	}

	//Its the anniversary week
	if (newLoyalty.isAnniversaryWeek && !oldLoyalty.isAnniversaryWeek) {
		let joinDate = new Date(newLoyalty.joinDate);
		let now = new Date();
		notification.push({ type: 'anniversary', achievement: now.getFullYear() - joinDate.getFullYear() })
	}

	//If a user has just logged in, we dont want to show all there new notifications, so we stop here ones. 
	if(storage.getItem('shouldIgnoreMembershipNotifications')){
		storage.removeItem('shouldIgnoreMembershipNotifications')
		return notification;
	}

	//Have we pased an achievement goal
	let newWins = getAchivmentLevel(newLoyalty.wins, achievements);
	let oldWins = getAchivmentLevel(oldLoyalty.wins, achievements);
	if (newWins > oldWins) {
		notification.push({ type: 'achievement', achievement: newWins })
	}

	//Have we got any sticker runs
	for (const badge in newLoyalty.badges) {
		let oldLevel = oldLoyalty.badges[badge] || 0;
		let newLevel = newLoyalty.badges[badge];

		if (Math.floor(oldLevel / 5) < Math.floor(newLevel / 5)) {
			notification.push({ type: 'sticker', achievement: badge })
		}
	}

	return notification;
}


function getAchivmentLevel(wins, achievements = []) {
	let achivedlevels = achievements.filter(achievement => wins >= achievement)
	if (!achivedlevels.length) {
		return 0
	}
	return achivedlevels.slice(-1)[0]

}