// Container component for the Loader component
import { connect } from 'react-redux';
import Loader from '../components/loader/Loader';

/**
 * mapStateToProps
 * 
 * @description maps the state from the application state to props for the 
 * Loader component
 * 
 * @param [object] state
 * @return [object] Loader state
 */
const mapStateToProps = (state, ownProps) => {
  const { loading } = state;
  const { className } = ownProps;
  return { loading, className };
}

/**
 * mapDispatchToProps
 * 
 * @description maps the actions to props for the Loader component
 * 
 * @param [function] dispatch
 * @return [object] Loader props
 */
const mapDispatchToProps = (dispatch) => {
  return {
     
  }
}

// map state and dispatch methods to props for Loader
const LoaderContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Loader);

export default LoaderContainer;