//format npm-module-or-app/reducer/ACTION_TYPE
const COMPLETE   = 'mosaic/startup/COMPLETE';
const STARTUP   = 'mosaic/startup/STARTUP';


const initialState = {complete: false};
export default function reducer(state = initialState, action = {}) {
	switch (action.type) {
    	case COMPLETE:
			return Object.assign({}, state, {complete: true});
		case STARTUP:
			return Object.assign({}, initialState);
 		default:
			return state;
  	}
}

// Action Creators
export function startupComplete() {
	return {
		type: COMPLETE
	};
}

// Action Creators
export function startup() {
	return {
		type: STARTUP
	};
}