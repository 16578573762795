let userAgent = window.navigator.userAgent.toLowerCase();
let ios = /iphone|ipod|ipad/.test(userAgent);
let android = /Android/i.test(userAgent);
let deviceClass = android ? 'android' : ios ? 'ios' : '';

/**
 * Iterates over parent elementsuntil it finds a parentElement of passed el with class "content-scroll", or
 * runs out of parents
 * @param {Object} el element to start searching from
 * @return {Object|null}
 */
export function findParentScroll(el) {
	while (el.parentElement && !el.classList.contains('content-scroll')) el = el.parentElement;
	return el;
};

/**
 * Gets the first element in the document with the "content-scroll" class
 * @return {Object|null}
 */
export function getScrollElement() {
	return document.getElementsByClassName('content-scroll')[0];
};

/**
 * Removes inline styles from an element (e.g. those we set with element.style)
 * @param {Object} element element for which to remove inline styles
 * @param {Array.<string>} props array of styles to clear
 */
export function resetInlineStyles(element, props) {
	if (!props || !props.length) {
		element.removeAttribute('style');
	} else {
		for (let i = 0, len = props.length; i < len; i++) {
			element.style[props[i]] = null;
		}
	}
};

/**
 * Scrolls an element in a control-scroll block into view
 * @param {Object} element
 */
export function scrollElementIntoView(element, padBottom = true) {
	let scrollElem = getScrollElement();
	if (scrollElem) {
		// get existing values of elements
		let nodeTopOffset = totalOffset(element); //absolute position of input
		let scrollElemStyles = window.getComputedStyle(scrollElem, null); //styles of content-scroll elem
		let scrollElemPadTop = +(scrollElemStyles.paddingTop.replace(/[^0-9]/g, ''));

		// calculate the new values for the elements
		let newScroll = nodeTopOffset - scrollElemPadTop;
		let bottomPadding = needsBottomPadding(scrollElem, newScroll);

		// add padding to the bottom of the content-scroll element if the new scroll position would leave
		// an input halfway down the page
		if (padBottom) {
			let currentPadding = +(scrollElemStyles.paddingBottom.replace(/[^0-9]/g, ''));
			let newPadding = bottomPadding + currentPadding;
			scrollElem.style.paddingBottom = newPadding + 'px';
		}

		if (deviceClass === 'ios') {
			// use the scrollIntoView web API, then bump the page down by the content-scroll padding (as the
			// scrollIntoView function moves the element right to the top of the page)
			element.scrollIntoView();
			scrollElem.scrollTop -= scrollElemPadTop;
		} else {
			// scroll content-scroll to input (with padding at top so it isn't hidden by buttons)
			scrollElem.scrollTop = (nodeTopOffset - scrollElemPadTop);
		}
	}
};


//////////////////
// private

/**
 * Figures out if an element needs bottom padding adding when it's been scrolled
 * @param {Object} el element to calculate on
 * @param {number} newScroll new scrollTop position of el
 * @returns {number}
 */
function needsBottomPadding(el, newScroll) {
	let clientHeight = el.clientHeight;
	let scrollHeight = el.scrollHeight;
	return scrollHeight < newScroll + clientHeight ? (newScroll + clientHeight) - scrollHeight : 0;
}

/**
 * Calculates the absolute top position of an element on the page, based on its and parents positions
 * @param {Object} el element to figure out position off
 * @returns {number}
 */
function totalOffset(el) {
	let top = 0;
	do {
		top += el.offsetTop || 0;
		el = el.offsetParent;
	} while (el);
	return top;
}
