import { sendProductShare } from '../../services/share/share';
import React from 'react';
import shareIcon from '../../assets/img/share/icon-share.png';


//////////////////


const BookmarkButton = React.createClass({
	triggerShare: function () {
		switch (this.props.type) {
			case 'product':
				let product = this.props.product;
				sendProductShare(
					product.ID,
					product.name,
					product.description,
					product.mainImage.original,
				);
				return;

			default:
				// do nothing for now
		}
	},
	render: function () {
		return (
			<span className={`share`} onClick={this.triggerShare}>
				<img className="icon icon-share" src={shareIcon} alt="share" />
			</span>
		);
	}
});


//////////////////


export default BookmarkButton;
