import React from 'react';
import ImageGallery from 'react-image-gallery';
import './ImageGalery.scss'

const ImageGalery = React.createClass({
    getInitialState(){
		return {
			autoPlay: false,
			slideInterval:6000,
			showPlayButton:false,
			showThumbnails:false,
			showFullscreenButton: false,
            showBullets: true,
            showNav: false,
			infinite: false
		}
	},
    render() {
        let { content, name } = this.props
        return (<div className={`image-gallery-wrapper ${content.fullwidth ? 'full' : 'borders'} ${content.bullets ? 'bullets' : 'no-bullets'} ${name}`}>
            <ImageGallery
                autoPlay={this.state.autoPlay}
                items={content.images}
                slideInterval={this.state.slideInterval}
                showPlayButton={this.state.showPlayButton}
                showThumbnails={this.state.showThumbnails}
                showBullets={content.bullets}
                showNav={this.state.showNav}
                showFullscreenButton={this.state.showFullscreenButton}
                infinite={this.state.infinite}
            />
        </div>)
    }
})

export default ImageGalery;