import config from '../config/config';
import { postMessage } from '../nativeBridge';
import { SHARE_PRODUCT, SHARE_IMAGE } from '../nativeBridge/messageTypes';

const SHARE_URL_BASE = config.shareUrl;


//////////////////
// public


/**
 * Builds the share data and sends it to the nativeBridge
 * @param {string} sku
 * @param {string} title
 * @param {string} description
 * @param {string} image
 */
export function sendProductShare(sku, title, description, image) {
	let url = buildShareURL('product', { sku });
	let message = {
		messageType: SHARE_PRODUCT,
		data: {
			url,
			title,
			description,
			image
		}
	};
	postMessage(message);
};
/*
* Builds the share data and sends it to the nativeBridge
* @param {string} title
* @param {string} description
* @param {string} image
*/
export function shareImage(title, description, image) {
   let message = {
	   messageType: SHARE_IMAGE,
	   data: {
		   title,
		   description,
		   image
	   }
   };
   postMessage(message);
};

/**
 * Builds the share data and sends it to the nativeBridge
 * @param {string} url
 * @param {string} title
 */
export function sendPageShare(id, title) {
	let url = buildShareURL('page', id );
	let message = {
		messageType: SHARE_PRODUCT,
		data: {
			url,
			title,
			description : "",
			image: ""
		}
	};
	postMessage(message);
};

/**
 * Sends an unspecific share message
 */
export function sendShare() {
	// does nothing for now
};


//////////////////
// private


/**
 * Builds the share URL
 * @param {string} sku
 * @returns {string}
 */
export function buildShareURL(type, data) {
	switch(type) {
		case 'product':
			return `${SHARE_URL_BASE}product/${data.sku}`;
		case 'page':
			return `${SHARE_URL_BASE}page/${data}`;
		default:
			return `${SHARE_URL_BASE}`;
	}
}
