import React from 'react';
import { hashHistory } from 'react-router';


/**
 * KlarnaPaymentForm
 *
 * @description
 * Component that wraps the html form for making klarna payments.
 * when mounted.
 *
 * @param [string] token, klarna client token
 * @param [array] token, paymentMethods
 * @param [string] token
 * @param [function] onPaymentSubmission, function to run asynchrounously on submit
 *
 */


const KlarnaPaymentForm = React.createClass({
	componentWillMount() {
		let { setPaymentButtonSate } = this.props;
		setPaymentButtonSate(false)
	},
	componentDidMount() {
		let { token, paymentMethod } = this.props;
		const script = document.createElement("script");
		script.src = `https://x.klarnacdn.net/kp/lib/v1/api.js`;
		script.async = true;
		script.className = "klarnaScript";
		document.body.appendChild(script);
		this.initKlarna(token, paymentMethod)
	},
	componentWillUnmount() {
		var scripts = document.getElementsByClassName('klarnaScript');
		while (scripts[0]) {
			scripts[0].parentNode.removeChild(scripts[0]);
		}
	},
	initKlarna(token, paymentMethod) {
		let { showError, hideKlarna, setPaymentButtonSate } = this.props;
		let parseKlarnaError = this.parseKlarnaError
		window.klarnaAsyncCallback = () => {
			window.Klarna.Payments.init({
				client_token: token
			});

			window.Klarna.Payments.load({
				container: '#klarna-payments-container-' + paymentMethod,
				payment_method_category: paymentMethod
			}, function (res) {
				setPaymentButtonSate(true)
				if (res.error) {
					showError(parseKlarnaError(res.error));
					hashHistory.goBack();
				} else if (!res.show_form) {
					showError("Unfortunately, we can't offer you Klarna on this purchase, please try paying with a different payment method.");
					hideKlarna();
					hashHistory.goBack();
				}
			});
		};
	},
	authKlarna() {
		let { paymentMethod, showError, hideKlarna, onPaymentSubmission, setPaymentButtonSate } = this.props;
		setPaymentButtonSate(false)
		window.Klarna.Payments.authorize({
			payment_method_category: paymentMethod
		}, function (res) {
			setPaymentButtonSate(true)
			if (res.show_form && res.approved) {
				//Aprroved
				onPaymentSubmission(res.authorization_token)
			} else if (res.show_form && !res.approved) {
				//Ajustable Error
				showError(this.parseKlarnaError(res.error));
				hashHistory.goBack();
			} else if (!res.show_form && !res.approved) {
				//Non Recovrable
				showError("Unfortunately, Klarna has rejected your credit application, please try again using a diffrent payment method.");
				hideKlarna();
				hashHistory.goBack();
			}
		})
	},
	parseKlarnaError(error) {
		let errorString = "Klarna has indicated that there a problems with the folowing parts of your order; "
		error.invalid_fields.forEach((field, index) => {
			errorString += field.replace(/\./g, ' - ').replace(/_/g, ' ');
			if (index === error.invalid_fields.length - 2) {
				errorString += " and "
			} else if (index !== error.invalid_fields.length - 1) {
				errorString += ", "
			}
		});
		errorString += ". Please try again."
		return errorString
	},
	render() {
		let { paymentMethod, disabledPaymentButton } = this.props;
		return (
			<div className='content-scroll'>
				<div id={'klarna-payments-container-' + paymentMethod} className="klarna-payment-widget"></div>
				<div className="form-action">
					<button className="submit action" onClick={this.authKlarna} disabled={disabledPaymentButton}>
						<div className="button-contents">
							Place Order & Pay
						</div>
					</button>
				</div>
			</div>
		)
	}
});

export default KlarnaPaymentForm;