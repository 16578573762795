import { connect } from 'react-redux';
import React from 'react';
import './MembershipPreferences.scss';
import SwipableViews from 'react-swipeable-views';
import Achievement from './achievements/Achievement';
import logoBasic from './logo.svg'
import logoPlus from './logoPlus.svg'
import BadgeGroup from './badges/BadgeGroup';
import Account from './account/Account';
import LoyaltyShare from '../../loyaltyShare/LoyaltyShare';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import Leaderboard from './leaderboard/Leaderboard';

//import Countdown from '../../countdownTimer/Countdown';


const MembershipPreferences = React.createClass({
    getInitialState() {
        return ({
            slide: 0,
            shareScreen: null
        })
    },
    componentDidMount() {
        // This code runs when the component is mounted
        if (localStorage.getItem("leaderboard")) {
            document.querySelector("#root > div > div:nth-child(2) > div.page-wrapper > span > div > div.content-scroll > div > div.membership-nav > div:nth-child(4)")
        }
    }
    ,
    setSlide(index) {
        this.setState({ slide: index })
    },
    showShareScreen(data) {
        this.setState({ shareScreen: data })
    },
    hideShare() {
        this.setState({ shareScreen: null })
    },
    render() {
        let { slide, shareScreen } = this.state;
        let { content, loyalty } = this.props;

        const logo = loyalty.isBoostWeek ? logoPlus : logoBasic;
        //const firstDayOfTheWeek = 
        getFirstDayOfWeek();
        return (

            <div className="membership-hub">
                <div className="membership-heading">
                    <div className="membership-logo" >
                        <img alt="logo" src={logo} />
                    </div>
                    <div className="membership-status">
                        <h1>{loyalty.wins}</h1>
                        Wins
                    </div>
                    <div className="membership-status">
                        <h1>{loyalty.boosts}</h1>
                        Boosts
                    </div>
                </div>
                {
                    //loyalty.isBoostWeek ? <div className="plusCountdown">access+ member until <Countdown end={firstDayOfTheWeek.toISOString()} showDaysInHours={true} showAllUnits={true}/> </div> : null
                }

                <div className="membership-nav">
                    <div onClick={() => this.setSlide(0)} className={`nav-item ${slide === 0 ? 'active' : 'inactive'}`}><span>account</span></div>
                    <div onClick={() => this.setSlide(1)} className={`nav-item ${slide === 1 ? 'active' : 'inactive'}`}><span>leaderboard</span></div>
                    <div onClick={() => this.setSlide(2)} className={`nav-item ${slide === 2 ? 'active' : 'inactive'}`}><span>stickers</span></div>
                    <div onClick={() => this.setSlide(3)} className={`nav-item ${slide === 3 ? 'active' : 'inactive'}`}><span>achievements</span></div>
                </div>
                <SwipableViews className="membership-details"
                    enableMouseEvents={true}
                    index={slide}
                    disabled={true}
                    axis="x"
                    animateTransitions={true}
                    onChangeIndex={this.handleChangeIndex}>
                    <Account />
                    <Leaderboard props={this.props} loyalty={loyalty} />
                    <div className="content-scroll">
                        {
                            content.badges.map((badge, index) => <BadgeGroup onClick={this.showShareScreen} key={index} badge={badge} collectedBadge={loyalty.badges[badge.id] || 0} />)
                        }
                    </div>
                    <div className="content-scroll">
                        <div className="achievement-list">
                            {
                                content.achievements.map(level => <Achievement onClick={this.showShareScreen} key={level} level={level} wins={loyalty.wins} />)
                            }
                        </div>
                    </div>
                </SwipableViews>
                <ReactCSSTransitionGroup
                    transitionName="loyalty-info"
                    transitionEnterTimeout={200}
                    transitionLeaveTimeout={200}
                >
                    {shareScreen ? <LoyaltyShare onDismiss={this.hideShare} {...shareScreen} /> : null}
                </ReactCSSTransitionGroup>
            </div>
        )
    }
});

function getFirstDayOfWeek() {
    const todayObj = new Date();
    todayObj.setHours(0, 0, 0, 0);
    const todayDate = todayObj.getDate();
    const todayDay = todayObj.getDay();

    // get first date of week
    const firstDayOfWeek = new Date(todayObj.setDate(todayDate - todayDay));

    // get last date of week
    const lastDayOfWeek = new Date(firstDayOfWeek);
    lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6);
    return lastDayOfWeek;
}

const mapStateToProps = state => {
    const { user, content } = state;
    return { loyalty: user.preferences.loyalty || { isPremium: false }, user: user.preferences, content };
};

const mapDispatchToProps = dispatch => {
    return {
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MembershipPreferences);
