import { getUserData, setUserData } from './userData';

const DEFAULT_PREFERENCES = {
	notifications: {
		notifyTimeBefore: [
			{ time: 10, interval: 60 }
		]
	}
};


//////////////////
// public


/**
 * Gets all user preferences, or a specific set of user preferences by property key
 * @param {string} [key] a specific key to get preferences for - can be a dot key
 * @return {Object|any} a preferences object, or a specific property
 */
export function getPreferences(key) {
	let userData = window.reduxStore.getState().user;
	let preferences = userData && userData.preferences ? userData.preferences : {};
	if (key) {
		return key.split('.').reduce((obj, key) => obj ? obj[key] : undefined, preferences);
	} else {
		return preferences;
	}
};

/**
 * Checks to see if the default preferences are set in the localStorage object
 * @returns {boolean}
 */
export function hasDefaultPreferences() {
	let defaults = ['notifications.notifyTimeBefore']; //array of dot keys on the preferences obj
	let has = true;
	let preferences = getPreferences();

	// check for all the default preferences
	defaults.map(key => key.split('.').reduce((obj, key) => {
		let res = obj ? obj[key] : undefined;
		has = Array.isArray(res)
			? (res.length ? has : false) //if res isArray, check it has length
			: (res ? has : false); //else check res exists
		return res;
	}, preferences));
	return has;
};

/**
 * Sets the default preferences
 */
export function setDefaultPreferences() {
	setPreferences(DEFAULT_PREFERENCES);
};

/**
 * Updates the preferences
 * @param {Object} preferences preferences object (can be partial)
 */
export function updatePreferences(key, preferences = {}) {
	let fullPreferences = getPreferences() || {};
	fullPreferences[key] = preferences;
	setPreferences(fullPreferences);
	return fullPreferences[key];
};


//////////////////
// private


/**
 * Updates the preferences object on the userData
 * @param {Object} preferences
 */
function setPreferences(preferences) {
	let userData = getUserData();
	userData.preferences = preferences;
	setUserData(userData)
}
