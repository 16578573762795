import { getProducts } from '../services/api';
import { showErrorFromResponse } from './error';

export const CLEAR_CALENDAR = 'CLEAR_CALENDAR';
export const LOAD_CALENDAR_PRODUCTS = 'LOAD_CALENDAR_PRODUCTS';
export const PROCESS_PRODUCTS = 'PROCESS_PRODUCTS';
export const RECEIVED_PRODUCTS_BY_MONTH = 'RECEIVED_PRODUCTS_BY_MONTH';

/**
 * Removes everything from the calendar
 * @returns {Object}
 */
export function clearCalendar() {
	return {
		type: CLEAR_CALENDAR
	}
};

/**
 * Loads all products in, orders them by date, and groups them by month
 * @returns {function} thunk
 */
export function loadProducts() {
	return dispatch => {
		dispatch({
			type: LOAD_CALENDAR_PRODUCTS
		})
		getProducts()
			.then(data => dispatch(processProducts(data)))
			.then(data => dispatch(receivedProductsByMonth()))
			.catch(error => dispatch(showErrorFromResponse(error)));
	};
};


//////////////////


/**
 * Processes the array of products from mosaic
 * @param {Array.<Object>} products
 * @returns {Object}
 */
export function processProducts(products) {
	return {
		type: PROCESS_PRODUCTS,
		products,
	}
};

/**
 * Called when we have the products grouped by release month
 * @returns {Object}
 */
export function receivedProductsByMonth() {
	return {
		type: RECEIVED_PRODUCTS_BY_MONTH
	}
};
