import { auth } from './auth';
import { bookmarks } from './bookmarks';
import { calendar } from './calendar';
import { pastLaunches } from './pastLaunches';

import { calendarEvents } from './calendarEvents';
import { checkout } from './checkout';
import { combineReducers } from 'redux'
import { content } from './content';
import { deliveryTypes } from './deliveryTypes';
import { error } from './error';
import { header } from './header';
import { loading } from './loading';
import { notifications } from './notifications';
import { order } from './order';
import { product } from './product';
import { session } from './session';
import { search } from './search';

import user from './user';

// ducks
import startup from '../modules/startup';

const appReducer = combineReducers({
	auth,
	bookmarks,
	calendar,
	calendarEvents,
	checkout,
	content,
	deliveryTypes,
	error,
	pastLaunches,
	header,
	loading,
	notifications,
	order,
	product,
	session,
	user,
	startup,
	search
});

export default appReducer;
