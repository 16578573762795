/**
 * Header presentational component
 *
 * This is a presentational component which wraps the header bar shown on each page.
 *
 * Receives props from the container component in containers/HeaderContainer
 *
 */
import React from 'react';
import MiniCart from '../miniCart/MiniCart';
import { hashHistory } from 'react-router';
import './Header.scss';
import config, { isInNativeApp } from "../../services/config/config";

import accountIcon from '../../assets/img/nav/icn-account.png';
import accountPremium from '../../assets/img/nav/membership/account.svg';
import accountPremiumPlus from '../../assets/img/nav/membership/account-plus.svg';

import logoDark from '../../assets/img/logo-small.png';

import UspBanner from '../uspBanner/UspBanner';
import Countdown from '../countdownTimer/Countdown';

const Header = React.createClass({
	getLogo(logo) {
		if (logo === false) {
			return logoDark
		}
		if (logo === true) {
			return logoDark
		} else {
			return logo
		}
	},
	render() {
		let { session, content, header, checkout, toggleMiniCart, getSession, clearSession, showTimeout, showLogo, preferences, auth } = this.props;

		let accountButtonType = "basic"
		let activeAccountIcon = accountIcon;

		if (preferences.loyalty && preferences.loyalty.isPremium) {
			if (preferences.loyalty.isBoostWeek) {
				accountButtonType = "plus";
				activeAccountIcon = accountPremiumPlus;
			} else {
				accountButtonType = "member";
				activeAccountIcon = accountPremium;
			}
		}

		let isUserLoggedIn = auth.loggedIn;

		return (
			<header className={`desktop-header solid`}>
				<div className="left">
					<img className={`app-logo`} alt="logo" src={this.getLogo(showLogo)} onClick={() => { hashHistory.push('/') }} />
					{showTimeout && session.expiryDate ? <UspBanner message="">
						<Countdown end={session.expiryDate} showAllUnits={false} countdownEnded={clearSession} pause={session.pause} />
					</UspBanner> : null}
				</div>
				<div className="header-site-nav">
					{!isInNativeApp() ?
						(config.headerLinks || []).map((headerLink, index) =>
							<a key={index} href={headerLink.href.includes("http") ? headerLink.href : `${config.storeUrl}${headerLink.href}`}>{headerLink.displayName}</a>
						) : null
					}
				</div>
				<div className="right">
					<MiniCart className="miniCart"
						subtotal={session.subTotal}
						delivery={checkout.deliveryMethod}
						total={session.total}
						products={content.products}
						stock={session.stock}
						show={true}
						forceShow={true}
						toggleMiniCart={toggleMiniCart}
						loading={header.loading}
						expiryDate={session.expiryDate}
						serverDate={session.serverDate}
						sessionID={session.ID}
						getSession={getSession}
						clearSession={clearSession}
						theme={content.theme}
					/>
					<button onClick={() => { isUserLoggedIn ? hashHistory.push('/preferences') : (() => { })() }} className={`button account-wrapper${isUserLoggedIn ? '' : ' disabled'}`}>
						<img className={`icon icon-account ${accountButtonType}`} alt="account button" src={activeAccountIcon} />
					</button>
				</div>
			</header >
		)
	}

});

export default Header;
