import React, { Component } from 'react'
import { getUserID } from '../../../../services/storage/storage';
import LeaderBoardTable from './LeaderBoardTable';
import http from "../../../../services/http/mosaicRestClient";
import UserTab from './UserTab';
import { getUserData } from '../../../../services/user';

export default class Leaderboard extends Component {

  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      content: props.props.content,
      loyalty: props.props.loyalty,
      user: props.props.user,
      currentUserID: getUserID(),
      leaderboardData: [],
      isLoading: true
    };
    this.loadData = this.loadData.bind(this);
  }

  async loadData() {
    let userData = getUserData(),
      userID;
    if (userData && userData.userID) userID = userData.userID;

    const response = await http.get(

      `/users/${userID}/leaderboard`,

      { hawkEnabled: false, sendSession: true },
      0
    )
    return response
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadData()
      .then(response => {
        if (this._isMounted) {
          this.setState({
            leaderboardData: response.Items,
            isLoading: false
          });
        }
      })
      .catch(error => {
        console.error(error);
        if (this._isMounted) {
          this.setState({
            isLoading: false
          });
        }
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    if (this.state.isLoading) {
      return <div>Loading...</div>
    }

    return (
      <div style={{
        maxWidth: "310px",
        margin: "0 auto"
      }}>
        <h2 style={{ fontSize: "25px", marginTop: "10px" }}>your wins</h2>
        <UserTab userID={this.state.currentUserID} userData={this.state.user} content={this.state.content} loyalty={this.props.loyalty} />
        <LeaderBoardTable content={this.state.content} leaderboard={this.state.leaderboardData} />
      </div>
    )
  }
}
