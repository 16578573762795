import React from 'react';
import { connect } from 'react-redux';

import { addCompletedScratchCard } from '../../../../actions/content'

import ScratchCard from './ScratchCard';
import scratchbg from './scratch-bg.png'
import './Image.scss'


const Image = React.createClass({
    getInitialState() {
        return {
            imgWidth: 0,
            imgHeight: 0,
            scratchComplete: false
        }
    },
    imageLoaded() {
        let { clientHeight, clientWidth } = this.imageContainer;
        this.setState({ imgWidth: clientWidth, imgHeight: clientHeight })
    },
    componentWillMount() {
        let { content, completedScratches } = this.props;
        let completed = completedScratches.includes(content.tag);
        if (completed) {
            this.setState({ scratchComplete: true })
        }
    },
    scartchOffComplete() {
        let { content, completedScratches } = this.props;
        //Mark as complet, if not already{
        if (!completedScratches.includes(content.tag)) {
            this.props.addCompletedScratchCard(content.tag)
        }
        this.setState({ scratchComplete: true })
    },
    render() {
        let { content, handelAction, name } = this.props;
        let { imgWidth, imgHeight, scratchComplete } = this.state;
        let imageObject = <img alt={content.alt || 'Content image'} onLoad={() => this.imageLoaded()} ref={element => this.imageContainer = element} onClick={() => { handelAction(content.action) }} src={content.src} />;
        switch (content.effect) {
            case 'scratchoff':
                //Only load the image scratch card once the BG images had loaded
                if (imgWidth !== 0) {
                    const settings = {
                        width: imgWidth,
                        height: imgHeight,
                        image: scratchbg,
                        finishPercent: scratchComplete ? 0 : 50,
                        onComplete: () => this.scartchOffComplete()
                    };
                    imageObject = <ScratchCard {...settings}>
                        {imageObject}
                        {content.action ?
                            <button disabled={!scratchComplete} type="submit" style={{ opacity: scratchComplete ? 1 : 0 }} onClick={() => { handelAction(content.action) }} className="action" >
                                <div className="button-contents">Go</div>
                            </button> : null}
                    </ScratchCard>
                }
                break;
            default:
                break;
        }
        return (
            <div className={`image-wrapper ${content.fullwidth ? 'full' : 'borders'} ${name}`}>
                {imageObject}
            </div>)
    }
});

const mapStateToProps = (state) => {
    let { content } = state;
    return {
        completedScratches: content.completedScratches || []
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        addCompletedScratchCard: (img) => dispatch(addCompletedScratchCard(img)),
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Image);