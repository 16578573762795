
/**
 * getQueryStringAsObject
 * 
 * @description returns the query params in the url as a set of key value pairs on an object. 
 * 
 * @return [object] query 
 * 
 */
export function getQueryStringAsObject(){
	var query_string = {};
	var query = window.location.search.substring(1);
	var vars = query.split("&").filter(value => value !== "");
	for (var i=0;i<vars.length;i++) {
		var pair = vars[i].split("=");
			// If first entry with this name
		if (typeof query_string[pair[0]] === "undefined") {
		query_string[pair[0]] = decodeURIComponent(pair[1]);
			// If second entry with this name
		} else if (typeof query_string[pair[0]] === "string") {
		var arr = [ query_string[pair[0]],decodeURIComponent(pair[1]) ];
		query_string[pair[0]] = arr;
			// If third or later entry with this name
		} else {
		query_string[pair[0]].push(decodeURIComponent(pair[1]));
		}
	} 

	return query_string;
}

/**
 * getQueryParam
 * 
 * @description returns a specific query parameter value.
 * 
 * @param [string] param key
 * @return [object] query 
 * 
 */
export function getQueryParam(param){
    let query = getQueryStringAsObject();
    return query[param];
}


/**
 * toQueryString
 * 
 * @description returns a query string from the key value pairs in the object
 * 
 * @param [object] obj
 * @return [string] query 
 * 
 */
export function toQueryString(obj) {
	var parts = [];
	Object.keys(obj).sort().forEach(i => {
		if (obj.hasOwnProperty(i)) {
            parts.push(encodeURIComponent(i) + "=" + encodeURIComponent(obj[i]));
		}
	});
    return parts.join("&");
}

/**
 * addQueryToURL
 * 
 * @description adds query params to a url
 * 
 * @param [string] url
 * @param [object] obj
 * @return [string] url with query 
 * 
 */
export function addQueryToURL(url, obj){
	let queryString = toQueryString(obj);
	let separator = url.indexOf('?') !== -1 ? "&" : "?";
	let fullURL = `${url}${separator}${queryString}`;
	return fullURL;
}

export function getCookie(name) {
	name = name + "=";
	const cDecoded = decodeURIComponent(document.cookie);
	const cArr = cDecoded.split('; ');
	let res;
	cArr.forEach(val => {
	  if (val.indexOf(name) === 0) res = val.substring(name.length);
	})
	return res
  }

