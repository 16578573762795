/*
	TODO: when DeliveryMethodSelector has been made more generic, swap out the method selection markup and logic from this
*/

// react
import React, { PropTypes } from 'react';
import { connect } from 'react-redux';
import { hashHistory } from 'react-router';
// components
import DeliveryMethodSelector from '../../deliveryMethodSelector/DeliveryMethodSelector';
import TitleBlock from '../../titleBlock/TitleBlock';
// actions
import { loadLocales, loadStores, updateStores } from '../../../actions/checkout';
import { loadDeliveryMethods } from '../../../actions/deliveryTypes';
import { clearPreferencesFromState, getUserPreferences, onUserPreferencesChange, updateUserPreferences } from '../../../actions/preferences';
// services
import { trackPage } from '../../../services/analytics/analytics';
// css
import './DeliveryMethodPreferences.scss';

const preferencesDeliveryKey = 'delivery';


//////////////////


const DeliveryMethodPreferences = React.createClass({
	defaultProps: {
		preferences: {}
	},
	PropTypes: {
		preferences: PropTypes.object.isRequired
	},
	getInitialState: () => ({
		search: '',
		isFormValid: true,
		preferences: {}
	}),
	componentWillMount() {
		trackPage('preferences/delivery-method');
		let { getDeliveryTypes, getLocales, loadStores, preferences } = this.props;
		let locale = preferences.locale;
		this.setState({ preferences: preferences });
		getLocales();
		loadStores();
		getDeliveryTypes(locale);

		if (this.props.isPageValid) {
			this.props.isPageValid(true);
		}
	},
	componentDidUpdate(prevProps, prevState) {
		// The parent component is notified whether the form is valid or not.
		if (this.props.isPageValid) {
			this.props.isPageValid(true);
		}
		if (this.props.onFormChange) {
			this.props.onFormChange(this.state);
		}
	},
	backToDashboard() {
		hashHistory.push('/preferences');
	},
	onCancel() {
		this.backToDashboard();
	},
	onSave() {
		let preferences = this.state.preferences;
		this.props.savePreferences(preferencesDeliveryKey, preferences);
		this.backToDashboard();
	},
	updatePreferences(newPreferences) {
		let preferences = { ...this.state.preferences }
		Object.assign(preferences, newPreferences)
		this.setState({ preferences: preferences });
	},
	updatePreference(property, val) {
		let newPreferences = {}
		newPreferences[property] = val
		this.updatePreferences(newPreferences)
	},
	// onClick handlers
	changeProperty(val, property) {
		this.updatePreference(property, val);
	},
	clearSelectedStore() {
		let newPreferences = Object.assign({}, this.state.preferences);
		delete newPreferences.method.storelocationID;
		delete newPreferences.method.storelocationName;
		this.changeProperty(newPreferences.method, 'method');
	},
	methodChange(val) {
		this.changeProperty(val, 'method')
	},
	onStoreSelection({ ID, name }) {
		if (!ID) return;
		const preferences = this.state.preferences;
		let newMethod = Object.assign({}, preferences.method, { storelocationID: ID, storelocationName: name });
		this.changeProperty(newMethod, 'method');
	},
	render() {
		let { checkout, deliveryTypes, hideButtons, theme } = this.props;
		let { preferences } = this.state

		return (
			<div className="Preferences Delivery-Preferences">
				<div className="content-scroll">
					<TitleBlock title="Delivery Preferences" />
					<h4>Manage your preferred delivery method</h4>
					<div className="form-horizontal">
						<p className="intro">
							This will speed up your checkout process when those seconds really matter!
							These can be changed at any time.
						</p>
					</div>
					{/* Delivery Method */}
					<DeliveryMethodSelector
						clearSelectedStore={this.clearSelectedStore}
						deliveryTypes={deliveryTypes}
						locale={preferences.locale}
						selectDeliveryMethod={this.methodChange}
						selectStore={this.onStoreSelection}
						stores={checkout.stores}
						deliveryMethod={preferences.method}
					/>
				</div>
				{
					hideButtons
						? null
						: <div className="form-action fixed">
							<div className="inline">
								<div className="action-split">
									<button className="cancel" onClick={this.onCancel}>Cancel</button>
								</div>
								<div className="action-split">
									<button className="action" onClick={this.onSave} style={theme.primaryButton} disabled={!this.state.isFormValid}>
										<div className="button-contents">Save</div>
									</button>
								</div>
							</div>
						</div>
				}
			</div >
		);
	}
});

const mapStateToProps = state => {
	const { checkout, content, deliveryTypes, loading, user } = state;
	return { checkout, deliveryTypes, loading, preferences: user.preferences.delivery, theme: content.theme };
};

const mapDispatchToProps = dispatch => {
	return {
		clearPreferences: key => dispatch(clearPreferencesFromState(key)),
		filterStores: stores => dispatch(updateStores(stores)),
		getDeliveryTypes: (locale) => dispatch(loadDeliveryMethods(locale)),
		getLocales: () => dispatch(loadLocales()),
		getPreferences: key => dispatch(getUserPreferences(key)),
		loadStores: () => dispatch(loadStores()),
		savePreferences: (key, data) => dispatch(updateUserPreferences(key, data)),
		updatePreferences: (key, property, data) => dispatch(onUserPreferencesChange(key, property, data)),
		updateStores: stores => dispatch(updateStores(stores))
	};
};


//////////////////


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(DeliveryMethodPreferences);
