import { showError } from '../actions/error';
import { postMessage } from '../services/nativeBridge/index';
import { addCalendarEvent, handleNativeResponse, hasCalendarEvent, rebuildCalendarEvents, removeCalendarEvent, setCalendarEvents } from '../services/calendarEvents/calendarEvents';
// import { EVTS, trackEvent } from '../services/analytics/analytics';


//////////////////
// public


export const ADD_CALENDAR_EVENT = 'ADD_CALENDAR_EVENT';
export const HAVE_CALENDAR_RESPONSE = 'HAVE_CALENDAR_RESPONSE';
export const REBUILD_CALENDAR_EVENTS = 'REBUILD_CALENDAR_EVENTS';
export const REMOVE_CALENDAR_EVENT = 'REMOVE_CALENDAR_EVENT';

/**
 * Called with the response from the native apps to the calendar event changes
 * @param {Object} response
 */
export function haveCalendarResponse(response) {
	return (dispatch, getState) => {
		// get the calendar event state and, if response is success, update in localStorage
		let { calendarEvents } = getState();
		let { errors, events } = handleNativeResponse(response, calendarEvents);

		// show any errors
		if (errors.length && calendarEvents.showErrors) {
			let error = {
				// if one error show it, else show generic "calendar events failed"
				errorCode: errors.length === 1 ? errors[0] : 'calendar-events-fail'
			};
			dispatch(showError(error));
		}

		// save new calendar events state
		setCalendarEvents(events);
		dispatch({
			response: {
				calendarEvents: events,
				updateWaiting: []
			},
			type: HAVE_CALENDAR_RESPONSE
		});
	};
};

/**
 * Sends the message payload to native
 * @param {Object} message
 * @returns {function} thunk
 */
export function sendEventsToNative(message) {
	return dispatch => postMessage(message);
};

/**
 * Toggles the calendar event for a product
 * @param {string} productID
 * @param {string} name
 * @param {string} launchDate as ISO string
 * @returns {function} thunk
 */
export function toggleEvent(productID, name, launchDate) {
	return dispatch => {
		if (hasCalendarEvent(productID)) {
			dispatch(removeProductEvent(productID));
		} else {
			dispatch(addProductEvent(productID, name, launchDate));
		}
	};
};

/**
 * Triggers the array of calendar events to be rebuilt
 * @param {Array.<object>} [products] array of updated products
 * @returns {function} thunk
 */
export function triggerRebuildEvents(products) {
	let calendarEvents = rebuildCalendarEvents(products);
	return dispatch => {
		dispatch({
			calendarEvents,
			type: REBUILD_CALENDAR_EVENTS
		});
	}
};


//////////////////
// private


/**
 * Add calendar event for a product
 * @param {string} productID
 * @param {string} name
 * @param {string} launchDate as ISO string
 * @returns {Object}
 */
export function addProductEvent(productID, name, launchDate) {
	// trackEvent(EVTS.CAT.PRODUCT, EVTS.ACT.ADD_CALENDAR_EVENT, productID); //not yet asked for

	let calendarEvents = addCalendarEvent(productID, name, launchDate);
	return {
		calendarEvents,
		type: ADD_CALENDAR_EVENT
	};
}

/**
 * Removes a calendar event for a product
 * @param {string} productID
 * @returns {Object}
 */
export function removeProductEvent(productID) {
	// trackEvent(EVTS.CAT.PRODUCT, EVTS.ACT.REMOVE_CALENDAR_EVENT, productID); //not yet asked for

	let calendarEvents = removeCalendarEvent(productID);
	return {
		calendarEvents,
		type: REMOVE_CALENDAR_EVENT
	}
}
