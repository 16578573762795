import React from 'react';
import LoginSignUpForm from '../loginSignUpForm/LoginSignUpForm';

import { trackPage } from '../../services/analytics/analytics';

const SignUp = ({ updateCallback, userSignup }) => {

	trackPage('signup');

	return (
		<div className="Checkout">
			<LoginSignUpForm isSignup={true} submitForm={userSignup} updateCallback={updateCallback} />
		</div>
	)
};

export default SignUp;
