export const APPLE_PAY_SUPPORTED = window.ApplePaySession ? true : false;
export const MERCHANT_IDENTIFIER = 'example.com.store';
let checkoutData = {};


/**
 *
 * Exports
 *
 */

/**
 *	startApplePaySession
 *
 * @param {Object} session
 * @param {Object} checkoutState
 * @param {Array} deliveryTypes
 *
 * @return {Object}
 */
export function startApplePaySession(session, checkoutState, deliveryTypes){
  checkoutData = createApplePayDataFromState(session,checkoutState, deliveryTypes);
  return checkoutData;
}



/**
 *
 * Helpers
 *
 */

function createPaymentRequest(session, checkoutState){
  let total = (parseFloat(checkoutState.deliveryMethod.price.amount) + parseFloat(session.subTotal.amount)).toFixed(2);
  return {
    countryCode: checkoutState.locale,
    currencyCode: session.subTotal.currency,
    supportedNetworks: ['Visa', 'MasterCard', 'Amex', 'Discover'],
    merchantCapabilities: ['supports3DS'],
    total: { label: 'size?', amount: total.toString() },
  }
}



function createApplePayDataFromState(session, checkoutState, deliveryTypes){

  let shippingMethods = createShippingMethodsFromState(checkoutState, deliveryTypes);
  let shippingContact = createShippingContactFromState(checkoutState);
  let paymentRequest = createPaymentRequest(session, checkoutState);

  return {
    shippingMethods,
    paymentRequest,
	shippingContact
  }
}

function createShippingMethodsFromState(checkoutState, deliveryTypes){
  let methods = [];
  // if were using click and collect thats the only method
  if(checkoutState.deliveryMethod.type === 'clickAndCollect'){
    methods.push(createAppleShippingOption(checkoutState.deliveryMethod, true));
  } else {
    // if not handle delivery
    deliveryTypes.forEach((el, i) => {
      if(el.type === 'delivery'){
        el.options.forEach((option, j) => {
          methods.push(option);
        })
      }
    });

    methods = methods.map((el, i) => {
      let selected = false;
      if(el.ID === checkoutState.deliveryMethod.ID) selected = true;
      return createAppleShippingOption(el, selected);
    })
    .filter((el, i) => {
      return el.isSelected === true;
    })

  }

  return methods;
}

function createAppleShippingOption(deliveryMethod, selected = false){
  return {
        label: deliveryMethod.name,
        detail: "",
        amount: deliveryMethod.price.amount,
        identifier: deliveryMethod.ID,
        isSelected : selected
    }
}

function createShippingContactFromState(checkoutState){
   return {
    name: checkoutState.customer.firstName,
    email: checkoutState.customer.email,
    phone: checkoutState.customer.phone,
    address1: checkoutState.billing.address1,
    address2: checkoutState.billing.address2,
    county: checkoutState.billing.county,
    postalCode: checkoutState.billing.postcode,
    countryCode: checkoutState.billing.locale,
    country: checkoutState.country
  }
}