import { hashHistory } from 'react-router';
import { connect } from 'react-redux';
import React from 'react';
import TitleBlock from '../../titleBlock/TitleBlock';
import './AccountDeletion.scss';

import { trackPage } from '../../../services/analytics/analytics';
import { navigateToExternal } from '../../../services/navigate/navigate';
import config from '../../../services/config/config';
import { deleteAccount } from '../../../actions/preferences';



//////////////////


const AccountDeletion = React.createClass({
  getInitialState: () => ({
    confirm: false
  }),
  componentWillMount() {
    trackPage('preferences/account-deletion');
  },
  backToDashboard() {
    hashHistory.goBack();
  },
  updateCheckbox() {
    let { confirm } = this.state;
    this.setState({ confirm: !confirm })
  },
  render() {
    return (
      <div className="Preferences Delete-Preferences">
        <div className="content-scroll">
          <TitleBlock title="Account Deletion" />
          <div className="form-horizontal">
            <div>
              <p>
                If you want us to delete your account, you've come to the right place.
              </p>
              <p>
                Please read the information below.  If after you've read this information,
                you still want us to delete your account, including personal data we hold
                about you (except as we explain below), please tick the box below and click submit.
              </p>
              <p>
                If you submit an account deletion request, you will be exercising your “right
                to erasure” under Article 17 of the UK General Data Protection Regulation. This means:
              </p>
              <ul className='dashed'>
                <li>
                  You will no longer be able to log into or use your account via
                  this {config.appDisplayName} app or our {config.storeDisplayName} website or app.
                  This is because your account credentials are the same across these platforms.
                </li>
                <li>
                  You will no longer have access to your order history.
                </li>
                <li>
                  You will no longer be able to shop with us via this {config.appDisplayName} app,
                  the {config.storeDisplayName} website or app using this account. Any future
                  purchases you wish to make would be as a guest or you can create a new account.
                </li>
                {
                  config.supportsMembership ?
                    <li>
                      If you are a size? access member, once your account is deleted you will lose the
                      benefits that you are entitled to as a size? access member.  You will not receive
                      a refund for any period that remains of your size? access membership.
                    </li> : null
                }
                <li>
                  We will delete the personal data we hold about you on our systems, except as
                  explained below.
                </li>
              </ul>
              <p>
                In accordance with data protection laws and guidance, we are permitted to continue 
                processing some of your personal data.  We will continue to do so in accordance with 
                our privacy policy available here: <span className='link' onClick={() => navigateToExternal(`${config.storeUrl}customer-service/privacy/`)}>{`${config.storeUrl}customer-service/privacy/`}</span>.
                These reasons include, but are not limited to, compliance with a legal obligation, 
                fraud prevention, to enforce our terms and conditions, and to manage marketing 
                suppression lists.  If you have any product guarantees or warranties with us, we 
                will not delete this data until those arrangements come to an end.
              </p>
            </div>
          </div>
          <div className="form-group check-option confirm">
            <input type="checkbox" id="confirm" checked={this.state.confirm} onChange={this.updateCheckbox} />
            <label htmlFor="confirm">
              <span className="check-box tick"></span>
              <span className="name">
                If you wish to proceed, please tick to confirm you have read and agree with the
                infomation above
              </span>
            </label>
          </div>
          <p>
            We will process your request shortly, and in any case, within one month.
          </p>
        </div>
        <div className="form-action fixed">
          <button disabled={!this.state.confirm} className="action" onClick={this.props.deleteAcount}>
            <div className="button-contents">Submit</div>
          </button>
        </div>
      </div>
    );
  }
});

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    deleteAcount: () => dispatch(deleteAccount()),
  };
};


//////////////////


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountDeletion);
