import React from 'react';
import { connect } from 'react-redux';

import CalendarItem from '../../../../calendarItem/columns/CalendarItem';
import './ProductList.scss'

//////////////////

const ProductList = React.createClass({
	render() {
		let { content, name, products } = this.props;

		let filteredProducts = products.filter(product=>product.status === 'available');


		if(content.genders.length){
			filteredProducts = filteredProducts.filter(product => content.genders.includes(product.gender))	
		}

		if(content.brands.length){
			filteredProducts = filteredProducts.filter(product => content.brands.includes(product.brand))	
		}

		let productsList = filteredProducts.map((product, i) => {
			return <CalendarItem
				key={product.sku || i}
				image={product.mainImage}
				launchDate={product.launchDate}
				launchStartDate={product.launchStartDate}
				launchType={product.launchType}
				sku={product.ID}
				price={product.price}
				title={product.name}
				subtitle={product.subTitle}
				product={product}
				soldOut={product.status === 'out-of-stock' || product.status === 'complete'} />;
		});

		return (
			<ul className={`products-wrapper ${content.fullwidth ? 'full' : 'borders'} ${name}`}>
				{productsList}
			</ul>
		)
	}
})

const mapStateToProps = (state) => {
	let { content } = state;
	return {
		products: content.products
	}
};

const mapDispatchToProps = (dispatch) => {
	return {}
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ProductList);
