/**
 * Checkout header
 *
 * Purely presentational component for displaying privacy copy
 */

import React from 'react';
import { navigateToExternal } from '../../services/navigate/navigate';
import config from '../../services/config/config';
import './PrivacyNotice.scss';

const PrivacyNotice = () => (
	<div className="privacy-notice">
		<p>By creating an account, you agree to comply with our terms and conditions. We will use your information in accordance with our <span className='link' onClick={() => navigateToExternal(`${config.storeUrl}customer-service/privacy/`)}>Privacy Policy</span></p>
	</div>
);


export default PrivacyNotice;