/**
 * mosaic rest client
 *
 * This module uses the restClientFactory functiont to create an object we can use as a rest client. You
 * can pass in the base url to change where your making http requests to.
 *
 */

import { createRestClient } from './restClientFactory';
import { getQueryParam } from '../url';
import config, { DEFAULT_CHANNEL } from '../config/config';
import { getAPIDomain, getAPIHost } from './util';

const mosaicHost = getAPIHost();
const mosaicDomain = getAPIDomain();


let channel = getQueryParam('channel');
let globalOptions = {
	query: {
		channel: channel || DEFAULT_CHANNEL,
		api_key: config.api.api_key
	},
	headers: {
		originalhost: mosaicHost
	}
}

export default createRestClient(mosaicDomain, globalOptions);
