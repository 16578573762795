import storage from '../storage/storage';

const SIZE_PREFERENCES_KEY = 'size-preferences';


//////////////////


export function clearSizePreference() {
	storage.removeItem(SIZE_PREFERENCES_KEY);
};

/**
 * Returns the 'prefered' sku from storage for a product
 * @param {string} plu
 * @returns {string} sku
 */
export function getSizePreferenceForProduct(product) {
	//Fist look if the user has already selected a size for this particular shoe
	let data = storage.getItem(SIZE_PREFERENCES_KEY);

	if (data) {
		try {
			data = JSON.parse(storage.getItem(SIZE_PREFERENCES_KEY));
		} catch (e) {
			data = {};
			console.warn(e);
		}
	} else {
		data = {};
	}

	if (data[product.ID]) {
		return data[product.ID];
	}

	//If they have not, then try and select one form them, based on there markting prefrences.

	let marketingPrefs = window.store.getState().user.preferences.marketing;

	if (marketingPrefs && marketingPrefs.shoeSize && marketingPrefs.shoeSize !== "") {
		let regEx = new RegExp(`(^| )${marketingPrefs.shoeSize.replace('.', '\\.')}( |$)`);
		let option = product.options.find((option) => option.name.match(regEx));
		return option ? option.optionID : undefined
	}

};

/**
 * Stores a 'prefered' sku in storage
 * @param {string} sku
 */
export function saveSizePreference(sku) {
	let plu = sku.split(':')[0],
		data = storage.getItem(SIZE_PREFERENCES_KEY);

	if (data) {
		data = JSON.parse(data);
	} else {
		data = {};
	}
	data[plu] = sku;
	return storage.setItemNoExpiry(SIZE_PREFERENCES_KEY, JSON.stringify(data));
};
