import { ENABLE_APPLE_PAY } from '../actions/applePay';
import {
	CLEAR_SELECTED_STORE,
	PREFILL_CHECKOUT_FROM_PREFERENCES,
	SELECT_DELIVERY_METHOD,
	SELECT_PAYMENT_METHOD,
	SELECT_STORE,
	TOGGLE_USE_BILLING_AS_DELIVERY,
	UPDATE_BILLING_ADDRESS,
	UPDATE_CUSTOMER,
	UPDATE_DELIVERY_ADDRESS,
	UPDATE_LOCALE,
	UPDATE_LOCALES,
	UPDATE_SEARCH,
	UPDATE_STORES,
	ENABLE_SAVED_CARD,
	ENABLE_CARD,
	ENABLE_KLARNA,
	DISABLE_KLARNA,
	DISABLE_APPLE_PAY,
	DISABLE_CARD,
	DISABLE_SAVED_CARD,
	HIDE_KLARNA,
	TOGGLE_CHECKOUT_CONFIRMATION
} from '../actions/checkout';
import { CHANGE_PREFERENCES, SAVED_CARD_UPDATE } from '../actions/preferences';
import { CLEAR_SESSION } from '../actions/session';


//////////////////


export const initialState = () => ({
	isDirty: false,
	sessionID: '',
	status: '',
	customer: {
		isPrefilled: false,
		firstName: '',
		lastName: '',
		email: '',
		phone: ''
	},
	delivery: {
		isPrefilled: false,
		firstName: '',
		lastName: '',
		postcode: '',
		address1: '',
		address2: '',
		town: '',
		county: '',
		locale: ''
	},
	products: [],
	deliveryMethod: {
		isPrefilled: false,
		ID: '',
		name: '',
		price: {},
		type: '',
		// kinda should be here, but then it means we have to delete before payment if not C&C
		// storelocationID: '',
		// storelocationName: ''
	},
	billing: {
		isPrefilled: false,
		firstName: '',
		lastName: '',
		locale: 'gb',
		postcode: '',
		address1: '',
		address2: '',
		town: '',
		county: ''
	},
	paymentMethod: {},
	paymentMethods: [
		{ name: 'Saved Card', ID: '3', type: 'RECURRING', show: false },
		{ name: 'Apple Pay', ID: '1', type: 'APPLE_PAY', show: false },
		{
			name: 'Klarna Pay in 3',
			ID: '4',
			type: 'KLARNA',
			klarnaType: 'pay_over_time',
			description: 'Shop now. Pay in 3 with Klarna',
			show: false,
			hidden: false,
			paymentMethodVariant: "klarna"
		},
		{
			name: 'Klarna Pay 30 days later',
			ID: '5',
			type: 'KLARNA',
			klarnaType: 'pay_later',
			description: 'Shop now. Pay in 30 days with Klarna',
			show: false,
			hidden: false,
			paymentMethodVariant: "klarna"
		},
		{ name: 'Card', ID: '2', type: 'CARD', show: false }
	],
	locale: 'gb',
	locales: [],
	stores: [],
	loaded: false,
	search: '',
	useBillingAsDelivery: true,
	checkoutConfirmation: false
});

// Checkout reducer
export const checkout = (state = initialState(), action) => {
	var deliveryMethod;
	let paymentMethods = [];
	let useBillingAsDelivery = {};
	let defaultSelectedPaymentMethod = {};
	switch (action.type) {
		case UPDATE_DELIVERY_ADDRESS:
			var delivery = Object.assign({}, state.delivery, action.address);
			return Object.assign({}, state, { delivery, isDirty: true });

		case UPDATE_CUSTOMER:
			var customer = Object.assign({}, state.customer, action.details);
			return Object.assign({}, state, { customer, isDirty: true });

		case UPDATE_LOCALE:
			let updates = {
				locale: action.locale,
				deliveryMethod: {},
				search: '',
				isDirty: true
			};
			updates.delivery = Object.assign({}, state.delivery);
			updates.delivery.locale = action.locale;
			return Object.assign({}, state, updates);

		case UPDATE_LOCALES:
			return Object.assign({}, state, { locales: action.locales });

		case SELECT_DELIVERY_METHOD:
			deliveryMethod = Object.assign({}, state.deliveryMethod, action.deliveryMethod);
			var billingWithPreFilledName = Object.assign({}, state.billing, getDeliveryFirstNameAndSurname(state.customer));
			var deliveryWithPreFilledName = Object.assign({}, state.delivery, getDeliveryFirstNameAndSurname(state.customer));
			//Set useBillingAsDelivery so a user has chance to set there billing address when choosing collect from store
			useBillingAsDelivery = {};
			if (deliveryMethod.type === 'clickAndCollect') {
				useBillingAsDelivery = { useBillingAsDelivery: false };
			}
			return Object.assign({}, state, { deliveryMethod, billing: billingWithPreFilledName, delivery: deliveryWithPreFilledName, isDirty: true }, useBillingAsDelivery);

		case UPDATE_BILLING_ADDRESS:
			let billing = Object.assign({}, state.billing, action.address);
			return Object.assign({}, state, { billing, isDirty: true });

		case SELECT_PAYMENT_METHOD:
			return Object.assign({}, state, { paymentMethod: action.paymentMethod, checkoutConfirmation: false });

		case ENABLE_APPLE_PAY:
			paymentMethods = state.paymentMethods.map((method, i) => {
				let newMethod = Object.assign({}, method);
				if (newMethod.type === 'APPLE_PAY') {
					newMethod.show = true;
				}
				return newMethod;
			});
			return Object.assign({}, state, { paymentMethods });

		case ENABLE_CARD:
			paymentMethods = state.paymentMethods.map((method, i) => {
				let newMethod = Object.assign({}, method);
				if (newMethod.type === 'CARD') {
					newMethod.show = true;
				}
				return newMethod;
			});
			return Object.assign({}, state, { paymentMethods });

		case ENABLE_SAVED_CARD:
			paymentMethods = state.paymentMethods.map((method, i) => {
				let newMethod = Object.assign({}, method);
				if (newMethod.type === 'RECURRING') {
					newMethod.show = true;
					newMethod.name = `Saved Card - ${action.card.expiryDate.slice(0, 2)}/${action.card.expiryDate.slice(2)}`;
					newMethod.description = `${action.card.cardNumber}`;
					newMethod.paymentMethodVariant = `${action.card.type.toLowerCase()}`;
					defaultSelectedPaymentMethod = newMethod;
				}
				return newMethod;
			});
			return Object.assign({}, state, { paymentMethods }, { paymentMethod: defaultSelectedPaymentMethod });
		case ENABLE_KLARNA:
			let klarnaInState = false;
			paymentMethods = state.paymentMethods.map((method, i) => {
				let newMethod = Object.assign({}, method);
				if (newMethod.type === 'KLARNA') {
					newMethod.show = true;
					klarnaInState = true;
				}
				return newMethod;
			});
			//Sometimes, for old users klarna is not in there payment state, here we add it and enable it
			if(!klarnaInState){
				paymentMethods.push({
					name: 'Klarna Slice it',
					ID: '4',
					type: 'KLARNA',
					klarnaType: 'pay_over_time',
					description: 'Pay in 3 interest-free instalments',
					show: true,
					hidden: false,
					paymentMethodVariant: "klarna"
				},
				{
					name: 'Klarna Pay later',
					ID: '5',
					type: 'KLARNA',
					klarnaType: 'pay_later',
					description: 'Pay 30 days after delivery',
					show: true,
					hidden: false,
					paymentMethodVariant: "klarna"
				},)
			}
			return Object.assign({}, state, { paymentMethods });
		case DISABLE_KLARNA:
			defaultSelectedPaymentMethod = {};
			paymentMethods = state.paymentMethods.map((method, i) => {
				let newMethod = Object.assign({}, method);
				if (newMethod.type === 'KLARNA') {
					newMethod.show = false;
				}
				return newMethod;
			});
			return Object.assign({}, state, { paymentMethods }, { paymentMethod: defaultSelectedPaymentMethod });

		case DISABLE_SAVED_CARD:
			paymentMethods = state.paymentMethods.map((method, i) => {
				let newMethod = Object.assign({}, method);
				if (newMethod.type === 'RECURRING') {
					newMethod.show = false;
				}
				return newMethod;
			});
			return Object.assign({}, state, { paymentMethods });
		case DISABLE_APPLE_PAY:
			paymentMethods = state.paymentMethods.map((method, i) => {
				let newMethod = Object.assign({}, method);
				if (newMethod.type === 'APPLE_PAY') {
					newMethod.show = false;
				}
				return newMethod;
			});
			return Object.assign({}, state, { paymentMethods });
		case DISABLE_CARD:
				paymentMethods = state.paymentMethods.map((method, i) => {
					let newMethod = Object.assign({}, method);
					if (newMethod.type === 'CARD') {
						newMethod.show = false;
					}
					return newMethod;
				});
				return Object.assign({}, state, { paymentMethods });
		case HIDE_KLARNA:
			paymentMethods = state.paymentMethods.map((method, i) => {
				let newMethod = Object.assign({}, method);
				if (newMethod.type === 'KLARNA') {
					newMethod.hidden = true;
				}
				return newMethod;
			});
			return Object.assign({}, state, { paymentMethods });
		case SAVED_CARD_UPDATE:
			paymentMethods = state.paymentMethods.map((method, i) => {
				let newMethod = Object.assign({}, method);
				if (method.type === 'RECURRING') {
					newMethod = { name: 'Saved Card', ID: '3', type: 'RECURRING', show: false }
				}
				return newMethod;
			});

			return Object.assign({}, state, { paymentMethods });

		case SELECT_STORE:
			deliveryMethod = Object.assign({}, state.deliveryMethod, {
				storelocationID: action.store.ID,
				storelocationName: action.store.name
			});
			return Object.assign({}, state, { deliveryMethod, isDirty: true });

		case CLEAR_SELECTED_STORE:
			let newDeliveryMethod = Object.assign({}, state.deliveryMethod);
			newDeliveryMethod.storelocationID = '';
			newDeliveryMethod.storelocationName = '';
			return Object.assign({}, state, { deliveryMethod: newDeliveryMethod, isDirty: true });

		case UPDATE_STORES:
			return Object.assign({}, state, { stores: action.stores });

		case UPDATE_SEARCH:
			return Object.assign({}, state, { search: action.value });

		case TOGGLE_USE_BILLING_AS_DELIVERY:
			return Object.assign({}, state, { useBillingAsDelivery: !state.useBillingAsDelivery, isDirty: true });
		case TOGGLE_CHECKOUT_CONFIRMATION:
			return Object.assign({}, state, { checkoutConfirmation: !state.checkoutConfirmation });
		case CLEAR_SESSION:
			return Object.assign({}, initialState());

		case PREFILL_CHECKOUT_FROM_PREFERENCES:
			//If we have storelocationID then we must be using click and collect, thus we want the user to be able to manually edit there billing address
			useBillingAsDelivery = {};
			if (action.prefilledPrefs.deliveryMethod.storelocationID) {
				useBillingAsDelivery = { useBillingAsDelivery: false };
			}
			if (!state.isDirty) {
				return Object.assign({}, state, action.prefilledPrefs, useBillingAsDelivery);
			} else {
				return Object.assign({}, state, useBillingAsDelivery);
			}

		case CHANGE_PREFERENCES:
			// when the preferences have updated, we want to update the checkout regardless
			return Object.assign({}, state, { isDirty: false });

		default:
			return state;
	}
}
export default checkout;


//////////////////


/**
 *
 * @param {*} delivery
 */
function getDeliveryFirstNameAndSurname(delivery) {
	return {
		firstName: delivery.firstName,
		lastName: delivery.lastName
	}
}