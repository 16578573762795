import React from 'react';

/**
 * Adyen3DsecureRedirectForm
 *
 * @description
 * Component that wraps the html form auto redirecting to 3DS
 *
 */

let Adyen3DsecureRedirectForm = React.createClass({

	componentDidMount() {
		this.paymentForm.submit();
	},

	render() {
		let { IssuerUrl, PaReq, MD, TermUrl } = this.props.location.query;
		return (
			<form method="POST" action={IssuerUrl} id="3dform" ref={(form) => this.paymentForm = form}>

				<input type="hidden" name="PaReq" value={PaReq} />
				<input type="hidden" name="MD" value={MD} />
				<input type="hidden" name="TermUrl" value={TermUrl} />
			</form>
		)
	}
});

export default Adyen3DsecureRedirectForm;
