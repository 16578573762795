import { hashHistory } from 'react-router';
import { connect } from 'react-redux';
import React from 'react';
import TitleBlock from '../../titleBlock/TitleBlock';
import AdyenPaymentForm from '../../adyenPaymentForm/AdyenPaymentForm';
import { savePaymentCard, getCard, deletePaymentCard } from '../../../actions/preferences';

import { trackPage } from '../../../services/analytics/analytics';

import './PaymentPreferences.scss';


//////////////////




const PaymentPreferences = React.createClass({
	componentWillMount() {
		this.props.getLatestCardDetails();
		trackPage('preferences/payment');
		// This screen is considered valid (ie the sign-up workflow can continue) if a card has been registered.
		if (this.props.isPageValid && this.props.preferences.saved) {
			this.props.isPageValid(true);
		}
	},
	componentDidUpdate() {
		if (this.props.isPageValid && this.props.preferences.saved) {
			this.props.isPageValid(true);
		}
	},
	render() {
		let { inSignupFlow, savePaymentData, user, preferences, deletePaymentCard, theme } = this.props;
		// console.log("🚀 ~ file: PaymentPreferences.js:34 ~ render ~ preferences:", preferences);

		return (
			<div className={'Preferences Payment-Preferences' + (inSignupFlow ? ' inSignupFlow' : '')}>
				<div className="content-scroll">
					<TitleBlock title="Payment Preferences" />
					{
						!preferences.saved
							? <div>
								<h4>Save your payment details</h4>
								<div className="form-horizontal">
									<p className="intro">
										Once submitted your card will be authorised. No payment will be taken and this can be changed at any time.
									</p>
									<AdyenPaymentForm generatedTime={new Date().toISOString()} onPaymentSubmission={savePaymentData} submitText="Save Card" storePaymentMethod={true} user={user} />
								</div>
							</div>
							: <div className="form-horizontal">
								<h4>Your saved card</h4>
								<div className="bank-card">
									<div className="bank-card-info">
										<div className="bank-card-number">
											{`${preferences.card.cardNumber}`}
										</div>
										<div className="bank-card-name">
											{preferences.card.clientName}
										</div>
										<div className="bank-card-expiry">
											Expiry&nbsp;{preferences.card.expiryDate.slice(0, 2)}/{preferences.card.expiryDate.slice(2)}
										</div>
									</div>
									<div className={`cardtype ${preferences.card.type}`}></div>
								</div>
								{
									inSignupFlow
										? null
										: <div className="form-action fixed">
											<div className="inline">
												<div className="action-split">
													<button className="cancel" onClick={() => hashHistory.goBack()}>Cancel</button>
												</div>
												<div className="action-split">
													<button className="action" onClick={deletePaymentCard} style={theme.primaryButton}>
														<div className="button-contents">Delete</div>
													</button>
												</div>
											</div>
										</div>
								}
							</div>
					}
				</div>
			</div>
		);
	}
});

const mapStateToProps = state => {
	const { user, content, loading } = state;
	return { user: user, preferences: user.preferences.payment, theme: content.theme, loading };
};

const mapDispatchToProps = dispatch => {
	return {
		savePaymentData: (data) => dispatch(savePaymentCard(data)),
		deletePaymentCard: () => dispatch(deletePaymentCard()),
		getLatestCardDetails: () => dispatch(getCard())

	};
};


//////////////////


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PaymentPreferences);
