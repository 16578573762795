import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';
import config from '../../services/config/config';
import TitleBlock from '../titleBlock/TitleBlock';
import { makeRecurringPayment } from '../../actions/payment';
import { createPreAuthOrder, completeOrder } from '../../actions/checkout';

class AdyenCVCOnlyPaymentForm extends Component {

	constructor(props) {
		super(props);
		this.state = {
			adyenCard: {},
			adyenStatus: 'idle',
			CVCValid: 'false',
			browserInfo: {},
		};
		this.handlePayment = this.handlePayment.bind(this);
		this.isCVCFormValid = this.isCVCFormValid.bind(this);
		this.handleCVCChange = this.handleCVCChange.bind(this);
	}

	componentDidMount() {
		/* 
			REQUIRED DELAY DUE TO RE-RENDER THAT HAPPENS ON PAYMENT PREFERENCES PAGE,
			FORM WILL NOT INITIALISE PROPERLY WITHOUT.
		*/
		this.delayedInitialize = setTimeout(() => {
			this.initialiseAdyenCheckout();
		}, 800);
	}

	componentWillUnmount() {
		/* 
			REQUIRED DELAY DUE TO RE-RENDER THAT HAPPENS ON PAYMENT PREFERENCES PAGE,
			FORM WILL NOT INITIALISE PROPERLY WITHOUT.
		*/
		if (this.delayedInitialize) {
			clearTimeout(this.delayedInitialize);
		}
	}

	handleCVCChange(state) {
		this.setState({ 
			CVCValid: state.isValid,
			browserInfo: state.data.browserInfo
		});
	};

	async initialiseAdyenCheckout() {

		this.setState({ adyenStatus: 'loading' });

		let { selectedPaymentMethod } = this.props;

		let configuration = {
			locale: config.adyen.locale,
			environment: config.adyen.environment,
			clientKey: config.adyen.adyenClientKey,
			onChange: this.handleCVCChange,
		};

		let cardType;
		// TODO: we are receiving a different string from mesh than what Adyen expects, overwriting for now so that Adyen recognises amex and enables 4 digit instead of 3.
		if (selectedPaymentMethod.paymentMethodVariant === "american_express") {
			cardType = "amex";
		} else {
			cardType = selectedPaymentMethod.paymentMethodVariant
		}

		let adyenCardInstance = await AdyenCheckout(configuration);

		let adyenCard = adyenCardInstance.create('securedfields', {
			type: cardType,
			styles: {
				base: {
					fontFamily: 'inherit',
					fontSize: '13px',
					color: '#000000'
				},
				error: {
					fontFamily: 'inherit',
					fontSize: '13px',
					color: '#C91515'
				},
				validated: {
					fontFamily: 'inherit',
					fontSize: '13px',
					color: '#1a7d4d',
				},
				placeholder: {
					fontFamily: 'inherit',
					fontSize: '13px',
					color: '#afaeae',
				}
			}
		}).mount('#customCard-container');

		this.setState({ adyenCard, adyenStatus: 'loaded' });
	}

	handlePayment() {
		let { adyenCard, browserInfo } = this.state;
		let { deliveryTypes, checkout, loyalty, params, refreshToken, session, context } = this.props;
		let { isValid, data } = adyenCard ? adyenCard.state : {};

		if (isValid) {
			let { encryptedSecurityCode } = data;

			let encryptedData = { 
				encryptedSecurityCode,
				browserInfo
			};

			if (context === 'checkout') {
				this.props.createOrderForSavedCard({
					checkout: checkout,
					deliveryTypes: deliveryTypes,
					session: session
				}).then(() => {
					this.props.onPaymentSubmission(encryptedData);
				})
			} else if (context === 'preauth') {
				refreshToken()
					.then((verification) => {
						this.props.createPreOrderForSavedCard({
							checkout: checkout,
							deliveryTypes: deliveryTypes,
							optionID: params.sku,
							boost: loyalty.isPremium && loyalty.isBoostWeek && localStorage.getItem('isBoosted'),
							answer: params.answer,
							verification
						}).then(() => {
							this.props.onPaymentSubmission(encryptedData);
						});
					});
			}

		}
	}

	isCVCFormValid() {
		// Simple way to make sure the checkout form & Adyen form are both valid before we unlock the Enter button
		let { CVCValid } = this.state;
		let { checkoutConfirmation, isFormValid } = this.props;
		if ((CVCValid && checkoutConfirmation) || (CVCValid && isFormValid)) {
			return true;
		} else {
			return false;
		}
	}

	render() {
		let { adyenStatus } = this.state;
		let { title, selectedPaymentMethod, context } = this.props;
		return (
			<div className='adyen-CVC-form'>
				{title ? <TitleBlock title={title} /> : null}
				<div id="customCard-container">
					{adyenStatus === 'loaded' &&
						<div>
							<div className="customCard_exp">
								<label>
									<span className="customCard_title">CVC:</span>
									<span data-cse="encryptedSecurityCode"></span>
								</label>
							</div>
						</div>
					}
				</div>
				{/* SUBMIT BUTTON */
					selectedPaymentMethod.type === "RECURRING" &&
					adyenStatus === 'loaded' &&
					<div className="form-action fixed">
						<button type="submit" className="submit action" onClick={this.handlePayment} disabled={!this.isCVCFormValid()}>
							<div className="button-contents">
								{context === 'checkout' ? 'Place order & pay' : this.props.submitText}
							</div>
						</button>
					</div>
				}
			</div>
		);
	}
}

AdyenCVCOnlyPaymentForm.propTypes = {
	title: PropTypes.string,
	onPaymentSubmission: PropTypes.func.isRequired,
	submitText: PropTypes.string.isRequired,
	storePaymentMethod: PropTypes.bool.isRequired,
	refreshToken: PropTypes.func.isRequired,
	context: PropTypes.oneOf(['checkout', 'preauth']).isRequired
};

/**
 * Maps the checkout state from the application state to props for the checkout component
 * @param {Object} state
 * @return {Object} checkout state
 */
let mapStateToProps = (state) => {
	let { checkout, user } = state;

	return {
		checkoutConfirmation: checkout.checkoutConfirmation,
		loyalty: user.preferences.loyalty || { isPremium: false },
	};
};

/**
 * mapDispatchToProps
 *
 * @description maps the actions to props for the Payment component
 *
 * @param [function] dispatch
 * @return [object] payment props
 */
let mapDispatchToProps = (dispatch) => {
	return {
		onPaymentSubmission: (data) => dispatch(makeRecurringPayment(data)),
		createPreOrderForSavedCard: ({ checkout, deliveryTypes, optionID, verification, answer }) => dispatch(createPreAuthOrder(checkout, deliveryTypes, optionID, verification, answer)),
		createOrderForSavedCard: ({ checkout, deliveryTypes, session }) => dispatch(completeOrder(checkout, deliveryTypes, session)),
	}
}

// map state and dispatch methods to props for Payment component
AdyenCVCOnlyPaymentForm = connect(
	mapStateToProps,
	mapDispatchToProps
)(AdyenCVCOnlyPaymentForm);

export default AdyenCVCOnlyPaymentForm;
