import { CALENDAR_EVENT } from '../nativeBridge/messageTypes';
import { postMessage } from '../nativeBridge';
import config from '../config/config';
import storage from '../storage/storage';

const CALENDAR_EVENTS_KEY = 'calendar';
const PRODUCT_URL_BASE = `${config.shareUrl}product/`;


//////////////////
// public


/**
 * Adds a calendar event, and returns the array of events, or false (if event already exists)
 * @param {string} productID
 * @param {string} name
 * @param {string} launchDate as ISOstring
 * @returns {Array.<Object>|false}
 */
export function addCalendarEvent(productID, name, launchDate) {
	let calendarEvents = getCalendarEvents();
	if (!hasCalendarEvent(productID)) {
		let event = {
			endDate: launchDate,
			eventAction: 'add',
			eventID: null,
			notes: '', //`The ${name} is launching on ${new Date(launchDate)}`
			productID,
			startDate: launchDate,
			title: `${config.calendarEventPrefix}${name}`
		};
		for (let i = 0, len = calendarEvents.length; i < len; i++) {
			if (!calendarEvents[i].eventAction) calendarEvents[i].eventAction = 'edit';
		}
		calendarEvents.push(event);

		updateNativeApps(calendarEvents);
		return calendarEvents;
	} else {
		return false;
	}
};

/**
 * Removes all calendar events
 * @returns {Array}
 */
export function clearCalendarEvents() {
	let events = getCalendarEvents();
	for (let i = 0, len = events.length; i < len; i++) {
		events[i].eventAction = 'delete';
	}
	updateNativeApps(events);
	return [];
};

/**
 * Gets the calendar events from localStorage
 * @returns {Array.<Object>}
 */
export function getCalendarEvents() {
	let calendarEvents = storage.getItemNoExpiry(CALENDAR_EVENTS_KEY);
	calendarEvents = calendarEvents ? JSON.parse(calendarEvents) : [];
	return calendarEvents;
};

/**
 * Handles the response to the calendar event changes from the native apps
 * Rebuilds the array of calendar events for localStorage from the native response, the events already in
 * localStorage, and the array of events sent to the native apps
 * Overly complicated because we can do one of three things on the events, each of which can succeed or fail
 * @param {Object} response the response from the native apps
 * @param {Object} state the existing calendarEvents state
 * @returns {{errors: Array.<string>, events: Array.<Object>}}
 */
export function handleNativeResponse(response, state) {
	let errors = [];
	let events = [];

	// build objects of events, indexed by ID
	let keyedEvents = {}; //the array of calendar events on localStorage
	let keyedWaiting = {}; //the array of updated calendar events just sent to native
	for (let i = 0, len = state.calendarEvents.length; i < len; i++) keyedEvents[state.calendarEvents[i].productID] = state.calendarEvents[i];
	for (let i = 0, len = state.updateWaiting.length; i < len; i++) keyedWaiting[state.updateWaiting[i].productID] = state.updateWaiting[i];

	// build events object from the successful events
	// adding in eventID from the response where appropriate
	for (let i = 0, len = response.events.length; i < len; i++) {
		let res = response.events[i];
		if (res.productID) {
			let event;
			let waitingEvent = keyedWaiting[res.productID];
			switch (waitingEvent.eventAction) {
				// if waiting.eventAction = add
				// 		fail - error, do nothing
				// 		success - add to events
				// 		remove from waiting
				case 'add':
					if (res.success) {
						event = waitingEvent;
						event.eventID = res.eventID;
					} else {
						errors.push('calendar-cannot-add');
					}
					delete keyedWaiting[res.productID];
					break;

				// if waiting.eventAction = delete
				// 		fail.manual-deletion - do nothing
				// 		fail - error, set from events
				// 		success - do nothing
				// 		remove from waiting
				case 'delete':
					if (res.success) {
						// no need to do anything
					} else {
						if (res.reason !== 'manual-deletion') {
							event = keyedEvents[res.productID];
							errors.push('calendar-cannot-delete');
						}
					}
					delete keyedWaiting[res.productID];
					break;

				// if waiting.eventAction = edit
				// 		fail - error, set from events
				// 		success - set from waiting
				// 		remove from waiting
				case 'edit':
					if (res.success) {
						event = waitingEvent;
						event.eventID = res.eventID; //might have changed
					} else {
						if (res.reason !== 'manual-deletion') {
							event = keyedEvents[res.productID];
							errors.push('calendar-cannot-edit');
						}
					}
					delete keyedWaiting[res.productID];
					break;

				default: //do nothing
			}
			if (event) { //i.e. everything but successful/manual delete
				delete event.eventAction;
				events.push(event);
			}
		}
	}

	// those still in waiting
	// 		if exist in events, copy from events -- we don't know what has happened, so ignore changes
	// 		if don't exist in events, do nothing
	// 		remove from waiting
	for (let key in keyedWaiting) {
		if (keyedEvents.hasOwnProperty(key)) {
			let event = keyedEvents[key];
			delete event.eventAction;
			events.push(event);
		}
	}

	return { errors, events };
};

/**
 * Checks to see if a calendar event is in localStorage for a productID
 * @param {string} productID
 * @returns {boolean}
 */
export function hasCalendarEvent(productID) {
	let calendarEvents = getCalendarEvents();
	let has = false;
	for (let i = 0, len = calendarEvents.length; i < len; i++) {
		if (calendarEvents[i].productID === productID) has = true;
	}
	return has;
};

/**
 * Rebuilds the calendar events, usually called when product data has changed
 * @param {Array.<Object>} [products] array of product objects
 * @returns {Array.<Object>|false}
 */
export function rebuildCalendarEvents(products) {
	let calendarEvents = getCalendarEvents();
	if (products && products.length) {
		calendarEvents = updateLaunchDates(calendarEvents, products);
		if (!calendarEvents) return; //if false, then nothing to update
	}
	for (let i = 0, len = calendarEvents.length; i < len; i++) {
		if (!calendarEvents[i].eventAction) calendarEvents[i].eventAction = 'edit';
	}
	updateNativeApps(calendarEvents);
	return calendarEvents;
};

/**
 * Removes a single calendar event, and returns an array of calendar events without the removed event, or false
 * if not able to find the event to remove it
 * @param {string} productID productID of the calendar event to remove
 * @returns {Array.<Object>|false}
 */
export function removeCalendarEvent(productID) {
	let calendarEvents = getCalendarEvents();
	let ind = -1;
	for (let i = 0, len = calendarEvents.length; i < len; i++) {
		if (calendarEvents[i].productID === productID) ind = i;
		calendarEvents[i].eventAction = 'edit';
	}

	if (ind !== -1) {
		calendarEvents[ind].eventAction = 'delete';
		updateNativeApps(calendarEvents);
		return calendarEvents;
	} else {
		return false;
	}
};

/**
 * Sets the calendar events on localStorage
 * @param {Array.<Object>} calendarEvents
 */
export function setCalendarEvents(calendarEvents) {
	let str = JSON.stringify(calendarEvents);
	storage.setItemNoExpiry(CALENDAR_EVENTS_KEY, str);
};


//////////////////
// private


/**
 * Builds the calendar events for the native apps
 * @param {Array.<Object>} calendarEvents
 * @returns {Array.<Object>}
 */
function buildAppCalendarEvents(calendarEvents) {
	let appCalendarEvents = [];
	for (let i = 0, len = calendarEvents.length; i < len; i++) {
		calendarEvents[i].url = `${PRODUCT_URL_BASE}${calendarEvents[i].productID}`;
		appCalendarEvents.push(calendarEvents[i]);
	}
	return calendarEvents;
}

/**
 * Updates the launch dates of calendar events and returns the changed events, or false if nothing changed
 * @param {Array.<Object>} calendarEvents
 * @param {Array.<Object>} products
 * @returns {Array.<Object>|false}
 */
function updateLaunchDates(calendarEvents, products) {
	let changes = false;

	// organise products into an object where they are keyed by ID
	let keyedProducts = {};
	for (let i = 0, len = products.length; i < len; i++) keyedProducts[products[i].ID] = products[i];

	// iterate over calendarEvents, check launchDate against matching product, update if necessary
	for (let i = 0, len = calendarEvents.length; i < len; i++) {
		let product = keyedProducts[calendarEvents[i].productID];
		if (product) {
			if (calendarEvents[i].startDate !== product.launchDate) {
				changes = true;
				calendarEvents[i].startDate = product.launchDate;
				calendarEvents[i].endDate = product.launchDate;
			}
		}
	}
	return changes ? calendarEvents : false; //if something's changed, return array of events, else false
}

/**
 * Sends a message to the native apps telling them to update the calendar
 * @param {Array.<Object>} calendarEvents
 */
function updateNativeApps(calendarEvents) {
	let payload = {
		messageType: CALENDAR_EVENT,
		data: {
			events: buildAppCalendarEvents(calendarEvents)
		}
	};
	postMessage(payload);
}
