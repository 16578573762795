import CalendarItemColumns from '../calendarItem/columns/CalendarItem';
import CalendarItemFull from '../calendarItem/full/CalendarItem';
import moment from 'moment';

import React, { PropTypes } from 'react';
import { connect } from 'react-redux';
import config from '../../services/config/config'

import './Calendar.scss';


//////////////////


const Calendar = React.createClass({
	componentDidMount() {
		let el = document.getElementsByClassName('Calendar')[0].getElementsByClassName('content-scroll')[0];
		el.scrollTop = 0;
	},
	hasLaunchDatePassed(launchDate) {
		launchDate = moment(launchDate);
		return moment().diff(launchDate) > 0;
	},
	canDisplayProduct(product) {
		let { loyalty } = this.props;
		//Is the product premium
		if (isPremiumProduct(product) && !loyalty.isPremium) {
			return false;
		}

		if (product.hideBeforeRelease && !this.hasLaunchDatePassed(product.launchStartDate || product.launchDate)) {
			return false;
		}

		if (!product.filters || product.filters.length === 0) {
			//if we dont have any filters, the product is ok to show
			return true;
		}
		let { completedScratchCards, actionProducts, bookmarks } = this.props;
		for (let filter in product.filters) {
			let filterObj = product.filters[filter];
			switch (filterObj.type) {
				case 'bookmarked':
					if (bookmarks.find((bookmark) => { return bookmark.ID === filterObj.value })) {
						return true;
					}
					break;
				case 'scratchoff':
					if (completedScratchCards.includes(filterObj.value)) {
						return true;
					}
					break;
				case 'action':
					if (actionProducts.includes(product.ID)) {
						return true;
					}
					break;
				default:
					console.log("Unknown filter type")
			}
		}
		return false
	},

	render() {
		const CalendarItem = config.layout.listStyle === "full" ? CalendarItemFull : CalendarItemColumns;

		let { productsByMonth, theme, pastLaunches } = this.props;
		let pastProduct = [].concat.apply([], Object.values(pastLaunches)).map((product) => <CalendarItem
			key={product.ID}
			buttonStyles={theme.primaryButton}
			image={product.mainImage}
			launchDate={product.launchDate}
			launchStartDate={product.launchStartDate}
			launchType={product.launchType}
			sku={product.ID}
			title={product.name}
			product={product}
			price={product.price}
			subtitle={product.subTitle}
			soldOut={product.status === 'out-of-stock' || product.status === 'complete'}
			trackScroll={this.props.trackScroll} />);
		// build calendar list
		let calendarList = [];
		let promoProducts = [];
		let keys = Object.keys(productsByMonth);
		keys.sort((a, b) => a > b ? 1 : a < b ? -1 : 0);
		for (let i = 0, len = keys.length; i < len; i++) {
			if (productsByMonth.hasOwnProperty(keys[i])) {
				productsByMonth[keys[i]].forEach((product, i) => {
					//Can Display product (because of filters)
					if (this.canDisplayProduct(product)) {
						//Get Promo products
						if (product.promoImage && product.promoImage.original && product.promoImage.original !== '') {
							promoProducts.push(<CalendarItem
								key={product.ID || i}
								buttonStyles={theme.primaryButton}
								image={product.promoImage}
								launchDate={product.launchDate}
								launchStartDate={product.launchStartDate}
								launchType={product.launchType}
								sku={product.ID}
								title={product.name}
								product={product}
								subtitle={product.subTitle}
								type="promo"
								price={product.price}
								soldOut={product.status === 'out-of-stock' || product.status === 'complete'}
								trackScroll={this.props.trackScroll} />);
						} else {
							calendarList.push(<CalendarItem
								key={product.ID || i}
								buttonStyles={theme.primaryButton}
								image={product.mainImage}
								launchDate={product.launchDate}
								launchStartDate={product.launchStartDate}
								launchType={product.launchType}
								sku={product.ID}
								title={product.name}
								product={product}
								price={product.price}
								subtitle={product.subTitle}
								soldOut={product.status === 'out-of-stock' || product.status === 'complete'}
								trackScroll={this.props.trackScroll} />);
						}
					}
				});
			}
		}

		calendarList.reverse(); //oldest month first
		// return full calendar markup
		return (
			<div className={`Calendar ${config.layout.listStyle} 
			nav-${config.layout.nav.position} 
			${calendarList.length === 0 && promoProducts.length === 0 ? 'no-scroll' : ""}`}>
				<div className="content-scroll">
					<h1 className="launches-title">Draws</h1>
					{calendarList.length === 0 && promoProducts.length === 0 ?
						<div className="no-products">
							<div className="overlay">
								No upcoming releases at the moment
							</div>
						</div>
						: <div>
							{promoProducts.length ? <ul className="promo-products">
								{promoProducts}
							</ul> : null}

							<ul> <li> <ul>
								{calendarList}
							</ul></li></ul>
						</div>
					}
					{pastProduct.length ? <div> <h1 className="launches-title">Previous Draws</h1>
						<ul> <li> <ul>
							{pastProduct}
						</ul></li></ul>
					</div> : null
					}
				</div>
			</div>
		);
	}
});

function isPremiumProduct(product) {
	//If isPremium false, then the product is not premium
	if (!product.isPremium) {
		return false;
	}

	//If we have no premiumEndDate, the product is always premium.
	if (!product.premiumEndDate) {
		return true;
	}

	//If were past the premium end date, the product is on general release
	let premiumEndDate = new Date(product.premiumEndDate);
	let now = new Date();
	return premiumEndDate > now;
}

Calendar.defaultProps = {
	products: []
};

Calendar.propTypes = {
	products: PropTypes.array.isRequired
};

const mapStateToProps = state => {
	const { content, bookmarks, user, pastLaunches } = state;
	return { pastLaunches: pastLaunches.productsByMonth, completedScratchCards: content.completedScratches || [], actionProducts: content.productsToShow || [], bookmarks: bookmarks.bookmarkedProducts, loyalty: user.preferences.loyalty || { isPremium: false } };
};

const mapDispatchToProps = dispatch => {
	return {};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Calendar)
