import React from 'react';
import { connect } from 'react-redux';

import { updateUserPreferences, updateMarketingPreferencesPlatform } from '../../../../actions/preferences';



const OptIn = React.createClass({
    getInitialState() {
        return {
            hide: false
        }
    },
    componentWillMount() {
        let { preferences, content } = this.props;
        if (preferences.optedIn && content.hideWithOptin) {
            this.setState({ hide: true });
        }
    },
    onChange() {
        let { preferences, personal } = this.props
        let updatedPrefrences = Object.assign({}, preferences, { optedIn: !preferences.optedIn, email: personal.email })
        this.props.savePreferences("marketing", updatedPrefrences);
        this.props.updatePlatform(preferences);
    },
    render() {
        let { preferences, content, name } = this.props
        return (!this.state.hide ? <div
            className={`optIn-wrapper ${content.fullwidth ? 'full' : 'borders'} ${name}`}>
            <div dangerouslySetInnerHTML={createMarkup(content.data)} />
            <div className="form-group check-option">
                <input type="checkbox" id="marketing" checked={preferences.optedIn} onChange={this.onChange} />
                <label htmlFor="marketing">
                    <span className="check-box tick"></span>
                    <span className="name">Yes, sign me up!</span>
                </label>
            </div>
        </div> : null)
    }
})

function createMarkup(html) {
    return { __html: html };
}

const mapStateToProps = state => {
    const { user } = state;
    return { preferences: user.preferences.marketing || { optedIn: false }, personal: user.preferences.personal };
};

const mapDispatchToProps = dispatch => {
    return {
        savePreferences: (key, data) => dispatch(updateUserPreferences(key, data)),
        updatePlatform: (data) => dispatch(updateMarketingPreferencesPlatform(data)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OptIn)