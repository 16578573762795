import React, {PropTypes} from 'react';
import {formatTimeForCountdown, getTimeRemaining} from '../../services/time/time';
import './Countdown.scss';

/**
 * Countdown component
 *
 * @description
 * This component will accept an end time to countdown to. This is the only required prop. You
 * can pass in other properties to customise this component :
 *
 * @param showAllUnits [bool] this will only show units that are left e.g. if 35 seconds left you will not see minutes, hours or days
 * @param start [string] ISO formatted date, this defaults to the current time on the locale machine (new Date().toISOString) but you can
 * specify this if you need to
 *
 * @example
 * <Countdown end="2016-11-10T11:52:02.074Z" showAllUnits={false}>Timer expires in:</Countdown>
 */

const Countdown = React.createClass({

	getInitialState(){

		return {
			days : `00`,
			hours : `00`,
			minutes : `00`,
			seconds : `00`
		};
	},

	updateTimeRemaining(end){
		let start = new Date();
		return getTimeRemaining(end || this.props.end, start);
	},

	componentWillMount(){
		let { showDaysInHours } = this.props;

		let interval = 1000;
		this.remaining = this.updateTimeRemaining();
		if(this.remaining.valueOf() > 0 || !this.props.countdownEnded){
			let time = formatTimeForCountdown(this.remaining, showDaysInHours);
			this.setState(time);

			this.timeInterval = setInterval(() => {
				if(!this.props.pause){
					let time = formatTimeForCountdown(this.remaining, showDaysInHours);
					this.setState(time);
					this.remaining = this.updateTimeRemaining();
					if(this.remaining.valueOf() < 0){
						if(this.props.countdownEnded) this.props.countdownEnded();
						clearInterval(this.timeInterval);
					}
				}

			}, interval);
		}else{
			if(this.props.countdownEnded) this.props.countdownEnded();
		}

	},


	componentWillReceiveProps(nextProps) {
		// Will prevent unneeded calculations on each render
		if (nextProps.end !== this.props.end) {
			this.remaining = this.updateTimeRemaining(nextProps.end);
			let time = formatTimeForCountdown(this.remaining);
			this.setState(time);
		}

	},

	componentWillUnmount(){
		clearInterval(this.timeInterval);
	},

	render(){
		let {days, hours, minutes, seconds } = this.state;
		let { showAllUnits, showDaysInHours } = this.props;
		if(typeof showAllUnits === 'undefined') showAllUnits = true;
        let speedHour = ( days === '00' && hours === '00' ) ? 'faster' : '';
		return (
			<div className={`countdown ${speedHour}`}>
				{this.props.children}
				{ ((!showAllUnits && days === '00') || showDaysInHours) ? null : <span className='days'><span className='count'>{days}<span><small>Days</small></span></span><span className='colon'>:</span></span>}
				{ (!showAllUnits && hours === '00' && days === '00') ? null : <span className='hours'><span className='count'>{hours}<span><small className='units'>Hrs</small></span></span><span className='colon'>:</span></span>}
				{ (!showAllUnits && minutes === '00' && hours === '00') ? null : <span className='minutes'><span className='count'>{minutes}<span><small className='units'>Mins</small></span></span><span className='colon'>:</span></span>}
				{ (!showAllUnits && seconds === '00' && minutes === '00') ? null : <span className='seconds'><span className='count'>{seconds}<span><small className='units'>Secs</small></span></span></span>}
			</div>
		)
	}
});

Countdown.propTypes = {
  showAllUnits: PropTypes.bool,
  showDaysInHours: PropTypes.bool,
  children: PropTypes.element
};

export default Countdown;