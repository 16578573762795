import React, {PropTypes} from 'react';
import DeliveryOption from './DeliveryOption';

const DeliveryMethod = ({options, displayName, updateDeliveryMethod, methodType, selectedDeliveryMethod}) => {

	const handleDeliverMethodSelection = (method) => {
		let newMethod = Object.assign({},method, {type : methodType} );
		return updateDeliveryMethod(newMethod);
	}

	const deliveryOptionsList = options.map((el, i) => <DeliveryOption key={i} methodType={methodType} option={el} updateDeliveryMethod={handleDeliverMethodSelection} selectedDeliveryMethod={selectedDeliveryMethod}/>);

	return (
		<li className="form-option-type">
				{deliveryOptionsList}
		</li>
	);
}


DeliveryMethod.defaultProps = {
	options:[],
	displayName: ""
}

DeliveryMethod.propTypes = {
	option : PropTypes.array,
	displayName: PropTypes.string,
	updateDeliveryMethod: PropTypes.func
}

export default DeliveryMethod;