import React, { Component } from "react";
import Achievement from "../achievements/Achievement";

export default class AchievementBadges extends Component {
	render() {
		const content = this.props.content;
		return (
			<div style={{ marginBottom: "10px" }}>
				<div className="achievements_title">
					Achievements
					<div className="title_divider" />
				</div>
				<div className="achievement_container">
					{content.achievements.map(level => (
						<Achievement
							key={level}
							level={level}
							wins={this.props.totalWins}
						/>
					))}
				</div>
			</div>
		);
	}
}