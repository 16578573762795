import React, { PropTypes } from 'react';
import TextField from '../textInput/TextInput';
const MyDetails = ({ collapsed, customer, updateCallback }) => {
	const updateCustomer = (update) => updateCallback(update);
	const handleEmailChange = (e) => updateCustomer({ email: e.target.value });
	const handleFirstNameChange = (e) => updateCustomer({ firstName: e.target.value });
	const handleLastNameChange = (e) => updateCustomer({ lastName: e.target.value });
	const handlePhoneChange = (e) => updateCustomer({ phone: e.target.value });
	const handleSubmit = (e) => e.preventDefault();
	if (collapsed && customer) {
		return (
			<form className="form-horizontal" onSubmit={evt => evt.preventDefault()}>
				<p>
					{customer.firstName}&nbsp;{customer.lastName}<br />
					{customer.email}<br />
					{customer.phone}
				</p>
			</form>
		);
	} else {
		return (
			<form className="form-horizontal" onSubmit={handleSubmit}>
				<div className="form-group">
					<TextField
						type="text"
						name="name"
						floatingLabelText="First Name"
						value={customer.firstName}
						onChange={handleFirstNameChange}
						required={true}
						hintText="Please enter your first name"
						fullWidth={true}
					/>
				</div>
				<div className="form-group">
					<TextField
						type="text"
						name="name"
						floatingLabelText="Surname"
						value={customer.lastName}
						onChange={handleLastNameChange}
						required={true}
						hintText="Please enter your  surname"
						fullWidth={true}
					/>
				</div>
				<div className="form-group">
					<TextField
						type="email"
						name="email"
						floatingLabelText="Email"
						value={customer.email}
						onChange={handleEmailChange}
						required={true}
						hintText="Please enter your email"
						fullWidth={true}
					/>
				</div>
				<div className='form-group'>
					<TextField
						type="tel"
						name="phone"
						floatingLabelText="Phone Number"
						value={customer.phone}
						onChange={handlePhoneChange}
						required={true}
						hintText="This should be 8-14 characters long and should only contain numbers or a '+' symbol"
						fullWidth={true}
					/>
				</div>
			</form>
		)
	}
};
MyDetails.propTypes = {
	customer: PropTypes.object.isRequired
};
export default MyDetails;