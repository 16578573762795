import React from 'react';
import { connect } from 'react-redux';
import { hashHistory } from 'react-router';

import { showPromoPopup } from '../../actions/content';
import CalendarItemColumns from '../calendarItem/columns/CalendarItem';
import CalendarItemFull from '../calendarItem/full/CalendarItem';

import config from '../../services/config/config'

import './PromoProduct.scss';


//////////////////


const PromoProduct = React.createClass({
    onClick() {
        let { showPopup, products } = this.props;
        if (products.length === 1) {
            hashHistory.push(`/product/${products[0]}`)
        } else {
            showPopup(products)
        }
    },
    render() {
        let { image, name, subTitle, trackScroll, productObjects } = this.props;
        let maxPrice;
        let minPrice;

        for (let product of productObjects) {
            maxPrice = !maxPrice || maxPrice < product.price.amount ? product.price.amount : maxPrice
            minPrice = !minPrice || minPrice > product.price.amount ? product.price.amount : minPrice
        }
        let product = productObjects[0];
        const CalendarItem = config.layout.listStyle === "full" ? CalendarItemFull : CalendarItemColumns;
        return (
            <CalendarItem
                key={product.ID}
                image={{ original: image }}
                launchDate={product.launchDate}
                launchStartDate={product.launchStartDate}
                launchType={product.launchType}
                sku={product.ID}
                title={name}
                type="promo"
                product={product}
                price={product.price}
                maxPrice={maxPrice}
                minPrice={minPrice}
                subtitle={subTitle}
                soldOut={product.status === 'out-of-stock' || product.status === 'complete'}
                trackScroll={() => { trackScroll(); this.onClick() }}
                noRedirect={true}
            />
        );
    }
});

//////////////////

const mapStateToProps = state => {
    return {}
};

const mapDispatchToProps = dispatch => {
    return {
        showPopup: (products) => dispatch(showPromoPopup(products))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PromoProduct)