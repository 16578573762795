import {
	CLEAR_BOOKMARK_PRODUCTS,
	GET_PRODUCTS_FOR_BOOKMARKS,
	HAVE_PRODUCTS_FOR_BOOKMARKS,
	PROCESS_BOOKMARK_PRODUCTS,
	REMOVE_PRODUCT_BOOKMARK
} from '../actions/bookmarks';

export const initialState = () => ({
	bookmarkedProducts: [],
	loaded: false,
	productsByID: {}
});

export const bookmarks = (state = initialState(), action) => {
	let productsByID = Object.assign({}, state.productsByID); //don't manipulate the state directly

	switch (action.type) {
		// clears the bookmarks state
		case CLEAR_BOOKMARK_PRODUCTS:
			return Object.assign({}, initialState());
		// builds an array of product objects for the users bookmarks
		case GET_PRODUCTS_FOR_BOOKMARKS:
			let bookmarks = action.bookmarks;
			let bookmarkedProducts = [];
			// make array of bookmarked products
			for (let i = 0, len = bookmarks.length; i < len; i++) {
				if (productsByID[bookmarks[i]]) {
					bookmarkedProducts.push(productsByID[bookmarks[i]]);
				}
			}
			// sort bookmarked products by launch date ascending (as array of bookmarks is unordered)
			bookmarkedProducts.sort((a, b) => {
				let aProp = a.launchDate;
				let bProp = b.launchDate;
				return aProp > bProp ? 1 : aProp < bProp ? -1 : 0;
			});

			// sort bookmarked products by launchType by month
			let monthOnlyArr = [];
			let ordered = [];
			if (bookmarkedProducts.length) {
				let prevMonth = new Date(bookmarkedProducts[0].launchDate).getMonth();
				let prevYear = new Date(bookmarkedProducts[0].launchDate).getYear();
				for (let i = 0, len = bookmarkedProducts.length; i < len; i++) {
					// iterates over bookmarked array, building separate monthOnly array, which gets added to full
					// ordered array when we've moved onto a new month (also takes into account a new year)
					let month = new Date(bookmarkedProducts[i].launchDate).getMonth();
					let year = new Date(bookmarkedProducts[i].launchDate).getYear();
					if (month !== prevMonth || (month === prevMonth && year !== prevYear)) {
						ordered = [].concat(ordered, monthOnlyArr);
						monthOnlyArr = [];
					}

					if (bookmarkedProducts[i].launchType === 'monthOnly') {
						monthOnlyArr.push(bookmarkedProducts[i]);
					} else {
						ordered.push(bookmarkedProducts[i]);
					}
					prevMonth = month;
					prevYear = year;
				}
				ordered = [].concat(ordered, monthOnlyArr);
			}

			// console.timeEnd('getBookmarks');
			return Object.assign({}, state, { bookmarkedProducts: ordered });

		// returns the array of product objects for the users bookmarks
		case HAVE_PRODUCTS_FOR_BOOKMARKS:
			return state;

		// builds a map of products { id: {product} }
		case PROCESS_BOOKMARK_PRODUCTS:
			let products = action.products,
				newProductsByID = {};
			for (let i = 0, len = products.length; i < len; i++) {
				newProductsByID[products[i].ID] = products[i];
			}
			return Object.assign({}, state, { productsByID: newProductsByID });

		// removes a bookmark from the list
		case REMOVE_PRODUCT_BOOKMARK:
			let newBookmarkedProducts = state.bookmarkedProducts.filter((product) => product.ID !== action.productID);
			return Object.assign({}, { bookmarkedProducts: newBookmarkedProducts });

		default:
			return state;
	}
};
