/**
 * Checkout presentational component
 * This is a presentational component which wraps the checkout page.
 * Receives props from the container component in containers/checkoutPage
 */
import React from 'react';
import { hashHistory } from 'react-router'
import CartTotals from '../cartTotals/CartTotals';
import MiniCartItems from '../miniCart/MiniCartItems';
import PaymentMethods from '../paymentMethods/PaymentMethods';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import TitleBlock from '../titleBlock/TitleBlock';
import CustomerDetails from './CustomerDetails';
import DeliveryMethod from './DeliveryMethod';
import DeliveryAddress from './DeliveryAddress';
import BillingAddress from './BillingAddress';
import OrderDisclaimer from './OrderDisclaimer';
import { isObjectFull } from '../../services/checkout/checkout';
import OptIn from './OptIn';
import withRecaptchaV3 from '../recaptcha/recaptcha';

//import css for forms
import '../../styles/bootstrap-forms.scss';
import '../../styles/forms.scss';

//page css
import './Checkout.scss';

import { EVTS, trackEvent, trackPage } from '../../services/analytics/analytics';
import Countdown from '../countdownTimer/Countdown';
import UspBanner from '../uspBanner/UspBanner';
import { navigateToExternal } from '../../services/navigate/navigate';
import config from '../../services/config/config';


//////////////////


const Checkout = React.createClass({
	componentWillMount() {
		// redirect if no session present. Might be better handled somewhere else
		if (!this.props.session.ID) {
			hashHistory.push('/');
			return;
		}

		this.props.loadDataForCheckout(this.props.session.ID)
			.then((data) => {
				// cache the initially loaded stores for autosuggest
				this.stores = this.props.checkout.stores;
				this.props.enablePaymentMethods(this.props.session.stock[0].product, this.props.checkout.locale, this.props.checkout.billing, this.props.checkout.useBillingAsDelivery);
				trackEvent(EVTS.CAT.CHECKOUT, EVTS.ACT.CHECKOUT_INIT, undefined, this.props.session.stock[0]);
			});

		trackPage('checkout');
	},
	isFormValid() {
		const { checkout } = this.props;
		let isFormValid = checkout.deliveryMethod.ID &&
			checkout.paymentMethod.ID &&
			isObjectFull('customer', checkout.customer) &&
			(checkout.checkoutConfirmation || checkout.paymentMethod.type !== 'KLARNA') &&
			isObjectFull('deliveryMethod', checkout.deliveryMethod) &&
			(checkout.useBillingAsDelivery || isObjectFull('billing', checkout.billing)) &&
			(isObjectFull('delivery', checkout.delivery) || checkout.deliveryMethod.type === 'clickAndCollect')
		return isFormValid
	},

	payment() {
		this.props.completeOrder({
			checkout: this.props.checkout,
			session: this.props.session,
			deliveryTypes: this.props.deliveryTypes
		});
	},

	trackSaveSection(section, data) {
		trackEvent(EVTS.CAT.CHECKOUT, EVTS.ACT[section]);
	},

	render() {
		const {
			billingAddressChanged,
			checkout,
			clearSelectedStore,
			removeItem,
			customerDetailsChanged,
			deliveryAddressChanged,
			deliveryTypes,
			filterStores,
			localeChange,
			products,
			selectDeliveryMethod,
			selectPaymentMethod,
			selectStore,
			session,
			theme,
			toggleUseBillingAsDelivery,
			toggleCheckoutConfirmation,
			loyalty
		} = this.props;

		return (
			<div className="Checkout">
				<div className="content-scroll" style={this.props.checkout.paymentMethod.type === "RECURRING" ? { paddingBottom: "100px" } : {}}>
					<div className="content-scroll-fix">
						<style>{`
							@media all and (min-width: 768px) {
								.header .usp, .desktop-header .usp,.native-header .usp  {
										display: none !important;
									}
								}
						`}</style>
						<div className='right'>
							{/* Mini Cart*/}
							<div className="form-horizontal">
								<MiniCartItems stock={session.stock} products={products} onRemove={removeItem} />
								<UspBanner message="Your items will be held for...">
									<Countdown end={session.expiryDate} showAllUnits={false} pause={session.pause} />
								</UspBanner>
								<div className='desktop'>
									<OptIn />
									<div> We will use your information in accordance with our <span className='link' onClick={() => navigateToExternal(`${config.storeUrl}customer-service/privacy/`)}>privacy policy</span>.</div>
									<br />
									<OrderDisclaimer checkout={checkout} toggleCheckoutConfirmation={toggleCheckoutConfirmation} />
									{/* Totals */}
									{
										(checkout.deliveryMethod.ID
											&& (checkout.deliveryMethod.type !== 'clickAndCollect'
												|| (checkout.deliveryMethod.type === 'clickAndCollect' && checkout.deliveryMethod.storelocationID)))
											? <CartTotals delivery={checkout.deliveryMethod.price} products={products} stock={session.stock} />
											: null
									}
									{checkout.paymentMethod.type !== 'RECURRING' ?
										<div className="form-action not-fixed">
											<button className="action" onClick={this.payment} disabled={!this.isFormValid()} style={theme.primaryButton}>
												<div className="button-contents">Place order & pay</div>
											</button>
										</div>
										: null}
								</div>
							</div>
						</div>
						<div className='left'>
							{/* Customer Details Selection */}
							<CustomerDetails
								checkout={checkout}
								customerDetailsChanged={customerDetailsChanged}
								theme={theme}
							/>

							{/* Delivery Method Selection */}
							<DeliveryMethod
								stores={this.stores}
								checkout={checkout}
								deliveryTypes={deliveryTypes}
								filterStores={filterStores}
								clearSelectedStore={clearSelectedStore}
								localeChange={(locale) => localeChange(locale, session.stock[0].product, checkout.billing, checkout.useBillingAsDelivery)}
								selectDeliveryMethod={selectDeliveryMethod}
								selectStore={selectStore}
								theme={theme}
							/>



							<ReactCSSTransitionGroup
								transitionName="checkout-block"
								transitionEnterTimeout={300}
								transitionLeaveTimeout={300} >


								{/* Delivery Address Selection */}
								<DeliveryAddress
									checkout={checkout}
									theme={theme}
									deliveryAddressChanged={deliveryAddressChanged}
									toggleUseBillingAsDelivery={() => toggleUseBillingAsDelivery(session.stock[0].product, checkout.locale, checkout.billing, checkout.useBillingAsDelivery)}
								/>



								{/* Billing Address */}
								<BillingAddress
									checkout={checkout}
									theme={theme}
									billingAddressChanged={(address) => billingAddressChanged(address, session.stock[0].product, checkout.locale, checkout.useBillingAsDelivery)}
								/>


								{/* Payment */}
								{
									(checkout.deliveryMethod.ID
										&& (checkout.deliveryMethod.type !== 'clickAndCollect'
											|| (checkout.deliveryMethod.type === 'clickAndCollect' && checkout.deliveryMethod.storelocationID)))
										? <div>
											<TitleBlock title="Payment Method" />
											<PaymentMethods options={checkout.paymentMethods}
												selectedPaymentMethod={checkout.paymentMethod}
												updatePaymentMethod={(paymentMethod) => {
													if (checkout.checkoutConfirmation) {
														toggleCheckoutConfirmation();
													}
													selectPaymentMethod(paymentMethod)
												}}
												loyalty={loyalty}
												product={products[0]}
												checkoutConfirmation={checkout.checkoutConfirmation}
												isFormValid={this.isFormValid()}
												refreshToken={this.props.refreshReCaptchaToken}
												session={session}
												context={'checkout'}
											/>
										</div>
										: null
								}
								<div className='mobile'>
									<OptIn />
									<div> We will use your information in accordance with our <span className='link' onClick={() => navigateToExternal(`${config.storeUrl}customer-service/privacy/`)}>privacy policy</span>.</div>
									<br />
									<OrderDisclaimer checkout={checkout} toggleCheckoutConfirmation={toggleCheckoutConfirmation} />
									{/* Totals */}
									{
										(checkout.deliveryMethod.ID
											&& (checkout.deliveryMethod.type !== 'clickAndCollect'
												|| (checkout.deliveryMethod.type === 'clickAndCollect' && checkout.deliveryMethod.storelocationID)))
											? <CartTotals delivery={checkout.deliveryMethod.price} products={products} stock={session.stock} />
											: null
									}
								</div>
							</ReactCSSTransitionGroup>
						</div>
					</div>
					{/* Actions */}
					{this.props.checkout.paymentMethod.type !== "RECURRING" ? <div className="form-action not-fixed mobile">
						<button className="action" onClick={this.payment} disabled={!this.isFormValid()} style={theme.primaryButton}>
							<div className="button-contents">Place order & pay</div>
						</button>
					</div> : null}
				</div>
			</div>
		);
	}
});

export default withRecaptchaV3(Checkout, 'PreAuth_Create');