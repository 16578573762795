import { ADD_CALENDAR_EVENT, HAVE_CALENDAR_RESPONSE, REBUILD_CALENDAR_EVENTS, REMOVE_CALENDAR_EVENT } from '../actions/calendarEvents';

const getInitialState = () => ({
	calendarEvents: [], //events on localStorage
	showErrors: true,
	updateWaiting: [] //events as just sent to native
});

export const calendarEvents = (state = getInitialState(), action) => {
	switch (action.type) {
		case ADD_CALENDAR_EVENT:
		case REMOVE_CALENDAR_EVENT:
			return Object.assign({}, state, { updateWaiting: action.calendarEvents, showErrors: true });

		case REBUILD_CALENDAR_EVENTS:
			// triggered by the app, so don't show errors
			return Object.assign({}, state, { updateWaiting: action.calendarEvents, showErrors: false });

		case HAVE_CALENDAR_RESPONSE:
			let obj = action.response;
			obj.showErrors = true;
			return Object.assign({}, state, obj);

		default:
			return state;
	}
};
