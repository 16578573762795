/**
 * Session
 *
 * Helper functions for managign sessions in local storage
 */

import storage from '../storage/storage';
import {getTimeRemaining} from '../time/time';

/**
 * setSession
 *
 * @param [string] object
 * @param [number] expiry defaults to 60 days
 * @return [boolean]
 */
export function setSession(session, expiry = 60*60*24*60){
    session = JSON.stringify(session);
    return storage.setItem('session', session, expiry);
}

/**
 * getSession
 *
 * @return [object, undefined] session
 */
export function getSession(){
    let session = storage.getItem('session');
    //Remove legacy session
    storage.removeItem('session');
    return session ? JSON.parse(session) : undefined;
}


/**
 * deleteSession
 *
 * @return [boolean]
 */
export function deleteSession(){
    storage.removeItem('session');
    storage.removeUserID();
}

/**
 * hasSessionExpired
 *
 * @return [boolean]
 */
export function hasSessionExpired(session){
    let sessionExpired = false;
    if(getTimeRemaining(session.expiryDate, new Date()).valueOf() < 1){
        sessionExpired = true;
    }
    return sessionExpired;
}