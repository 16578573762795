/**
 * Storage
 *
 * Provides simple caching functionality onto the usual localStorage interface.
 */

const TIMESTAMP_LABEL = ':ts';

/**
 * prefixCacheKey
 *
 * @description prepend the userID to cache key (if available) to allow multiple users to
 * have cached items.
 *
 * @param [string] key
 * @return {string}
 */
function prefixCacheKey(key){
	let userID = getUserID();
	if(!userID) userID = 'guest';
	return userID + '--' + key;
}

/**
 * setItem
 *
 * @description when using this function to set an item in localStorage if will also store an expiry time
 * for that item. This expiry will be stored with the key `${key}:ts` (timestamp).
 *
 * @param [string] key
 * @param [string] content
 * @param [number] expiry time in seconds
 */
function setItem(key, content, expiry = 60*60){
	let cacheKey = prefixCacheKey(key);

    expiry *= 1000;
    expiry += Date.now();
    try {
        localStorage.setItem(cacheKey, content);
        localStorage.setItem(cacheKey + TIMESTAMP_LABEL, expiry);
    } catch(e){
        // we've most likely hit out storage limit here. Lets not break the 
        //app
        clearExpiredItems();
    }
}

/**
 * Adds an item to localStorage without an expiry (for things that should persist (e.g. bookmarks)
 * @param {string} key
 * @param {string} content
 */
function setItemNoExpiry(key, content){
	let cacheKey = prefixCacheKey(key);

    localStorage.setItem(cacheKey, content);
}

/**
 * removeItem
 *
 * @description remove an item and its timestampe from storage
 * @param [string] key
 *
 */
function removeItem(key){
	let cacheKey = prefixCacheKey(key);
    localStorage.removeItem(cacheKey)
    localStorage.removeItem(cacheKey + TIMESTAMP_LABEL)
}

/**
 * Returns an item with no expiry from localStorage
 * @param {string} key
 * @return {string}
 */
function getItemNoExpiry(key){
	let cacheKey = prefixCacheKey(key);

    return localStorage.getItem(cacheKey);
}

/**
 * getItem
 *
 * @description This function will check if an item has expired (using the saved timestamp) and if its
 * still valid that return that data.
 *
 * @param [string] key
 * @return [string, undefined] the stored item or undefined
 */
function getItem(key){
	let cacheKey = prefixCacheKey(key);

    let item = localStorage.getItem(cacheKey);
    let expiryTime = localStorage.getItem(cacheKey + TIMESTAMP_LABEL);

     if (item !== null && expiryTime) {

        if (hasExpired(expiryTime)) {
            // clean up the old key
            removeItem(cacheKey)
        } else {
            return item;
        }
    } else if(item !== null && !expiryTime) {
        return item;
    }
}

/**
 * Adds userID to cache which can then be used in all other cache keys
 * @param {string} userID
 */
function setUserID(userID){
    localStorage.setItem('userID', userID);
}
/**
 * Gets userID from cache which can then be used in all other cache keys
 * @return {string} userID
 */
export function getUserID(){
    return localStorage.getItem('userID');
}
/**
 * Removes a userID from cache (user logging out)
 */
function removeUserID(){
	// console.log('remove user ID');
    localStorage.removeItem('userID')
}

/**
 * clearExpiredItems
 *
 * @description This function will go through all stored keys and delete any that have expired.
 *
 */
function clearExpiredItems(){
    for(var key in localStorage) {
        if(key.indexOf(TIMESTAMP_LABEL) === -1){
            // we have the keys without timestamps in
            // lets check if we can still get them
            // if they've expired they will be deleted
            getItem(key);
        }
    }
}

/**
 * hasExpired
 *
 * @description This function returns true if the date supplied in has passed
 * @return [boolean]
 */
function hasExpired(expiryTime){
    return Date.now() > expiryTime;
}

export default {
	setItem,
	setItemNoExpiry,
	getItem,
	getItemNoExpiry,
	removeItem,
	setUserID,
	removeUserID,
	clearExpiredItems
};
