import React from 'react'
import PropTypes from 'prop-types'
import CollapsibleSection from './CollapsibleSection';
import AddressForm from '../addressForm/AddressForm';
import { EVTS, trackEvent } from '../../services/analytics/analytics';

const BillingAddress = ({ checkout, theme, billingAddressChanged }) => {
    return (
        <span>
            {
                checkout.deliveryMethod.ID && !checkout.useBillingAsDelivery
                    ? <div>
                        <CollapsibleSection
                            title="Billing Address" propName="billing" checkoutData={checkout.billing} theme={theme}
                            trackSaveSection={() => trackEvent(EVTS.CAT.CHECKOUT, EVTS.ACT['BILLING_ADDRESS_SAVE'])}
                            editBlock={
                                <AddressForm address={checkout.billing} locales={checkout.locales} updateAddress={billingAddressChanged} />
                            }
                            collapsedBlock={
                                <AddressForm address={checkout.billing} locales={checkout.locales} updateAddress={billingAddressChanged} collapsed={true} />
                            }
                        />
                    </div>
                    : null
            }
        </span>
    )
}

BillingAddress.propTypes = {
    checkout: PropTypes.shape({
        billing: PropTypes.object.isRequired
    }),
}

export default BillingAddress
