import { TOGGLE_MINI_CART } from '../actions/header';
import { LOADING_SESSION, UPDATE_SESSION, CLEAR_SESSION } from '../actions/session';

const initialState = {showMiniCart : false, loading: false};

export const header = (state = initialState, action) => {
  
  switch (action.type) {
    case TOGGLE_MINI_CART:
       return Object.assign({}, state, {showMiniCart: action.display});
    case LOADING_SESSION:
      return Object.assign({}, state, {loading: true});
    case UPDATE_SESSION:
      return Object.assign({}, state, {loading: false});
    case CLEAR_SESSION:
      return Object.assign({}, initialState);
    default:
      return state;
  }
}

export default header;