import { CLEAR_CALENDAR, PROCESS_PRODUCTS } from '../actions/calendar';
import { isStatusAvailable } from '../services/product/product';

export const initialState = {
	loaded: false,
	productsByMonth: {}
};

export const calendar = (state = initialState, action) => {
	switch (action.type) {
		// clears the calendar state
		case CLEAR_CALENDAR:
			return Object.assign({}, initialState);

		// groups products by release month
		case PROCESS_PRODUCTS:
			// group products by month
			let products = [...action.products];
			let productsByMonth = {};
			//Sort products
			products.sort((a, b) => new Date(a.launchDate) > new Date(b.launchDate) ? -1 : new Date(a.launchDate) < new Date(b.launchDate) ? 1 : 0);

			/* build from products in reverse date order, so we can use unshift to add to the front of the
			 * array, and push to put monthOnly launches to the end of the array
			 * This means we can build an array of products in date order, with the monthOnly launches
			 * (those where we know the month but not the date) at the end of each month list, 
			 * in this list we only want to include currently active launches
			 */
			for (let i = products.length - 1; i > -1; i--) {
				if (isListingLive(products[i])) {
					let launchDate = new Date(products[i].launchDate);
					let monthYearKey = `${launchDate.toISOString().substr(0, 7)}`; // YYYYMM
					if (!productsByMonth[monthYearKey]) productsByMonth[monthYearKey] = [];
					if (products[i].launchType === 'monthOnly') {
						productsByMonth[monthYearKey].push(products[i]);
					} else {
						productsByMonth[monthYearKey].unshift(products[i]);
					}
				}
			}
			return Object.assign({}, state, { productsByMonth });

		default:
			return state;
	}

	function isListingLive(product){
		return (isStatusAvailable(product.status));
	}
};
