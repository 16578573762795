import React from 'react';
import MembershipBenefit from './MembershipBenefit';


//////////////////


export default () => {
    return (
        <div>
            <MembershipBenefit title="First access to our sales">
                Skip the queue and gain access to our sales hours before they go live. You’ll never have to deal with another stressful winter sale or Black Friday again!
            </MembershipBenefit>
            <MembershipBenefit title="Birthday promotions">
                We never forget our member’s birthdays. Expect an exclusive birthday treat on your special day.
            </MembershipBenefit>
            <MembershipBenefit title="Loyal VIP rewards">
                Loyal members are always rewarded, keep an eye out for benefits throughout the year.
            </MembershipBenefit>
            <MembershipBenefit title="Membership anniversary">
                size?access members come first – after a year of exclusive benefits, you’ll be rewarded generously.
            </MembershipBenefit>
        </div >
    )
};