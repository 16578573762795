import config, { DEV, UAT, LIVE, LOCAL } from '../config/config';


export function checkStatusForRateLimit(error){
    if(error && error.response && error.response.status && 
        (error.response.status === 502 || error.response.status === 429 || error.response.status === 400 || error.response.status === 401)){
        return true
    }
    return false; 
}

export function getAPIHost(){
    let env = config.env;
    let mosaicHost;

    // easy way to switch environments when testing locally
    if (window.location.hostname === 'localhost') {
        let qPrams = new URLSearchParams(window.location.search);
        env = qPrams.get('env') || LOCAL
    }

    // depending on our environment (based on url) we want to switch our api base
    switch (env) {
        //case LOCAL:
            //mosaicHost = 'http://localhost:4500/local'
            //break;
        case LOCAL:
        case DEV:
            mosaicHost = 'https://dev-mosaic-platform.nonprod.jdmesh.co';
            break;
        case UAT:
            mosaicHost = 'https://uat-mosaic-platform.nonprod.jdmesh.co';
            break;
        case LIVE:
        default:
            mosaicHost = 'https://mosaic-platform.jdmesh.co';
            break;
    }

    return mosaicHost;
}

export function getAPIDomain() {
    return `${getAPIHost()}/stores/${config.store}`;
}