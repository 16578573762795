import { Link, Route, Router, hashHistory } from 'react-router';
import React from 'react';
import { navigateToExternal } from '../../services/navigate/navigate';
import TitleBlock from '../titleBlock/TitleBlock';
import './Preferences.scss';
import config from './../../services/config/config'
import premiumLogo from './membership/logo.svg'

import { trackPage } from '../../services/analytics/analytics';

import BillingPreferences from './billing/BillingPreferences';
import DeliveryPreferences from './delivery/DeliveryPreferences';
import PaymentPreferences from './payment/PaymentPreferences';
import PreOrderPreferences from './preOrders/PreOrders';
import HelpfulLinks from './help/HelpfulLinks';
import Tutorial from './tutorial/Tutorial';
import Marketing from './marketing/Marketing';
import PersonalPreferences from './personal/PersonalPreferences';
import NotificationsPreferences from './notifications/NotificationsPreferences';
import MembershipPreferences from './membership/MembershipPreferences';
import SignupForm from './membership/SignupForm';


//////////////////


const Preferences = ({ firstName, loyalty, isLoggedIn, logout, showLogin }) => {
	trackPage('preferences');
	return (
		<div className="Preferences list">
			<div className={`left ${window.location.href.includes('/preferences?') ? 'base' : 'non-base'}`}>
				<div className="content-scroll">
					<div className='welcome'>
						{isLoggedIn && firstName ? <h4 className="greeting">Hi {firstName}</h4> : null}
					</div>
					<TitleBlock title="Account Preferences" />
					<div className='welcome'>
						<div className="form-horizontal">
							<p className="intro">
								Amend and edit your billing and delivery information so you can checkout in seconds. Also, choose your preferred push notifications, to remind you when one of your bookmarked trainers is about to launch.
							</p>
						</div>
					</div>
					<div className="form-horizontal form-options list-links">
						<ul>
							{/* doing all the isLoggedIn blocks separately so styling doesn't break */}
							{config.supportsMembership ?
								<li className={`form-option-type${window.location.href.includes('membership') ? ' active' : ''}`}>
									<Link to="/preferences/membership">
										<div className="check-option">
											<label>
												<img className="premium-logo" src={premiumLogo} alt="premium logo" />
												{loyalty.isPremium ? null : <p>Free join. Sign up now</p>}
											</label>
										</div>
									</Link>
								</li>
								: null
							}
							{
								isLoggedIn
									? <li className={`form-option-type${window.location.href.includes('personal') ? ' active' : ''}`}>
										<Link to="/preferences/personal">
											<div className="check-option">
												<label>
													<h4>Name, email and phone</h4>
													<p>Personal Details</p>
												</label>
											</div>
										</Link>
									</li>
									: null
							}
							{
								isLoggedIn
									? <li className={`form-option-type${window.location.href.includes('preferences/raffle') ? ' active' : ''}`}>
										<Link to="/preferences/raffle">
											<div className="check-option">
												<label>
													<h4>Manage your draw entries</h4>
													<p>Keep an eye on your draw entries</p>
												</label>
											</div>
										</Link>
									</li>
									: null
							}
							{
								isLoggedIn && config.supportsMembership
									? <li className={`form-option-type${window.location.href.includes('preferences/raffle') ? ' active' : ''}`} onClick={() => {localStorage.setItem("leaderboard", "default value", 1)}} >
										<Link to="/preferences/membership">
											<div className="check-option">
												<label>
													<h4><span className='size_orange'>size?</span>launches leaderboard</h4>
													<p>See who is winning the most draws and track your own achievements</p>
												</label>
											</div>
										</Link>
									</li>
									: null
							}
							{
								isLoggedIn && !loyalty.isPremium
									? <li className={`form-option-type${window.location.href.includes('preferences/marketing') ? ' active' : ''}`}>
										<Link to="/preferences/marketing">
											<div className="check-option">
												<label>
													<h4>Manage your marketing preferences</h4>
													<p>Favorite brand, shoe size</p>
												</label>
											</div>
										</Link>
									</li>
									: null
							}
							{
								isLoggedIn
									? <li className={`form-option-type${window.location.href.includes('preferences/delivery') ? ' active' : ''}`}>
										<Link to="/preferences/delivery">
											<div className="check-option">
												<label>
													<h4>Manage your delivery address</h4>
													<p>Delivery/Shipping Details</p>
												</label>
											</div>
										</Link>
									</li>
									: null
							}
							{
								isLoggedIn
									? <li className={`form-option-type${window.location.href.includes('preferences/billing') ? ' active' : ''}`}>
										<Link to="/preferences/billing">
											<div className="check-option">
												<label>
													<h4>Manage your billing address</h4>
													<p>Billing Details</p>
												</label>
											</div>
										</Link>
									</li>
									: null
							}
							{
								isLoggedIn
									? <li className={`form-option-type${window.location.href.includes('preferences/payment') ? ' active' : ''}`}>
										<Link to="/preferences/payment">
											<div className="check-option">
												<label>
													<h4>Manage your payment preferences</h4>
													<p>Payment Details</p>
												</label>
											</div>
										</Link>
									</li>
									: null
							}
							<li className="form-option-type" onClick={() => navigateToExternal(`${config.storeUrl}page/delivery-returns/`)}>
								<div className="check-option">
									<label>
										<h4>Delivery and Returns Information</h4>
										<p>Methods, costs and how to return your item</p>
									</label>
								</div>
							</li>
							{config.is_app ?
								<li className={`form-option-type${window.location.href.includes('preferences/notifications') ? ' active' : ''}`}>
									<Link to="/preferences/notifications">
										<div className="check-option">
											<label>
												<h4>Manage your notifications</h4>
												<p>Notification Preferences</p>
											</label>
										</div>
									</Link>
								</li> : null
							}
							{config.is_app ?
								<li className={`form-option-type${window.location.href.includes('preferences/tutorial') ? ' active' : ''}`}>
									<Link to="/preferences/help/tutorial">
										<div className="check-option">
											<label>
												<h4>Tutorial</h4>
												<p>How to use the app</p>
											</label>
										</div>
									</Link>
								</li> : null
							}

							<li className={`form-option-type${window.location.href.includes('preferences/help') ? ' active' : ''}`}>
								<Link to="/preferences/help">
									<div className="check-option">
										<label>
											<h4>Get some help</h4>
											<p>Helpful links, T&Cs{config.is_app ? <span>, Privacy Policy and Delete your account</span> : <span> & Privacy Policy</span>}</p>
										</label>
									</div>
								</Link>
							</li>
							{
								isLoggedIn
									? <li className="form-option-type">
										<Link onClick={() => {
											config.is_app || window.location.hostname === 'localhost' ? logout() : window.location = `${config.storeUrl}myaccount/logout/`
										}}>
											<div className="check-option">
												<label>
													<h4>Logout</h4>
												</label>
											</div>
										</Link>
									</li>
									: null
							}
						</ul>
					</div>
				</div>
			</div>
			<div className={`right ${window.location.href.includes('/preferences?') ? 'base' : 'non-base'}`}>
				<Router history={hashHistory}>
					<Route path="/preferences/billing" component={BillingPreferences} />
					<Route path="/preferences/delivery" component={DeliveryPreferences} />
					<Route path="/preferences/personal" component={PersonalPreferences} />
					<Route path="/preferences/notifications" component={NotificationsPreferences} />
					<Route path="/preferences/help" component={HelpfulLinks} />
					<Route path="/preferences/help/tutorial" component={Tutorial} />
					<Route path="/preferences/marketing" component={Marketing} />
					<Route path="/preferences/payment" component={PaymentPreferences} />
					<Route path="/preferences/raffle" component={PreOrderPreferences} />
					<Route path="/preferences/membership" component={MembershipPreferences} />
					<Route path="/preferences/membership/signup" component={SignupForm} />
					<Route path="*" component={() => Greeting({ firstName, isLoggedIn })} />
				</Router>
			</div>
		</div >
	);
};

function Greeting(props) {
	return (<div className='welcome'>
		{props.isLoggedIn && props.firstName ? <h4 className="greeting">Hi {props.firstName}</h4> : null}
		<div className="form-horizontal">
			<p className="intro">
				Amend and edit your billing and delivery information so you can checkout in seconds. Also, choose your preferred push notifications, to remind you when one of your bookmarked trainers is about to launch.
			</p>
		</div>
	</div>);
}

export default Preferences;
