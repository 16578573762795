import { clearBookmarkProducts, bookmarkProduct, removeBookmarkedProduct } from '../../actions/bookmarks';
import { connect } from 'react-redux';
import ProductList from '../productList/ProductList';
import React, { PropTypes } from 'react';
import './Bookmarks.scss';
import config from '../../services/config/config'


//////////////////


const Bookmarks = React.createClass({
	defaultProps: {
		bookmarkedProducts: []
	},
	PropTypes: {
		bookmarkedProducts: PropTypes.array.isRequired
	},

	componentWillMount() {
		//this.props.getBookmarkProducts();
	},
	componentWillUnmount() {
		// this.props.clearBookmarkProducts();
	},
	componentDidMount() {
		let el = document.getElementsByClassName('Bookmarks')[0].getElementsByClassName('content-scroll')[0];
		el.scrollTop = this.props.scrollTop || 0;
	},
	render() {
		let { bookmarkedProducts, theme, trackScroll } = this.props;
		return (
			<div className={`Bookmarks ${(bookmarkedProducts && bookmarkedProducts.length ? '' : 'no-scroll')} ${(bookmarkedProducts && bookmarkedProducts.length ? 'bookmarksTrue' : 'bookmarksFalse')} ${config.layout.listStyle} nav-${config.layout.nav.position} `}>
				<div className="content-scroll">
					{
						bookmarkedProducts && bookmarkedProducts.length
							? <div>
								<h2>Bookmarked</h2>
								<ProductList products={bookmarkedProducts} theme={theme} trackScroll={trackScroll} />
							</div>
							: <div className="no-bookmarks">
								<div className="background">?</div>
								<div className="overlay">
									You have not bookmarked any products yet.<br></br>Bookmark a product to get notified when it launches.
							</div>
							</div>
					}
				</div>
			</div>
		);
	}
});

const mapStateToProps = state => {
	const { bookmarks, content, loading } = state;
	return { bookmarkedProducts: bookmarks.bookmarkedProducts, theme: content.theme, loading, content };
};

const mapDispatchToProps = dispatch => {
	return {
		clearBookmarkProducts: () => dispatch(clearBookmarkProducts()),
		bookmarkProduct: (productID, productName) => dispatch(bookmarkProduct(productID, productName)),
		removeBookmarkedProduct: (productID, productName) => dispatch(removeBookmarkedProduct(productID, productName))
	}
};


//////////////////


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Bookmarks);
