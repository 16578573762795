import { postMessage } from '../services/nativeBridge/index';
import { PUSH_ENABLED } from '../services/nativeBridge/messageTypes';
import { registerToken } from '../services/api';
import storage from '../services/storage/storage';

const PUSH_TOKEN_KEY = 'pushToken';


//////////////////


export const HAVE_PUSH_NATIVE_RESPONSE = 'HAVE_PUSH_NATIVE_RESPONSE';
export const IS_PUSH_ENABLED = 'IS_PUSH_ENABLED';

/**
 * Sends a message to the native apps asking if push notifications are enabled
 */
export function isPushEnabled() {
	let message = {
		messageType: PUSH_ENABLED,
		data: {}
	};
	postMessage(message);
};

/**
 * Handles the response from native
 * @param {Object} response
 */
export function pushNativeResponse(response) {
	// if successful, and we've not got this token, register it
	if (response.success && response.payload) {
		let pushToken = storage.getItemNoExpiry(PUSH_TOKEN_KEY);
		if (!pushToken || pushToken !== response.payload.token) {
			registerToken(response.payload);
			storage.setItemNoExpiry(PUSH_TOKEN_KEY, response.payload.token);
		}
	}

	return {
		type: HAVE_PUSH_NATIVE_RESPONSE
	}
};
