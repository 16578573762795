import getCardActivationDate from './getCardActivationDate'
import config from '../config/config'

export default (product)=>{

    //If we not supporting klarna exclusive.
    if(!config.supportsKlarnaExclusive){
        return false
    }

    //If the product is a legacy product (without payment gateway) then its always adyen only 
    //(so not klarna exclusive)
    if(!product.paymentGateways){
        return false
    }
    //If Klarna is not an included payment gateway, then it can't be klarna exclusive
    if(!product.paymentGateways.includes('Klarna')){
        return false
    }

    //If there is only one payment gateway, its klarna
     if(product.paymentGateways.length === 1){
         return true
     }

    //If the product is non raffle, it cant be klarna exclusive by this point
    if(product.launchType !== 'raffle'){
        return false
    }

    //If we are before the card activation date then we are still in the klarna exclusive period
    let now = new Date();
    if(+ getCardActivationDate(product) > now){
        return true
    }

    return false
}