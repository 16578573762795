// react and react dom renderer libraries
import React from 'react';
import ReactDOM from 'react-dom';
// redux bindings and middleware
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import { persistStore, persistReducer } from 'redux-persist'
import createIdbStorage from '@piotr-cz/redux-persist-idb-storage'
import { PersistGate } from 'redux-persist/integration/react'

import thunkMiddleware from 'redux-thunk';
import createLogger from 'redux-logger';
// import root component
import App from './App';
// import app reducer
import rootReducer from './reducers';
// import required actions
import { loadContent, showSplashLogin } from './actions/content';
import { isPushEnabled } from './actions/push';
import { startupComplete, startup } from './modules/startup';
import contentActions from './services/contentService/actions'

// import native message handler
import { nativeBridgeMessageHandler } from "./actions/nativeBridge";
import { getSession } from './services/session';
import { hasDefaultPreferences, isUserLoggedIn, setDefaultPreferences } from './services/user';

import { doSessionLogin, logout } from './actions/auth';

import storage from './services/storage/storage';
import { analyticsInit } from './services/analytics/analytics';

import { getCookie, getQueryStringAsObject } from './services/url';
import { showError } from './actions/error';
import { getUserData } from './services/user';

import { postMessage } from './services/nativeBridge';

import { loaded } from './actions/loading';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

// include fetch polyfill for older browsers
import 'whatwg-fetch';
//include css
import './index.scss';
import config, { isInNativeApp } from './services/config/config';
import { showReviewModal } from './actions/preferences';
import { oauthGet } from './services/oauth/oauth';

// assign a listener for native communication
window.mosaicBridge = {};
window.mosaicBridge.postMessage = (payload) => store.dispatch(nativeBridgeMessageHandler(payload));
// console.log(window.mosaicBridge.postMessage);

serviceWorkerRegistration.register();

//Clear the oauthRefresh flag
localStorage.removeItem('oauthRefresh')

// Apply our redux middleware (logger should not be used in production)
let middlewares = [thunkMiddleware]; // lets us dispatch() functions (helpful for async events)
if (process.env.NODE_ENV === `development`) {
  const loggerMiddleware = createLogger();
  middlewares.push(loggerMiddleware);
  const freeze = require('redux-freeze');
  middlewares.push(freeze);
}

// create the redux store
// we can pass the current saved session for initial state
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
let store
const persistConfig = {
  key: () => { return localStorage.getItem('userID') || 'guest' },
  storage: createIdbStorage({ name: 'MeshMosaic', storeName: 'session' }),
  blacklist: ['startup', 'loading', 'search', 'error'],
  serialize: true,
}

let appReducer = persistReducer(persistConfig, rootReducer)

//If were using the redux dev tools, load them
if (composeEnhancers != null) {
  store = createStore(appReducer, getSession(), composeEnhancers(
    applyMiddleware(...middlewares))
  );
} else {
  //If not don't
  store = createStore(appReducer, getSession(),
    applyMiddleware(...middlewares)
  );
}

//
window.mosaicAction = (action) => {
  contentActions(store.dispatch, action)
}
store.dispatch(startup());

window.reduxStore = store;

window.store = store;

document.title = config.appDisplayName;

// start analytics
analyticsInit();

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistStore(store, null, persistorCallback)}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

function persistorCallback() {
  let queryParams = {};
  let queryParamParts = window.location.search.substring(1).split('&');
  queryParamParts.forEach((value, index) => {
    let keyValues = value.split('=');
    queryParams[keyValues[0]] = keyValues[1];
  });
  // console.log('#####', queryParams['appversion'], '#####');
  let supported = true;
  if ((typeof queryParams['appversion'] === 'undefined' || queryParams['appversion'] === '1') && window.location.hostname !== 'localhost' && config.is_app) {
    supported = false;
    console.log("App version unspported: ", queryParams['appversion']);
    store.dispatch(showError({ errorCode: "app-version-unsupported" }, error => {
      let iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
      let storeUrl = 'https://play.google.com/store/apps/details?id=size.launch';
      if (iOS) {
        storeUrl = 'https://itunes.apple.com/gb/app/size-previews/id1220362486?mt=8';
      }
      postMessage({
        "messageType": "navigate-to-external",
        "data": {
          "url": storeUrl
        }
      });
    }));
  }

  // Fix bug which has marked users as logged in when they have no user data
  let userData = getUserData();
  if (!userData || !userData.userID) {
    store.dispatch(logout(false));
  }

  let qPrams = new URLSearchParams(window.location.search);

  //If were not loged in via the website, log us out
  if (!config.is_app && !(qPrams.get('sessionBypass') || isInNativeApp())) {
    let sessionID = getCookie('session.ID')
    if (sessionID) {
      store.dispatch(doSessionLogin(sessionID))
    } else {
      store.dispatch(logout(false));
    }
  } else if (isInNativeApp()) {
    //If were using the native exerience, trigger the getting of an oauth token
    oauthGet();
  }
  // before we render our view lets see if the user is logged in to decide whether we
  // need to show the splash screen loggin
  if (!isUserLoggedIn()) {
    store.dispatch(showSplashLogin());
  } else {
    //TODO: import action creator
    store.dispatch({ type: 'LOGIN' });
  }



  window.store = store;

  // use any errors passed in the query params to populate the state
  let query = getQueryStringAsObject();
  if (query.error) {
    store.dispatch(showError({ errorCode: query.error, paymentErrorCode: query.code }))
  }

  // aggressively clear any expired items in storage on bootstrap
  storage.clearExpiredItems();
  // lets load our content (we'll need this wherever we are in the app so makes sense to be here')
  if (!isInNativeApp()) {
    store.dispatch(loadContent(false, true));
  }

  //Can we show the user a review modal
  if (config.is_app) {
    showReviewModal();
  }

  // the user should always have notification preferences, so call a function to make sure of that
  if (!hasDefaultPreferences()) setDefaultPreferences();

  // on app load we want to register the device for push notifications
  isPushEnabled();

  if (!supported) {
    store.dispatch(loaded());
  }

  //If were not waiting for an ouahgt token, were loaded
  if (!isInNativeApp()) {
    store.dispatch(startupComplete());
  }
}