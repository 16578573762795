import bookmarkIcon from '../../assets/img/bookmarks/icon-bookmark.png';
import bookmarkIconActive from '../../assets/img/bookmarks/icon-bookmark-active.png';
import React from 'react';


//////////////////


const BookmarkButton = ({isBookmarked, productID, launchDate, launchType, toggleBookmark, name}) => (
	<span className={`bookmark${isBookmarked ? ' active' : ''}`} onClick={(e) => toggleBookmark(productID, name, launchDate, launchType)}>
		<img className="icon icon-bookmark inactive" src={bookmarkIcon} alt="bookmark" />
		<img className="icon icon-bookmark active" src={bookmarkIconActive} alt="bookmark" />
	</span>
);


//////////////////


export default BookmarkButton;
