import DateTime from '../dateTime/DateTime';
import Price from '../price/Price';
import React, { PropTypes } from 'react';
import config from '../../services/config/config';


import './UpcomingLaunchItem.scss';


//////////////////


const CalendarItem = ({ price, maxPrice, launchDate, launchesOn, plu, title, subtitle, brand, brands }) => {
    let brandItem = brands.find(item => item.id === brand)
    return (<li className="upcoming-launch" >
        <div className="top">
            <div className="left">
                <div className="subtitle">{subtitle}</div>
                <div className="title">{title} </div>
                <div className="launchDate"><DateTime date={launchDate} format="DD/MM/YY"></DateTime></div>
            </div>
            <div className="right">
                {brandItem ? <img src={brandItem.image} alt={brand.name} /> : null}
            </div>
        </div>
        <div className="bottom">
            <div>
                <h3>Product Price</h3>
                <span className="priceWrapper">{price.amount === maxPrice.amount ?
                    <Price currency={price.currency} amount={price.amount} /> :
                    (<span>
                        <Price currency={price.currency} amount={price.amount} /> - <Price currency={price.currency} amount={maxPrice.amount} />
                    </span>)}
                </span>
            </div>
            <div>
                <h3>Launch Time</h3>
                <span><DateTime date={launchDate} format="hh:mm A"></DateTime></span>
            </div>
            <div>
                <h3>Style Code</h3>
                <span>{plu}</span>
            </div>
            <div>
                <h3>Launching on</h3>
                <span>{config.launchLocations[launchesOn]}</span>
            </div>
        </div>
    </li >
    )
};

CalendarItem.defaultProps = {
    image: {},
    launchType: 'full',
    title: ""
};

CalendarItem.propTypes = {
    image: PropTypes.object.isRequired,
    launchDate: PropTypes.string,
    launchStartDate: PropTypes.string,
    launchType: PropTypes.string,
    plu: PropTypes.string.isRequired,
    title: PropTypes.string,
};


//////////////////


export default CalendarItem;
