import { connect } from 'react-redux';
import React from 'react';
import { hashHistory } from 'react-router';
import logo from '../logo.svg'
import { navigateToExternal } from '../../../../services/navigate/navigate';

import appStore from './appstore.png'
import playStore from './google-play.png'

import LaunchApp from '../benefits/LaunchApp';
import VideoJs from '../../../videoJS/Video.js'
import NativeApp from '../benefits/NativeApp';

const MembershipPreferences = React.createClass({

    render() {
        return (
            <div>
                <div className="membership-logo">
                    <img alt="logo" src={logo} />
                </div>
                <VideoJs src="https://mesh-uploads-storage.jdmesh.co/mesh-uploads/size/2021/9/fddff728-bb49-4354-9083-62c6998f7e8f_1920x1080.mp4"
                    poster="https://mesh-uploads-storage.jdmesh.co/mesh-uploads/size/2021/9/2fd66d26-4e29-40e4-bb64-7784a3a6bc54_Landscape_Video_still1.jpg"
                    preload="auto" />
                <br />
                <p>
                    Upgrade your launches experience with a size?access membership now!
                </p>
                <p>
                    Sign up for <b>FREE</b> and get exclusive access on the size?launches and size? apps, with a long list of benefits included. What are you waiting for? join now.
                </p>
                <h3>Membership Benefits</h3>
                <h4>Exclusively on size?launches</h4>
                <LaunchApp />
                <h4>Exclusively on size? app</h4>
                <div className="nativeLinks">
                    <img onClick={() => navigateToExternal("https://apps.apple.com/gb/app/size/id736767882")} className="appstore-ios" src={appStore} alt="app store" />
                    <img onClick={() => navigateToExternal("https://play.google.com/store/apps/details?id=com.size")} className="appstore-android" src={playStore} alt="play store" />
                </div>
                <NativeApp />
                <p>
                    Full terms and conditions <span className='link' onClick={() => navigateToExternal(`https://m.size.co.uk/customer-service/sizeaccess/`)}>here</span>
                </p>
                {!this.props.step ?
                    <div className="form-action fixed">
                        <button onClick={() => hashHistory.push('/preferences/membership/signup')} className="action">
                            <div className="button-contents">Sign Up Now</div>
                        </button>
                    </div> : null}
            </div>
        )
    }
});

const mapStateToProps = state => {
    const { user } = state;
    return { loyalty: user.preferences.loyalty };
};

const mapDispatchToProps = dispatch => {

};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MembershipPreferences);

