import { connect } from 'react-redux';
import React from 'react';
import './Tutorial.scss';
import "../../../node_modules/react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from 'react-image-gallery';
import { hashHistory } from 'react-router'

//Tutorial Images
import tutorialImage1 from "../../assets/img/tutorial/Page_1.jpg";
import tutorialImage2 from "../../assets/img/tutorial/Page_2.jpg";
import tutorialImage3 from "../../assets/img/tutorial/Page_3.jpg";
import tutorialImage4 from "../../assets/img/tutorial/Page_4.jpg";
import tutorialImage5 from "../../assets/img/tutorial/Page_5.jpg";
import tutorialImage6 from "../../assets/img/tutorial/Page_6.jpg";
import tutorialImage7 from "../../assets/img/tutorial/Page_7.jpg";

//////////////////


const Tutorial = React.createClass({
	getInitialState() {
		return { finalSlide: false }
	},
	nextPage() {
		let { finalSlide } = this.state;
		if (finalSlide) {
			hashHistory.push('/')
		} else {
			this.refs.tutorialGallery.slideToIndex(this.refs.tutorialGallery.getCurrentIndex() + 1);
		}
	},
	onSlide(index) {
		this.setState({ finalSlide: index === 6 })
	},
	render() {
		let { onboarding } = this.props;
		let { finalSlide } = this.state;
		return (
			<div className={`Tutorial`}>
				<ImageGallery
					ref="tutorialGallery"
					autoPlay={onboarding === true ? false : true}
					items={[
						{ original: tutorialImage1 },
						{ original: tutorialImage2 },
						{ original: tutorialImage3 },
						{ original: tutorialImage4 },
						{ original: tutorialImage5 },
						{ original: tutorialImage6 },
						{ original: tutorialImage7 }
					]}
					slideInterval={10000}
					showNav={false}
					onTouchStart={() => { if(!onboarding)this.refs.tutorialGallery.pause()} }
					onTouchEnd={() => { if(!onboarding)this.refs.tutorialGallery.play() }}
					showBullets={true}
					showPlayButton={false}
					showFullscreenButton={false}
					infinite={false}
					showThumbnails={false}
					onSlide={this.onSlide}
				/>
				{ onboarding ?
					<div className="form-action">
						<button className="action" onClick={this.nextPage}>
							<div className="button-contents">{finalSlide ? "Finish" : "Next"}</div></button>
							<div className="skip" onClick={()=>hashHistory.push('/')}>Skip</div>
					</div> : null
				}
			</div>
		);
	}
});

const mapStateToProps = state => {
	return {};
};

const mapDispatchToProps = dispatch => {
	return {}
};


//////////////////


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Tutorial);
