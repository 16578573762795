import React from "react";
import LeaderBoardItem from "./LeaderBoardItem";

function LeaderBoardTable({ content, leaderboard }) {
    const sortedData = leaderboard.sort((a, b) => b.wins - a.wins);
    return <div className="leaderboard_table_wrapper">
        <div className="leaderboard_table_titles">
            <div className="left_table_title">our top 10<br /> 2023 winners*</div>
            <div className="right_table_title">overall<br /> achievements</div>
        </div>
        {
            content && leaderboard ? sortedData.map((item, index) => (
                <LeaderBoardItem key={item.userID.substring(0, 6)} props={item} placement={index} content={content} />
            )) : <div>Loading...</div>
        }
    </div>;
}

export default LeaderBoardTable;
