import errorMap from "../../resources/errorMappings.json";

/**
 * getErrorByCode
 *
 * @description gets the error message by key from the json object in error mappings
 * @param [string] code
 * @return [string] error
 */
export function getErrorByCode(code){
    return errorMap[code] || 'Error';
};
