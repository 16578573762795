import React from 'react';
import Price from '../price/Price';
import { getProductFromProductsByOptionID, getOptionByOptionID } from '../../services/product/product';
import TitleBlock from '../titleBlock/TitleBlock';
import config, { isInNativeApp } from '../../services/config/config';

const MiniCartItems = ({ stock, products, onRemove }) => {
    let stockLines = stock.map((stock) => {
        let product = getProductFromProductsByOptionID(stock.optionID, products);
        let option = getOptionByOptionID(stock.optionID, product);

        return (
            <li key={stock.optionID}>
                <div className="order-item-image">
                    <img src={product.images[0].original} srcSet={product.images[0].srcSet} alt={product.name} />
                </div>
                <div className="order-item-info">
                    <h4>{product.name}</h4>
                    <h4>{product.subTitle}</h4>
                    <div className="bottom-block">
                        <span>
                            {option.name}
                        </span>
                        <span className="price">
                            <Price amount={product.price.amount} currency={product.price.currency} />
                        </span>
                    </div>
                </div>
                <div className="order-item-remove">
                    {/* No stock has been claimed so it cannot be removed */}
                    {stock.ID ? <span onClick={(e) => onRemove(stock.ID)}>Remove</span> : null}
                </div>
            </li>
        );
    });

    return (
        <div className="order-details order-preview">
            <TitleBlock title="Your Reserved Items:" />
            <ul>
                {stockLines}
            </ul>
            {!config.is_app && config.store !== "hipstoregb" && !isInNativeApp() ?
                <a target="_blank" className='missing' rel="noopener noreferrer" href={`${config.storeUrl}cart`}>Something missing?</a>
                : null}
        </div>
    )
}

export default MiniCartItems;