import { hashHistory } from 'react-router';
import { loadRaffleEntries } from '../../../actions/content';
import { connect } from 'react-redux';
import React from 'react';
import TitleBlock from '../../titleBlock/TitleBlock';
import './PreOrderPreferences.scss';
import PreOrder from './PreOrder'
import { trackPage } from '../../../services/analytics/analytics';

//////////////////


const PreOrderPreferences = React.createClass({
	componentWillMount() {
		this.props.loadRaffleEntries();
		trackPage('preferences/preOrders');
	},
	backToDashboard() {
		hashHistory.goBack();
	},
	render() {
		let items = [];
		for (const [index, value] of this.props.orders.entries()) {
			items.push(<PreOrder key={index} index={index} PreOrder={value} />)
		}
		return (
			<div className="Preferences PreOrders">
				<div className="content-scroll">
					<TitleBlock title="Draw entries" />
					<div className="form-horizontal">
						<p className="intro">
							Keep an eye on the status of your draw entries, check back here to see if you're a winner!
						</p>
						{items.length ?
							<ul className="PreOrderList">
								{items}
							</ul> :
							<div className="no-raffles">
								<div className="background">?</div>
								<div className="overlay">
									You haven't entered any raffles yet.
									</div>
							</div>
						}
					</div>

				</div>
			</div>
		);
	}
});

const mapStateToProps = state => {
	const { content } = state;
	return { orders: content.orders };
};

const mapDispatchToProps = dispatch => {
	return {
		loadRaffleEntries: () => dispatch(loadRaffleEntries()),
	};
};


//////////////////


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PreOrderPreferences);
