import React, { PropTypes } from 'react';
import { Link } from 'react-router';

import './splashLogin.scss';
import splashLogo from '../../assets/img/login/logo.png';
import previewsLogo from '../../assets/img/logo-small.png';
import config, { isInNativeApp } from '../../services/config/config';
import { hashHistory } from 'react-router'

//////////////////


const SplashLogin = React.createClass({
	login() {
		if (config.is_app) {
		  hashHistory.push('/login');
		} else {
		  if (window.location.href.includes("mobile")) {
			config.storeUrl = config.storeUrl.replace("desktop", "mobile");
		  }
		  document.cookie = 'launchesSessionExpired=true; path=/; domain=.thehipstore.co.uk;';
		  window.location = `${config.storeUrl}myaccount/logout`;
		}
	  },
	  
	signup() {
		if (config.is_app) {
			hashHistory.push('/signup');
		} else {
			if (window.location.href.includes("mobile")) {
				config.storeUrl = config.storeUrl.replace("desktop", "mobile");
			}
			window.location = `${config.storeUrl}myaccount/register?redirect=${window.location.origin}`
		}
	},
	render() {
		let { className, description, descriptionlogin, continueAsGuest, closeSplash, allowGuests } = this.props;
		let path = window.location.hash.split('?')[0].replace('#', '');
		return (
			<div className={`${className} ${isInNativeApp() ? 'hide' : ''}`}>
				<div className="splashHeader">
					<h1><img src={previewsLogo} alt="logo" /></h1>
					<div className="description">
						<p>{description}</p>
						<p>{descriptionlogin}</p>
					</div>
				</div>
				<div className="splashActions">
					<Link onClick={() => { closeSplash(path); this.login() }} className="action login"><div className="button-contents">Login</div></Link>
					<Link onClick={() => { closeSplash(path); this.signup() }} className="action"><div className="button-contents">Create an Account</div></Link>
					<div className="splashFooter">
						{
							allowGuests
								? <button onClick={continueAsGuest}>Skip login (just browse)</button>
								: null
						}
						<span>Powered by <img src={splashLogo} alt="logo" /></span>
					</div>
				</div>
			</div>
		);
	}
});

SplashLogin.defaultProps = {

};

SplashLogin.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string
};

export default SplashLogin;
