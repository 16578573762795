//////////////////

import { performSearch } from "../services/api";
import { showError } from "./error";


export const UPDATE_SEARCH_TEAM = 'UPDATE_SEARCH_TEAM';
export const UPDATE_SEARCH_RESULTS = 'UPDATE_SEARCH_RESULTS';
export const LOAD_MORE_SEARCH_RESULTS = 'LOAD_MORE_SEARCH_RESULTS';
export const LOADING_SEARCH_RESULTS = 'LOADING_SEARCH_RESULTS';
export const UPDATED_SEARCH_RESULTS = 'UPDATED_SEARCH_RESULTS';
export const TRACK_SEARCH_SCROLL = 'TRACK_SEARCH_SCROLL';


export function updateSearchTerm(term) {
	return {
		term,
		type: UPDATE_SEARCH_TEAM,
	};
}

export function updateSearchResults(products) {
	return {
		type: UPDATE_SEARCH_RESULTS,
		products
	};
}

export function finishedGettingSearchResults() {
	return {
		type: UPDATED_SEARCH_RESULTS
	};
}


export function getMoreSearchResult(products, page) {
	return {
		type: LOAD_MORE_SEARCH_RESULTS,
		products,
		page
	};
}

export function loadSearchResults(products, page) {
	return {
		type: LOAD_MORE_SEARCH_RESULTS,
		products,
		page
	};
}

function loading() {
	return {
		type: LOADING_SEARCH_RESULTS
	}
}

/**
 * Tracks the scroll distance of the component
 * @param {number} scrollTop scroll distance in pixels from top
 */
 export function trackScroll(scrollTop) {
	return {
		scrollTop,
		type: TRACK_SEARCH_SCROLL
	};
}

export function lazyLoad() {
	return (dispatch, getState) => {
		let { search } = getState();
		return performSearch(search.term, search.page)
			.then((data) => {
				dispatch(updateSearchResults(data));
				dispatch(finishedGettingSearchResults());
			}).catch((error) => {
				dispatch(finishedGettingSearchResults());
				dispatch(showError(error));
				return Promise.reject();
			});
	}
}

export function search(term) {
	return (dispatch, getState) => {
		dispatch(loading())
		return performSearch(term, 1)
			.then((data) => {
				dispatch(updateSearchResults(data));
				dispatch(finishedGettingSearchResults());
			}).catch((error) => {
				dispatch(finishedGettingSearchResults());
				dispatch(showError(error));
				return Promise.reject();
			});
	}
}


