/**
 * Checkout presentational component
 * This is a presentational component which wraps the checkout page.
 * Receives props from the container component in containers/checkoutPage
 */
import React from 'react';
import CartTotals from '../cartTotals/CartTotals';
import PaymentMethods from '../paymentMethods/PaymentMethods';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import TitleBlock from '../titleBlock/TitleBlock';
import CustomerDetails from './CustomerDetails';
import PreAuthDisclaimer from './PreAuthDisclaimer';
import DeliveryMethod from './DeliveryMethod';
import MiniCartItems from '../miniCart/MiniCartItems'
import DeliveryAddress from './DeliveryAddress';
import BillingAddress from './BillingAddress';
import OptIn from './OptIn';
// import QuantityDropdown from '../product/QuantityDropdown';

import withRecaptchaV3 from '../recaptcha/recaptcha';
import { isObjectFull } from '../../services/checkout/checkout';

import { getQueryParam } from '../../services/url';

//import css for forms
import '../../styles/bootstrap-forms.scss';
import '../../styles/forms.scss';

//page css
import './Checkout.scss';

import { EVTS, trackEvent, trackPage } from '../../services/analytics/analytics';
import MoreInfoPopup from '../moreInfoPopup/MoreInfoPopup';
import storage from '../../services/storage/storage';


//////////////////


const PreAuth = React.createClass({
    getInitialState: () => ({
        showPopup: false,
    }),
    componentWillMount() {
        this.props.loadDataForPreAuthCheckout(this.props.params.sku)
            .then((data) => {
                // cache the initially loaded stores for autosuggest
                this.stores = this.props.checkout.stores;
                trackEvent(EVTS.CAT.CHECKOUT, EVTS.ACT.CHECKOUT_INIT, undefined, { product: this.props.product });
                this.props.enablePaymentMethods(this.props.product, this.props.checkout.locale, this.props.checkout.billing, this.props.checkout.useBillingAsDelivery);
            });
        trackPage('pre-auth');
    },
    componentDidMount() {
        //If were coming back from 3DSecure, try and compleat payment
        if (getQueryParam('3DSCallback')) {
            //Remove the 3DSCallback param
            window.history.replaceState && window.history.replaceState(
                null, '', window.location.pathname + window.location.search.replace(/[?&]3DSCallback=[^&]+/, '').replace(/^&/, '?')
            );
            this.props.complete3DS();
        }
    },
    payment(answer, useBoost) {
        // refresh the token and submit
        this.props.refreshReCaptchaToken()
            .then((verification) => {
                this.props.preAuthoriseOrder({
                    checkout: this.props.checkout,
                    deliveryTypes: this.props.deliveryTypes,
                    optionID: this.props.params.sku,
                    boost: useBoost,
                    answer,
                    verification,
                });
            });
    },

    isFormValid() {
        const { checkout, product } = this.props;
        let isFormValid = checkout.deliveryMethod.ID &&
            checkout.paymentMethod.ID &&
            isObjectFull('customer', checkout.customer, product) &&
            checkout.checkoutConfirmation &&
            isObjectFull('deliveryMethod', checkout.deliveryMethod, product) &&
            (checkout.useBillingAsDelivery || isObjectFull('billing', checkout.billing)) &&
            (isObjectFull('delivery', checkout.delivery, product) || checkout.deliveryMethod.type === 'clickAndCollect')
        return isFormValid
    },
    trackSaveSection(section, data) {
        trackEvent(EVTS.CAT.CHECKOUT, EVTS.ACT[section]);
    },
    toggleCheckoutConfirmation() {
        if (!storage.getItemNoExpiry('acceptedPaymentTerms') && this.props.checkout.paymentMethod.type === "CARD" && !this.props.product.charity) {
            storage.setItemNoExpiry('acceptedPaymentTerms', "true");
            this.setState({ showPopup: true });
        }
        this.props.toggleCheckoutConfirmation();
    },
    hidePaymentPopup() {
        this.setState({ showPopup: false });
    },
    onSelectQuantity(quantity) {
        this.props.selectQuantityOfEntries(quantity);
    },

    render() {
        const { billingAddressChanged,
            checkout,
            clearSelectedStore,
            customerDetailsChanged,
            deliveryAddressChanged,
            deliveryTypes,
            filterStores,
            localeChange,
            products,
            product,
            selectDeliveryMethod,
            selectPaymentMethod,
            selectStore,
            theme,
            toggleUseBillingAsDelivery,
            toggleCheckoutConfirmation,
            loyalty,
            // quantityOfPreviousEntries
        } = this.props;

        // const maxDisplay = 10;
        return (
            <div className="Checkout">
                <div className="content-scroll" style={this.props.checkout.paymentMethod.type === "RECURRING" ? { paddingBottom: "100px" } : {}}>
                    <div className="checkout-header">
                    </div>
                    <div className="content-scroll-fix">
                        <div className='right'>
                            {/* Mini Cart*/}
                            <div className="form-horizontal">
                                <MiniCartItems stock={[{ product, optionID: this.props.params.sku }]} products={products} />
                            </div>
                            <div className='desktop'>
                                <ReactCSSTransitionGroup
                                    transitionName="checkout-block"
                                    transitionEnterTimeout={300}
                                    transitionLeaveTimeout={300} >
                                    <PreAuthDisclaimer product={product} checkout={checkout} toggleCheckoutConfirmation={this.toggleCheckoutConfirmation} />
                                    <OptIn />
                                    {/* Totals */}
                                    {
                                        (checkout.deliveryMethod.ID &&
                                            (checkout.deliveryMethod.type !== 'clickAndCollect'
                                                || (checkout.deliveryMethod.type === 'clickAndCollect' && checkout.deliveryMethod.storelocationID)))
                                            ? <CartTotals delivery={checkout.deliveryMethod.price} products={products} stock={[{ product, optionID: this.props.params.sku }]} />
                                            : null
                                    }
                                    {this.props.checkout.paymentMethod.type !== "RECURRING" ? <div className="form-action not-fixed">
                                        <button className={`action ${loyalty.isPremium && !product.charity && (loyalty.isBoostWeek || localStorage.getItem('isBoosted')) ? 'boosted' : ''}`} onClick={() => this.payment(this.props.params.answer, loyalty.isPremium && loyalty.isBoostWeek && localStorage.getItem('isBoosted'))} disabled={!this.isFormValid()} style={theme.primaryButton}>
                                            <div className="button-contents">Enter{loyalty.isPremium && !product.charity ? ' with size?access' + (loyalty.isBoostWeek ? '+' : '') + (localStorage.getItem('isBoosted') ? '+' : '') : null}</div>
                                        </button>
                                    </div> : null}
                                </ReactCSSTransitionGroup>
                            </div>
                        </div>
                        <div className='left'>
                            {/* Customer Details Selection */}
                            <CustomerDetails
                                checkout={checkout}
                                customerDetailsChanged={customerDetailsChanged}
                                theme={theme}
                            />

                            {/* {product.charity && product.launchType === 'raffle' ?
                                <div>
                                    Number of Entries<br />
                                    <QuantityDropdown quantity={product.quantityOfEntries} max={product.entryLimit || 100} quantityChange={this.onSelectQuantity} maxDisplay={maxDisplay} price={product.price} quantityOfPreviousEntries={quantityOfPreviousEntries} />
                                </div> : null} */}


                            {/* Delivery Method Selection */}
                            <DeliveryMethod
                                stores={this.stores}
                                checkout={checkout}
                                deliveryTypes={deliveryTypes}
                                filterStores={filterStores}
                                clearSelectedStore={clearSelectedStore}
                                localeChange={(locale) => localeChange(locale, product, checkout.billing, checkout.useBillingAsDelivery)}
                                selectDeliveryMethod={selectDeliveryMethod}
                                selectStore={selectStore}
                                theme={theme}
                                product={product}
                                loyalty={loyalty}
                            />
                            <ReactCSSTransitionGroup
                                transitionName="checkout-block"
                                transitionEnterTimeout={300}
                                transitionLeaveTimeout={300} >


                                {/* Delivery Address Selection */}
                                <DeliveryAddress
                                    checkout={checkout}
                                    theme={theme}
                                    deliveryAddressChanged={deliveryAddressChanged}
                                    toggleUseBillingAsDelivery={() => toggleUseBillingAsDelivery(product, checkout.locale, checkout.billing, checkout.useBillingAsDelivery)}
                                />



                                {/* Billing Address */}
                                <BillingAddress
                                    checkout={checkout}
                                    theme={theme}
                                    billingAddressChanged={(address) => billingAddressChanged(address, product, checkout.locale, checkout.useBillingAsDelivery)}
                                />


                                {/* Payment */}
                                {
                                    (checkout.deliveryMethod.ID
                                        && (checkout.deliveryMethod.type !== 'clickAndCollect'
                                            || (checkout.deliveryMethod.type === 'clickAndCollect' && checkout.deliveryMethod.storelocationID)))
                                        ? <div>
                                            <TitleBlock title="Payment Method" />
                                            <PaymentMethods options={checkout.paymentMethods}
                                                selectedPaymentMethod={checkout.paymentMethod}
                                                updatePaymentMethod={(paymentMethod) => {
                                                    if (checkout.checkoutConfirmation) {
                                                        toggleCheckoutConfirmation();
                                                    }
                                                    selectPaymentMethod(paymentMethod)
                                                }}
                                                params={this.props.params}
                                                product={product}
                                                refreshToken={this.props.refreshReCaptchaToken}
                                                loyalty={loyalty}
												context={'preauth'}
                                                />
                                        </div>
                                        : null
                                }
                                <div className='mobile'>
                                    <PreAuthDisclaimer product={product} checkout={checkout} toggleCheckoutConfirmation={this.toggleCheckoutConfirmation} />
                                    <OptIn />
                                    {/* Totals */}
                                    {
                                        (checkout.deliveryMethod.ID &&
                                            (checkout.deliveryMethod.type !== 'clickAndCollect'
                                                || (checkout.deliveryMethod.type === 'clickAndCollect' && checkout.deliveryMethod.storelocationID)))
                                            ? <CartTotals delivery={checkout.deliveryMethod.price} products={products} stock={[{ product, optionID: this.props.params.sku }]} />
                                            : null
                                    }
                                </div>
                            </ReactCSSTransitionGroup>
                        </div>
                    </div>
                    {/* Actions */}
                    {this.props.checkout.paymentMethod.type !== "RECURRING" ? <div className="form-action not-fixed mobile">
                        <button className={`action ${loyalty.isPremium && !product.charity && (loyalty.isBoostWeek || localStorage.getItem('isBoosted')) ? 'boosted' : ''}`} onClick={() => this.payment(this.props.params.answer, loyalty.isPremium && loyalty.isBoostWeek && localStorage.getItem('isBoosted'))} disabled={!this.isFormValid()} style={theme.primaryButton}>
                            <div className="button-contents">Enter{loyalty.isPremium && !product.charity ? ' with size?access' + (loyalty.isBoostWeek ? '+' : '') + (localStorage.getItem('isBoosted') ? '+' : '') : null}</div>
                        </button>
                    </div> : null}
                </div>
                <MoreInfoPopup showLearnMore={this.state.showPopup} hideLearnMore={this.hidePaymentPopup} text={'Please note: we <b>pre-authorise your payment</b> and hold the money until the draw closes. Unsuccessful entries will have their funds released, this can take <b>3-5 days to appear in your account</b> after the draw closes.'} closeText={'Accept'} />
            </div>
        );
    }
});

export default withRecaptchaV3(PreAuth, 'PreAuth_Create');