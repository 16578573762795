import React from 'react';
import config from '../../services/config/config';
import { navigateToExternal } from '../../services/navigate/navigate';

import './SocialLinks.scss';

import facebook from './socials/Facebook.png'
import instagram from './socials/Instagram.png'
import tiktok from './socials/TikTok.png'
import twitter from './socials/Twitter.png'
import youtube from './socials/YouTube.png'


let images = { facebook, instagram, tiktok, twitter, youtube }


const SocialLinks = () => (
	<div className="social-links">
		<p>Follow Us</p>
		<div className="link-list">
			{Object.entries(config.social).map(([key, value]) =>
				<div className="social-link" key={value} onClick={() => navigateToExternal(value)}>
					<img alt={key} src={images[key]} />
				</div>
			)}
		</div>
	</div>
);


export default SocialLinks;