import React, { PropTypes } from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import CalendarItemColumns from '../calendarItem/columns/CalendarItem';
import CalendarItemFull from '../calendarItem/full/CalendarItem';

import config from '../../services/config/config'

//////////////////


const ProductList = ({ products, theme, trackScroll }) => {
	const CalendarItem = config.layout.listStyle === "full" ? CalendarItemFull : CalendarItemColumns;
	let productsList = products.map((product, i) => {
		return <CalendarItem
			key={product.sku || i}
			buttonStyles={theme.primaryButton}
			image={product.mainImage}
			launchDate={product.launchDate}
			launchStartDate={product.launchStartDate}
			launchType={product.launchType}
			sku={product.ID}
			price={product.price}
			title={product.name}
			subtitle={product.subTitle}
			product={product}
			soldOut={product.status === 'out-of-stock' || product.status === 'complete'}
			trackScroll={trackScroll} />;
	});

	return (
		<ul className="products">
			<ReactCSSTransitionGroup
				transitionName="bookmarks"
				transitionEnterTimeout={400}
				transitionLeaveTimeout={400}>
				{productsList}
			</ReactCSSTransitionGroup>
		</ul>
	)
};

ProductList.defaultProps = {
	products: []
};

ProductList.propTypes = {
	products: PropTypes.array.isRequired
};


//////////////////


export default ProductList;
