import { connect } from 'react-redux';
import React from 'react';
import './Slider.scss';
import SwipableViews from 'react-swipeable-views';
import FeatureItem from '../../compontents/image/FeatureItem'
import PageItem from '../../pageTypes/page/Page'
import { setContentIndex } from '../../../../actions/content'
import { virtualize } from 'react-swipeable-views-utils';
import { mod } from 'react-swipeable-views-core';

const VirtualizeSwipeableViews = virtualize(SwipableViews);

const Feature = React.createClass({
	getSliderIndex() {
		let { sliderIndex, pageID } = this.props;
		if (!Array.isArray(sliderIndex)) {
			return 0;
		}
		return sliderIndex.find((object) => object.id === pageID) ? sliderIndex.find((object) => object.id === pageID).index : 0
	},
	slideRenderer(params) {
		const { index, key } = params;
		let { content } = this.props;

		return this.buildPage(content[mod(index, content.length)], key, index)
	},
	buildPage(contentItem, key, index) {
		let { campaigns } = this.props;
		switch (contentItem.type) {
			case 'page':
				let page = campaigns.pages.find((page) => (page.id === contentItem.data.src))
				return <PageItem key={key} name={page.name} content={page.content} />
			case 'slider-image':
				return <FeatureItem
					key={key}
					index={index}
					image={contentItem.data.image}
					title={contentItem.data.title}
					subTitle={contentItem.data.subTitle}
					action={contentItem.data.action} />
			default:
				console.log('Unknown element')
		}
	},
	setIndex(index) {
		let { setIndex, pageID } = this.props;
		setIndex(pageID, index);
	},
	render() {
		let { content, loop } = this.props;
		let options = {};

		//Limit the number of pages, if were not looping
		if(!loop){
			options = {
				slideCount : content.length
			}
		}
		return (
			<div className="ContentSlider">
				<div className="content-scroll">
					<VirtualizeSwipeableViews
						slideRenderer={this.slideRenderer}
						enableMouseEvents={true}
						disabled={false}
						axis="x"
						index={this.getSliderIndex()}
						onChangeIndex={(index) => (this.setIndex(index))}
						animateTransitions={true}
						{ ...options }
					/>
				</div>
			</div>
		);
	}
});

const mapStateToProps = state => {
	let { content } = state
	return { sliderIndex: content.contentSliderIndex, campaigns: content.campaigns };
};

const mapDispatchToProps = dispatch => {
	return {
		setIndex: (id, index) => dispatch(setContentIndex(id, index))
	};
};


//////////////////


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Feature);
