// Container component for the checkout page
import { connect } from 'react-redux';
import {
	clearSelectedStore,
	completeOrder,
	enablePaymentMethods,
	loadDataForCheckout,
	loadStores,
	selectDeliveryMethod,
	selectPaymentMethod,
	selectStore,
	toggleUseBillingAsDelivery,
	updateBillingAddress,
	updateCustomer,
	updateDelivery,
	updateLocale,
	updateLocales,
	updateSearch,
	updateStores,
	toggleCheckoutConfirmation
} from '../actions/checkout';
import { updateDeliveryTypes } from "../actions/deliveryTypes";
import { clearSession } from '../actions/session';
import { removeItem } from '../actions/product';
import Checkout from '../components/checkout/Checkout';


//////////////////


/**
 * Maps the checkout state from the application state to props for the checkout component
 * @param {Object} state
 * @return {Object} checkout state
 */
const mapStateToProps = (state) => {
	const { checkout, loading, deliveryTypes, session, content, user } = state;
	return { checkout, loading, deliveryTypes, session, theme: content.theme, products: content.products, loyalty: user.preferences.loyalty };
};

/**
 * Maps the checkout actions to props for the checkout component
 * @param {function} dispatch
 * @return {Object} checkout props
 */
const mapDispatchToProps = (dispatch) => {
	return {
		billingAddressChanged: (address, product, locale, useAs) => {
			if (address.locale) {
				dispatch(enablePaymentMethods(product, locale, address, useAs))
			}
			dispatch(updateBillingAddress(address))
		},
		clearSelectedStore: () => dispatch(clearSelectedStore()),
		clearSession: () => dispatch(clearSession()),
		completeOrder: (checkout, session) => dispatch(completeOrder(checkout, session)),
		customerDetailsChanged: (details) => dispatch(updateCustomer(details)),
		deliveryAddressChanged: (address) => dispatch(updateDelivery(address)),
		enablePaymentMethods: (product, locale, billing, useAs) => dispatch(enablePaymentMethods(product, locale, billing, useAs)),
		filterStores: (stores) => dispatch(updateStores(stores)),
		loadDataForCheckout: (sessionID) => dispatch(loadDataForCheckout(sessionID)),
		loadStores: () => dispatch(loadStores()),
		localeChange: (locale, product, billing, useAs) => {
			dispatch(enablePaymentMethods(product, locale, billing, useAs))
			dispatch(updateLocale(locale))
		},
		receivedDeliverytypes: (deliveryTypes) => dispatch(updateDeliveryTypes(deliveryTypes)),
		receivedLocales: (locales) => dispatch(updateLocales(locales)),
		selectDeliveryMethod: (deliveryMethod) => dispatch(selectDeliveryMethod(deliveryMethod)),
		selectPaymentMethod: (method) => dispatch(selectPaymentMethod(method)),
		selectStore: (store) => dispatch(selectStore(store)),
		toggleUseBillingAsDelivery: (product, locale, address, useAs) => {
			dispatch(enablePaymentMethods(product, locale, address, !useAs))
			dispatch(toggleUseBillingAsDelivery())
		},
		updateSearch: (value) => dispatch(updateSearch(value)),
		removeItem: (stockID) => dispatch(removeItem(stockID)),
		toggleCheckoutConfirmation: () => dispatch(toggleCheckoutConfirmation()),
	}
};

// map state and dispatch methods to props for checkout
const CheckoutPage = connect(
	mapStateToProps,
	mapDispatchToProps
)(Checkout);

export default CheckoutPage;
