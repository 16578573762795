import React from 'react';


//////////////////


export default ({ badge, active, index }) => {
    return (
        <div className={`badge ${active ? 'active' : 'inactive'}`} >
            {index === 5 && !active ? <div><div>+1 Boost</div></div> : <img src={badge.image} alt={badge.name} />}
        </div >
    )
};