// Container component for the Home page
import { connect } from 'react-redux';
import { logout } from '../actions/auth';
import { isUserLoggedIn } from '../services/user/index';
import Preferences from '../components/preferences/Preferences';

/**
 * Maps the state from the application state to props for the  component
 * @param {Object} state
 * @return {Object} home page state
 */
const mapStateToProps = (state) => {
	const { content, user, loading } = state;
	let isLoggedIn = !!isUserLoggedIn();
	let showLogin = content.guest && content.guest.guestOnly ? false : true;
	return { firstName: user.preferences.personal.firstName, isLoggedIn, loading, showLogin, loyalty: user.preferences.loyalty || {isPremium: false} };
};

/**
 * Maps the checkout actions to props for the Content component
 * @param {function} dispatch
 * @return {Object} home page props
 */
const mapDispatchToProps = (dispatch) => {
	return {
		logout: () => dispatch(logout())
	};
};

// map state and dispatch methods to props for home page
const PreferencesPage = connect(
	mapStateToProps,
	mapDispatchToProps
)(Preferences);

export default PreferencesPage;
