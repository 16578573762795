import React from 'react'
import PropTypes from 'prop-types'
import CollapsibleSection from './CollapsibleSection';
import AddressForm from '../addressForm/AddressForm';
import { EVTS, trackEvent } from '../../services/analytics/analytics';


const DeliveryAddress = ({ checkout, theme, deliveryAddressChanged, toggleUseBillingAsDelivery }) => {
    return (
        <span>
            {
                checkout.deliveryMethod.ID && checkout.deliveryMethod.type !== 'clickAndCollect'
                    ? <div>
                        <CollapsibleSection
                            title="Delivery Address" propName="delivery" checkoutData={checkout.delivery} theme={theme}
                            trackSaveSection={() => trackEvent(EVTS.CAT.CHECKOUT, EVTS.ACT['DELIVERY_ADDRESS_SAVE'])}
                            editBlock={
                                <AddressForm address={checkout.delivery} updateAddress={deliveryAddressChanged} />
                            }
                            collapsedBlock={
                                <AddressForm address={checkout.delivery} updateAddress={deliveryAddressChanged} collapsed={true} />
                            }
                        />
                        <div className="check-option useBilling">
                            <input type="checkbox" id="useBilling" checked={checkout.useBillingAsDelivery} onChange={toggleUseBillingAsDelivery} />
                            <label htmlFor="useBilling">
                                <span className="check-box"></span>
                                <span className="name">Use delivery address as billing address</span>
                            </label>
                        </div>
                    </div>
                    : null
            }
        </span>
    )
}

DeliveryAddress.propTypes = {
    checkout: PropTypes.shape({
        delivery: PropTypes.object.isRequired
    }),
}

export default DeliveryAddress
