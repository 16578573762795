import React from 'react';
import {hashHistory} from 'react-router';
import backIcon from '../../assets/img/nav/icn-back.png';

/**
 * BackButton component
 * 
 * @description
 * This component will go back in the window history when clicked
 * 
 * @example
 * <BackButton />
 */

const BackButton = ({className}) => {
	return (
		<span className={className} onClick={hashHistory.goBack}>
			<img className="icon icon-back" alt="back button" src={backIcon}/>
		</span>
	);
}

export default BackButton;