import Post from './Post';
import React from 'react';

import './Blog.scss';

import config from '../../services/config/config'

//////////////////


const Blog = React.createClass({
	componentDidMount() {
		let el = document.getElementsByClassName('Blog')[0].getElementsByClassName('content-scroll')[0];
		el.scrollTop = this.props.scrollTop || 0;
	},
	render() {
		let { posts } = this.props;
		// build productList list
		let list = posts.map((post, i) => {
			return (<Post
				key={i}
				index={i}
				title={post.title}
				feature_media={post.feature_media}
				excerpt={post.excerpt}
				link={post.link}
				categories={post.categories}
			/>);
		});

		// return full calendar markup
		return (
			<div className={`Blog nav-${config.layout.nav.position}`}>
				<div className="content-scroll">
					{list.length === 0 ?
						<div className="no-blog">
							<div className="background">?</div>
							<div className="overlay">
								No blog posts at the moment<br></br>Check the calendar for future launches
							</div>
						</div>
						: <ul className="post-list">
							{list}
						</ul>
					}
				</div>
			</div>
		);
	}
});

export default Blog;
