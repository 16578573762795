import React from 'react';
import CollapsibleSection from './CollapsibleSection';
import MyDetails from '../myDetails/MyDetails';
import PropTypes from 'prop-types';
import { EVTS, trackEvent } from '../../services/analytics/analytics';

const CustomerDetails = ({ checkout, theme, customerDetailsChanged}) => {
    return (
        <CollapsibleSection
            title="My Details" propName="customer" checkoutData={checkout.customer} theme={theme}
            trackSaveSection={() => trackEvent(EVTS.CAT.CHECKOUT, EVTS.ACT['MY_DETAILS_SAVE'])}
            editBlock={
                <MyDetails key="mydetails-edit" customer={checkout.customer} updateCallback={customerDetailsChanged} />
            }
            collapsedBlock={
                <MyDetails key="mydetails-collapsed" customer={checkout.customer} collapsed={true} />
            }
        />
    )
}

CustomerDetails.propTypes = {
    checkout: PropTypes.shape({
        customer: PropTypes.object.isRequired
    }),
    theme: PropTypes.object.isRequired,
    customerDetailsChanged: PropTypes.func.isRequired
}

export default CustomerDetails
