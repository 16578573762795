import iosPostMessage from './iosPostMessage';
import androidPostMessage from './androidPostMessage';
import localPostMessage from './localPostMessage';

/**
 * postMessage
 *
 * @description abstracts the native messegenger to communicate with android and ios webviews
 *
 * @param {object} payload
 * @return {Promise}
 */
function postMessage(payload) {
    let deferredResponse;
    // console.log(payload);
    if(window.webkit && window.webkit.messageHandlers){
        try {
            deferredResponse = iosPostMessage(payload);
        } catch (e){
            throw new Error('Unable to send message to IOS');
        }

    } else if(window.Android){
        try {
            deferredResponse = androidPostMessage(payload);
        } catch (e){
            throw new Error('Unable to send message to Android');
        }
	}else{
        try {
            deferredResponse = localPostMessage(payload);
        } catch (e){
            throw new Error('Unable to send message to Android');
        }
    }
    
    return deferredResponse;

}

export default postMessage;