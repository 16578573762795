/**
 * Oauth Rest Client
 */
import { deleteRequest, get, post, put } from './restClientFactory';
import { oauthRefresh, oauthError } from '../oauth/oauth';


export const oauthRestClient = {
	get: function (url, options = {}, expiry) {
		return new Promise((resolve, reject) => {
			if (!localStorage.getItem('oauthRefresh')) {
				options.headers.Authorization = localStorage.getItem('oauthToken');
				get(url, options, expiry).then(data => {
					return resolve(data)
				}).catch((error) => {
					if (error.message === 'Unauthorized') {
						window.addEventListener('oauthRefreshed', function oauthReceivedGet() {
							options.headers.Authorization = localStorage.getItem('oauthToken');
							window.removeEventListener('oauthRefreshed', oauthReceivedGet);
							get(url, options, expiry).then(data => {
								return resolve(data)
							}).catch((error) => {
								if (error.message === 'Unauthorized') {
									window.store.dispatch(oauthError())
									resolve()
								} else {
									return reject(error);
								}
							})
						});
						oauthRefresh();
					} else {
						return reject(error);
					}
				})
			}
		});
	},
	put: function (url, payload, options = {}, expiry) {
		return new Promise((resolve, reject) => {
			if (!localStorage.getItem('oauthRefresh')) {
				options.headers.Authorization = localStorage.getItem('oauthToken');
				put(url, payload, options, expiry).then(data => {
					return resolve(data)
				}).catch((error) => {
					if (error.message === 'Unauthorized') {
						window.addEventListener('oauthRefreshed', function oauthReceivedDelete() {
							options.headers.Authorization = localStorage.getItem('oauthToken');
							window.removeEventListener('oauthRefreshed', oauthReceivedDelete);
							put(url, payload, options, expiry).then(data => {
								return resolve(data)
							}).catch((error) => {
								if (error.message === 'Unauthorized') {
									window.store.dispatch(oauthError())
									resolve()
								} else {
									return reject(error);
								}
							})
						});
						oauthRefresh();
					} else {
						return reject(error);
					}
				})
			}
		});
	},
	post: function (url, payload, options = {}, expiry) {
		return new Promise((resolve, reject) => {
			if (!localStorage.getItem('oauthRefresh')) {
				options.headers.Authorization = localStorage.getItem('oauthToken');
				post(url, payload, options, expiry).then(data => {
					return resolve(data)
				}).catch((error) => {
					if (error.message === 'Unauthorized') {
						window.addEventListener('oauthRefreshed', function oauthReceivedDelete() {
							options.headers.Authorization = localStorage.getItem('oauthToken');
							window.removeEventListener('oauthRefreshed', oauthReceivedDelete);
							post(url, payload, options, expiry).then(data => {
								return resolve(data)
							}).catch((error) => {
								if (error.message === 'Unauthorized') {
									window.store.dispatch(oauthError())
									resolve()
								} else {
									return reject(error);
								}
							})
						});
						oauthRefresh();
					} else {
						return reject(error);
					}
				})
			}
		});
	},
	delete: function (url, options = {}, expiry) {
		return new Promise((resolve, reject) => {
			if (!localStorage.getItem('oauthRefresh')) {
				options.headers.Authorization = localStorage.getItem('oauthToken');
				deleteRequest(url, options, expiry).then(data => {
					return resolve(data)
				}).catch((error) => {
					if (error.message === 'Unauthorized') {
						window.addEventListener('oauthRefreshed', function oauthReceivedDelete() {
							options.headers.Authorization = localStorage.getItem('oauthToken');
							window.removeEventListener('oauthRefreshed', oauthReceivedDelete);
							deleteRequest(url, options, expiry).then(data => {
								return resolve(data)
							}).catch((error) => {
								if (error.message === 'Unauthorized') {
									window.store.dispatch(oauthError())
									resolve()
								} else {
									return reject(error);
								}
							})
						});
						oauthRefresh();
					} else {
						return reject(error);
					}
				})
			}
		});
	}
}