import { connect } from 'react-redux';
import React from 'react';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import { updateMarketingPreferencesPlatform, updateUserPreferences } from '../../../../actions/preferences';
import LaunchApp from '../benefits/LaunchApp';
import { navigateToExternal } from '../../../../services/navigate/navigate';
import config from '../../../../services/config/config';


const brands = ['Nike', 'Adidas', 'Reebok', 'New Balance', 'Clarks Original', 'Puma', 'Fila'];
const shoeSizes = ['3', '3.5', '4', '4.5', '5', '5.5', '6', '6.5', '7', '7.5', '8', '8.5', '9', '9.5', '10', '10.5', '11', '11.5', '12', '12.5', '13', '13.5', '14'];
const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

const genders = ['male', 'female', 'other', 'prefer not to say']

const Account = React.createClass({
    getInitialState() {
        return ({
            slide: 0
        })
    },
    componentWillMount() {
        let { user } = this.props;
        let mergedPreferences = Object.assign({}, this.state.preferences, user.marketing)
        this.setState({ preferences: mergedPreferences });
        // No validation restriction on this page
        if (this.props.isPageValid) {
            this.props.isPageValid(true);
        }
    },
    updatePreferences(newPreferences) {
        let preferences = { ...this.state.preferences }
        Object.assign(preferences, newPreferences)
        this.setState({ preferences: preferences }, this.onSave);
    },
    updatePreference(property, val) {
        let newPreferences = {}
        newPreferences[property] = val
        this.updatePreferences(newPreferences)
    },
    onSave() {
        let preferences = this.state.preferences;
        this.props.savePreferences('marketing', preferences);
        this.props.updatePlatform(preferences);
    },
    updateCheckbox() {
        this.updatePreference('optedIn', !this.state.preferences.optedIn);
    },
    updateGender(evt, index, val) {
        this.updatePreference('gender', val);
    },
    updateBrand(evt, index, val) {
        this.updatePreference('favoriteBrand', val);
    },
    updateShoeSize(evt, index, val) {
        this.updatePreference('shoeSize', val);
    },
    render() {
        let { loyalty } = this.props;
        const handleSubmit = e => e.preventDefault();
        let startDate = new Date(loyalty.joinDate)
        return (
            <div>
                <div className="content-scroll">
                    <div className="memberSince">Member Since {monthNames[startDate.getMonth()]} {startDate.getFullYear()}</div>
                    <h4>preferences</h4>
                    <form className="form-horizontal" onSubmit={handleSubmit}>
                        <div>
                            <p className="emailSignupLable">
                                Would you like to receive marketing communications?
                            </p>
                            <div className="form-group check-option useBilling">
                                <input type="checkbox" id="useBilling" checked={this.state.preferences.optedIn} onChange={this.updateCheckbox} />
                                <label htmlFor="useBilling">
                                    <span className="check-box tick"></span>
                                    <span className="name">Yes, sign me up!</span>
                                </label>
                            </div>
                            <p>Gender</p>
                            <SelectField
                                onChange={(evt, index, val) => this.updateGender(evt, index, val)}
                                value={this.state.preferences.gender}
                                underlineStyle={{ borderColor: '#777777', borderWidth: '2px' }}
                                iconStyle={{ fill: '#777777' }}
                                labelStyle={{ color: '#777777' }}
                                autoWidth={true}
                            >
                                {genders.map((val, index) => {
                                    return (<MenuItem key={index} value={val} primaryText={val.charAt(0).toUpperCase() + val.slice(1)} />)
                                }
                                )}
                            </SelectField>
                            <p>What is your favorite brand?</p>
                            <SelectField
                                value={this.state.preferences.favoriteBrand}
                                onChange={(evt, two, val) => this.updateBrand(evt, two, val)}
                                underlineStyle={{ borderColor: '#777777', borderWidth: '2px' }}
                                iconStyle={{ fill: '#777777' }}
                                labelStyle={{ color: '#777777' }}
                                autoWidth={true}
                            >
                                {brands.map((val, index) => {
                                    return (<MenuItem key={index} value={val} primaryText={val} />)
                                }
                                )}
                            </SelectField>
                        </div>
                        <div>
                            <p>What is your shoe size?</p>
                            <SelectField
                                onChange={(evt, index, val) => this.updateShoeSize(evt, index, val)}
                                value={this.state.preferences.shoeSize}
                                underlineStyle={{ borderColor: '#777777', borderWidth: '2px' }}
                                iconStyle={{ fill: '#777777' }}
                                labelStyle={{ color: '#777777' }}
                                autoWidth={true}
                            >
                                {
                                    shoeSizes.map((val, index) => <MenuItem key={index} value={val} primaryText={val} />)
                                }
                            </SelectField>
                        </div>
                    </form>

                    <div>
                        <p>
                        By signing up for marketing communications, you will be opted in to receive communications for the JD Sports Group. For full details on how we use your information, view our our <span className='link' onClick={() => navigateToExternal(`${config.storeUrl}customer-service/privacy/`)}>Privacy Policy</span>.
                        </p>
                    </div>
                    <br/>

                    <h4>membership benefits</h4>
                    <LaunchApp />
                    <br />
                    <p>
                        Full terms and conditions <span className='link' onClick={() => navigateToExternal(`https://m.size.co.uk/customer-service/sizeaccess/`)}>here</span>
                    </p>
                </div >
            </div >
        )
    }
});

const mapStateToProps = state => {
    const { user, content } = state;
    return { loyalty: user.preferences.loyalty || {isPremium: false}, user: user.preferences, content };
};

const mapDispatchToProps = dispatch => {
    return {
        savePreferences: (key, data) => dispatch(updateUserPreferences(key, data)),
        updatePlatform: (data) => dispatch(updateMarketingPreferencesPlatform(data, true)),
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Account);

