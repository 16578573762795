import React from 'react';
import { hashHistory } from 'react-router';
import './Order.scss';
import Price from '../price/Price';
import TitleBlock from '../titleBlock/TitleBlock';
import SocialLinks from './../socialLinks/SocialLinks'
import { trackPage, trackPreAuth } from '../../services/analytics/analytics';
import { notifyLogin } from '../../services/userActions/login';


const PreOrder = React.createClass({
    componentWillMount() {
        let { product, order } = this.props;
        trackPage();
        trackPreAuth(product, order);

        //Make sure we have the user loged linked with Airship
        notifyLogin(order.customer.ID);
    },
    render() {
        let { order, product } = this.props;
        return (
            <div className="Checkout Order">

                <div className="content-scroll">
                    <TitleBlock title="Entry Confirmed" />
                    <div className="form-horizontal">
                        <p>
                            Your draw entry has been confirmed. Please find the details of your entry below.
						</p>
                    </div>
                    <TitleBlock title={`Reference: ${order.orderID.substring(0, 6)}`} />
                    <div className="form-horizontal">
                        <div className="order-details">
                            <h4>Your Details</h4>
                            <p>{order.customer.firstName} {order.customer.lastName}</p>
                            <p>{order.customer.email}</p>
                            <p>{order.customer.phone}</p>
                        </div>
                        <div className="order-details order-lines">
                            <h4>Your Items</h4>
                            <div className="order-item">
                            <div className="order-item-image">
                                <img src={product.images[0].original} srcSet={product.images[0].srcSet} alt={product.name} />
                            </div>
                            <div className="order-item-info">
                                <h4>{product.name}</h4>
                                <h4>{product.subTitle}</h4>
                                <div className="bottom-block">
                                    <span>
                                        {product.option.name}
                                    </span>
                                    <span className="price">
                                        <Price amount={product.price.amount} currency={product.price.currency} />
                                    </span>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="order-details order-totals">
                            <h3>Total: <Price amount={order.total.amount} currency={order.total.currency} /></h3>
                        </div>
                    </div>

                </div>
                <div className="form-action">
                    <SocialLinks />
                        <button className="action" onClick={() => hashHistory.push('/')}>
                            <div className="button-contents">Done</div></button>
                </div>

            </div>


        );
    }
})

export default PreOrder;
