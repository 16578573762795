import React from 'react'
import PropTypes from 'prop-types'
import CollapsibleSection from './CollapsibleSection';
import DeliveryMethodSelector from '../deliveryMethodSelector/DeliveryMethodSelector';
import { EVTS, trackEvent } from '../../services/analytics/analytics';

const DeliveryMethod = ({ checkout, deliveryTypes, filterStores, clearSelectedStore, localeChange, selectDeliveryMethod, selectStore, stores, theme, product, loyalty }) => {

    return (
        <CollapsibleSection
            title="Delivery Method" propName="deliveryMethod" product={product} checkoutData={checkout.deliveryMethod} theme={theme}
            trackSaveSection={() => trackEvent(EVTS.CAT.CHECKOUT, EVTS.ACT['DELIVERY_METHOD_SAVE'])}
            editBlock={
                <DeliveryMethodSelector clearSelectedStore={clearSelectedStore}
                    deliveryTypes={deliveryTypes}
                    filterStores={filterStores}
                    locale={checkout.locale}
                    locales={checkout.locales}
                    localeChange={localeChange}
                    selectDeliveryMethod={selectDeliveryMethod}
                    selectStore={selectStore}
                    stores={stores}
                    deliveryMethod={checkout.deliveryMethod}
                    charity={product && product.charity ? product.charity : false}
                    launchDay={isLaunchDayAvalable(product, loyalty)} />
            }
            collapsedBlock={
                <DeliveryMethodSelector clearSelectedStore={clearSelectedStore}
                    deliveryTypes={deliveryTypes}
                    filterStores={filterStores}
                    locale={checkout.locale}
                    locales={checkout.locales}
                    localeChange={localeChange}
                    selectDeliveryMethod={selectDeliveryMethod}
                    selectStore={selectStore}
                    stores={stores}
                    deliveryMethod={checkout.deliveryMethod}
                    collapsed="true"
                    charity={product && product.charity ? product.charity : false}
                    launchDay={isLaunchDayAvalable(product, loyalty)} />
            }
        />
    )
}

function isLaunchDayAvalable(product, loyalty) {
    if (!product) {
        return false;
    }
    if (product.launchDayDeliveryMethod) {
        if (product.launchDayDeliveryMembersExclusive) {
            return loyalty && loyalty.isPremium;
        } else {
            return true
        }
    }

    return false
}

DeliveryMethod.propTypes = {
    checkout: PropTypes.shape({
        customer: PropTypes.object.isRequired
    }),
}

export default DeliveryMethod
