import {ORDER_SUCCESS} from '../actions/checkout';

export const order = (state = {}, action) => {
    switch(action.type){
        case ORDER_SUCCESS:
            return Object.assign({}, state, action.order);
        default: 
            return state;
    }
}
