
import React, { PropTypes } from 'react';
import Moment from 'moment';
import DateTime from '../dateTime/DateTime';

import './LaunchDateMini.scss';


//////////////////


const LaunchDateMini = ({launchDate}) => (
	<div className="launchDateMini">
		<div className="content">
			<div>
				<div className="mount"><DateTime date={launchDate} format="MMM" /></div>
				<div className="date"><DateTime date={launchDate} format="DD" /></div>
			</div>
		</div>
	</div>
);

LaunchDateMini.propTypes = {
	position: PropTypes.instanceOf(Moment)
};


//////////////////


export default LaunchDateMini;
