import React from 'react';
import { connect } from 'react-redux';

import { activateLoyalty, updateMarketingPreferencesPlatform } from '../../actions/preferences';
import LoginSignupJourney from './LoginSignupJourney';
import DeliveryPreferences from '../preferences/delivery/DeliveryPreferences';

import DeliveryMethodPreferences from '../preferences/deliveryMethod/DeliveryMethodPreferences'
import BillingPreferences from '../preferences/billing/BillingPreferences';
import PaymentPreferences from '../preferences/payment/PaymentPreferences';
import NotificationPreferences from '../preferences/notifications/NotificationsPreferences';
import MarketingPrefrences from '../preferences/marketing/Marketing';
import config from '../../services/config/config';
import MembershipPreferences from '../preferences/membership/MembershipPreferences';
import SignupForm from '../preferences/membership/SignupForm';
import { loadContent } from '../../actions/content';
import storage from '../../services/storage/storage';


//////////////////


const LoginSignupJourneyPage = React.createClass({
	getInitialState() {
		return {
			isNextDisabled: true
		};
	},
	shouldComponentUpdate(nextProps) {
		if (nextProps.isNextDisabled !== this.state.isNextDisabled) {
			return true;
		}
		// performance workaround to stop multiple renders
		return nextProps.location.action === 'PUSH';
	},
	updateNextButtonStatus(isPageValid) {
		if (isPageValid) {
			this.setState({ isNextDisabled: false });
		}
		else {
			this.setState({ isNextDisabled: true });
		}
	},
	updatePreferenceValue(preference) {
		this.setState(preference);
	},
	componentWillMount() {
		this.props.loadContent();
	},
	render() {
		let { params, loyalty } = this.props;
		let screens = [];

		/* Screens object:
		 * 	preferencesKey - if the page saves to preferences on continue, provide the key
		 * 	markup - the component we want on that screen
		 * 		hideButton - if the page supports it, hides that pages cancel/save buttons
		 *		inSignupFlow - boolean to tell the page it's in the signup flow
		 */
		// push individually so we have more control over when to show a page or not
		// e.g. we could hide billing if delivery preferences are saved with useAsBilling

		if (storage.getItem('wasSignup')) {
			screens.push({
				preferencesKey: 'delivery',
				markup: <DeliveryMethodPreferences hideButtons={true} onFormChange={this.updatePreferenceValue} isPageValid={this.updateNextButtonStatus} />
			});
		} else {
			screens.push({
				preferencesKey: 'delivery',
				markup: <DeliveryPreferences hideButtons={true} onFormChange={this.updatePreferenceValue} isPageValid={this.updateNextButtonStatus} />
			});
			screens.push({
				preferencesKey: 'billing',
				markup: <BillingPreferences hideButtons={true} onFormChange={this.updatePreferenceValue} isPageValid={this.updateNextButtonStatus} />
			});
		}
		screens.push({
			markup: <PaymentPreferences inSignupFlow={true} isPageValid={this.updateNextButtonStatus} />
		});
		if (config.is_app) {
			screens.push({
				preferencesKey: 'notifications',
				markup: <NotificationPreferences hideButtons={true} onFormChange={this.updatePreferenceValue} isPageValid={this.updateNextButtonStatus} />
			});
		}
		if (config.supportsMembership) {
			if (!loyalty.isPremium) {
				screens.push({
					skipNumber: 2,
					markup: <MembershipPreferences updateNextButtonStatus={this.updateNextButtonStatus} step={params.step} />,
				})
				screens.push({
					onNext: (preferences) => {
						this.props.activteLoyalty(preferences)
					},
					preferencesKey: 'marketing',
					markup: <SignupForm hideButtons={true} onFormChange={this.updatePreferenceValue} isPageValid={this.updateNextButtonStatus} />,
				});
			}
		} else {
			screens.push({
				onNext: (preferences) => {
					this.props.updateMarketingPreferencesPlatform(preferences);
				},
				preferencesKey: 'marketing',
				markup: <MarketingPrefrences hideButtons={true} onFormChange={this.updatePreferenceValue} isPageValid={this.updateNextButtonStatus} />
			})
		}
		return (
			<div className="Journey">
				<LoginSignupJourney step={params.step} screens={screens} isNextDisabled={this.state.isNextDisabled} preferences={this.state.preferences} />
			</div>
		);
	}
});


//////////////////


const mapStateToProps = state => {
	const { user } = state;
	return { loyalty: user.preferences.loyalty || { isPremium: false } };
};

const mapDispatchToProps = dispatch => {
	return {
		updateMarketingPreferencesPlatform: (data) => dispatch(updateMarketingPreferencesPlatform(data)),
		activteLoyalty: (data) => dispatch(activateLoyalty(data)),
		loadContent: () => dispatch(loadContent()),
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(LoginSignupJourneyPage);
