/*
	NOTE: Notifications are sent to the native device to register, which then returns a true/false success
		  Because of this, all functions that modify notifications return promises which we must handle and
		  pass around.
*/
import { NAVIGATE_TO, NOTIFICATION_SUBSCRIBE } from '../nativeBridge/messageTypes';
import { postMessage } from '../nativeBridge';
import { getPreferences } from '../user/preferences';
import moment from 'moment';
import storage from '../storage/storage';

const NOTIFICATIONS_KEY = 'notifications';


//////////////////
// public


/**
 * Adds a notification
 * @param {string} productID ID of product to notification
 * @param {string} name product name
 * @param {string} launchDate product launchDate as ISO string
 * @returns {Array.<Object>|boolean}
 */
export function addNotification(productID, name, launchDate, launchType) {
	let notifications = getNotifications();
	if (!hasNotification(productID)) {
		let notification = {
			productID,
			name,
			launchDate,
			launchType
		};
		notifications.push(notification);
		updateNativeApps(notifications);
		return notifications;
	} else {
		return false;
	}
};

/**
 * Deletes all notifications
 * @returns {Array}
 */
export function clearNotifications() {
	updateNativeApps([]);
	return [];
};

/**
 * Gets all of the users notifications
 * @returns {Array.<Object>}
 */
export function getNotifications() {
	let notifications = storage.getItemNoExpiry(NOTIFICATIONS_KEY);
	notifications = notifications ? JSON.parse(notifications) : [];
	return notifications;
}

/**
 * Determines if the user has a product notification set
 * @param {string} productID ID of product to check
 * @returns {boolean}
 */
export function hasNotification(productID) {
	let has = false;
	let notifications = getNotifications();
	for (let i = 0, len = notifications.length; i < len; i++) {
		if (notifications[i].productID === productID) has = true;
	}
	return has;
};

/**
 * A function that allows us to rebuild all the notifications for the native apps.
 * Used if notification preferences have changed, and when product lists are updated
 * @param {Array.<Object>} [products] array of product objects
 */
export function rebuildNotifications(products) {
	let notifications = getNotifications();
	if (products && products.length) {
		notifications = updateLaunchDates(notifications, products);
		if (!notifications) return; //if falsey, then nothing to update
	}
	updateNativeApps(notifications);
	return notifications;
};

/**
 * Removes a notification
 * @param {string} productID ID of product to unbookmark
 * @returns {Array.<Object>|boolean}
 */
export function removeNotification(productID) {
	let ind = -1;
	let notifications = getNotifications();
	for (let i = 0, len = notifications.length; i < len; i++) {
		if (notifications[i].productID === productID) ind = i;
	}

	if (ind !== -1) {
		notifications.splice(ind, 1);
		updateNativeApps(notifications);
		return notifications;
	} else {
		return false;
	}
};

/**
 * Sets the users notifications - first by sending to the native app, and then, if successful, setting in
 * local storage
 * @param {Array.<Object>} notifications
 */
export function setNotifications(notifications) {
	let str = JSON.stringify(notifications);
	storage.setItemNoExpiry(NOTIFICATIONS_KEY, str);
};


//////////////////
// private


/**
 * Builds the notifications payload that is sent to the app
 * Every notification change needs to recalculate the time the notification is shown, in case the user has
 * changed their notifications settings.
 * Ignores any notification that would be sent in the past
 * @param {Array.<Object>} notifications
 * @returns {Array.<Object>}
 */
function buildAppNotifications(notifications) {
	let appNotifications = [];
	let notifyTimeBeforeArr = getPreferences('notifications.notifyTimeBefore') || [];
	let today = +new Date();

	// for every notification preference the user has set
	for (let i = 0, leni = notifyTimeBeforeArr.length; i < leni; i++) {
		let notifyTimeBefore = notifyTimeBeforeArr[i].time * notifyTimeBeforeArr[i].interval * 1000; //*1000 to get ms
		// for every product the user wants a notification for
		for (let j = 0, lenj = notifications.length; j < lenj; j++) {
			// iff we have all the data we need
			if (notifications[j].launchDate && notifications[j].name && notifications[j].productID && notifications[j].launchType) {
				// figure out notification message and time
				//let launchDayString = moment(notifications[j].launchDate).from(today); //e.g. "today", "in 3 days"
				let launchTimeString = moment(notifications[j].launchDate).format('h:mma'); //e.g. 3.04pm
				let notificationDate = +new Date(notifications[j].launchDate) - notifyTimeBefore; //datetime it shows

				// if the notification time is an actual time and not in the past
				if (!isNaN(notificationDate) && today < notificationDate) {
					// build the payload!
					let notiText = (notifications[j].launchType === 'raffle') ? "closing at" : "is launching at";
					
					let notification = {
						uid: `${notifications[j].productID}-${notificationDate}`,
						date: new Date(notificationDate).toISOString(),
						message: `${notifications[j].name} ${notiText} ${launchTimeString}`,
						action: {
							messageType: NAVIGATE_TO,
							data: {
								path: '/product/' + notifications[j].productID
							}
						}
						// buttons: [], //future consideration
						// sound: '' //we don't actually know what this is, sooo...
					};
					appNotifications.push(notification);
				}
			}
		}
	}
	return appNotifications;
}

/**
 * Uses a set of products to update launch dates on saved notifications
 * @param {Array.<Object>} notifications the products the user wants to be notified for
 * @param {Array.<Object>} products products the app is showing
 * @returns {Array.<Object>|false}
 */
function updateLaunchDates(notifications, products) {
	let changes = false;

	// organise products into an object where they are keyed by ID
	let keyedProducts = {};
	for (let i = 0, len = products.length; i < len; i++) keyedProducts[products[i].ID] = products[i];

	// iterate over notifications, check launchDate against matching product, update if necessary
	for (let i = 0, len = notifications.length; i < len; i++) {
		let prod = keyedProducts[notifications[i].productID];
		if (prod) {
			if (notifications[i].launchDate !== prod.launchDate) {
				changes = true;
				notifications[i].launchDate = prod.launchDate;
			}
		}
	}
	return changes ? notifications : false; //if we've changed something return the notifications, else false
}

/**
 * Tells the native apps that the user's selected notifications have changed
 * @param {Array.<Object>} notifications
 */
function updateNativeApps(notifications) {
	// build the payload
	let payload = {
		messageType: NOTIFICATION_SUBSCRIBE,
		data: {
			notifications: buildAppNotifications(notifications)
		}
	};
	postMessage(payload);
}
