import config from '../config/config';
import * as googleAnalitics from './googleAnalitics'
import * as hero from './hero'
import * as redeye from './redeye'
import * as appsflyer from './appsflyer'

//////////////////
// public

/**
 * Event names
 */
export const EVTS = {
	ACT: {
		BOOKMARK_ADD: 'bookmark_add', BOOKMARK_REMOVE: 'bookmark_remove',
		CALENDAR_ADD: 'calendar_add', CALENDAR_REMOVE: 'calendar_remove',
		PRODUCT_ADD: { ga: 'add to cart', hero: 'ecommerce:add', appsflyer: 'af_add_to_cart' }, PRODUCT_REMOVE: { ga: 'basket_remove', hero: 'ecommerce:remove' },
		PRODUCT_VIEW: { hero: 'ecommerce:detail', appsflyer: 'af_content_view' },
		LOGIN: 'login', LOGOUT: 'logout',
		NOTIFY_ADD: 'notify_add', NOTIFY_REMOVE: 'notify_remove',
		SIGNUP: 'signup',
		MY_DETAILS_SAVE: 'my_details_save',
		DELIVERY_METHOD_SAVE: 'delivery_method_save',
		DELIVERY_ADDRESS_SAVE: 'delivery_address_save',
		BILLING_ADDRESS_SAVE: 'billing_address_save',
		PAYMENT_DETAILS_SAVE: 'payment_details_save',
		CHECKOUT_INIT: 'checkout_init',
		SEARCH: {ga: 'search'},
		PLAY: 'play', PAUSE: 'pause', END: 'end'
	},
	CAT: {
		ACCOUNT: 'account',
		PRODUCT: 'product',
		CHECKOUT: 'checkout',
		INTERACT: 'UX'
	}
};

let providers = [googleAnalitics, hero, appsflyer, redeye];

/**
 *
 */
export function analyticsInit() {
	//Should We include redeye
	if (config.redeye) {
		providers.push(redeye)
	}
	for (let provider of providers) {
		if (typeof provider.analyticsInit === "function") {
			provider.analyticsInit()
		}
	}
}

/**
 *
 */
export function endSessionOnNextPageview() {
	for (let provider of providers) {
		if (typeof provider.endSessionOnNextPageview === "function") {
			provider.endSessionOnNextPageview()
		}
	}
}

/**
 *
 * @param {*} category
 * @param {*} action
 * @param {*} opt_label
 * @param {*} opt_value
 */
export function trackEvent(category, action, opt_label, opt_value, additions = null, customData = undefined) {
	for (let provider of providers) {
		if (typeof provider.trackEvent === "function") {
			provider.trackEvent(category, action, opt_label, opt_value, additions, customData)
		}
	}
}

export function trackProductAction(action, product) {
	for (let provider of providers) {
		if (typeof provider.trackProductAction === "function") {
			provider.trackProductAction(action, product)
		}
	}
}

/**
 *
 * @param {*} order
 */
export function trackOrder(order) {
	for (let provider of providers) {
		if (typeof provider.trackOrder === "function") {
			provider.trackOrder(order)
		}
	}
}

/**
 *
 * @param {*} order
 */
export function trackPreAuth(product, order) {
	for (let provider of providers) {
		if (typeof provider.trackPreAuth === "function") {
			provider.trackPreAuth(product, order)
		}
	}
}

/**
 *
 * @param {*} path
 * @param {*} ec_options
 */
export function trackPage(path = null, overrides = null) {
	for (let provider of providers) {
		if (typeof provider.trackPage === "function") {
			provider.trackPage(path, overrides)
		}
	}
}
