import React, { Component } from "react";

/**
 * A presentational component that displays the annual wins.
 *
 * Props:
 * - totalWins (number): The total number of wins to display.
 *
 * @class AnnualWins
 * @extends {Component}
 */
export default class AnnualWins extends Component {
	render() {
		return (
			<div style={{ marginBottom: "10px" }}>
				<div className="anual_wins">
					<div className="title_divider" />
					2023 Draw Wins:
				</div>
				<div className="win_counter">{this.props.totalWins}</div>
			</div>
		);
	}
}
