import React, {PropTypes} from 'react';
import './Price.scss';

const Price = ({amount, currency, className}) => {
	return (
		<span className={className}>£{amount}</span>
	)
}

Price.propTypes = {
	amount: PropTypes.string.isRequired,
	// currency: PropTypes.string.isRequired
}

export default Price;