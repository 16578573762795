import CalendarItemColumns from '../calendarItem/columns/CalendarItem';
import CalendarItemFull from '../calendarItem/full/CalendarItem';
import PromoProduct from '../promoProduct/PromoProduct';

import _ from 'lodash';

import moment from 'moment';
import React, { PropTypes } from 'react';
import { connect } from 'react-redux';
import config from '../../services/config/config'

import './Calendar.scss';
import { filterCampaigns } from '../../actions/content';
import Campagin from '../contentService/Campagin';


//////////////////


const Calendar = React.createClass({
	componentWillMount() {
		//this.props.getCalendar();
	},
	componentWillUnmount() {
		// this.props.clearCalendar();
	},
	componentDidMount() {
		let el = document.getElementsByClassName('Calendar')[0].getElementsByClassName('content-scroll')[0];
		el.scrollTop = this.props.scrollTop || 0;
	},
	hasLaunchDatePassed(launchDate) {
		launchDate = moment(launchDate);
		return moment().diff(launchDate) > 0;
	},
	canDisplayProduct(product) {
		let { loyalty } = this.props;
		//Is the product premium
		if (isPremiumProduct(product) && !loyalty.isPremium) {
			return false;
		}

		if (product.hideBeforeRelease && !this.hasLaunchDatePassed(product.launchStartDate || product.launchDate)) {
			return false;
		}

		if (!product.filters || product.filters.length === 0) {
			//if we dont have any filters, the product is ok to show
			return true;
		}
		let { completedScratchCards, actionProducts, bookmarks } = this.props;
		for (let filter in product.filters) {
			let filterObj = product.filters[filter];
			switch (filterObj.type) {
				case 'bookmarked':
					if (bookmarks.find((bookmark) => { return bookmark.ID === filterObj.value })) {
						return true;
					}
					break;
				case 'scratchoff':
					if (completedScratchCards.includes(filterObj.value)) {
						return true;
					}
					break;
				case 'action':
					if (actionProducts.includes(product.ID)) {
						return true;
					}
					break;
				default:
					console.log("Unknown filter type")
			}
		}
		return false
	},
	render() {
		const CalendarItem = config.layout.listStyle === "full" ? CalendarItemFull : CalendarItemColumns;

		let { productsByMonth, theme, campaigns, banners } = this.props;

		banners = _.cloneDeep(banners)
		// build calendar list
		let calendarList = [];
		let promoProducts = [];
		let keys = Object.keys(productsByMonth);
		let campaignContent = filterCampaigns(campaigns.campaigns, this.props.loyalty, 'launches').map((campaign, index)=>{
			return <Campagin key={`plp-${campaign.id}`} homepage={campaign.homePage} />
		});
		keys.sort((a, b) => a > b ? 1 : a < b ? -1 : 0);
		for (let i = 0, len = keys.length; i < len; i++) {
			if (productsByMonth.hasOwnProperty(keys[i])) {
				let splitKey = keys[i].split('-');
				let groupLabel = `${moment.months([splitKey[1]] - 1)} ${splitKey[0]}`;
				let monthList = [];
				productsByMonth[keys[i]].forEach((product, i) => {
					//Can Display product (because of filters)
					if(this.canDisplayProduct(product)){
						//Does a product belong to a banner
						let shouldSkip = false;
						for (let banner of banners){
							if(banner.products.includes(product.ID)){
								shouldSkip = true;
								banner.productObjects ? banner.productObjects.push(product) : banner.productObjects =  [product]
							}
						}
						if(!shouldSkip){
						monthList.push(<CalendarItem
							key={product.ID || i}
							buttonStyles={theme.primaryButton}
							image={product.mainImage}
							launchDate={product.launchDate}
							launchStartDate={product.launchStartDate}
							launchType={product.launchType}
							sku={product.ID}
							title={product.name}
							product={product}
							price={product.price}
							subtitle={product.subTitle}
							soldOut={product.status === 'out-of-stock' || product.status === 'complete'}
							trackScroll={this.props.trackScroll} />);
						}
					}
				});

				// create markup for each month in the calendar
				if (monthList.length) {
					calendarList.push(
						<li key={keys[i]}>
							<ul className="calendarMonth">
								<li className="calendarMonthLabel">
									<h4>{groupLabel}</h4>
								</li>
								{monthList}
							</ul>
						</li>
					);
				}
			}
		}

		promoProducts = banners.filter(promoProduct => promoProduct.productObjects)
			.map(promoProduct =><PromoProduct key={promoProduct.ID} trackScroll={this.props.trackScroll} {... promoProduct} />)
		calendarList.reverse(); //oldest month first
		// return full calendar markup
		return (
			<div className={`Calendar ${config.layout.listStyle} 
			nav-${config.layout.nav.position} 
			${calendarList.length === 0 && promoProducts.length === 0 && campaignContent.length === 0 ? 'no-scroll' : ""}`}>
				<div className="content-scroll">
					{calendarList.length === 0 && promoProducts.length === 0 ?
						<div className="no-products">
							<div className="background">?</div>
							<div className="overlay">
								No upcoming releases at the moment<br></br>Check the calendar for future launches
							</div>
						</div>
						: <div>
							{campaignContent.length ? <ul className="plp-campaign">
								{campaignContent}
							</ul> : null}
							{promoProducts.length ? <ul className="promo-products">
								{promoProducts}
							</ul> : null}
							<ul>
								{calendarList}
							</ul>
						</div>
					}
				</div>
			</div>
		);
	}
});

function isPremiumProduct(product) {
	//If isPremium false, then the product is not premium
	if (!product.isPremium) {
		return false;
	}

	//If we have no premiumEndDate, the product is always premium.
	if (!product.premiumEndDate) {
		return true;
	}

	//If were past the premium end date, the product is on general release
	let premiumEndDate = new Date(product.premiumEndDate);
	let now = new Date();
	return premiumEndDate > now;
}

Calendar.defaultProps = {
	products: []
};

Calendar.propTypes = {
	products: PropTypes.array.isRequired
};

const mapStateToProps = state => {
	const { content, bookmarks, user } = state;
	return { completedScratchCards: content.completedScratches || [], actionProducts: content.productsToShow || [], bookmarks: bookmarks.bookmarkedProducts, loyalty: user.preferences.loyalty || { isPremium: false }, banners: content.banners || [] };
};

const mapDispatchToProps = dispatch => {
	return {};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Calendar)
