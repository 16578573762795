// TODO: genericfy enough so that we can use this in Delivery Preferences
import React from 'react';
import _ from 'lodash';
import ReactDOM from 'react-dom';
import TextField from '../textInput/TextInput';
import DeliveryTypes from '../deliveryMethods/DeliveryTypes';
import LocaleSelector from '../localeSelector/LocaleSelector';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import TitleBlock from '../titleBlock/TitleBlock';
import Price from '../price/Price';
import { scrollElementIntoView } from '../../services/ui/ui';
import './DeliveryMethodSelector.scss';

const storesNotFoundMessage = "No stores found";
let userAgent = window.navigator.userAgent.toLowerCase();
let ios = /iphone|ipod|ipad/.test(userAgent);
let android = /Android/i.test(userAgent);
let deviceClass = android ? 'android' : ios ? 'ios' : '';

const DeliveryMethodSelector = React.createClass({
	getInitialState: () => ({
		search: '',
		visibleStores: []
	}),
	fetchStores(value) {
		if (value) {
			this.setState({ visibleStores: getSuggestions(value, this.props.stores), search: value });
		} else {
			this.clearStores();
			this.setState({ search: value });
		}
	},
	clearStores() {
		this.setState({ visibleStores: [] });
	},
	onStoreSelection(event, suggestion) {
		if (!suggestion.ID) {
			event.preventDefault();
			return;
		}
		this.props.selectStore(suggestion);
	},

	onChange(evt, elem) {
		if (deviceClass !== 'ios') scrollElementIntoView(ReactDOM.findDOMNode(elem));
	},
	getFilteredStores() {
		let filteredStores;
		if (this.state.search) {
			filteredStores = this.state.visibleStores.map((el, i) => {
				return (
					<li className="form-option-type" key={i} onClick={evt => this.onStoreSelection(evt, el)}>
						<div className="check-option">
							<label>
								<span className="check-box"></span>
								<span className="name">{el.name}</span>
							</label>
						</div>
					</li>
				);
			});
		} else {
			filteredStores = null;
		}

		return filteredStores
	},
	render() {
		let { deliveryMethod, locale, locales, clearSelectedStore, collapsed, deliveryTypes, localeChange, selectDeliveryMethod, charity, launchDay } = this.props;
		let filteredStores = this.getFilteredStores();

		let deliveryTypesCopy = _.cloneDeep(deliveryTypes);

		let filteredDeliveryTypes = deliveryTypesCopy.map(deliveryType => {
			deliveryType.options = deliveryType.options.filter(option => {

				//Show chartiy delivery and only chartiy delivery and for charity raffles;
				if(!option.charity !== !charity){
					return false;
				}
				
				//If the product is launch day, show all eligable delivery methods
				if(launchDay){
					return true
				}else{
					//if its not, only show non launc day ones
					return !option.launchDay
				}
			}).sort((a, b) => {
				if(a.launchDay && !b.launchDay){
					return -1;
				}
				if(b.launchDay && !a.launchDay){
					return -1;
				}
				return 0;
			});
			return deliveryType
		});

		if (collapsed && deliveryMethod) {
			return (
				<form className="form-horizontal" onSubmit={evt => evt.preventDefault()}>
					<p>
						{deliveryMethod.name},<br />
						{
							deliveryMethod.type === 'clickAndCollect'
								? <span>{deliveryMethod.storelocationName},<br /></span>
								: null
						}
						<Price className="price" amount={deliveryMethod.price.amount} currency={deliveryMethod.price.currency} />
					</p>
				</form>
			);
		} else {
			return (
				<div className="DeliveryMethodSelector">
					{
						locales
							? <form>
								<LocaleSelector updateLocale={localeChange} locale={locale} locales={locales} />
							</form>
							: null
					}
					<div style={locale ? { display: 'block' } : { display: 'none' }}>
						{locales ? <TitleBlock title="Select a Method" /> : null}
						<DeliveryTypes style={filteredDeliveryTypes ? { display: 'block' } : { display: 'none' }} deliveryMethod={deliveryMethod} deliveryTypes={filteredDeliveryTypes} updateDeliveryMethod={selectDeliveryMethod} />
						<ReactCSSTransitionGroup
							transitionName="checkout-block"
							transitionEnterTimeout={300}
							transitionLeaveTimeout={300}
							ref={(input) => { this.searchBox = input; }} >
							{/* C&C Store */}
							{
								(deliveryMethod.ID && deliveryMethod.type === 'clickAndCollect')
									? <div>
										<TitleBlock title="Select a store" />
										{
											deliveryMethod.storelocationID
												? <div className="form-horizontal form-options">
													<ul>
														<li className="form-option-type close" onClick={clearSelectedStore}>
															<div className="check-option">
																<input type="radio" name="storeSelect" id="selectedStore" checked selected readOnly />
																<label htmlFor="selectedStore">
																	<span className="check-box"></span>
																	<span className="name">{deliveryMethod.storelocationName}</span>
																	<span className="price"><span></span></span>
																</label>
															</div>
														</li>
													</ul>
												</div>
												: <form className="form-horizontal form-options" onSubmit={e => e.preventDefault()}>
													<div className="form-group form-suggestion">
														<TextField
															type="text"
															floatingLabelText="Search: Enter City or Town"
															value={this.state.search}
															onChange={evt => {
																this.fetchStores(evt.target.value);
																this.onChange(evt, this.searchBox);
															}}
															hintText="Search by store name"
															fullWidth={true}
															name="town"
														/>
														<ul>
															{filteredStores}
														</ul>
													</div>
												</form>
										}
									</div>
									: null
							}
						</ReactCSSTransitionGroup>
					</div>
				</div>
			);
		}
	}
});


DeliveryMethodSelector.defaultProps = {
	stores: [],
	deliveryMethod: {}
};

export default DeliveryMethodSelector;


//////////////////


/**
 * Autosuggest filter function
 * @param {string} value
 * @param {Array.<Object>} stores
 */
function getSuggestions(value, stores) {
	const inputValue = value.trim().toLowerCase();
	const inputLength = inputValue.length;
	let suggestedStores = inputLength === 0 ? stores : stores.filter(store =>
		store.name.toLowerCase().indexOf(inputValue) !== -1
	);

	if (suggestedStores.length === 0) suggestedStores.push({ name: storesNotFoundMessage });
	suggestedStores = suggestedStores.slice(0, 10); //max 10
	return suggestedStores;
}

// MOSAIC-119: different getSuggestions method to order by town, for fake closest store functionality
/*function getSuggestions(value, stores) {
	const inputValue = value.trim().toLowerCase();
	const inputLength = inputValue.length;
	let suggestedStores = inputLength === 0 ? stores : stores.filter(store =>
		store.address.town.toLowerCase().trim().indexOf(inputValue) !== -1
	);
	suggestedStores.sort(function (a, b) {
		let aInd = a.address.town.toLowerCase().indexOf(inputValue);
		let bInd = b.address.town.toLowerCase().indexOf(inputValue);
		let ret = aInd > bInd ? 1 : aInd < bInd ? -1 : 0;
		if (ret === 0) {
			let aTown = a.address.town.toLowerCase();
			let bTown = b.address.town.toLowerCase();
			ret = aTown > bTown ? 1 : aTown < bTown ? -1 : 0;
		}
		return ret;
	});

	if (suggestedStores.length === 0) suggestedStores.push({ name: storesNotFoundMessage });
	// suggestedStores = suggestedStores.slice(0, 10); //max 10
	return suggestedStores;
}*/
