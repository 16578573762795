/**
 * cachedFetch module
 * 
 * This module wraps the native fetch functionality to provide a http cache in local storage. The 
 * API remains the same as the native fetch function with the option to pass in an expriy property on your 
 * options. This cache can be switched off using the URL param 'bypassCache'.
 * 
 */

import storage from '../storage/storage';
import compression from 'lz-string';
import { getQueryParam } from '../url';

/**
 * cachedFetch 
 * @description fetch with http cache using local storage. Provide an 'expriy' in minutes on your options object.
 * @param [string] url
 * @param [object] options
 * 
 * @return [function] cachedFetch
 */
const cachedFetch = (url, options) => {	
  let expiry;
  
  if (typeof options === 'object') {
    expiry = options.expiry;
  }

  let cacheKey = url
  let cached = storage.getItem(cacheKey);

  if(!options.bypassCache && cached && options.method === 'GET'){
    //Atempt to decompress the string in local storage
    let decompressed = compression.decompressFromUTF16(cached);
    if(isJsonString(decompressed)){
      cached = decompressed
    }
     // Use the URL as the cache key to localStorage
    let response = new Response(new Blob([cached]));
    // needed for safari (false by default) 
    // this prop is read-only in chrome and firefox 
    if(!response.ok) response.ok = true; 
    return Promise.resolve(response)
  }

  return fetch(url, options).then(response => {
    // let's only store in cache if the content-type is
    // JSON or something non-binary
    if (response.ok && expiry) {
      
      let contentType = response.headers.get('Content-Type')
      if (contentType && (contentType.match(/application\/json/i) || contentType.match(/text\//i))) {
        // There is a .json() instead of .text() but
        // we're going to store it in localStorage as
        // string anyway.
        // If we don't clone the response, it will be
        // consumed by the time it's returned. This
        // way we're being un-intrusive.
        response.clone().text().then(content => {
          //Cache a compressed string to local storage
          storage.setItem(cacheKey, compression.compressToUTF16(content), expiry)
        })
      }
    } else {
      // lets make sure we delete any currently cached data if we're now getting errors
      storage.removeItem(cacheKey);
    }

    return response
  })
}

function isJsonString(str) {
  try {
      JSON.parse(str);
  } catch (e) {
      return false;
  }
  return true;
}

export default getQueryParam('bypassCache') ? fetch : cachedFetch;