import { hasDatePassed } from '../../../services/time/time';

import { Link } from 'react-router';
import BookmarkProductButton from '../../../containers/BookmarkButtonContainer';
import DateTime from '../../dateTime/DateTime';


import React, { PropTypes } from 'react';

import './CalendarItem.scss';
import Price from '../../price/Price';
import LaunchDateMini from '../../launchDateMini/LaunchDateMini';


//////////////////


const CalendarItem = ({ buttonStyles, image, launchDate, launchStartDate, launchType, sku, title, subtitle, soldOut, trackScroll, type, product, price, minPrice, maxPrice, noRedirect }) => (
    <li className={`product-full ${type}`}>
        <Link to={!noRedirect ? `/product/${sku}` : null} onClick={trackScroll ? () => trackScroll() : null}>
            <div className="image" style={{ backgroundImage: `url(${image.original || image})` }} />
        </Link>
        <div className="launchDate">
            <h3><DateTime date={launchDate} format="DD.MM.YYYY" /></h3>
        </div>
        <div className="productInfo">
            <div className="productDetails">
                <div>
                    <div className="subtitle">
                        <h3 className="title">{subtitle} </h3>
                    </div>
                    <h3 className="title">{title}</h3>
                    {
                        price && minPrice === maxPrice ? <Price className="price" amount={price.amount} currency={price.currency} /> : null
                    }
                    {
                        minPrice && maxPrice && minPrice !== maxPrice ?<span className="price"><Price amount={minPrice} currency={price.currency} /> - <Price amount={maxPrice} currency={price.currency} /></span> : null
                    }
                </div>
                <div>
                    <LaunchDateMini launchDate={launchDate}/>
                </div>
            </div>
            <div className="itemFooter">
                <span className="actions">
                    {
                        type !== 'promo' ? <BookmarkProductButton productID={sku} name={title} launchDate={launchDate} launchType={launchType} /> : null
                    }
                </span>
                <span className="launchInfo">
                    {
                        launchType === 'monthOnly'
                            ? soldOut ? <h3 className="soldout">Sold Out</h3> : <DateTime date={launchDate} format="MMMM" />
                            : launchType === 'raffle'
                                ? launchStartDate && !hasDatePassed(launchStartDate)
                                    ? <DateTime date={launchDate} format="DD MMMM" />
                                    : launchDate && !hasDatePassed(launchDate)
                                        ? <h3 className="outnow"> Draw Open</h3>
                                        : <h3 className="soldout">Draw Closed</h3>
                                : launchDate && !hasDatePassed(launchDate)
                                    ? <DateTime date={launchDate} format="DD MMMM" />
                                    : soldOut
                                        ? <h3 className="soldout">Sold Out</h3>
                                        : <h3 className="outnow">Out Now!</h3>
                    }
                </span>
            </div>
        </div>
    </li>
);

CalendarItem.defaultProps = {
    image: {},
    launchType: 'full',
    title: ""
};

CalendarItem.propTypes = {
    image: PropTypes.object.isRequired,
    launchDate: PropTypes.string,
    launchStartDate: PropTypes.string,
    launchType: PropTypes.string,
    sku: PropTypes.string.isRequired,
    title: PropTypes.string,
};


//////////////////


export default CalendarItem;
