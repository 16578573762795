import { hasDatePassed } from '../../services/time/time';
import { Link } from 'react-router';
import DateTime from '../dateTime/DateTime';
import React from 'react';

import './SearchItem.scss';
import Price from '../price/Price';


//////////////////


const SearchItem = ({ mainImage, index, launchDate, launchStartDate, launchType, ID, name, subTitle, trackScroll, price, status }) => {
	let soldOut = status === 'out-of-stock' || status === 'complete';
	return (
		<Link className="search-item" to={`/product/${ID}`} onClick={trackScroll ? () => trackScroll() : null}>
			<div className="image" style={{backgroundImage: `url(${mainImage.original})`}} />
			<div className="product-description">
				<h1>{name}</h1>
				<h2>{subTitle}</h2>
				{
					price ? <Price className="price" amount={price.amount} currency={price.currency} /> : null
				}
				<span className="launchInfo">
					{
						launchType === 'monthOnly'
							? soldOut ? <h3 className="soldout">Sold Out</h3> : <DateTime date={launchDate} format="MMMM" />
							: launchType === 'raffle'
								? launchStartDate && !hasDatePassed(launchStartDate)
									? <DateTime date={launchDate} format="DD MMMM" />
									: launchDate && !hasDatePassed(launchDate)
										? <h3 className="outnow"> Draw Open</h3>
										: <h3 className="soldout">Draw Closed</h3>
								: launchDate && !hasDatePassed(launchDate)
									? <DateTime date={launchDate} format="DD MMMM" />
									: soldOut
										? <h3 className="soldout">Sold Out</h3>
										: <h3 className="outnow">Out Now!</h3>
					}
				</span>
			</div>
			<div className="arrow-wrapper"><div className="arrow" /></div>
		</Link>
	)
};


//////////////////


export default SearchItem;