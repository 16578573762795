/**
 * Checkout Actions
 *
 * Here you'll find all the action creators & actions for the checkout flow of the application. Async
 * actions (using redux-thunk) are at the bottom.
 */
import { hashHistory } from 'react-router';
import { enableApplePayIfSupported, startApplePay } from './applePay';
import { updateDeliveryTypes, loadDeliveryMethods } from './deliveryTypes';
import { showError } from './error';
import { loading, loaded, loading3DS, loaded3DS } from './loading';
import { updateSession } from './session';
import { getLocales, getStores, getDeliveryMethods, createOrder, getSession, createPreOrder } from '../services/api';
import { setPreferencesOnCheckout } from '../services/checkout/checkout';
import { getSavedCards, compleatPreAuth3DS, startKlarnaSession } from '../services/api';
import { getUserData } from '../services/user';
import { savedCardUpdate } from './preferences';
import config from '../services/config/config'
import isKlarnaExclusive from '../services/payment/isKlarnaExclusive'
import storage from '../services/storage/storage';


//////////////////
// public

export const CLEAR_SELECTED_STORE = 'CLEAR_SELECTED_STORE';
export const LOAD_LOCALES = 'LOAD_LOCALES';
export const LOAD_STORES = 'LOAD_STORES';
export const ORDER_SUCCESS = 'ORDER_SUCCESS';
export const PAYMENT_FAILURE = 'PAYMENT_FAILURE';
export const PAYMENT_SUCCESS = 'PAYMENT_SUCCESS';
export const PREFILL_CHECKOUT_FROM_PREFERENCES = 'PREFILL_CHECKOUT_FROM_PREFERENCES';
export const SELECT_DELIVERY_METHOD = 'SELECT_DELIVERY_METHOD';
export const SELECT_PAYMENT_METHOD = 'SELECT_PAYMENT_METHOD';
export const SELECT_STORE = 'SELECT_STORE';
export const TOGGLE_USE_BILLING_AS_DELIVERY = 'TOGGLE_USE_BILLING_AS_DELIVERY';
export const UPDATE_BILLING_ADDRESS = 'UPDATE_BILLING_ADDRESS';
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';
export const UPDATE_DELIVERY_ADDRESS = 'UPDATE_DELIVERY_ADDRESS';
export const UPDATE_LOCALE = 'UPDATE_LOCALE';
export const UPDATE_LOCALES = 'UPDATE_LOCALES';
export const UPDATE_PAYMENT_METHODS = 'UPDATE_PAYMENT_METHODS';
export const UPDATE_SEARCH = 'UPDATE_SEARCH';
export const UPDATE_STORES = 'UPDATE_STORES';
export const TOGGLE_CHECKOUT_CONFIRMATION = 'TOGGLE_CHECKOUT_CONFIRMATION';
export const PRE_ORDER_SUCCESS = 'PRE_ORDER_SUCCESS';
export const KLARNA_START_SUCCESS = 'KLARNA_START_SUCCESS';

/**
 * Action creator for updating the store search term
 * @param {string} value
 * @return {object} update search action
 */
export function updateSearch(value) {
	return {
		type: UPDATE_SEARCH,
		value
	}
};

/**
 * updateDelivery
 *
 * @param {object} address
 * @return {object} update delivery action
 */
export function updateDelivery(address) {
	return {
		type: UPDATE_DELIVERY_ADDRESS,
		address
	}
};

/**
 * updateDelivery
 *
 * @param {object} details
 * @return {object} update customer action
 */
export function updateCustomer(details) {
	return {
		type: UPDATE_CUSTOMER,
		details
	}
};

/**
 * updateLocale
 *
 * @param {string} locale
 * @return {object} locale action
 */
export function updateLocale(locale) {
	return (dispatch) => {
		dispatch({
			type: UPDATE_LOCALE,
			locale
		});
		dispatch((loadDeliveryMethods(locale)));
	}
};

/**
 * updateLocales
 *
 * @param {array} locales
 * @return {object} locales action
 */
export function updateLocales(locales) {
	return {
		type: UPDATE_LOCALES,
		locales
	}
};

/**
 * selectDeliveryMethod
 *
 * @param {string} id
 * @return {object} deliveryMethod action
 */
export function selectDeliveryMethod(deliveryMethod) {
	return {
		type: SELECT_DELIVERY_METHOD,
		deliveryMethod
	}
};

/**
 * selectPaymentMethod
 *
 * @param {string} id
 * @return {object} paymentMethod action
 */
export function selectPaymentMethod(paymentMethod) {
	return {
		type: SELECT_PAYMENT_METHOD,
		paymentMethod
	}
};

/**
 * updatePaymentMethods
 *
 * @param {string} id
 * @return {object} paymentMethod action
 */
export function updatePaymentMethods(paymentMethods) {
	return {
		type: UPDATE_PAYMENT_METHODS,
		paymentMethods
	}
};

/**
 * updateBillingAddress
 *
 * @param {object} address
 * @return {object} billing action
 */
export function updateBillingAddress(address) {
	return {
		type: UPDATE_BILLING_ADDRESS,
		address
	}
};

/**
 * selectStore
 *
 * @param {object} store
 * @return {object} store action
 */
export function selectStore(store) {
	return {
		type: SELECT_STORE,
		store
	}
};

/**
 * clearSelectedStore
 *
 * @return {object} clear store action
 */
export function clearSelectedStore() {
	return {
		type: CLEAR_SELECTED_STORE
	}
};

/**
 * selectDeliveryMethod
 *
 * @param {array} stores
 * @return {object} stores action
 */
export function updateStores(stores) {
	return {
		type: UPDATE_STORES,
		stores
	}
};

/**
 * orderSuccess
 *
 * @param {object} order
 * @return {object} stores action
 */
export function orderSuccess(order) {
	return {
		type: ORDER_SUCCESS,
		order
	}
};

/**
 * orderSuccess
 *
 * @param {object} order
 * @return {object} stores action
 */
export function preOrderSuccess(preOrder) {
	return {
		type: PRE_ORDER_SUCCESS,
		order: preOrder
	}
};

/**
 * klarnaStartSuccess
 *
 * @param {object} klarnaPaymentResponse
 * @return {object} stores action
 */
export function klarnaStartSuccess(klarnaPaymentResponse) {
	return {
		type: KLARNA_START_SUCCESS,
		klarnaPaymentResponse: klarnaPaymentResponse
	}
};

/**
 * paymentSuccess
 *
 * @param {object} payment
 * @return {object} payment successful action
 */
export function paymentSuccess(payment) {
	return {
		type: PAYMENT_SUCCESS,
		payment
	}
};

/**
 * paymentFailure
 *
 * @param {array} stores
 * @return {object} stores action
 */
export function paymentFailure(error) {
	return {
		type: PAYMENT_FAILURE,
		error
	}
};

/**
 *
 */
export function toggleUseBillingAsDelivery() {
	return {
		type: TOGGLE_USE_BILLING_AS_DELIVERY
	}
};

/**
 *
 */
export function toggleCheckoutConfirmation() {
	return {
		type: TOGGLE_CHECKOUT_CONFIRMATION
	}
};

/**
 * Sets relevant user preferences on the checkout object
 * @param {Object} preferences
 * @returns {Object}
 */
export function prefillCheckout(checkoutState, preferences) {
	let prefilledPrefs = setPreferencesOnCheckout(checkoutState, preferences);
	return {
		prefilledPrefs,
		type: PREFILL_CHECKOUT_FROM_PREFERENCES
	}
};


//////////////////

/**
 * loadDataForCheckout
 *
 * @return {function} thunk
 */
export function loadDataForCheckout(sessionID) {
	return (dispatch, getState) => {
		// set loading
		dispatch(loading());

		// try to get the session first, if we cant get this theres no point in continuing to checkout
		return getSession(sessionID)
			.then((session) => {
				let { user, checkout } = getState(),
					userData = getUserData(),
					userID,
					userDeliveryLocale = checkout && checkout.delivery && checkout.delivery.locale ? checkout.delivery.locale : user.preferences.delivery.locale;

				if (userData && userData.userID) userID = userData.userID;


				let dataSources = [getLocales(), getDeliveryMethods(userDeliveryLocale), getStores()];
				//If the user is logged in we should attempt to get saved cards from adyen. 
				//Sometimes users have saved cards on there device but not in there adyen account (JDG-27721)
				if (user.preferences.payment) {
					dataSources.push(getSavedCards(userID));
				}

				dispatch(updateSession(session));
				return Promise.all(dataSources)
					.then(values => {
						let locales = values[0].locales;
						let deliverytypes = values[1].deliverytypes;
						let stores = values[2].storelocations;
						let savedCard = values[3];

						if (savedCard) dispatch(savedCardUpdate(savedCard));
						dispatch(updateLocales(locales));
						dispatch(updateDeliveryTypes(deliverytypes))
						dispatch(updateStores(stores));

						let { checkout } = getState();
						dispatch(prefillCheckout(checkout, user.preferences))
						dispatch(loaded());
					});
			})
			.catch((error) => {
				if (error.response) {
					error.response.json().then((error) => {
						dispatch(showError(error));
					});
				}

				dispatch(loaded());
			});
	}
};


//////////////////

/**
 * loadDataForPreAuthCheckout
 *
 * @return {function} thunk
 */
export function loadDataForPreAuthCheckout() {
	return (dispatch, getState) => {
		// set loading
		dispatch(loading());


		let { user, checkout } = getState(),
			userData = getUserData(),
			userID,
			userDeliveryLocale = checkout && checkout.delivery && checkout.delivery.locale ? checkout.delivery.locale : user.preferences.delivery.locale;

		if (userData && userData.userID) userID = userData.userID;


		let dataSources = [getLocales(), getDeliveryMethods(userDeliveryLocale), getStores()];
		//If the user is logged in we should attempt to get saved cards from adyen. 
		//Sometimes users have saved cards on there device but not in there adyen account (JDG-27721)
		if (user.preferences.payment) {
			dataSources.push(getSavedCards(userID));
		}

		return Promise.all(dataSources)
			.then(values => {

				let locales = values[0].locales;
				let deliverytypes = values[1].deliverytypes;
				let stores = values[2].storelocations;
				let savedCard = values[3];

				if (savedCard) dispatch(savedCardUpdate(savedCard));
				dispatch(updateLocales(locales));
				dispatch(updateDeliveryTypes(deliverytypes))
				dispatch(updateStores(stores));

				let { checkout } = getState();
				dispatch(prefillCheckout(checkout, user.preferences))
				dispatch(loaded());
			})
			.catch((error) => {
				if (error.response) {
					error.response.json().then((error) => {
						dispatch(showError(error));
					});
				}
				dispatch(loaded());
			});
	}
};

/**
 * create order
 *
 * @return {function} thunk
 */
export function completeOrder() {
	return (dispatch, getState) => {
		let { checkout, session } = getState();
		storage.setItem('paymentMethod', JSON.stringify(checkout.paymentMethod))

		if (checkout.paymentMethod.type === 'APPLE_PAY') return dispatch(startApplePay());

		switch (checkout.paymentMethod.type) {
			case 'APPLE_PAY':
				return dispatch(startApplePay());

			case 'RECURRING':
				dispatch(loading('Making payment'));

				return createOrder(checkout, session.ID)
					.then(data => {
						console.log(data);
						dispatch(orderSuccess(data));
					}).catch((error) => {
						dispatch(loaded());
						if (error.response) {
							error.response.json().then((error) => {
								dispatch(showError(error))
							})
						} else if (error.message) {
							error.errorInfo = error.message;
							dispatch(showError(error));
						} else {
							dispatch(showError(error));
						}
					});

			case 'CARD':
				dispatch(loading('Proceeding to payment'));
				return createOrder(checkout, session.ID)
					.then(data => {
						dispatch(orderSuccess(data));
						hashHistory.push(`/payment`);
						dispatch(loaded());
					})
					.catch((error) => {
						dispatch(loaded());
						if (error.response) {
							error.response.json().then((error) => {
								dispatch(showError(error))
							})
						} else if (error.message) {
							error.errorInfo = error.message;
							dispatch(showError(error));
						} else {
							dispatch(showError(error));
						}
					});
			case 'KLARNA':
				dispatch(loading('Proceeding to payment'));
				return createOrder(checkout, session.ID)
					.then(data => {
						dispatch(orderSuccess(data));
						return startKlarnaSession(session.ID)
					}).then(data => {
						if (data.successInfo.paymentMethods.includes(checkout.paymentMethod.klarnaType)) {
							//Disable the klarna payment button on the klaran form, 
							//it will be re-eanbled once the widget is init'ed
							data.successInfo.enablePaymentButton = false;
							dispatch(klarnaStartSuccess(data.successInfo));
							hashHistory.push(`/klarna`);
							dispatch(loaded());
						} else {
							dispatch(loaded());
							dispatch(showError(showError({ errorCode: "klarna-method-not-supported" })));
						}
					})
					.catch((error) => {
						dispatch(loaded());
						if (error.response) {
							error.response.json().then((error) => {
								dispatch(showError(error))
							})
						} else if (error.message) {
							error.errorInfo = error.message;
							dispatch(showError(error));
						} else {
							dispatch(showError(error));
						}
					});

			default:
				break;
		}

	}
};

/**
 * create order
 *
 * @return {function} thunk
 */
export function createPreAuthOrder(checkout, deliveryTypes, optionID, verification, answer) {
	return (dispatch, getState) => {
		let { checkout, product } = getState();
		let productID = optionID.split(":")[0];
		let checkoutState = { ...checkout, productID, optionID }
		let userData = getUserData();
		checkoutState.userID = userData.userID;
		storage.setItem('paymentMethod', JSON.stringify(checkout.paymentMethod))
		switch (checkout.paymentMethod.type) {
			case 'RECURRING':
				// console.log('createPreAuthOrder ~ RECURRING');
				dispatch(loading('Making payment'));

				return createPreOrder(checkoutState, verification, answer, product)
					.then(data => {
						dispatch(preOrderSuccess(data));
					}).catch((error) => {
						dispatch(loaded());
						if (error.response) {
							error.response.json().then((error) => {
								dispatch(showError(error))
							})
						} else if (error.message) {
							error.errorInfo = error.message;
							dispatch(showError(error));
						} else {
							dispatch(showError(error));
						}
					});
			case 'CARD':
				dispatch(loading('Proceeding to payment'));
				return createPreOrder(checkoutState, verification, answer, product)
					.then(data => {
						dispatch(preOrderSuccess(data));
						hashHistory.push(`/payment`);
						dispatch(loaded());
					})
					.catch((error) => {
						dispatch(loaded());
						if (error.response) {
							error.response.json().then((error) => {
								dispatch(showError(error))
							})
						} else if (error.message) {
							error.errorInfo = error.message;
							dispatch(showError(error));
						} else {
							dispatch(showError(error));
						}
					});
			case 'KLARNA':
				dispatch(loading('Proceeding to payment'));
				return createPreOrder(checkoutState, verification, null, product)
					.then(data => {
						dispatch(preOrderSuccess(data));
						return startKlarnaSession(null, data.orderID)
					}).then(data => {
						if (data.successInfo.paymentMethods.includes(checkout.paymentMethod.klarnaType)) {
							//Disable the klarna payment button on the klaran form, 
							//it will be re-eanbled once the widget is init'ed
							data.successInfo.enablePaymentButton = false;
							dispatch(klarnaStartSuccess(data.successInfo));
							hashHistory.push(`/klarna`);
							dispatch(loaded());
						} else {
							dispatch(loaded());
							dispatch(showError(showError({ errorCode: "klarna-method-not-supported" })));
						}
					})
					.catch((error) => {
						dispatch(loaded());
						if (error.response) {
							error.response.json().then((error) => {
								dispatch(showError(error))
							})
						} else if (error.message) {
							error.errorInfo = error.message;
							dispatch(showError(error));
						} else {
							dispatch(showError(error));
						}
					});

			default:
				break;
		}

	}
};

/**
 * compleat a 3ds transaction
 *
 * @return {function} thunk
 */
 export function completeTDS() {
	return (dispatch, getState) => {
		let session = getState().session,
            //sessionID = session.ID,
            preOrderID = session.preOrder ? session.preOrder.orderID: null;

		dispatch(loading3DS('Completing Payment'));
		return compleatPreAuth3DS(preOrderID).then(data =>{
			hashHistory.push(`/preorder-confirmation/${preOrderID}`);
			dispatch(loaded3DS());
		}).catch((error) => {
			dispatch(loaded3DS());
			if (error.response) {
				error.response.json().then((error) => {
					dispatch(showError(error))
				})
			} else if (error.message) {
				error.errorInfo = error.message;
				dispatch(showError(error));
			} else {
				dispatch(showError(error));
			}
		});
	}
};

/**
 * loadStores
 *
 * @return {function} thunk
 */
export function loadStores() {
	return (dispatch) => getStores().then(data => dispatch(updateStores(data.storelocations)));
};

/**
 * loadLocales
 *
 * @return {function} thunk
 */
export function loadLocales() {
	return (dispatch) => getLocales().then(data => dispatch(updateLocales(data.locales)));
};

export const ENABLE_SAVED_CARD = "ENABLE_SAVED_CARD";

function enableSavedCard(card) {
	return {
		type: ENABLE_SAVED_CARD,
		card
	}
}

export const ENABLE_CARD = "ENABLE_CARD";

export function enableCardMethod() {
	return {
		type: ENABLE_CARD,
	}
}

export const ENABLE_KLARNA = "ENABLE_KLARNA";

export function enableKlarna() {
	return {
		type: ENABLE_KLARNA
	}
}

export const DISABLE_KLARNA = "DISABLE_KLARNA";

export function disableKlarna() {
	return {
		type: DISABLE_KLARNA
	}
}

export const DISABLE_CARD = "DISABLE_CARD";

export function disableCard() {
	return {
		type: DISABLE_CARD
	}
}

export const DISABLE_APPLE_PAY = "DISABLE_APPLE_PAY";

export function disableApplePay() {
	return {
		type: DISABLE_APPLE_PAY
	}
}

export const DISABLE_SAVED_CARD = "DISABLE_SAVED_CARD";

export function disableSavedCard() {
	return {
		type: DISABLE_SAVED_CARD
	}
}

export const HIDE_KLARNA = "HIDE_KLARNA";

export function hideKlarna() {
	return {
		type: HIDE_KLARNA
	}
}
/**
 *
 */
export function enablePaymentMethods(product, locale, billing, useDeliveryAsBilling) {
	return (dispatch, getState) => {
		if (product.paymentGateways && product.paymentGateways.includes('Klarna') && config.klarnaLocals.includes(locale) && (useDeliveryAsBilling || config.klarnaLocals.includes(billing.locale))) {
			dispatch(enableKlarna());
		} else {
			dispatch(disableKlarna());
		}
		if (!product.paymentGateways || (product.paymentGateways && product.paymentGateways.includes('Adyen') && !isKlarnaExclusive(product))) {
			dispatch(enableCardMethod());
			if (product.launchType !== 'raffle') {
				dispatch(enableApplePayIfSupported());
			}
			dispatch(enableSavedCardMethod());
		} else {
			dispatch(disableCard());
			dispatch(disableApplePay());
			dispatch(disableSavedCard());
		}
	}
};

/**
 *
 */
export function enableSavedCardMethod() {
	return (dispatch, getState) => {
		let state = getState();
		if (state.user.preferences.payment && state.user.preferences.payment.saved) {
			dispatch(enableSavedCard(state.user.preferences.payment.card));
		}
	}
};
