import { connect } from 'react-redux';
import { clearCalendar, loadProducts } from '../actions/calendar';
import Calendar from '../components/calendar/Calendar';
import DesktopCalendar from '../components/calendar/DesktopCalendar';

import config from '../services/config/config'

const mapStateToProps = state => {
	const { calendar, loading, content } = state;
	return { productsByMonth: calendar.productsByMonth, loading, theme: content.theme, campaigns: content.campaigns };
};

const mapDispatchToProps = dispatch => {
	return {
		clearCalendar: () => dispatch(clearCalendar()),
		getCalendar: () => dispatch(loadProducts())
	};
};

let CalendearComponent = config.layout.nav.position === 'none' ? DesktopCalendar : Calendar

const CalendarPage = connect(
	mapStateToProps,
	mapDispatchToProps
)(CalendearComponent);

export default CalendarPage;
