/**
 * Hawk Rest Client
 */
import { postMessage } from '../nativeBridge';
import { sha256 } from 'js-sha256';
import { deleteRequest, get, post, put } from './restClientFactory';


export const hawkRestClient = {
	get: function (url, options = {}, expiry) {
		const promise = new Promise((resolve, reject) => {
			window.addEventListener('hawkReceived', function hawkReceivedGet(e) {
				if (e.detail.payload.url === url) {
					console.log(e);
					if (e.detail.success !== true) {
						reject(e.detail);
					}
					options.headers.Authorization = e.detail.payload.hawk;
					window.removeEventListener('hawkReceived', hawkReceivedGet);
					resolve(get(url, options, expiry));
				}
			});
		});

		postMessage({
			messageType: 'hawk-sign',
			data: {
				apikey: options.query.api_key,
				method: 'GET',
				url: url
			}
		});
		return promise;
	},
	put: function (url, payload, options = {}, expiry) {
		const promise = new Promise((resolve, reject) => {
			window.addEventListener('hawkReceived', function hawkReceivePut(e) {
				if (e.detail.payload.url === url) {
					console.log(e);
					if (e.detail.success !== true) {
						reject(e.detail);
					}
					options.headers.Authorization = e.detail.payload.hawk;
					window.removeEventListener('hawkReceived', hawkReceivePut);
					resolve(put(url, payload, options, expiry));
				}
			});
		});
		postMessage({
			messageType: 'hawk-sign',
			data: {
				apikey: options.query.api_key,
				method: 'PUT',
				url: url,
				payloadHash: sha256(JSON.stringify(payload))
			}
		});
		return promise;
	},
	post: function (url, payload, options = {}, expiry) {
		const promise = new Promise((resolve, reject) => {
			window.addEventListener('hawkReceived', function hawkReceivePost(e) {
				if (e.detail.payload.url === url) {
					console.log(e);
					if (e.detail.success !== true) {
						reject(e.detail);
					}
					options.headers.Authorization = e.detail.payload.hawk;
					window.removeEventListener('hawkReceived', hawkReceivePost);
					resolve(post(e.detail.payload.url, payload, options, expiry));
				}
			});
		});

		postMessage({
			messageType: 'hawk-sign',
			data: {
				apikey: options.query.api_key,
				method: 'POST',
				url: url,
				payloadHash: sha256(JSON.stringify(payload))
			}
		});
		return promise;
	},
	delete: function (url, options = {}, expiry) {
		const promise = new Promise((resolve, reject) => {
			window.addEventListener('hawkReceived', function hawkReceiveDelete(e) {
				if (e.detail.payload.url === url) {
					console.log(e);
					if (e.detail.success !== true) {
						reject(e.detail);
					}
					options.headers.Authorization = e.detail.payload.hawk;
					window.removeEventListener('hawkReceived', hawkReceiveDelete);
					resolve(deleteRequest(e.detail.payload.url, options, expiry));
				}
			});
		});

		postMessage({
			messageType: 'hawk-sign',
			data: {
				apikey: options.query.api_key,
				method: 'DELETE',
				url: url
			}
		});
		return promise;
	}
}