import React from 'react';
import { connect } from 'react-redux';

import { updateUserPreferences, updateMarketingPreferencesPlatform } from '../../actions/preferences';
import config from '../../services/config/config';
import { navigateToExternal } from '../../services/navigate/navigate';

const OptIn = React.createClass({
    getInitialState() {
        return {
            hide: false
        }
    },
    componentWillMount() {
        let { preferences } = this.props;
        this.setState({ hide: preferences.optedIn });
    },
    onChange() {
        let { preferences, personal } = this.props
        let updatedPrefrences = Object.assign({}, preferences, { optedIn: !preferences.optedIn, email: personal.email })
        this.props.savePreferences("marketing", updatedPrefrences);
        this.props.updatePlatform(preferences);
    },
    render() {
        let { preferences } = this.props
        return (!this.state.hide ? <div
            className='optIn'>
            <div>
                Want to be one of the first to know about our upcoming launches? You can opt out at any time via the user preferences screen or by contacting us using the details set out in our <span className='link' onClick={() => navigateToExternal(`${config.storeUrl}customer-service/privacy/`)}>privacy policy</span>
            </div>
            <div className="form-group check-option">
                <input type="checkbox" id="marketing" checked={preferences.optedIn} onChange={this.onChange} />
                <label htmlFor="marketing">
                    <span className="check-box tick"></span>
                    <span className="name">Yes, sign me up!</span>
                </label>
            </div>
        </div> : null)
    }
})

const mapStateToProps = state => {
    const { user } = state;
    return { preferences: user.preferences.marketing || { optedIn: false }, personal: user.preferences.personal };
};

const mapDispatchToProps = dispatch => {
    return {
        savePreferences: (key, data) => dispatch(updateUserPreferences(key, data)),
        updatePlatform: (data) => dispatch(updateMarketingPreferencesPlatform(data)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OptIn)