/**
 * Header presentational component
 *
 * This is a presentational component which wraps the header bar shown on each page.
 *
 * Receives props from the container component in containers/HeaderContainer
 *
 */
import React from 'react';
import MiniCart from '../miniCart/MiniCart';
import { hashHistory } from 'react-router';
import './Header.scss';

import logoDark from '../../assets/img/logo-small.png';

import UspBanner from '../uspBanner/UspBanner';
import Countdown from '../countdownTimer/Countdown';
import BackButton from '../backButton/BackButton';

import homeIcon from '../../assets/img/nav/icn-home.png';

const Header = React.createClass({
	getLogo(logo) {
		if (logo === false) {
			return logoDark
		}
		if (logo === true) {
			return logoDark
		} else {
			return logo
		}
	},
	render() {
		let { session, content, header, checkout, toggleMiniCart, getSession, clearSession, showTimeout, showLogo, showBackButton, showHomeButton } = this.props;

		return (
			<header className={`native-header solid`}>
				<div className="left">
					{showBackButton ?
						<BackButton className="back button" />
						: null
					}
					{showHomeButton ?
						<button onClick={() => hashHistory.push('/')} className='home button'>
							<img className="icon icon-home" alt="home button" src={homeIcon} />
						</button>
						: null
					}
					{!showHomeButton && !showBackButton ?
						<img className={`app-logo`} alt="logo" src={this.getLogo(showLogo)} onClick={() => { hashHistory.push('/') }} />
						: null}
					{showTimeout && session.expiryDate ? <UspBanner message="">
						<Countdown end={session.expiryDate} showAllUnits={false} countdownEnded={clearSession} pause={session.pause} />
					</UspBanner> : null}
				</div>
				<div className="right">
					<MiniCart className="miniCart"
						subtotal={session.subTotal}
						delivery={checkout.deliveryMethod}
						total={session.total}
						products={content.products}
						stock={session.stock}
						show={true}
						forceShow={true}
						toggleMiniCart={toggleMiniCart}
						loading={header.loading}
						expiryDate={session.expiryDate}
						serverDate={session.serverDate}
						sessionID={session.ID}
						getSession={getSession}
						clearSession={clearSession}
						theme={content.theme}
					/>
				</div>
			</header >
		)
	}

});

export default Header;
