import React from 'react';
import Badge from './Badge';
import './Badge.scss'




//////////////////


export default ({ badge, collectedBadge, onClick }) => {
    let finishedCollections = Math.floor(collectedBadge / 5);

    let click = finishedCollections !== 0 ? onClick : function () { }

    let badges = [<div key={0} onClick={()=>click({ type: 'sticker', achievement: badge.id })} className="badge-total">
        <h1>{Math.floor(finishedCollections)}</h1>
        Finished<br />Collection{finishedCollections === 1 ? null : 's'}
    </div>];
    for (let i = 1; i <= 5; i++) {
        let active = i <= collectedBadge % 5;
        badges.push(<Badge key={i} index={i} badge={badge} active={active} />)
    }
    return (<div className="badge-group">
        <div className="badge-scroll">
            {
                badges
            }
        </div>
        <h4>{badge.name}</h4>
    </div>
    )
};