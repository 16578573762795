import { connect } from 'react-redux';
import BookmarkButton from '../components/bookmarkButton/BookmarkButton';
import { toggleNotiAndEventForBookmarks } from '../actions/bookmarks';
import { hasBookmark } from '../services/bookmarks/bookmarks';


const mapStateToProps = (state, ownProps) => {
	const { productID, launchDate, launchType } = ownProps;
	const isBookmarked = hasBookmark(productID);
	return { productID, isBookmarked, launchDate, launchType };
}

const mapDispatchToProps = dispatch => {
	return {
		toggleBookmark: (productID, productName, launchDate, launchType) => dispatch(toggleNotiAndEventForBookmarks(productID, productName, launchDate, launchType))
	};
};

const BookmarkProductButton = connect(
	mapStateToProps,
	mapDispatchToProps
)(BookmarkButton);

export default BookmarkProductButton;
