import http from './http/mosaicRestClient';
import { setSession, deleteSession } from '../services/session';
import { extractOrderFromState } from './order/order';
import { getUserData } from './user';
import storage from './storage/storage';

export function getHomeContent(bypassCache) {
	if (bypassCache) {
		return http.get('/content', { hawkEnabled: false, bypassCache: true });
	} else {
		return http.get('/content', { hawkEnabled: false });
	}
}

export function getRaffleEntries(userID) {
	return http.get(`/user/${userID}/preauth`, { hawkEnabled: false, sendSession: true }, 0);
}

export async function getProductBySku(sku) {
	let data = await getHomeContent();
	let product = data.products.find((el) => el.ID === sku);

	//If we dont have a product in the main content pull, see if its on the backend
	if (!product) {
		product = await getProductByID(sku)
	}

	if (product) {
		//move options into options.options to support same data structure
		product.options.options = product.options;

		//Make blog copy null to override old value in redux if its missing on the OG object
		if (!product.hasOwnProperty("blogCopy")) {
			product.blogCopy = null;
		}

		///Make sizeGuide null to override its old value in redux if its missing on the new product
		if (!product.hasOwnProperty("sizeGuide")) {
			product.sizeGuide = null;
		}

		//Make audio null to override old value in redux if its missing on the OG object
		if (!product.hasOwnProperty("audio")) {
			product.audio = null;
		}

		//Make question copy null to override old value in redux if its missing on the OG object
		if (!product.hasOwnProperty("question")) {
			product.question = null;
		}

		//Make charity false to override old value in redux if its missing on the OG object
		if (!product.hasOwnProperty("charity")) {
			product.charity = false;
		}

		//Make launchDay false to override old value in redux if its missing on the OG object
		if (!product.hasOwnProperty("launchDay")) {
			product.launchDay = false;
		}

		//Make launchDay false to override old value in redux if its missing on the OG object
		if (!product.hasOwnProperty("launchDayDeliveryMethod")) {
			product.launchDayDeliveryMethod = false;
		}

		//Make launchDayDeliveryMembersExclusive false to override old value in redux if its missing on the OG object
		if (!product.hasOwnProperty("launchDayDeliveryMembersExclusive")) {
			product.launchDayDeliveryMembersExclusive = false;
		}

		//Make subTotal null to override old value in redux if its missing on the OG object
		if (!product.hasOwnProperty("subTitle")) {
			product.subTitle = null;
		}

		//Make paymentGateways ['Adyen'] to override old value in redux if its missing on the OG object
		if (!product.hasOwnProperty("paymentGateways")) {
			product.paymentGateways = ['Adyen'];
		}

		//Make paymentGateways ['Adyen'] to override old value in redux if its missing on the OG object
		if (!product.hasOwnProperty("isPremium")) {
			product.isPremium = false;
		}
		return product;
	}
};

export function getRaffleEntriesByProduct(sku, userID) {
	return http.get(`/user/${userID}/preauth/product/${sku}`, { hawkEnabled: false, sendSession: true }, 0);
}

export function getLoyalty(userID) {
	return http.get(`/users/${userID}/loyalty`, { hawkEnabled: false, sendSession: true }, 0);
}

export function getMarketing(userID) {
	return http.get(`/users/${userID}/marketingPreferences`, { hawkEnabled: false, sendSession: true }, 0);
}

export function withdrawPreauth(userID, orderID) {
	return http.delete(`/user/${userID}/preauth/${orderID}`, { hawkEnabled: false }, 0);
}

export function getCanReview(userID) {
	return http.get(`/user/${userID}/review`, { hawkEnabled: false, sendSession: true }, 0);
}

export function getStock(productID) {
	return http.get(`/products/${productID}/options`, { hawkEnabled: false }, 0);
}

export function getProductByID(productID) {
	return http.get(`/products/${productID}/details`, { hawkEnabled: false }, 0);
}

export function addToBasket({ productID, optionID, verification, session }) {

	let addToBasketResponse,
		userData = getUserData(),
		userID;

	if (userData && userData.userID) userID = userData.userID;

	if (session && session.ID) {
		addToBasketResponse = http.put(`/sessions/${session.ID}`, {
			productID,
			optionID,
			verification
		}, { sendSession: true, query: { userID } }, 0);
	} else {
		addToBasketResponse = http.post('/sessions', {
			productID,
			optionID,
			verification
		}, { sendSession: true, query: { userID } });
	}

	return addToBasketResponse.then((data) => {
		setSession(data.sessionID);
		return data;
	});
}


export function getLocales() {
	return http.get('/locales', { hawkEnabled: false });
}

export function deleteUser(userID) {
	return http.delete(`/users/${userID}`, { hawkEnabled: true });
}

export function getDeliveryMethods(locale = "gb") {
	return http.get('/deliverymethods', { query: { locale }, hawkEnabled: false });
}

export function getProducts() {
	var deferred = new Promise(function  (resolve, reject) {
		getHomeContent()
			.then((data) => {
				resolve(data.products);
			})
	});
	return deferred;
};

export function getStores() {
	return http.get(`/storelocations`, { hawkEnabled: false });
}

export function getSession(id) {
	return http.get(`/sessions/${id}`, undefined, 1)
		.catch((error) => {
			switch (error.response.status) {
				case 404:
				case 410:
					deleteSession();
					break;
				default:
			}
			return Promise.reject();
		})
}

export function createOrder(checkoutState, sessionID) {
	let userData = getUserData(),
		userID;
	let order = extractOrderFromState(checkoutState);
	if (userData && userData.userID) userID = userData.userID;

	return http.put(`/sessions/${sessionID}/order`, order, { query: { userID }, sendSession: true });
}

export function createPreOrder(checkoutState, verification, answer, product) {
	let order = extractOrderFromState(checkoutState);
	let googleClientID = storage.getItemNoExpiry("gaClientID");
	// quantity of enteries defaults to 1 if product is not passed in
	let quantityOfEntries = 1;
	if(product) {
		// quantityOfEnteries is only a property of charity raffles
		quantityOfEntries = product.quantityOfEntries || 1;
	}
	
	let payload = Object.assign({}, order, { verification: verification, quantityOfEntries: quantityOfEntries });

	if (googleClientID) {
		payload = Object.assign({}, payload, { googleClientID: googleClientID });
	}

	if (answer) {
		payload = Object.assign({}, payload, { answer: answer });
	}

	if (localStorage.getItem('isBoosted')) {
		payload = Object.assign({}, payload, { boost: true });
	}
	let userID = payload.userID;
	delete payload.userID;

	return http.post(`/preAuthorise/order`, payload, { query: { userID }, sendSession: true });
}

export function compleatPreAuth3DS(preOrderID) {
	return http.put(`/preAuthorise/${preOrderID}/payment/3dsecure`, {});
}

export function sendEncryptedPaymentData(sessionID, data, preOrderID) {
	let payload = {
		encryptedData: data
	};
	if (preOrderID) {
		return http.put(`/preAuthorise/payment/${preOrderID}`, payload, { query: { type: 'CARD' } }, 0);
	} else {
		return http.put(`/sessions/${sessionID}/payment`, payload, { query: { type: 'CARD' } }, 0);
	}

}

export function startKlarnaSession(sessionID, preOrderID) {
	if (preOrderID) {
		return http.put(`/preAuthorise/payment/${preOrderID}`, { encryptedData: true }, { query: { type: 'KLARNA' } }, 0);
	} else {
		return http.put(`/sessions/${sessionID}/payment`, { encryptedData: true }, { query: { type: 'KLARNA' } }, 0);
	}
}

export function sendKlarnaPaymentData(sessionID, preOrderID, authToken) {
	if (preOrderID) {
		return http.put(`/preAuthorise/${preOrderID}/payment/klarna`, { authorizationToken: authToken }, {}, 0);
	} else {
		return http.put(`/sessions/${sessionID}/payment/klarna`, { authorizationToken: authToken }, {}, 0);
	}
}

export function sendRecurringPayment(sessionID, userID, data, preOrderID) {
	let payload = {
		user: userID,
		encryptedData: data
	};
	// console.log("🚀 ~ file: api.js:262 ~ sendRecurringPayment ~ payload:", payload)
	if (preOrderID) {
		return http.put(`/preAuthorise/payment/${preOrderID}`, payload, { query: { type: 'RECURRING' } }, 0);
	} else {
		return http.put(`/sessions/${sessionID}/payment`, payload, { query: { type: 'RECURRING' } }, 0);
	}

}

export function disablePaymentDetails(userID) {
	return http.delete(`/users/${userID}/card`, { sendSession: true }, 0);
}

export function removeItemByStockID(sessionID, stockID) {
	return http.delete(`/sessions/${sessionID}/stock/${stockID}`, undefined, 0)
		.catch((error) => {
			switch (error.response.status) {
				case 404:
				case 410:
					deleteSession();
					break;
				default:
			}
			return Promise.reject();
		});
}

export function sendEncryptedToken(sessionID, data) {
	let payload = {
		token: data
	};
	return http.put(`/sessions/${sessionID}/payment`, payload, { query: { type: 'APPLE_PAY' } }, 0);
}

// save a users card preferences
export function saveEncryptedCardData(userID, data) {
	let payload = {
		encryptedData: data
	};
	return http.post(`/users/${userID}/card`, payload, { sendSession: true }, 0);
}

// get a users saved cards
export function getSavedCards(userID) {
	return http.get(`/users/${userID}/card`, { sendSession: true }, 0);
}

/**
 * Call to mosaic to log the user in
 * @param {{username: string, password: string}} formData login data for the user
 * @returns {q}
 */
export function login(formData, verification) {
	let payload = Object.assign({}, formData, { verification: verification });
	return http.post('/users/login', payload, undefined, 0);
};

/**
 * Call to mosaic to log the user in
 * @param {{username: string, password: string}} formData login data for the user
 * @returns {q}
 */
export function loginFromSession(sessionID) {
	return http.post('/users/session', { sessionID }, undefined, 0);
};

/**
 * Call to mosaic to log the user in
 * @param {string} oauthToken login data for the user
 * @returns {q}
 */
 export function loginFromOauth(oauthToken) {
	return http.post('/users/oauth', { oauthToken }, undefined, 0);
};

/**
 * Creates a new MESH and Mosaic account
 * @param {{email: string, password: string, firstName: string lastName: string}} formData signup data for the user
 * @returns {q}
 */
export function createAccount(formData, verification) {
	let payload = Object.assign({}, formData);
	payload.email = payload.username;
	payload.verification = verification;
	delete payload.password2;
	delete payload.username;
	return http.post('/users/signup', payload, undefined, 0);
};

/**
 *
 * @param {*} payload
 */
export function registerToken(payload) {
	return http.post('/social/tokens', payload, undefined, 0);
}

// Update marketing preferences
export function updateMarketingPreferences(userID, payload) {
	return http.put(`/users/${userID}/marketingPreferences`, payload, undefined, 0);
}

export function performSearch(term, page) {
	return http.get(`/products/search`, { query: { query: term, page } }, 0);
}

// Update activate loyalty
export function activateLoyaltyInMesh(userID, payload) {
	return http.post(`/users/${userID}/loyalty`, payload, { sendSession: true }, 0);
}