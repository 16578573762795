import React, {PropTypes} from 'react';
import "../../../node_modules/react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from 'react-image-gallery';
import "./ProductGallery.scss";


const ProductGallery = React.createClass({

	getInitialState(){
		return {
			autoPlay: false,
			slideInterval:6000,
			showPlayButton:false,
			showThumbnails:false,
			showFullscreenButton: false,
            showBullets: true,
            showNav: false,
			infinite: false
		}
	},

	render(){
		return (
			<ImageGallery
                autoPlay={this.state.autoPlay}
                items={this.props.images}
                slideInterval={this.state.slideInterval}
                showPlayButton={this.state.showPlayButton}
                showThumbnails={this.state.showThumbnails}
                showBullets={this.state.showBullets}
                showNav={this.state.showNav}
                onSlide={this.stopAutoplay}
                showFullscreenButton={this.state.showFullscreenButton}
                infinite={this.state.infinite}
            />
		)
	}
})

ProductGallery.propTypes = {
	images: PropTypes.array
}

export default ProductGallery;