import React from 'react';
import loader from '../../assets/img/loading/spinner.gif';
import './Loader.scss';

const Loader = ({loading, children, className}) => (
	<div className={`loaderWrapper ${className}`} style={loading.loading || loading.loading3DS ? {display: 'block'} : {display: 'none'}}>
		<div className="loader">
			<img src={loader} alt="loader"/>
			<h5>Loading...</h5>
			{ loading.message ? <h5>{loading.message}</h5> : null }
		</div>
	</div>
)

export default Loader;