/**
 * Header presentational component
 *
 * This is a presentational component which wraps the header bar shown on each page.
 *
 * Receives props from the container component in containers/HeaderContainer
 *
 */
import React from 'react';
import MiniCart from '../miniCart/MiniCart';
import { hashHistory } from 'react-router';
import './Header.scss';
import BackButton from '../backButton/BackButton';
import homeIcon from '../../assets/img/nav/icn-home.png';
import searchIcon from '../../assets/img/nav/icn-search.svg';

import accountIcon from '../../assets/img/nav/icn-account.png';
import accountPremium from '../../assets/img/nav/membership/account.svg';
import accountPremiumPlus from '../../assets/img/nav/membership/account-plus.svg';

import logoDark from '../../assets/img/logo-small.png';

import UspBanner from '../uspBanner/UspBanner';
import Countdown from '../countdownTimer/Countdown';

let styles = {};

const Header = React.createClass({
	componentDidMount() {
		// safari header fix
		// real pain but the header does not stay fixed when the keyboard is up on safari
		// so we can set it to absolute when the keyboard is focused to move it to the top of the screen
		//if ('ontouchstart' in document.documentElement) {
		//	document.addEventListener("focus", () => {
		//		styles = Object.assign({}, styles, { position: 'absolute' });
		//		this.forceUpdate()
		//	}, true);
		//	document.addEventListener("blur", () => {
		//		styles = Object.assign({}, styles, { position: 'fixed' });
		//		this.forceUpdate()
		//	}, true);
		//}
	},
	getLogo(logo) {
		if (logo === false) {
			return null
		}
		if (logo === true) {
			return logoDark
		} else {
			return logo
		}
	},
	render() {
		let { session, content, header, checkout, toggleMiniCart, getSession, clearSession, appHeader, solid, showTimeout, showLogo, showBackButton, backAlign, showHomeButton, showPreferencesButton, hideMiniCart, preferences, showSearch } = this.props;
		let showMiniCart = session.ID && !hideMiniCart && (showPreferencesButton || showHomeButton);

		let accountButtonType = "basic"
		let activeAccountIcon = accountIcon;

		if (preferences.loyalty && preferences.loyalty.isPremium) {
			if (preferences.loyalty.isBoostWeek) {
				accountButtonType = "plus";
				activeAccountIcon = accountPremiumPlus;
			} else {
				accountButtonType = "member";
				activeAccountIcon = accountPremium;
			}
		}

		if (content.theme.headerColour) styles = Object.assign({}, styles, { backgroundColor: content.theme.headerColour });
		return (
			<header className={`header ${appHeader ? 'app-header' : ''} ${solid ? 'solid' : ''}`} style={styles}>
				{showBackButton ? <BackButton className={"back " + backAlign} /> : null}
				{showTimeout && session.expiryDate ? <UspBanner message="">
					<Countdown end={session.expiryDate} showAllUnits={false} countdownEnded={clearSession} pause={session.pause} />
				</UspBanner> : null}
				{
					showPreferencesButton
						? <button onClick={() => hashHistory.push('/preferences')} className="logo">
							<img className={`icon icon-account ${accountButtonType}`} alt="account button" src={activeAccountIcon} />
							{content.theme ? <img src={content.theme.logo} alt="logo" /> : null}
						</button>
						: null
				}
				{
					showHomeButton
						? <button onClick={() => hashHistory.push('/')} className={`logo ${showPreferencesButton ? 'right' : null}`}>
							<img className="icon icon-home" alt="home button" src={homeIcon} />
							{content.theme ? <img src={content.theme.logo} alt="logo" /> : null}
						</button>
						: null
				}
				{
					showLogo
						?
						<img className={`app-logo ${showTimeout && session.expiryDate ? 'with-countdown' : ''} ${showMiniCart ? 'with-minicart' : ''}`} alt="logo" src={this.getLogo(showLogo)} />
						: null
				}
				{
					/* use preferences and home to hide minicart - hide if neither are showing */
					showMiniCart & !showSearch
						? <MiniCart className="miniCart"
							subtotal={session.subTotal}
							delivery={checkout.deliveryMethod}
							total={session.total}
							products={content.products}
							stock={session.stock}
							show={header.showMiniCart}
							toggleMiniCart={toggleMiniCart}
							loading={header.loading}
							expiryDate={session.expiryDate}
							serverDate={session.serverDate}
							sessionID={session.ID}
							getSession={getSession}
							clearSession={clearSession}
							theme={content.theme}
						/>
						: null
				}
				{
					// Show the search only if were not showing the minicart
					showSearch & !showMiniCart ?
						<button onClick={() => hashHistory.push('/search')} className={`search logo`}>
							<img className="icon icon-search" alt="search button" src={searchIcon} />
						</button>
						: null
				}
			</header >
		)
	}

});

export default Header;
