// Container component for the Home page
import { connect } from 'react-redux';
import Content from '../components/content/Content';
import { loadContent, setIndex, trackScroll } from '../actions/content';

/**
 * Maps the state from the application state to props for the Content and
 * Bookmark components
 * @param {Object} state
 * @return {Object} home page state
 */
const mapStateToProps = (state) => {
	const { bookmarks, calendar, content, loading } = state;
	return { bookmarks, calendar, content, loading };
};

/**
 * Maps the bookmark actions to props for the Bookmark component
 * @param {function} dispatch
 * @return {Object} home page props
 */
const mapDispatchToProps = (dispatch) => {
	return {
		setIndex: (index, invert, isCampaignSlide, pageHeaderLogo, pageTransperentHeader) => dispatch(setIndex(index, invert, isCampaignSlide, pageHeaderLogo, pageTransperentHeader)),
		trackScroll: (scrollTop) => dispatch(trackScroll(scrollTop)),
		loadContent: () => dispatch(loadContent())
	}
};

// map state and dispatch methods to props for home page
const HomePage = connect(
	mapStateToProps,
	mapDispatchToProps
)(Content);

export default HomePage;
