import React from 'react';
import { connect } from 'react-redux';
import contentActions from '../../../../services/contentService/actions'


const FeatureItem = React.createClass({
    render() {
        let { image, title, subTitle, action, handelAction } = this.props
        return (<div className="swiper-slide">
            <div className="background-image" style={{ backgroundImage: `url(${image})` }} onClick={() => handelAction(action)}>
                <div className="content">
                    <div className="footer">
                        <div className="title">{title}</div>
                        <div className="subTitle">{subTitle}</div>
                    </div>
                </div>
            </div>
        </div>)
    }
}
);

const mapStateToProps = (state) => {
    return {}
};

const mapDispatchToProps = (dispatch) => {
    return {
        handelAction: (action) => contentActions(dispatch, action),
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FeatureItem);