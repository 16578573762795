import { postMessage } from '../nativeBridge';
import { APPSFLYER_EVENT } from '../nativeBridge/messageTypes';

/**
 * Tracks an apps flyer event 
 * @param {string} eventName
 * @param {object} event
 */
export default (eventName, event) => {
	let message = {
		messageType: APPSFLYER_EVENT,
		data: {
			eventName,
			eventParams: event
		}
	};
	postMessage(message);
};
