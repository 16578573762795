import appsflyer from '../appsflyerEvents/track'
import { getBookmarks } from '../bookmarks/bookmarks';
import { EVTS } from './analytics'

export function trackEvent(category, action, opt_label, opt_value, additions = null, params = null) {
	switch (action) {
		case EVTS.ACT.LOGIN:
			appsflyer('af_login', {});
			break;
		case EVTS.ACT.SIGNUP:
			appsflyer('af_complete_registration', {});
			break;
		case EVTS.ACT.BOOKMARK_ADD:
			let bookmarks = getBookmarks().map((id) => window.store.getState().content.products.find(product => product.ID === id))
			let product = bookmarks.slice(-1)[0]
			appsflyer('af_add_to_wishlist', {
				af_price: product.price.amount,
				af_content_type: product.gender,
				af_content_id: product.ID,
				af_content: product.name,
				af_currency: product.price.currency,
				af_quantity: 1
			})
			break;
		case EVTS.ACT.PAYMENT_DETAILS_SAVE:
			appsflyer('af_add_payment_info', {
				af_success: true,
			})
			break;
		case EVTS.ACT.CHECKOUT_INIT:
			appsflyer('af_initiated_checkout', {
				af_price: opt_value.product.price.amount,
				af_content_id: opt_value.product.PLU,
				af_content_type: 'Trainer',
				af_currency: opt_value.product.price.currency,
				af_quantity: 1
			})
			break;
		default:
			break;

	}
}

export function trackProductAction(action, product) {
	if (action.appsflyer) {
		appsflyer(action.appsflyer, {
			af_price: product.price.amount,
			af_content_id: product.ID,
			af_currency: product.price.currency,
			af_quantity: 1
		})
	}
}

/**
 *
 * @param {*} order
 */
export function trackOrder(order) {
	appsflyer('af_purchase', {
		af_revenue: order.total.amount,
		af_currency: order.total.currency,
		af_quantity: 1,
		af_order_id: order.sessionID,
		af_price: order.subTotal.amount,
		af_purchase_currency: order.total.currency,
	})
}

/**
 *
 * @param {*} order
 */
export function trackPreAuth(product, order) {
	appsflyer('af_purchase', {
		af_revenue: order.total.amount,
		af_currency: order.total.currency,
		af_quantity: 1,
		af_order_id: order.orderID,
		af_price: order.subTotal.amount,
		af_purchase_currency: order.total.currency,
	})
}