import { clearSizePreference } from '../product/sizePreference';
import storage from '../storage/storage';
import { notifyLogout } from '../userActions/logout';


const USER_STORAGE_KEY = 'user';


//////////////////


/**
 * Clears all logged in user data (userID, preferences - except notifications)
 */
export function clearUserData() {
	let userData = getUserData();
	notifyLogout(userData.userID);
	let loggedOutUserData = {
		preferences: {}
	}
	

	if (userData.preferences){
		loggedOutUserData = {
			preferences: {
				notifications: userData.preferences.notifications
			}
		};
	}
	
	setUserData(loggedOutUserData);
	clearSizePreference();
	storage.removeUserID();
};

/**
 * Get the full user data object, or a specific key
 * @param {string} [key] a key for a specific set of userData - can be a dot key
 * @returns {Object|any} userData object, or a specific property
 */
export function getUserData(key) {
	let userData = JSON.parse(storage.getItemNoExpiry(USER_STORAGE_KEY));
	if (key) {
		return key.split('.').reduce((obj, key) => obj ? obj[key] : undefined, userData);
	} else {
		return userData || {};
	}
};

/**
 * Sets the whole userData object in localStorage
 * @param {Object} userData
 */
export function setUserData(userData) {
	// save userID as own key so that we can use in cache key
	storage.setUserID(userData.userID);

	// store user data
	storage.setItemNoExpiry(USER_STORAGE_KEY, JSON.stringify(userData));
};
