import { hashHistory } from 'react-router';
import { updateUserPreferences, activateLoyalty } from '../../../actions/preferences';
import { connect } from 'react-redux';
import React from 'react';
import logo from './logo.svg'
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem'
import './../marketing/Marketing.scss'
import TextField from '../../textInput/TextInput';
import { navigateToExternal } from '../../../services/navigate/navigate';
import config from '../../../services/config/config';

const preferencesKey = 'marketing';

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
const brands = ['Nike', 'Adidas', 'Jordans', 'Reebok', 'New Balance', 'Clarks Original', 'Puma', 'Fila'];
const shoeSizes = ['3', '3.5', '4', '4.5', '5', '5.5', '6', '6.5', '7', '7.5', '8', '8.5', '9', '9.5', '10', '10.5', '11', '11.5', '12', '12.5', '13', '13.5', '14'];

const genders = ['male', 'female', 'other', 'prefer not to say']

const MarketingPreferences = React.createClass({
    getInitialState: () => ({
        preferences: {
            optedIn: false,
            birthday: {
                day: '',
                month: '',
                year: ''
            }
        },
        phone: '',
        isPhoneValid: undefined
    }),
    updateValidationStates(isFieldValid, fieldName) {
        // Validation state of individual fields
        if (this.state[fieldName] !== isFieldValid) {
            const stateUpdate = {};
            stateUpdate[fieldName] = isFieldValid;
            this.setState(stateUpdate);
        } else {

        }

    },
    onCancel() {
        this.backToDashboard();
    },
    backToDashboard() {
        hashHistory.push('/preferences/membership');
    },
    updatePreferences(newPreferences) {
        let preferences = { ...this.state.preferences }
        Object.assign(preferences, newPreferences)
        this.setState({ preferences: preferences });
    },
    updatePreference(property, val) {
        let newPreferences = {}
        newPreferences[property] = val
        this.updatePreferences(newPreferences)
    },
    updatePersonalPreference(property, val) {
        let pref = { ...this.props.customer, 'phone': val }
        this.props.savePreferences('personal', pref);
    },
    componentWillMount() {
        let { preferences, customer } = this.props;
        let mergedPreferences = Object.assign({}, this.state.preferences, preferences)
        this.setState({ preferences: mergedPreferences, phone: customer.phone });
    },
    onSave() {
        let preferences = this.state.preferences;
        this.props.savePreferences(preferencesKey, preferences);
        this.props.activateLoyalty(preferences)
        this.backToDashboard();
    },
    updateBrand(evt, index, val, property) {
        this.updatePreference('favoriteBrand', val);
    },
    updateGender(evt, index, val) {
        this.updatePreference('gender', val);
    },

    updatePhone(evt, index, val) {
        this.setState({ phone: val });
        let _this = this
        setTimeout(function () {
            if (_this.state.isPhoneValid) {
                _this.updatePersonalPreference('phone', val);
            }
        }, 100);

    },

    updateShoeSize(evt, index, val) {
        this.updatePreference('shoeSize', val);
    },
    updateDay(evt, index, val) {
        let birthday = {
            ...this.state.preferences.birthday,
            day: val
        }
        this.updatePreference('birthday', birthday);
    },
    updateMonth(evt, index, val) {
        let birthday = {
            ...this.state.preferences.birthday,
            month: index + 1
        }
        this.updatePreference('birthday', birthday);
    },
    updateYear(evt, index, val) {
        let birthday = {
            ...this.state.preferences.birthday,
            year: val
        }
        this.updatePreference('birthday', birthday);
    },
    updateCheckbox() {
        this.updatePreference('optedIn', !this.state.preferences.optedIn);
    },
    componentDidUpdate() {
        if (this.props.onFormChange) {
            this.props.onFormChange(this.state);
        }

        if (this.props.isPageValid) {
            this.props.isPageValid(this.isFull());
        }
    },

    isFull() {
        let { birthday } = this.state.preferences;
        let { isPhoneValid } = this.state;

        let dateInvalid = !birthday || !birthday.year || !birthday.day || !birthday.month;
        return this.state.preferences.gender && this.state.preferences.favoriteBrand && this.state.preferences.shoeSize && !dateInvalid && isPhoneValid;
    },
    componentDidMount() {
        if (this.props.isPageValid) {
            this.props.isPageValid(this.isFull());
        }
    },
    render() {
        const handleSubmit = e => e.preventDefault();

        let years = [];
        var now = new Date();
        var yearsAgo = now.getFullYear() - 18;

        let days = [];
        let chosenYear = this.state.preferences.birthday.year || yearsAgo;
        let chosenMonth = this.state.preferences.birthday.month || 1;
        let totalDays = new Date(chosenYear, chosenMonth, 0).getDate();

        if (this.state.preferences.birthday && this.state.preferences.birthday.day > totalDays) {
            this.updateDay(undefined, undefined, undefined)
        }

        for (let i = 1; i <= totalDays; i++) {
            days.push(<MenuItem key={i} value={i} primaryText={i} />);
        }

        for (let i = yearsAgo; i >= 1920; i--) {
            years.push(<MenuItem key={i} value={i} primaryText={i} />);
        }

        let { birthday } = this.state.preferences;
        let dateInvalid = !birthday || !birthday.year || !birthday.day || !birthday.month;

        let { phone } = this.state;

        let full = this.isFull()
        return (
            <div className="Preferences Marketing-Preferences Size-Accses-Signup">
                <div className="content-scroll">
                    <img alt="logo" src={logo} />
                    <hr />
                    <div className="form-horizontal">
                        <p className="intro">
                            Would you like to receive marketing communications?
                        </p>
                        <div className="form-group check-option useBilling">
                            <input type="checkbox" id="useBilling" checked={this.state.preferences.optedIn} onChange={this.updateCheckbox} />
                            <label htmlFor="useBilling">
                                <span className="check-box tick"></span>
                                <span className="name">Yes, sign me up!</span>
                            </label>
                        </div>
                    </div>
                    <form className="form-horizontal dropdowns" onSubmit={handleSubmit}>
                        <div className='form-group telephone'>
                            <TextField
                                type="tel"
                                floatingLabelText="Mobile Number"
                                value={phone}
                                onChange={evt => this.updatePhone(null, null, evt.target.value)}
                                hintText="Please enter your mobile number"
                                required={true}
                                autoWidth={true}
                                name="phone"
                                isFieldValid={isValid => this.updateValidationStates(isValid, 'isPhoneValid')}
                            />
                        </div>
                        <div className="gender-input">
                            <SelectField
                                required={true}
                                onChange={(evt, index, val) => this.updateGender(evt, index, val)}
                                value={this.state.preferences.gender}
                                underlineStyle={{ borderColor: this.state.preferences.gender ? '#000000' : '#C91515', borderWidth: '2px' }}
                                iconStyle={{ fill: '#000000' }}
                                labelStyle={{ color: '#000000' }}
                                autoWidth={true}
                                floatingLabelText="Gender"
                            >
                                {genders.map((val, index) => {
                                    return (<MenuItem key={index} value={val} primaryText={val.charAt(0).toUpperCase() + val.slice(1)} />)
                                }
                                )}
                            </SelectField>
                        </div>
                        <div>
                            <SelectField
                                required={true}
                                value={this.state.preferences.favoriteBrand}
                                onChange={(evt, two, val) => this.updateBrand(evt, two, val)}
                                underlineStyle={{ borderColor: this.state.preferences.favoriteBrand ? '#000000' : '#C91515', borderWidth: '2px' }}
                                iconStyle={{ fill: '#000000' }}
                                labelStyle={{ color: '#000000' }}
                                autoWidth={true}
                                floatingLabelText="What is your favorite brand?"
                            >
                                {brands.map((val, index) => {
                                    return (<MenuItem key={index} value={val} primaryText={val} />)
                                }
                                )}
                            </SelectField>
                        </div>
                        <div>
                            <SelectField
                                required={true}
                                onChange={(evt, index, val) => this.updateShoeSize(evt, index, val)}
                                value={this.state.preferences.shoeSize}
                                underlineStyle={{ borderColor: this.state.preferences.shoeSize ? '#000000' : '#C91515', borderWidth: '2px' }}
                                iconStyle={{ fill: '#000000' }}
                                labelStyle={{ color: '#000000' }}
                                autoWidth={true}
                                floatingLabelText="What is your shoe size?"

                            >
                                {
                                    shoeSizes.map((val, index) => {
                                        return (<MenuItem key={index} value={val} primaryText={val} />)
                                    }
                                    )
                                }
                            </SelectField>
                        </div>
                        <br />
                        <div>
                            <p>Date of birth</p>
                            <div className="dateInput">
                                <span className={this.state.preferences.birthday.day ? 'active' : ''}>
                                    <SelectField
                                        onChange={(evt, index, val) => this.updateDay(evt, index, val)}
                                        style={{ width: "65px" }}
                                        value={this.state.preferences.birthday.day}
                                        underlineStyle={{ borderColor: dateInvalid && !this.state.preferences.birthday.day ? '#C91515' : '#000000', borderWidth: '2px' }}
                                        iconStyle={{ fill: '#000000' }}
                                        labelStyle={{ color: '#000000' }}
                                        autoWidth={true}
                                        floatingLabelText="31"
                                    >
                                        {days}
                                    </SelectField>
                                </span>
                                <span className={`mounth ${this.state.preferences.birthday.month ? 'active' : ''}`}>
                                    <SelectField
                                        onChange={(evt, index, val) => this.updateMonth(evt, index, val)}
                                        style={{ width: "100%" }}
                                        value={this.state.preferences.birthday.month}
                                        underlineStyle={{ borderColor: dateInvalid && !this.state.preferences.birthday.month ? '#C91515' : '#000000', borderWidth: '2px' }}
                                        iconStyle={{ fill: '#000000' }}
                                        labelStyle={{ color: '#000000' }}
                                        autoWidth={true}
                                        floatingLabelText="January"
                                    >
                                        {months.map((val, index) => {
                                            return (<MenuItem key={index} value={+index + 1} primaryText={val} />)
                                        })}
                                    </SelectField>
                                </span>
                                <span className={this.state.preferences.birthday.year ? 'active' : ''}>
                                    <SelectField
                                        onChange={(evt, index, val) => this.updateYear(evt, index, val)}
                                        style={{ width: "85px" }}
                                        value={this.state.preferences.birthday.year}
                                        underlineStyle={{ borderColor: dateInvalid && !this.state.preferences.birthday.year ? '#C91515' : '#000000', borderWidth: '2px' }}
                                        iconStyle={{ fill: '#000000' }}
                                        labelStyle={{ color: '#000000' }}
                                        autoWidth={true}
                                        floatingLabelText="2000"
                                    >
                                        {years}
                                    </SelectField>
                                </span>
                            </div>
                        </div>
                        <br />
                        <div>
                            <p>
                                By creating an account, you agree to comply with our terms and conditions. We will use your information in accordance with our <span className='link' onClick={() => navigateToExternal(`${config.storeUrl}customer-service/privacy/`)}>Privacy Policy</span>.
                            </p>
                        </div>
                        <br />
                        {this.props.hideButtons ? null :
                            <div className="form-action fixed">
                                <button disabled={!full} onClick={this.onSave} className="action" >
                                    <div className="button-contents">Sign Up</div>
                                </button>
                            </div>
                        }
                    </form>

                </div>
            </div >
        )
    }
});

const mapStateToProps = state => {
    const { user } = state;
    return { preferences: user.preferences.marketing, customer: user.preferences.personal };
};

const mapDispatchToProps = dispatch => {
    return {
        savePreferences: (key, data) => dispatch(updateUserPreferences(key, data)),
        activateLoyalty: (data) => dispatch(activateLoyalty(data)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MarketingPreferences);

