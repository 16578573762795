import { hashHistory } from 'react-router';
import { loadRaffleEntries, withdraw } from '../../../../actions/content';
import { connect } from 'react-redux';
import React from 'react';
import TitleBlock from '../../../titleBlock/TitleBlock';
import './PreOrderWithdraw.scss';
import PreOrder from '../PreOrder'
import { trackPage } from '../../../../services/analytics/analytics';

//////////////////


const Withdraw = React.createClass({
	componentWillMount() {
		this.props.loadRaffleEntries();
		trackPage('preferences/preOrders/withdraw');
	},
	backToDashboard() {
		hashHistory.goBack();
	},
	render() {
		let preorder = this.props.orders.find(order => order.id === this.props.params.id)
		return (
			<div className="Preferences PreOrders Withdraw">
				<div className="content-scroll">
					<TitleBlock title="Cancel Entry" />
					<div className="form-horizontal">
						<p className="intro">
							You are about to cancel your entry - are you SURE?
						</p>
						<ul>
							{preorder ? <PreOrder withdraw={() => { this.props.withdraw(this.props.params.id) }} cancel={true} PreOrder={preorder} /> : null}
						</ul>
					</div>
				</div>
			</div>
		);
	}
});

const mapStateToProps = state => {
	const { content } = state;
	return { orders: content.orders };
};

const mapDispatchToProps = dispatch => {
	return {
		loadRaffleEntries: () => dispatch(loadRaffleEntries()),
		withdraw: (id) => dispatch(withdraw(id))
	};
};


//////////////////


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Withdraw);
