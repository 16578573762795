import SidebarItem from './SidebarItem';

import React from 'react';

import './Sidebar.scss';

import config from '../../services/config/config'

//////////////////


const Sidebar = React.createClass({
	render() {
		let { items, expand, toggleExpand, invert } = this.props;
		let position = config.layout.nav.position;
		if(position === 'none'){
			return <div/>
		}
		const elements = []
		let entryData = [...items.entries()];
		let maxElements = window.innerHeight > 570 ? 5 : 4;
		if (position === 'bottom') {
			//For bottom navs we show less stuff and have it inverse
			maxElements = window.innerWidth > 350 ? 5 : 4;
			//Because on bottom nav, the arrow to show more is quite big,
			//We need remove 1 item from the nav to acomidate it (if needed)
			if(entryData.length > maxElements){
				maxElements--;
			}
			entryData = entryData.reverse();
		}
		let extraElements = entryData.length - maxElements;

		for (const [i, item] of entryData) {
			elements.push(<SidebarItem
				key={item.label || i}
				row={extraElements > 0 && extraElements > i ? '2' : '1'}
				label={item.label}
				active={item.active}
				itemType={item.itemType || "Standard"}
				clickHandler={item.clickHandler}
				image={item.image}
				path={item.path}>
			</SidebarItem>)
		}
		return (
			<div className={`sidebar ${position} ${invert ? 'invert' : ''}  ${expand ? 'expand' : 'contract'}`}>
				<div className="backdrop" onClick={(e) => toggleExpand(e)} />
				<div className="sidebarElements">
					<div className="row-1-holder">
						{elements.length > maxElements ?
							<div className="more-wrapper left" onClick={(e) => toggleExpand(e)}>
								<div className={`more ${expand ? 'contract' : 'expand'}`} />
							</div>
							: null
						}
						{elements}
					</div>
					<div className="row-2-holder">
						{elements}
						{elements.length > maxElements ?
							<div className="more-wrapper bottom" onClick={(e) => toggleExpand(e)}>
								<div className={`more ${expand ? 'contract' : 'expand'}`} />
							</div>
							: null
						}
					</div>
				</div>
				<div>
				</div>
			</div>)
	}
});


//////////////////


export default Sidebar;
