import { postMessage } from '../nativeBridge';
import { NAVIGATE_TO_EXTERNAL } from '../nativeBridge/messageTypes';

/**
 * Navigate to an external page
 * @param {string} url full URL of the external page
 */
export const navigateToExternal = (url) => {
	let message = {
		messageType: NAVIGATE_TO_EXTERNAL,
		data: {
			url
		}
	};
	postMessage(message);
};
