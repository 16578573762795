import { getBookmarks } from '../bookmarks/bookmarks';
import config from '../config/config';
import { EVTS } from './analytics'
import moment from 'moment';
import { canEnterProduct } from '../product/product';
import storage from '../storage/storage';

const base = {
	site: config.redeye ? config.redeye.site : "",
	currency: config.redeye ? config.redeye.currency : ""
}

export function trackProductAction(action, product) {
	let payload = {}
	switch (action) {
		case EVTS.ACT.PRODUCT_VIEW:
			if (product.launchType === 'raffle') {
				payload = Object.assign({}, base, {
					nourl: convertViewTitle(product.name),
					view_raff_event: "view-raffle-event",
					view_raff_name: product.name,
					view_raff_brand: product.brand,
					view_raff_gender: product.gender,
					view_raff_val: fomatPriceForRedeye(product.price.amount),
					view_raff_image: removeHttps(product.mainImage.original),
					view_raff_sku: product.ID,
					view_raff_app: "Y",
					view_raff_live: canEnterProduct(product) ? 'Y' : 'N',
					view_raff_start: formatDateForRedeye(product.launchStartDate || product.launchDate),
					view_raff_close: formatDateForRedeye(product.launchStartDate ? product.launchDate : null),
					email: window.store.getState().user.preferences.personal.email,
				});
			} else {
				payload = Object.assign({}, base, {
					nourl: convertViewTitle(product.name),
					view_launchprod_event: "view-product-event",
					view_launchprod_name: product.name,
					view_launchprod_brand: product.brand,
					view_launchprod_gender: product.gender,
					view_launchprod_val: fomatPriceForRedeye(product.price.amount),
					view_launchprod_image: removeHttps(product.mainImage.original),
					view_launchprod_sku: product.ID,
					view_launchprod_app: "Y",
					view_launchprod_live: canEnterProduct(product) ? 'Y' : 'N',
					view_launchprod_launch: formatDateForRedeye(product.launchDate),
					email: window.store.getState().user.preferences.personal.email,
				});
			}
			break;
		case EVTS.ACT.PRODUCT_ADD:
			let option = product.options.options.find(option => option.selected)
			payload = Object.assign({}, base, {
				nourl: "launch-app-basket",
				bask_launch_event: "launch-app-basket",
				bask_launchprod_name1: product.name,
				bask_launchprod_brand1: product.brand,
				bask_launchprod_gender1: product.gender,
				bask_launchprod_each_val1: fomatPriceForRedeye(product.price.amount),
				bask_launchprod_qnt1: 1,
				bask_launchprod_val1: fomatPriceForRedeye(product.price.amount),
				bask_launchprod_launch1: formatDateForRedeye(product.launchStartDate ? product.launchDate : null),
				bask_launchprod_image1: removeHttps(product.mainImage.original),
				bask_sku1: product.PLU,
				bask_launchprod_size1: option.name,
				bask_launch_items: 1,
				bask_launch_val: fomatPriceForRedeye(product.price.amount),
				bask_launchprod_app: 'Y',
				email: window.store.getState().user.preferences.personal.email,
			});
			break;
		case EVTS.ACT.PRODUCT_REMOVE:
			payload = Object.assign({}, base, {
				nourl: "launch-app-basket",
				bask_launch_event: "launch-app-basket",
				bask_launch_total_items: 0,
				bask_launch_total_val: 0,
				bask_launchprod_app: 'Y',
				email: window.store.getState().user.preferences.personal.email,
			});
			break;
		default:
			payload = undefined;
			break;
	}
	if (payload) {
		makeRequest(payload)
	}
}

function removeHttps(url) {
	return url.replace(/^(http|https):\/\//, '')
}

export function trackEvent(category, action, opt_label, opt_value, additions = null, customData) {
	let payload = Object.assign({}, base)
	switch (action) {
		case EVTS.ACT.LOGIN:
			payload.nourl = "login";
			payload.email = customData.customer.personal.email;
			break;
		case EVTS.ACT.BOOKMARK_ADD:
		// eslint-disable-next-line
		case EVTS.ACT.BOOKMARK_REMOVE:
			let bookmarks = getBookmarks()
				.map((id) => window.store.getState().content.products.find(product => product.ID === id))
				.filter((product) => product).reverse()
				.slice(0, 4)
			payload.nourl = "add-to-bookmarks";
			payload.mark_event = "bookmark-event";
			payload.mark_app = "Y";
			payload.email = window.store.getState().user.preferences.personal.email;
			for (let i in bookmarks) {
				let index = parseInt(i) + 1;
				let bookmarkObJ = bookmarks[i]
				payload[`mark_type${index}`] = bookmarkObJ.launchType === 'raffle' ? 'Raffle' : 'Product';
				payload[`mark_name${index}`] = bookmarkObJ.name;
				payload[`mark_brand${index}`] = bookmarkObJ.brand;
				payload[`mark_val${index}`] = fomatPriceForRedeye(bookmarkObJ.price.amount);
				payload[`mark_image${index}`] = removeHttps(bookmarkObJ.mainImage.original);
				payload[`mark_sku${index}`] = bookmarkObJ.PLU;
				payload[`mark_start${index}`] = formatDateForRedeye(bookmarkObJ.launchStartDate || bookmarkObJ.launchDate);
				payload[`mark_close${index}`] = formatDateForRedeye(bookmarkObJ.launchStartDate ? bookmarkObJ.launchDate : null);
				payload[`mark_raff_live${index}`] = canEnterProduct(bookmarkObJ) ? 'Y' : 'N';
			}
			break;
		default:
			payload = undefined;
			break;
	}
	if (payload) {
		makeRequest(payload)
	}
}

/**
 *
 * @param {*} order
 */
export function trackOrder(order) {
	// console.log(order)
	let payload = Object.assign({}, base, {
		nourl: "launch-app-sale",
		sale_launch_conf: "launch-sale-confirm",
		sale_launchprod_app: "Y",
		email: order.customer.email,
		mobile: order.customer.phone,
		firstName: order.customer.firstName,
		lastname: order.customer.lastName,
		sale_launch_paymethod: convertPaymentToRedeye(),
		sale_launch_subval: fomatPriceForRedeye(order.subTotal.amount),
		sale_launch_delmethod: order.deliveryMethod.name,
		sale_launch_delval: fomatPriceForRedeye(order.deliveryMethod.price.amount),
		sale_launch_items: "1",
		sale_launch_totval: fomatPriceForRedeye(order.total.amount),
		sale_launch_ref: order.sessionID,
		emailpermit: window.store.getState().user.preferences.marketing.optedIn ? 'yes' : 'no'
	})
	makeRequest(payload)
}


/**
 *
 * @param {*} order
 */
export function trackPreAuth(product, order) {
	let payload = Object.assign({}, base, {
		nourl: "raffle-entry",
		enter_raff_event: "enter-raffle-event",
		enter_raff_name: product.name,
		enter_raff_sku: product.PLU,
		enter_raff_brand: product.brand,
		enter_raff_gender: product.gender,
		enter_raff_size: product.option.name,
		enter_raff_image: removeHttps(product.mainImage.original),
		enter_raff_date: formatDateForRedeye(product.launchDate),
		enter_raff_app: 'Y',
		enter_raff_charity: product.charity ? 'Y' : 'N',
		enter_raff_prod_val: fomatPriceForRedeye(product.price.amount),
		enter_raff_del_val: fomatPriceForRedeye(order.deliveryMethod.price.amount),
		enter_raff_val: fomatPriceForRedeye(order.total.amount),
		enter_raff_payment: convertPaymentToRedeye(),
		email: order.customer.email,
		int_segment: order.delivery.locale.toUpperCase(),
		firstname: order.delivery.firstName,
		lastname: order.delivery.lastName,
		mobile: order.customer.phone,
	})
	makeRequest(payload)
}

function formatDateForRedeye(date) {
	if (!date) return undefined;
	return moment(date).format('DD/MM/YYYY HH:mm:ss');
}

function convertPaymentToRedeye() {
	let paymentString = storage.getItem('paymentMethod');
	if (!paymentString) {
		return undefined
	}

	let payment = JSON.parse(paymentString);
	switch (payment.type) {
		case 'APPLE_PAY':
			return 'Apple Pay';
		case 'RECURRING':
			return 'Saved Card';
		case 'CARD':
			return 'New Card';
		case 'KLARNA':
			return payment.name;
		default:
			return payment.type;
	}
}

function fomatPriceForRedeye(price) {
	if (!price) return undefined;
	return (Math.round(price * 100) / 100).toFixed(2);
}

function convertViewTitle(title) {
	return title.replace(/([a-z])([A-Z])/g, "$1-$2").replace(/\s+/g, '-').toLowerCase();
}

function makeRequest(params) {
	if (config.redeye) {
		var img = document.createElement('img');
		img.src = `${config.redeye.baseURL}cgi-bin/rr/blank.gif?${new URLSearchParams(params).toString()}`;
		document.getElementById('root').appendChild(img);
	}
}
