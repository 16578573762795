/**
 * Basic Rest Client
 */
import { deleteRequest, get, post, put } from './restClientFactory';

//////////////////
// public


/**
 * Factory function to create a rest client object
 * @param {string} base base domain for requests
 * @param {Object} globalOptions object of global options for all requests
 * @returns {Object}
 */
export const basicRestClient = {
	get: function (url, options = {}, expiry) {
		return get(url, options, expiry)
	},
	put: function (url, payload, options = {}, expiry) {
		return put(url, payload, options, expiry);
	},
	post: function (url, payload, options = {}, expiry) {
		return post(url, payload, options, expiry);
	},
	delete: function (url, options = {}, expiry) {
		return deleteRequest(url, options, expiry)
	}
};