
import React from 'react';
import { connect } from 'react-redux';
import searchIcon from '../../assets/img/nav/icn-search.svg';
import loader from '../../assets/img/loading/spinner.gif';

import './Search.scss'
import SearchItem from './SearchItem';
import InfiniteScroll from 'react-infinite-scroll-component';
import { EVTS, trackEvent } from '../../services/analytics/analytics';
import config from '../../services/config/config'



//////////////////

let debounce;

const Search = React.createClass({
	componentDidMount() {
		let { search } = this.props;
		let { products, term, loading, scrollTop } = search;
		if (products.length !== 0 && term.length >= 3 && !loading) {
			let el = document.getElementsByClassName('infinite-scroll-component')[0];
			el.scrollTop = scrollTop || 0;
		}
	},
	updateSearchTerm(term) {
		if (debounce) {
			clearTimeout(debounce)
		}
		if (term.length >= 3) {
			debounce = setTimeout(() => {
				this.props.doSearch(term);
				trackEvent(EVTS.CAT.PRODUCT, EVTS.ACT.SEARCH, 'product-search', term)
			}, 300)
		}
		this.props.updateSearchTerm(term);

	},
	render() {
		const trackScroll = () => {
			let element = document.getElementsByClassName('infinite-scroll-component')[0];
			this.props.trackScroll(element.scrollTop);
		};
		let { search, lazyLoad } = this.props;
		let { products, term, loading, max } = search;

		let trendingNow = config.trendingNow;

		return (
			<div className="Search">
				<div className='search-header'>
					<div className="search-group">
						<img alt="search" src={searchIcon} />
						<input
							type="text"
							name="name"
							placeholder="Search..."
							value={term}
							onChange={e => this.updateSearchTerm(e.target.value)}
						/>

					</div>
				</div>
				<div className='search-body'>
					{products.length === 0 && term.length < 3 ?
						<div className="trending-now">
							<h1>Trending now...</h1>
							{trendingNow.map((trending, index) => <div key={index} onClick={() => this.updateSearchTerm(trending)} className="trending-item">{trending}</div>)}
						</div> : null
					}
					{products.length === 0 && term.length >= 3 && !loading ?
						<div>
							No products found
						</div> : null
					}
					{products.length === 0 && term.length >= 3 && loading ?
						<div className="loading">
							<img src={loader} alt="loading" />
							Searching...
						</div> : null
					}
					{products.length !== 0 && term.length >= 3 && !loading ?
						<div id="search-result">
							<InfiniteScroll
								dataLength={products.length} //This is important field to render the next data
								next={() => { console.log('More'); lazyLoad() }}
								hasMore={products.length < max}
								loader={<h4>Loading...</h4>}
								height="100%"
								children={products.map((product) => <SearchItem trackScroll={trackScroll} key={product.ID} {...product} />)}
							/>
						</div>
						: null}
				</div>
			</div >
		);
	}
});

const mapStateToProps = state => {
	return {};
};

const mapDispatchToProps = dispatch => {
	return {};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Search)
