import { hashHistory } from 'react-router';
import { clearPreferencesFromState, getUserPreferences, onUserPreferencesChange, updateUserPreferences } from '../../../actions/preferences';
import { loadLocales } from '../../../actions/checkout';
import { connect } from 'react-redux';
import React, { PropTypes } from 'react';
import TitleBlock from '../../titleBlock/TitleBlock';
import AddressPreferenceForm from '../../addressForm/AddressPreferenceForm';
import './BillingPreferences.scss';

import { trackPage } from '../../../services/analytics/analytics';
import config from '../../../services/config/config';
import { navigateToExternal } from '../../../services/navigate/navigate';

const preferencesKey = 'billing';
const deliveryPreferencesKey = 'delivery';


//////////////////


const BillingPreferences = React.createClass({
	defaultProps: {
		preferences: {}
	},
	PropTypes: {
		preferences: PropTypes.object.isRequired
	},
	getInitialState: () => ({
		isFormValid: false,
		preferences: {}
	}),
	componentWillMount() {
		trackPage('/preferences/billing');
		let { getLocales, preferences } = this.props;
		this.setState({ preferences: preferences });
		getLocales();
	},
	componentDidUpdate(prevProps, prevState) {
		// If the validation status has changed, it is sent back to the parent component.
		if (this.props.isPageValid && (this.state.isFormValid !== prevState.isFormValid)) {
			this.props.isPageValid(this.state.isFormValid);
		}
		if (this.props.onFormChange) {
			this.props.onFormChange(this.state);
		}
	},
	updateValidationState(isFormValid) {
		this.setState({ isFormValid: isFormValid });
	},
	backToDashboard() {
		hashHistory.push('/preferences')
	},
	onCancel() {
		this.backToDashboard();
	},
	onSave() {
		let preferences = this.state.preferences;
		this.props.savePreferences(preferencesKey, preferences);
		this.backToDashboard();
	},
	setRef(input) {
		this.postcodeRef = input;
	},
	updatePreferences(newPreferences) {
		let preferences = { ...this.state.preferences }
		Object.assign(preferences, newPreferences)
		this.setState({ preferences: preferences });
	},
	updatePreference(property, val) {
		let newPreferences = {}
		newPreferences[property] = val
		this.updatePreferences(newPreferences)
	},
	changeProperty(val, property) {
		this.updatePreference(property, val);
		this.props.updatePreferences(deliveryPreferencesKey, 'useAsBilling', false);
	},
	localeChange(locale) {
		this.updatePreferences({ postcode: '', locale: locale });
		this.postcodeRef.setState({
			valid: false
		});
	},
	render() {
		let { hideButtons, theme } = this.props;
		let { preferences } = this.state

		return (
			<div className="Preferences Billing-Preferences">
				<div className="content-scroll">
					<TitleBlock title="Billing Preferences" />
					<h4>Manage your billing address</h4>
					<div className="form-horizontal">
						<p className="intro">
							Enter your billing information to speed up your checkout process when those seconds really matter!
							This can be changed at any time.
						</p>
					</div>

					<AddressPreferenceForm
						useCase="billing"
						postcodeRef={this.setRef}
						onLocaleChange={(locale) => this.localeChange(locale)}
						onFormChange={(val, property) => this.changeProperty(val, property)}
						isFormValid={this.updateValidationState}
						preferences={preferences}
						preferencesKey={preferencesKey}
					/>
					<div>
						<span>We will use your information in accordance with our <span className='link' onClick={() => navigateToExternal(`${config.storeUrl}customer-service/privacy/`)}>privacy policy</span>.</span>
					</div>
				</div>
				{
					hideButtons
						? null
						: <div className="form-action fixed">
							<div className="inline">
								<div className="action-split">
									<button className="cancel" onClick={this.onCancel}>Cancel</button>
								</div>
								<div className="action-split">
									<button className="action" onClick={this.onSave} style={theme.primaryButton} disabled={!this.state.isFormValid}>
										<div className="button-contents">Save</div>
									</button>
								</div>
							</div>
						</div>
				}
			</div>
		);
	}
});

const mapStateToProps = state => {
	const { checkout, content, loading, user } = state;
	return { locales: checkout.locales, loading, preferences: user.preferences.billing, theme: content.theme };
};

const mapDispatchToProps = dispatch => {
	return {
		clearPreferences: (key) => dispatch(clearPreferencesFromState(key)),
		getPreferences: (key) => dispatch(getUserPreferences(key)),
		savePreferences: (key, data) => dispatch(updateUserPreferences(key, data)),
		updatePreferences: (key, property, data) => dispatch(onUserPreferencesChange(key, property, data)),
		getLocales: () => dispatch(loadLocales())
	};
};


//////////////////


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(BillingPreferences);
