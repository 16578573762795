import React, {PropTypes} from 'react';
import './UspBanner.scss';

const UspBanner = ({message, backgroundColor, children}) => {
	let styles = {}
	
	if(backgroundColor) styles.backgroundColor = backgroundColor;
	
	return (
		<div style={styles} className="usp">
			{children}
			{ message ? <h4>{message}</h4> : null}
		</div>
		)
};

UspBanner.propTypes = {
	message: PropTypes.string,
	background: PropTypes.string
}

export default UspBanner;