import React from 'react';
import { navigateToExternal } from '../../services/navigate/navigate';
import Category from './categories/Category';

import './Post.scss';


//////////////////


const Post = ({ title, excerpt, feature_media, link, categories, index }) => {
    categories = categories.map((category, i) => (
        <Category key={i} id={i} title={category.name} slug={category.slug}></Category>
    ))
    return (
        <li className="blog-post" onClick={() => navigateToExternal(link)}>
            < img alt={title} src={feature_media} ></img>
            <h2 className="title" dangerouslySetInnerHTML={createMarkup(title)} />
            <div className="excerpt" dangerouslySetInnerHTML={createMarkup(excerpt)} />
            <ul className="category-list">
                {categories}
            </ul>
        </li >
    )
};

/**
 * Returns html in the correct format to set dangerously
 * with react
 *
 * @param {String} html
 * @return {Object}
 */
function createMarkup(html) {
    return { __html: html };
}
//////////////////


export default Post;
