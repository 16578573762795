// libraries
import React from 'react';
import isKlarnaExclusive from '../../services/payment/isKlarnaExclusive'

//images
import klarnaExclusive from '../../assets/img/payments/klarna/KlarnaExclusive.png'


/**
 *
 * Component
 *
 */

const KlarnaExclusive = React.createClass({
    render() {
        let { product } = this.props
        if(product && isKlarnaExclusive(product)){
            return (<img className="klarna-exclusive" src={klarnaExclusive} alt="Klarna Exclusive" />)
        }
        return null
    }
});

export default KlarnaExclusive
