import React from 'react';
import SizeList from '../sizeList/SizeList';
import AnswerList from '../answerList/AnswerList';
// import QuantityDropdown from './QuantityDropdown';

import NotifyAndBookmarkButton from '../notifyAndBookmarkButton/NotifyAndBookmarkButton';

import { hasDatePassed } from '../../services/time/time';
import DateTime from '../dateTime/DateTime'
import KlarnaCalculator from '../klarnaCalculator/KlarnaCalculator';
import { connect } from 'react-redux';
import { BOOST_COPY, DATE_BOOST_COPY } from '../loyaltyShare/LoyaltyShare';
import MoreInfoPopup from '../moreInfoPopup/MoreInfoPopup';
import config from '../../services/config/config';
import storage from '../../services/storage/storage';



const PreLaunchInteractions = ({ launchType, launchStartDate, launchDate, selectSize, options, sizeSelected, buttonStyles }) => {

	// raffles have a raffle start date and a launch start date
	const startDate = launchType === 'raffle' ? launchStartDate : launchDate;

	return (
		<div className="releaseSoon">
			<SizeList sizes={options} onSizeSelection={selectSize} launchDate={startDate} />
			{launchType === 'monthOnly' || !options.length ? null : <NotifyAndBookmarkButton disabled={sizeSelected ? false : true} className='action' style={buttonStyles}>
				<div className="button-contents">
					<span>Notify me & add to Bookmarks</span>
				</div>
			</NotifyAndBookmarkButton>}
		</div>
	)
}

const LaunchedInteractionsElement = React.createClass({
	getInitialState: () => ({
		answer: false,
		showLearnMore: false,
		learnMoreText: '',
	}),
	onSelectAnswer(answer) {
		this.setState({ answer })
	},
	showLearnMore(text) {
		this.setState({ showLearnMore: true, learnMoreText: text })
	},
	hideLearnMore() {
		this.setState({ showLearnMore: false })
	},
	selectSize(size) {
		if (!this.props.loyalty.isPremium &&
			this.props.launchType === 'raffle'
			&& config.supportsMembership
			&& !storage.getItem('dontShowPremiumPopup')
			&& !this.props.charity) {
			storage.setItemNoExpiry('dontShowPremiumPopup', 'true');
			this.setState({ showLearnMore: true, learnMoreText: 'SIGNUP' })
		}
		this.props.selectSize(size)
	},
	onSelectQuantity(quantity) {
		this.props.quantityOfEntries(quantity)
	},

	render() {
		let { launchType, launchDate, launchStartDate, isAvailable, options, buttonStyles, addToBasket, outOfStock, goToPreAuth, sizeSelected, question, answers, charity, isTimedKlarnaExclusive, timeToCard, price, paymentGateways, loyalty,
			// maxQuantity, quantity, quantityOfPreviousEntries
		} = this.props
		// raffles have a raffle start date and a launch date
		const startDate = launchType === 'raffle' ? launchStartDate : launchDate;
		let { answer, learnMoreText, showLearnMore } = this.state;
		// const maxDisplay = 10;
		// console.log(quantityOfPreviousEntries);
		return (
			<div className="releaseLive">
				{(!hasDatePassed(launchDate) || launchType !== 'raffle') && hasDatePassed(startDate) && paymentGateways && paymentGateways.includes('Klarna') ?
					<KlarnaCalculator price={price} instalments={3} timeInterval="Monthly" /> : null
				}
				{question && charity && launchType === 'raffle' && !hasDatePassed(launchDate) ?
					<div className="question">
						For a chance to win, answer the following question correctly...<br />
						<div className="questionText">
							{question}
						</div>
						<AnswerList answers={answers} selectdAnswer={answer} onSelectAnswer={this.onSelectAnswer} />
						Then select a size...
					</div> : null}

				{(hasDatePassed(startDate) && isAvailable)
					? (launchType === 'raffle' && hasDatePassed(launchDate))
						? null
						: <div>
							{isTimedKlarnaExclusive ?
								<div className="klarnaTimedDisclaimer">* Card payment will open from <DateTime date={timeToCard} format="MMMM Do \a\t HH:mm" /></div> : null}
							<SizeList sizes={options} onSizeSelection={this.selectSize} launchDate={launchDate} />
						</div>
					: null
				}

				{/* {question && charity && launchType === 'raffle' && !hasDatePassed(launchDate) ?
					<div>
						<span>Number of Entries<br /></span>
						<QuantityDropdown quantity={quantity} max={maxQuantity || 100} quantityChange={this.onSelectQuantity} maxDisplay={maxDisplay} price={price} quantityOfPreviousEntries={quantityOfPreviousEntries} />
					</div> : null} */}

				{launchType === 'raffle' ?
					<div>
						{hasDatePassed(launchDate) ?
							<button className='action launchButton' style={buttonStyles} disabled={true}>
								<div className="button-contents">
									<span>Draw Closed</span>
								</div>
							</button> :
							<div>

								{!loyalty.isBoostWeek || charity ?
									<button className='action launchButton' style={buttonStyles} onClick={() => goToPreAuth(sizeSelected, answer)} disabled={!sizeSelected || (charity && answer === false)}>
										<div className="button-contents">
											<span>Enter Draw {loyalty.isPremium && !charity ? '- with size?access' : null}</span>
										</div>
									</button> : null
								}
								{(loyalty.boosts > 0 || loyalty.isBoostWeek) && !charity ?
									<div>
										<button className='action launchButton boosted' style={buttonStyles} onClick={() => goToPreAuth(sizeSelected, answer, !loyalty.isBoostWeek)} disabled={!sizeSelected || (charity && answer === false)}>
											<div className="button-contents">
												<span>Enter Draw - with size?access+</span>
											</div>
										</button>
										{!loyalty.isBoostWeek ? <div className="moreInfoLink" onClick={() => this.showLearnMore(BOOST_COPY)}><u>Learn More</u> about size?access+ boosts</div>
											: <div className="moreInfoLink" onClick={() => this.showLearnMore(DATE_BOOST_COPY)}><u>Learn More</u> about size?access+</div>}
									</div> : null
								}
							</div>
						}
					</div>

					:
					<button className='action launchButton' style={buttonStyles} onClick={addToBasket} disabled={hasDatePassed(launchDate) ? outOfStock : !options.length}>
						<div className="button-contents">
							{(hasDatePassed(launchDate) && outOfStock) ? <span>Sold out</span> : <span>Add to Bag</span>}
						</div>
					</button>
				}
				<MoreInfoPopup showLearnMore={showLearnMore} hideLearnMore={this.hideLearnMore} text={learnMoreText} />
			</div>
		)
	}
});

const mapStateToProps = (state) => {
	const { user } = state;
	return { loyalty: user.preferences.loyalty || { isPremium: false } };
};

const mapDispatchToProps = dispatch => {
	return {};
};

//////////////////
const LaunchedInteractions = connect(
	mapStateToProps,
	mapDispatchToProps
)(LaunchedInteractionsElement);


const ProductInteractions = ({ addToBasket, isAvailable, buttonStyles, launchDate, launchProduct, options, selectSize, dateFormat, launchType, outOfStock, sizeSelected, launchStartDate, goToPreAuth, question, answers, charity, isTimedKlarnaExclusive, timeToCard, price, paymentGateways, quantityOfEntries, quantity, maxQuantity, quantityOfPreviousEntries }) => {
	// raffles have a raffle start date and a launch date
	const startDate = launchType === 'raffle' ? launchStartDate : launchDate;

	let notLaunchedInteractions = false;
	let launchInteractions = true;

	if (!hasDatePassed(startDate) && isAvailable) {
		notLaunchedInteractions = true;
		launchInteractions = false;
	}

	return <div className="interactions">

		{launchInteractions ? <LaunchedInteractions
			launchType={launchType}
			launchStartDate={launchStartDate}
			launchDate={launchDate}
			selectSize={selectSize}
			quantityOfEntries={quantityOfEntries}
			options={options}
			sizeSelected={sizeSelected}
			buttonStyles={buttonStyles}
			outOfStock={outOfStock}
			addToBasket={addToBasket}
			isAvailable={isAvailable}
			goToPreAuth={goToPreAuth}
			question={question}
			answers={answers}
			charity={charity}
			isTimedKlarnaExclusive={isTimedKlarnaExclusive}
			timeToCard={timeToCard}
			price={price}
			paymentGateways={paymentGateways}
			quantity={quantity}
			maxQuantity={maxQuantity}
			quantityOfPreviousEntries={quantityOfPreviousEntries}
		/> : null
		}

		{notLaunchedInteractions ?
			<PreLaunchInteractions
				launchType={launchType}
				launchStartDate={launchStartDate}
				launchDate={launchDate}
				selectSize={selectSize}
				quantityOfEntries={quantityOfEntries}
				options={options}
				sizeSelected={sizeSelected}
				buttonStyles={buttonStyles}
				isAvailable={isAvailable}
			/>
			: null
		}
	</div>;
};


export default ProductInteractions;
