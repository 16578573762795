import { navigateToExternal } from '../../../services/navigate/navigate';
import config from '../../../services/config/config';
import React from 'react';
import TitleBlock from '../../titleBlock/TitleBlock';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import './HelpfulLinks.scss';
import { trackPage } from '../../../services/analytics/analytics';
import { filterCampaigns } from '../../../actions/content';
import Help from '../../contentService/Help';


//////////////////

const HelpfulLinks = React.createClass({

	render() {
		trackPage('preferences/help');

		let { campaigns, loyalty } = this.props;

		let campaignPages = [];

		// console.log(campaigns);

		// Find all the helper campaigns
		filterCampaigns(campaigns, loyalty, 'help').forEach(campaign => {
			campaignPages.push(
				<Help campaign={campaign} />
			);
		});

		return (
			<div className="Preferences Helpful-Links">
				<div className="content-scroll">
					<TitleBlock title="Helpful Links" />
					<h4>Get some help</h4>
					<div className="form-horizontal">
						<p className="intro">
							A collection of links to help you out.
						</p>
					</div>

					<div className="form-horizontal form-options list-links">
						<ul>
							<li className="form-option-type" onClick={() => navigateToExternal(`${config.storeUrl}track-my-order/`)}>
								<div className="check-option">
									<label>
										<h4>See my order status</h4>
										<p>Track your order</p>
									</label>
								</div>
							</li>
							{campaignPages}
							<li className="form-option-type" onClick={() => navigateToExternal(`${config.storeUrl}customer-service/terms/`)}>
								<div className="check-option">
									<label>
										<h4>Terms and Conditions</h4>
										<p>Online Sale of Goods</p>
									</label>
								</div>
							</li>
							<li className="form-option-type" onClick={() => navigateToExternal(`${config.storeUrl}customer-service/contact/`)}>
								<div className="check-option">
									<label>
										<h4>Contact Us</h4>
										<p>Get in touch with the {config.storeDisplayName} help team</p>
									</label>
								</div>
							</li>
							<li className="form-option-type" onClick={() => navigateToExternal(`${config.storeUrl}pages/faqs/`)}>
								<div className="check-option">
									<label>
										<h4>Frequently asked questions</h4>
										<p>Find your answers here</p>
									</label>
								</div>
							</li>
							<li className="form-option-type" onClick={() => navigateToExternal(`${config.storeUrl}customer-service/privacy/`)}>
								<div className="check-option">
									<label>
										<h4>Privacy Policy</h4>
										<p>See our privacy policy here</p>
									</label>
								</div>
							</li>
							{config.is_app ?
								<li className="form-option-type">
									<Link to="/preferences/delete">
										<div className="check-option">
											<label>
												<h4>Delete My Account</h4>
												<p>Delete your {config.appDisplayName} Account</p>
											</label>
										</div>
									</Link>
								</li> : null
							}
						</ul>
					</div>
				</div>
			</div >
		)
	}
});

//////////////////


const mapStateToProps = state => {
	const { user, content } = state;
	return { loyalty: user.preferences.loyalty, campaigns: content.campaigns.campaigns };
};


export default connect(
	mapStateToProps
)(HelpfulLinks);
