/**
 * Payment Actions
 *
 * Here you'll find all the action creators & actions for payments. Async
 * actions (using redux-thunk) are at the bottom.
 *
 */

import { sendEncryptedPaymentData, sendRecurringPayment, sendKlarnaPaymentData } from '../services/api';
import { showError } from './error';
import { checkPaymentStatus, createPaymentError } from '../services/payment';
import { loading, loaded } from './loading';
import { clearSession } from './session';
import { hashHistory } from 'react-router';
import { getUserData } from '../services/user';
import { getAPIDomain } from '../services/http/util';
import { getQueryStringAsObject, addQueryToURL } from '../services/url';


export const PAYMENT_START = 'PAYMENT_START';
/**
 * paymentStart
 *
 * @return [object] action
 */
export function paymentStart() {
    return {
        type: PAYMENT_START
    }
}

export const PAYMENT_SUCCESS = 'PAYMENT_SUCCESS';
/**
 * paymentSuccess
 *
 * @param [object] order
 * @return [object] action
 */
export function paymentSuccess(order) {
    return {
        type: PAYMENT_SUCCESS,
        order
    }
}

export const PAYMENT_FAILURE = 'PAYMENT_FAILURE';
/**
 * paymentFailure
 *
 * @return [object] action
 */
export function paymentFailure() {
    return {
        type: PAYMENT_FAILURE
    }
}

/****************************************
 * ASYNC ACTION CREATORS
 * (dependent on redux thunk middleware)
 *
 ***************************************/

/**
 * makeEncryptedPayment
 *
 * @param [string] orderID
 * @param [object] data, encrypted payment data generated by the adyen library
 * @return [object] action
 */
export function makeEncryptedPayment(orderID, data) {
    return (dispatch, getState) => {
        let session = getState().session,
            preOrderID = session.preOrder ? session.preOrder.orderID : null;

        dispatch(paymentStart());
        dispatch(loading('Making payment'));
        return sendEncryptedPaymentData(orderID, data, preOrderID)
            .then((data) => {
                // throw error if payment was not successfull
                checkPaymentStatus(data);
                if (data.redirectUrl) {
                    let TermUrl;
                    //merge in new params
                    if (preOrderID) {
                        TermUrl = `${getAPIDomain()}/preAuthorise/${preOrderID}/payment/3dsecure`;
                    } else {
                        TermUrl = `${getAPIDomain()}/sessions/${orderID}/payment/3dsecure`;
                    }
                    // add params to the redirect url
                    let currentQueryParams = getQueryStringAsObject();

                    if (window.location.hostname.includes("jdmesh.co")) {
                        TermUrl += "?isNewClient=1"
                    }

                    let newQueryParams = {
                        IssuerUrl: data.redirectUrl,
                        PaReq: data.paReq,
                        MD: data.md,
                        TermUrl: TermUrl
                    }
                    if (currentQueryParams) {
                        newQueryParams = { ...currentQueryParams, ...newQueryParams };
                    }
                    const redirectUrl = addQueryToURL(`/payment/3dsecure`, newQueryParams);
                    // redirect to the 'adyen redirect form' with the details
                    hashHistory.push(redirectUrl);
                } else {
                    // payment was successfull to reach this point
                    if (preOrderID) {
                        hashHistory.push(`/preorder-confirmation/${preOrderID}`);
                    } else {
                        hashHistory.push(`/order-confirmation/${orderID}`);
                    }

                    dispatch(paymentSuccess(data.sessionID));
                    dispatch(clearSession());
                    dispatch(loaded());
                    return orderID;
                }
            }).catch((error) => {
                error = createPaymentError(error);
                dispatch(paymentFailure(error));
                dispatch(loaded());
                dispatch(showError(error));
                return Promise.reject();
            });
    }
}

/**
 * makeKlarnaPayment
 *
 * @param [string] orderID
 * @param [object] data, encrypted payment data generated by the adyen library
 * @return [object] action
 */
export function makeKlarnaPayment(orderID, data) {
    return (dispatch, getState) => {
        let session = getState().session,
            preOrderID = session.preOrder ? session.preOrder.orderID : null;

        dispatch(paymentStart());
        dispatch(loading('Making Klarna payment'));
        return sendKlarnaPaymentData(orderID, preOrderID, data)
            .then((data) => {
                // throw error if payment was not successfull
                checkPaymentStatus(data);
                // payment was successfull to reach this point
                if (preOrderID) {
                    hashHistory.push(`/preorder-confirmation/${preOrderID}`);
                } else {
                    hashHistory.push(`/order-confirmation/${orderID}`);
                }

                dispatch(paymentSuccess(data.sessionID));
                dispatch(loaded());
                dispatch(clearSession());
                return orderID;
            }).catch((error) => {
                error = createPaymentError(error);
                dispatch(paymentFailure(error));
                dispatch(loaded());
                dispatch(showError(error));
                return Promise.reject();
            });
    }
}

export function makeRecurringPayment(data) {
    return (dispatch, getState) => {
        let session = getState().session,
            sessionID = session.ID,
            preOrderID = session.preOrder ? session.preOrder.orderID : null,
            userData = getUserData(),
            userID;
        // console.log("SESSION:", session);
        // console.log("preOrderID:", preOrderID);

        if (userData && userData.userID) userID = userData.userID;

        dispatch(paymentStart());
        dispatch(loading('Making payment'));
        return sendRecurringPayment(sessionID, userID, data, preOrderID)
            .then((data) => {
                // console.log("sendRecurringPayment success DATA:", data);
                // throw error if payment was not successfull
                checkPaymentStatus(data);
                if (data.redirectUrl) {
                    let TermUrl;
                    //merge in new params
                    if (preOrderID) {
                        TermUrl = `${getAPIDomain()}/preAuthorise/${preOrderID}/payment/3dsecure`;
                    } else {
                        TermUrl = `${getAPIDomain()}/sessions/${sessionID}/payment/3dsecure`;
                    }

                    // add params to the redirect url
                    let currentQueryParams = getQueryStringAsObject();
                    let newQueryParams = {
                        IssuerUrl: data.redirectUrl,
                        PaReq: data.paReq,
                        MD: data.md,
                        TermUrl: TermUrl
                    }
                    if (currentQueryParams) {
                        newQueryParams = { ...currentQueryParams, ...newQueryParams };
                    }
                    const redirectUrl = addQueryToURL(`/payment/3dsecure`, newQueryParams);
                    // redirect to the 'adyen redirect form' with the details
                    hashHistory.push(redirectUrl);
                    return sessionID;
                } else {
                    // payment was successfull to reach this point
                    if (preOrderID) {
                        hashHistory.push(`/preorder-confirmation/${preOrderID}`);
                    } else {
                        hashHistory.push(`/order-confirmation/${sessionID}`);
                    }
                    dispatch(paymentSuccess(data.sessionID));
                    dispatch(clearSession());
                    dispatch(loaded());
                    return sessionID;
                }
            }).catch((error) => {
                error = createPaymentError(error);
                dispatch(paymentFailure(error));
                dispatch(loaded());
                dispatch(showError(error));
                return Promise.reject();
            });
    }
}
