import React from 'react';
import moment from 'moment';
import './DateTime.scss';

/**
 * DateTime component
 *
 * @description
 * This component will accept a date as a string or object (whatever the moment library supports) and will display
 * this as a data and time.
 *
 * @param [string, objet] full date

 * @example
 * <DateTime date="2016-11-10T11:52:02.074Z" />
 */

const DateTime = ({ date, format }) => {
	let newDate = moment(date);
	let formattedDate = format ? `${newDate.format(format)}` : `${newDate.format('DD MMM YYYY')} / ${newDate.format("HH:mm")}`;

	return (
		<h4 className="dateTime">{formattedDate}</h4>
	);
}

export default DateTime;