import { CLOSE_SPLASH_LOGIN, CONTINUE_AS_GUEST, GET_USER_FROM_STORAGE } from '../actions/content';
import { LOGIN_DETAILS_CHANGE, LOGIN_RESPONSE, LOGOUT, SIGNUP_DETAILS_CHANGE, SIGNUP_RESPONSE } from '../actions/auth';
import { SAVE_USER_TO_DEVICE } from '../actions/preferences';

const initialState = () => ({
	guestUser: false,
	loggedIn: false,
	firstName: '',
	lastName: '',
	password: '',
	password2: '',
	username: '',
	billing: {
		firstName: '',
		lastName: '',
		address1: '',
		address2: '',
		town: '',
		county: '',
		postcode: '',
		locale: '',
		phone: ''
	},
	delivery: {
		firstName: '',
		lastName: '',
		address1: '',
		address2: '',
		town: '',
		county: '',
		postcode: '',
		locale: 'gb',
		phone: '',
		useAsBilling: true
	},
});

export const auth = (state = initialState(), action) => {
	switch (action.type) {
		case CONTINUE_AS_GUEST:
			return Object.assign({}, state, { loggedIn: false, guestUser: true });

		case LOGIN_RESPONSE:
		case SIGNUP_RESPONSE:
			return Object.assign({}, state, { loggedIn: !!action.success });

		case LOGIN_DETAILS_CHANGE:
		case SIGNUP_DETAILS_CHANGE:
			return Object.assign({}, state, action.formData);
		case CLOSE_SPLASH_LOGIN:
		case LOGOUT:
			return Object.assign({}, initialState());

		case GET_USER_FROM_STORAGE:
		case SAVE_USER_TO_DEVICE:
			return Object.assign({}, initialState(), { loggedIn: true });

		default:
			return state;
	}
};
