/**
 * loading Actions
 * 
 * Here you'll find all the action creators & actions for managing the loading state of the application.
 * 
 */

export const LOADING = 'LOADING';
/**
 * loading
 * 
 * @param [string] message
 * @return [object] action
 */
export function loading(message){
    
    return {
        type: LOADING,
        message
    }
}

export const LOADED = 'LOADED';
/**
 * loaded
 * 
 * @return [object] action
 */
export function loaded(){
    return {
        type: LOADED
    }
}

export const LOADING_3DS = 'LOADING_3DS';
/**
 * loading
 * 
 * @param [string] message
 * @return [object] action
 */
export function loading3DS(message){
    
    return {
        type: LOADING_3DS,
        message
    }
}

export const LOADED_3DS = 'LOADED_3DS';
/**
 * loaded
 * 
 * @return [object] action
 */
export function loaded3DS(){
    return {
        type: LOADED_3DS
    }
}