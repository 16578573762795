import React from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { logout } from '../../actions/auth';
import { isUserLoggedIn } from '../../services/user/index';

import './holdingPage.scss';
import previewsLogo from '../../assets/img/holdingPage/logo.png';
import Countdown from '../countdownTimer/Countdown';
import SocialLinks from '../socialLinks/SocialLinks';
import { closeSplashLogin } from '../../actions/content';



//////////////////


const Holding = ({ closeSplash, firstName, isLoggedIn, logout, end }) => {
	return (
		<div className="holdingPage">
			<h1><img src={previewsLogo} alt="logo" /></h1>
			<div className="description">
				<h2>Coming Soon...</h2>
				<Countdown end={end} countdownEnded={()=>{window.location.reload()}} />
			</div>
			<div className="socials">
				<div className="socialsContent">
					<p><span> {isLoggedIn ? `Welcome ${firstName}.` : "Register now for first access."}</span> For more info head on over to our social channels.</p>
					<SocialLinks />
				</div>
			</div>
			{!isLoggedIn ?
				<div className="holdingActions">
					<Link onClick={() => closeSplash('/holding')} to="/login"  className="action login"><div className="button-contents">Login</div></Link>
					<Link onClick={() => closeSplash('/holding')} to="/signup" className="action"><div className="button-contents">Create an Account</div></Link>
				</div> : <div className="holdingActions">
					<Link onClick={() => logout()} className="action logout"><div className="button-contents">Logout</div></Link>
				</div>
			}
		</div>
	);
};

 const mapStateToProps = (state) => {
	const { user, content } = state;
	let isLoggedIn = !!isUserLoggedIn();
	return { firstName: user.preferences.personal.firstName, isLoggedIn, end: content.overrideMessage.end };
};

const mapDispatchToProps = (dispatch) => {
	return {
		logout: () => dispatch(logout()),
		closeSplash: path => dispatch(closeSplashLogin(path))
	};
};

const HoldingPage = connect(
	mapStateToProps,
	mapDispatchToProps
)(Holding);

export default HoldingPage;
