import React from 'react';

import './MembershipBenefit.scss'

export default React.createClass({
    getInitialState() {
        return ({
            expand: false
        })
    },
    toggleExpand() {
        let { expand } = this.state;
        this.setState({ expand: !expand })
    },
    render() {
        let { title } = this.props;
        let { expand } = this.state;

        return (
            <div className="membership-benefit">
                <h1 onClick={this.toggleExpand}><span>{title}</span> <div className={`more ${expand ? 'contract' : 'expand'}`} /></h1>
                <div className={`benefit ${expand ? 'expand' : 'contract'}`}>
                    {this.props.children}
                </div>
            </div >
        )
    }
});

