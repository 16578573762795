import React from 'react';
import HeaderContainer from './containers/HeaderContainer';
import LoaderContainer from './containers/LoaderContainer';
import Debug from './components/debug/Debug';
import Error from './containers/Error';
import { hashHistory } from 'react-router'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { hasSessionExpired } from './services/session';
import { getQueryParam } from './services/url';
import SplashLogin from './components/splashLogin/SplashLogin';

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { black } from 'material-ui/styles/colors';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import injectTapEventPlugin from 'react-tap-event-plugin';
import config, { isInNativeApp } from './services/config/config'

import './base.scss'
import HoldingPage from './components/holdingPage/HoldingPage';
import { shouldShowHoldingPage } from './services/holdingPage';
import PromoPopup from './components/promoProduct/PromoPopup';

let standalone = window.navigator.standalone;
let userAgent = window.navigator.userAgent.toLowerCase();
let safari = /safari/.test(userAgent);
let ios = /iphone|ipod|ipad/.test(userAgent);
let android = /Android/i.test(userAgent);
let deviceClass = android ? 'android' : ios ? 'ios' : '';

deviceClass += isInNativeApp() ? ' native-embed' : ''

// Needed for onTouchTap
// http://stackoverflow.com/a/34015469/988941
injectTapEventPlugin();

// This replaces the textColor value on the palette
// and then update the keys for each component that depends on it.
// More on Colors: http://www.material-ui.com/#/customization/colors
const muiTheme = getMuiTheme({
	palette: {
		textColor: black
	}
});

/**
 * Wraps the 'page' components of the app in a react transition components.
 * This allows us to transition from page to page. Might be better to split out the other compoents
 * like the header, error and loader so this just wraps the content
 */
const TransitionWrapper = ({ children, location, appHeader, content, closeSplashLogin, continueAsGuest, showSplash, showBackButton, backAlign, showHomeButton, showPreferencesButton, hideMiniCart, solid, showLogo, showTimeout, showSearch }) => {
	// splash login logic
	let allowGuests = false;
	let showSplashLogin = !!showSplash;
	if (content.guest && content.guest.guestOnly) showSplashLogin = false; //hide login if only allowing guests
	if ((content.guest && content.guest.guestOnly) || (content.guest && content.guest.allowGuests)) allowGuests = true; //show guest login if allowGuests, or if we're only allowing guests - should never be in the situation where the splash shows and login is turned off, but here to be sure guests can get through

	return (
		<MuiThemeProvider muiTheme={muiTheme}>
			<div>
				<HeaderContainer appHeader={appHeader} showBackButton={showBackButton} showHomeButton={showHomeButton} showPreferencesButton={showPreferencesButton} hideMiniCart={hideMiniCart} backAlign={backAlign} solid={solid} showTimeout={showTimeout} showLogo={showLogo} showSearch={showSearch} />
				<Error />
				<PromoPopup />
				<LoaderContainer className={`${appHeader ? 'app-header' : ''}`} />
				{
					showSplashLogin ?
						shouldShowHoldingPage(content.overrideMessage) ?
							<HoldingPage /> :
							<SplashLogin className="splashLogin"
								allowGuests={allowGuests}
								continueAsGuest={continueAsGuest}
								closeSplash={closeSplashLogin}
								title="Size Launches"
								description={`Already got a ${config.storeDisplayName} account? Login with your email and password. Otherwise simply create an account.`}
								descriptionlogin="" />
						: (
							<div className={`page-wrapper ${appHeader ? 'app-header' : ''}`}>
								<ReactCSSTransitionGroup
									transitionName="page"
									transitionEnterTimeout={500}
									transitionLeaveTimeout={500} >
									{React.cloneElement(children, {
										key: location.pathname
									})}
								</ReactCSSTransitionGroup>
							</div>
						)
				}
			</div>
		</MuiThemeProvider>
	);
};

/**
 * This is a wrapper component for the application. Used for top level logic
 * e.g. routing, splashScreen, route transitions, session control
 */
const Root = React.createClass({
	getInitialState() {
		return Object.assign({}, canShowButtons(this.props.content), { showSplash: true });
	},
	componentWillMount() {
		this.appHeader = getQueryParam('channel') ? true : false;
		this.setState({ showDebug: getQueryParam('debug') })
	},
	componentDidMount() {
		hashHistory.listen(location => {
			if (this.props.session.expiryDate && hasSessionExpired(this.props.session)) {
				this.props.clearSession();
			}
		});
	},
	componentWillUpdate(nextProps, nextState) {
		// session has been cleared
		// lets make sure we go home
		if (this.props.session.ID && !nextProps.session.ID) {
			switch (this.props.location.pathname) {
				case '/checkout':
				case '/payment':
				case '/klarna':
					hashHistory.push('/');
					break;
				default:
					break;
			}
		}
	},
	componentWillReceiveProps(nextProps) {
		let { auth, content } = nextProps;
		let currentPath = this.props.location.pathname;
		let nextPath = nextProps.location.pathname;

		// handle logged in user going back to login/signup, instead redirect to home/entry page
		if (auth.loggedIn &&
			(nextPath === '/login' || nextPath === '/signup') &&
			(currentPath !== '/login' && currentPath !== '/signup')) {
			let replace = content.loginSignupJourneyRedirect ? content.loginSignupJourneyRedirect : '/';
			hashHistory.replace(replace);
			return;
		}

		// handle showing splash screen
		let showSplash = (!auth.loggedIn && !auth.guestUser && nextPath !== '/login' && nextPath !== '/signup');
		this.setState(Object.assign({}, canShowButtons(content), { showSplash }));
	},
	showDebug() {
		this.setState(this.setState({ showDebug: true }))
	},
	hideDebug() {
		this.setState(this.setState({ showDebug: false }))
	},
	render() {
		let { startup } = this.props;
		let { showDebug } = this.state
		return (
			<div className={deviceClass}>
				{config.debug ?
					<div>
						<div onClick={() => { this.showDebug() }} className="debugBanner">{config.env}</div>
						<Debug show={showDebug} hideDebug={this.hideDebug} />
					</div>
					: null}
				{
					startup.complete
						? <TransitionWrapper {...this.state} {...this.props} appHeader={this.appHeader} />
						: <div className="splash-screen" role="presentation">
							<div id="splash-logo"></div>
						</div>
				}
				<script />
			</div>
		)
	}
});

export default Root;


//////////////////


function canShowButtons(properties) {
	let path = window.location.hash.split('?')[0];
	let showBackButton = false;
	let backAlign = "right";
	let showLogo = false;
	let solid = false;
	let showHomeButton = true;
	let showPreferencesButton = false;
	let showTimeout = false;
	let hideMiniCart = false;
	let showSearch = false;

	if (ios) {
		if (!standalone && safari) {
			//browser
			showBackButton = true;
		} else if (standalone && !safari) {
			//standalone
		} else if (!standalone && !safari) {
			//uiwebview
			showBackButton = true;
		}
	}

	if (isInNativeApp()) {
		showBackButton = true;
	}

	if (path === '#/'
		|| path === '#/journey/1'
		|| path === '#/preferences'
		|| path === '#/preferences/raffle/withdraw/done'
		|| path.indexOf('/preorder-confirmation/') !== -1
		|| path.indexOf('/order-confirmation/') !== -1) showBackButton = false;
	if (path === '#/'
		|| path.indexOf('/preferences/') !== -1
		|| path.indexOf('/product/') !== -1
		|| path.indexOf('/preorder-confirmation/') !== -1
		|| path.indexOf('/order-confirmation/') !== -1
		|| path.indexOf('/checkout/') !== -1) showPreferencesButton = true;
	if (path.indexOf('/checkout/') !== -1) backAlign = "left-offset";
	if (path === '#/login' || path === '#/signup' || path.indexOf('/journey/') !== -1) backAlign = "left";
	if (path === '#/' || path.indexOf('/checkout/') !== -1 || path.indexOf('/preorder-confirmation/') !== -1 || path.indexOf('/order-confirmation/') !== -1) { showLogo = true; solid = true; }
	if (showPreferencesButton || showBackButton || path === '#/login' || path === '#/signup' || path.indexOf('/journey/') !== -1 || path.indexOf('/checkout') !== -1 || path.indexOf('/payment') !== -1 || path.indexOf('/klarna') !== -1) { showHomeButton = false; }
	if (path.indexOf('/checkout') !== -1 || path.indexOf('/payment') !== -1) {
		showTimeout = true; solid = true; showLogo = true; backAlign = "left"; hideMiniCart = true;
		if (!showBackButton) {
			showPreferencesButton = true;
		}
	}

	if (path === '#/') {
		showSearch = true;
	}

	if (ios && window.history.length === 1 && path.indexOf('/product/') !== -1) {
		showBackButton = false;
		showHomeButton = true;
	}

	if (path.indexOf('/onboarding') !== -1) {
		showLogo = false;
		showBackButton = false;
		showHomeButton = false;
	}

	if (path.indexOf('/search') !== -1) {
		showLogo = true;
		backAlign = 'left'
		showBackButton = true;
		showHomeButton = false;
	}


	//Campaign Logic

	//For the "Home Page"
	if (path === '#/' && properties && properties.isCampaignSlide) {
		showLogo = properties.pageHeaderLogo;
		solid = !properties.pageTransperentHeader
	}

	//For Content Pages 
	if (path.indexOf('/page/') !== -1 && properties) {
		backAlign = "left";
		showLogo = properties.pageHeaderLogo;
		solid = !properties.pageTransperentHeader
	}

	//If Were On native bake in, we should never show the account button
	if (isInNativeApp()) {
		showPreferencesButton = false;
	}

	return { showBackButton, backAlign, showHomeButton, showPreferencesButton, solid, showLogo, showTimeout, hideMiniCart, showSearch };
}
