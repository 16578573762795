import React from 'react';

import { trackPage } from '../../../services/analytics/analytics';
import Tutorial from '../../tutorial/Tutorial';


//////////////////


export default () => {

	trackPage('preferences/help/tutorial');

	return (
		<div className="Preferences Tutorial">
			<Tutorial />
		</div>
	)
};

