import { logout } from '../../actions/auth';
import { showError } from '../../actions/error';
import { loaded, loaded3DS } from '../../actions/loading';
import { startupComplete } from '../../modules/startup';
import { postMessage } from '../nativeBridge';
import { OAUTH_GET, OAUTH_REFRESH } from '../nativeBridge/messageTypes';
import { nativeClose } from '../nativeClose/nativeClose';

export const oauthGet = () => {
	let message = {
		messageType: OAUTH_GET,
		data: {}
	};
	postMessage(message);
};

export const oauthRefresh = () => {
	if (!localStorage.getItem('oauthRefresh')) {
		//Stop Multiple Oauth Refreshes been in progress
		localStorage.setItem('oauthRefresh', 'in-progress', 30)
		let message = {
			messageType: OAUTH_REFRESH,
			data: {}
		};
		postMessage(message);
	}
};

export function oauthError() {
	return dispatch => {
		dispatch(loaded())
		dispatch(loaded3DS())
		dispatch(startupComplete())
		dispatch(logout(false));
		dispatch(showError({ errorCode: 'session-expired-oauth', dismissButton: 'Login Again' }, () => {
			nativeClose(true)
		}))
	}
}