import React from 'react';
import './AnswerList.scss';

const AnswerList = ({ answers, onSelectAnswer, selectdAnswer }) => {
	const handleAnswerSelection = (answer) => {
		onSelectAnswer(answer);
	}

	let list = answers.map((answer, i) => {
		return (
			<li key={i}>
				<div className="check-option">
					<input type="radio" name="answerSelect" id={`answerSelected_${i}`} checked={(selectdAnswer === i)} 
					onClick={(e) => handleAnswerSelection(i)}/>
					<label htmlFor={`answerSelected_${i}`}>
						<span className="check-box"></span>
						<span className="name">{String.fromCharCode(65+i)}. {answer}</span>
					</label>
				</div>
				{/* <button
					className={(selectdAnswer === i ? "answer selected" : "answer")}
					onClick={(e) => handleSizeSelection(i)}
				>
					{answer}
				</button> */}
			</li>
		)
	});

	return (
		<ul className="answer-list">
			{list}
		</ul>
	);
};


export default AnswerList;