import { getBookmarks, addBookmark, hasBookmark, removeBookmark } from '../services/bookmarks/bookmarks';
import { addNotifiedProduct, removeNotifiedProduct } from '../actions/notifications';
import { addProductEvent, removeProductEvent } from '../actions/calendarEvents';
import { getProducts } from '../services/api';
import { showErrorFromResponse } from './error';
import { EVTS, trackEvent } from '../services/analytics/analytics';


//////////////////
// public

export const ADD_PRODUCT_BOOKMARK = 'ADD_PRODUCT_BOOKMARK';
export const CLEAR_BOOKMARK_PRODUCTS = 'CLEAR_BOOKMARK_PRODUCTS';
export const GET_PRODUCTS_FOR_BOOKMARKS = 'GET_PRODUCTS_FOR_BOOKMARKS';
export const HAVE_PRODUCTS_FOR_BOOKMARKS = 'HAVE_PRODUCTS_FOR_BOOKMARKS';
export const LOAD_PRODUCTS_FOR_BOOKMARKS = 'LOAD_PRODUCTS_FOR_BOOKMARKS';
export const PROCESS_BOOKMARK_PRODUCTS = 'PROCESS_BOOKMARK_PRODUCTS';
export const REMOVE_PRODUCT_BOOKMARK = 'REMOVE_PRODUCT_BOOKMARK';

/**
 * Clears the state
 * @return {Object}
 */
export function clearBookmarkProducts() {
	return {
		type: CLEAR_BOOKMARK_PRODUCTS
	};
};

/**
 * Loads all products in, orders them by date, and groups them by month
 * @returns {function} thunk
 */
export function loadProducts() {
	return dispatch => {
		dispatch({
			type: LOAD_PRODUCTS_FOR_BOOKMARKS
		})
		getProducts()
			.then(data => dispatch(processProducts(data)))
			.then(data => dispatch(getProductsForBookmarks(data)))
			.then(data => dispatch(haveBookmarkedProducts()))
			.catch(error => dispatch(showErrorFromResponse(error)));
	};
};

/**
 * Toggles a bookmark on a product
 * @param {string} productID
 * @param {string} productName
 * @returns {function} thunk
 */
export function toggleBookmark(productID, productName) {
	return (dispatch) => {
		if (hasBookmark(productID)) {
			dispatch(removeBookmarkedProduct(productID, productName));
		} else {
			dispatch(bookmarkProduct(productID, productName));
		}
	}
}

/**
 * Toggles a bookmark on a product, bases on the bookmark state also add adds or removes a notification and event for a product
 * @param {string} productID
 * @param {string} productName
 * @returns {function} thunk
 */
export function toggleNotiAndEventForBookmarks(productID, productName, launchDate, launchType) {
	return (dispatch) => {
		if (hasBookmark(productID)) {
			dispatch(removeBookmarkedProduct(productID, productName));
			dispatch(removeNotifiedProduct(productID));
			dispatch(removeProductEvent(productID));
		} else {
			dispatch(bookmarkProduct(productID, productName));
			dispatch(addNotifiedProduct(productID, productName, launchDate, launchType));
			dispatch(addProductEvent(productID, productName, launchDate));
		}
	}
}

/**
 * Add a product to bookmarks
 * @param {string} productID
 * @param {string} productName
 * @returns {Object}
 */
export function bookmarkProduct(productID, productName) {
	addBookmark(productID);
	return dispatch => {
		dispatch({
			type: ADD_PRODUCT_BOOKMARK
		});
		dispatch(loadProducts());
		trackEvent(EVTS.CAT.PRODUCT, EVTS.ACT.BOOKMARK_ADD, productName);
	};
}


//////////////////
// private

/**
 * Pulls out the products for the bookmarks
 * @@returns {Object}
 */
export function getProductsForBookmarks() {
	let bookmarks = getBookmarks();
	return {
		bookmarks,
		type: GET_PRODUCTS_FOR_BOOKMARKS
	}
}

/**
 * Called when we have the product info for the users bookmarks
 * @returns {Object}
 */
export function haveBookmarkedProducts() {
	return {
		type: HAVE_PRODUCTS_FOR_BOOKMARKS
	}
};

/**
 * Processes the array of products from mosaic
 * @param {Array.<Object>} products
 * @returns {Object}
 */
export function processProducts(products) {
	return {
		products,
		type: PROCESS_BOOKMARK_PRODUCTS
	}
}

/**
 * Remove a product from bookmarks
 * @param {string} productID
 * @returns {Object}
 */
export function removeBookmarkedProduct(productID, productName) {
	removeBookmark(productID);
	trackEvent(EVTS.CAT.PRODUCT, EVTS.ACT.BOOKMARK_REMOVE, productName);
	return {
		type: REMOVE_PRODUCT_BOOKMARK,
		productID
	};
}
