import { connect } from 'react-redux';
import React from 'react';
import TextField from '../textInput/TextInput';
import TitleBlock from '../titleBlock/TitleBlock';
import PrivacyNotice from '../privacyNotice/PrivacyNotice';
import config from '../../services/config/config';
import { navigateToExternal } from '../../services/navigate/navigate';
import withRecaptchaV3 from '../recaptcha/recaptcha';
import './LoginSignUpForm.scss';
import AddressSignupForm from '../addressForm/AddressSignupForm';

//////////////////


const LoginSignUpForm = React.createClass({
	onSubmit(e) {
		e.preventDefault();
		let auth = this.props.auth;
		// refresh the token and submit
		this.props.refreshReCaptchaToken()
			.then((verification) => this.props.submitForm({...auth}, verification));
	},
	getInitialState: () => ({
		inFocus: {
			firstName: undefined,
			lastName: undefined,
			email: undefined,
			password: undefined,
			password2: undefined,
			billing: undefined,
			delivery: undefined,
		},
		isFirstNameValid: undefined,
		isLastNameValid: undefined,
		isEmailValid: undefined,
		isPasswordValid: undefined,
		isPassword2Valid: undefined,

		isDeliveryValid: undefined,
		isBillingValid: undefined,

		hideFormAction: false,
	}),
	updateValidationStates(isFieldValid, fieldName) {
		// Validation state of individual fields
		if (this.state[fieldName] !== isFieldValid) {
			const stateUpdate = {};
			stateUpdate[fieldName] = isFieldValid;
			this.setState(stateUpdate);
		}
	},
	updatePreferences(newPreferences, type) {
		let preferences = { ...this.props.auth[type] }
		Object.assign(preferences, newPreferences)

		let newState = {};
		newState[type] = preferences
		this.props.updateCallback(newState);
	},
	updatePreference(property, val, type) {
		let newPreferences = {}
		newPreferences[property] = val;
		this.updatePreferences(newPreferences, type)
	},
	// onClick handlers
	changeProperty(val, property, type) {
		this.updatePreference(property, val, type);
	},
	deliveryLocaleChange(locale) {
		this.updatePreferences({ postcode: '', locale: locale }, 'delivery');
		this.deliveryPostcodeRef.setState({
			valid: false
		});
	},
	billingLocaleChange(locale) {
		this.updatePreferences({ postcode: '', locale: locale }, 'billing');
		this.billingPostcodeRef.setState({
			valid: false
		});
	},
	updateFocusStates(isInFocus, fieldName) {
		//Get the current inFocus object
		var currentInFocus = { ...this.state.inFocus }

		//Update the inFocus object and store it back to the state
		currentInFocus[fieldName] = isInFocus;
		this.setState({ inFocus: currentInFocus });

		//Check if any of the fields are now in focus
		let aFieldIsInFocus = Object.values(currentInFocus).some((el) => el === true);

		//Check if user agent is Android
		let userAgent = window.navigator.userAgent.toLowerCase();
		let isAndroid = /Android/i.test(userAgent);

		//Update CSS depending on focus state
		if (aFieldIsInFocus && isAndroid) {
			this.setState({ hideFormAction: true });
		} else {
			this.setState({ hideFormAction: false });
		}
	},
	setDeliveryRef(input) {
		this.deliveryPostcodeRef = input;
	},
	setBillingRef(input) {
		this.billingPostcodeRef = input;
	},
	forgotPassword(evt) {
		navigateToExternal(`${config.storeUrl}myaccount/forgot-password/`);
	},
	render() {
		let { auth, isSignup, updateCallback } = this.props;
		let { isFirstNameValid, isLastNameValid, isEmailValid, isPasswordValid, isPassword2Valid, isDeliveryValid, isBillingValid } = this.state;

		const isFormFull = type => {
			switch (type) {
				case 'login':
					return isEmailValid && isPasswordValid;

				case 'signup':
					return isFirstNameValid && isLastNameValid && isEmailValid && isPasswordValid && isPassword2Valid && isDeliveryValid && (auth.delivery.useAsBilling || isBillingValid);

				default:
					return false;
			}
		};
		let canSubmit = isFormFull(isSignup ? 'signup' : 'login');

		return (
			<div className="loginSignup">
				<form onSubmit={this.onSubmit}>
					<div className="content-scroll">
						<TitleBlock title={isSignup ? 'Sign up' : 'Login'} />
						<div className="form-horizontal">
							{
								isSignup
									? <span className="signupFields">
										<div className="form-group">
											<TextField
												type="text"
												name="name"
												floatingLabelText="First Name"
												value={auth.firstName}
												onChange={e => { updateCallback({ firstName: e.target.value }); this.changeProperty(e.target.value, 'firstName', 'delivery'); }}
												isFieldFocused={isInFocus => this.updateFocusStates(isInFocus, 'firstName')}
												required={true}
												isFieldValid={isValid => this.updateValidationStates(isValid, 'isFirstNameValid')} />
										</div>
										<div className="form-group">
											<TextField
												type="text"
												name="name"
												floatingLabelText="Last Name"
												value={auth.lastName}
												onChange={e => { updateCallback({ lastName: e.target.value }); this.changeProperty(e.target.value, 'lastName', 'delivery'); }}
												isFieldFocused={isInFocus => this.updateFocusStates(isInFocus, 'lastName')}
												required={true}
												isFieldValid={isValid => this.updateValidationStates(isValid, 'isLastNameValid')} />
										</div>
									</span>
									: null
							}
							<div className="form-group">
								<TextField
									type="email"
									name="email"
									floatingLabelText="Email"
									value={auth.username}
									onChange={e => updateCallback({ username: e.target.value })}
									isFieldFocused={isInFocus => this.updateFocusStates(isInFocus, 'email')}
									isFieldValid={isValid => this.updateValidationStates(isValid, 'isEmailValid')}
									required={true} />
							</div>
							<div className="form-group">
								<TextField
									type="password"
									name="password"
									floatingLabelText="Password"
									value={auth.password}
									onChange={e => updateCallback({ password: e.target.value })}
									isFieldValid={isValid => this.updateValidationStates(isValid, 'isPasswordValid')}
									isFieldFocused={isInFocus => this.updateFocusStates(isInFocus, 'password')}
									required={true} />
							</div>
							{
								isSignup
									? <div className="form-group">
										<TextField
											type="password"
											name="password2"
											floatingLabelText="Confirm password"
											value={auth.password2}
											onChange={e => updateCallback({ password2: e.target.value })}
											isFieldValid={isValid => this.updateValidationStates(isValid, 'isPassword2Valid')}
											isFieldFocused={isInFocus => this.updateFocusStates(isInFocus, 'password2')}
											required={true} />
									</div>
									: null
							}
							{
								isSignup
									? <div className="form-group form-text">
										<p>Please enter 8 alpha numeric characters (letters & numbers)</p>
									</div>
									: null
							}

							{
								isSignup
									? <AddressSignupForm
										useCase="delivery"
										postcodeRef={this.setDeliveryRef}
										onLocaleChange={this.deliveryLocaleChange}
										onFormChange={(val, property) => this.changeProperty(val, property, 'delivery')}
										isFormValid={isValid => this.updateValidationStates(isValid, 'isDeliveryValid')}
										preferences={auth.delivery}
										isFieldFocused={isInFocus => this.updateFocusStates(isInFocus, 'delivery')}
									/>
									: null
							}

							{
								isSignup && !auth.delivery.useAsBilling
									? <AddressSignupForm
										useCase="billing"
										postcodeRef={this.setBillingRef}
										onLocaleChange={this.billingLocaleChange}
										onFormChange={(val, property) => this.changeProperty(val, property, 'billing')}
										isFormValid={isValid => this.updateValidationStates(isValid, 'isBillingValid')}
										preferences={auth.billing}
										isFieldFocused={isInFocus => this.updateFocusStates(isInFocus, 'billing')}
									/>
									: null
							}

							{
								isSignup ? <div className='desktop'>
									<div className="form-group form-text">
										<p>This app is protected by reCAPTCHA and the Google{'\u00A0'}
										<span className='link' onClick={() => navigateToExternal("https://policies.google.com/privacy")} >Privacy Policy</span> and{'\u00A0'}
										<span className='link' onClick={() => navigateToExternal("https://policies.google.com/terms")}>Terms of Service</span> apply.</p>
									</div>
								<PrivacyNotice />
								</div> : null
							}

							{
								isSignup ? <div className="spacer" /> : null
							}

							<div className={`form-action ${this.state.hideFormAction ? 'hidden' : ''}`}>
								<div className="form-group form-text">
									<p>This app is protected by reCAPTCHA and the Google{'\u00A0'}
										<span className='link' onClick={() => navigateToExternal("https://policies.google.com/privacy")} >Privacy Policy</span> and{'\u00A0'}
										<span className='link' onClick={() => navigateToExternal("https://policies.google.com/terms")}>Terms of Service</span> apply.</p>
								</div>
								<PrivacyNotice />
								<div className="inline">
									{
										isSignup
											? null
											: <button type="button" className="action forgotten" onClick={this.forgotPassword}>Forgotten Password?</button>
									}
									<button type="submit" className="action login" disabled={!canSubmit}><div className="button-contents">{isSignup ? 'Sign up' : 'Login'}</div></button>
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>
		);
	}
});

const mapStateToProps = state => {
	const { auth } = state;
	return { auth };
};


//////////////////


export default connect(
	mapStateToProps
)(withRecaptchaV3(LoginSignUpForm, 'Login/SignUp'));
