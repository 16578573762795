import { hashHistory } from 'react-router';
import { connect } from 'react-redux';
import React from 'react';
import TitleBlock from '../../titleBlock/TitleBlock';
import './AccountDeletion.scss';

import { trackPage } from '../../../services/analytics/analytics';



//////////////////


const Confirm = React.createClass({
  componentWillMount() {
    trackPage('preferences/account-deletion/done');
  },
  backToDashboard() {
    hashHistory.goBack();
  },
  onSave() {
    this.backToDashboard();
  },
  render() {
    return (
      <div className="Preferences Delete-Preferences">
        <div className="content-scroll">
          <TitleBlock title="Account Deletion" />
          <div className="form-horizontal">
            <div>Thank you for submitting your request to us.</div>
            <br/>
            <div>You'll continue to have access to your account until we've processed your request.</div>
          </div>
        </div>
        <div className="form-action fixed">
          <button className="action" onClick={() => hashHistory.push('/')}>
            <div className="button-contents">Continue</div>
          </button>
        </div>
      </div>
    );
  }
});

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {};
};


//////////////////


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Confirm);
