import { showError } from '../actions/error';
import { postMessage } from '../services/nativeBridge';
// import { EVTS, trackEvent } from '../services/analytics/analytics';
import { addNotification, hasNotification, rebuildNotifications, removeNotification, setNotifications } from '../services/notifications/notifications';


//////////////////
// public

export const ADD_NOTIFICATION_PRODUCT = 'ADD_NOTIFICATION_PRODUCT';
export const HAVE_NATIVE_RESPONSE = 'HAVE_NATIVE_RESPONSE';
export const REBUILD_NOTIFICATIONS = 'REBUILD_NOTIFICATIONS';
export const REMOVE_NOTIFICATION_PRODUCT = 'REMOVE_NOTIFICATION_PRODUCT';


/**
 * Called when a get a response from the native apps
 * @param {Object} response
 * @returns {function} thunk
 */
export function haveNativeResponse(response) {
	return (dispatch, getState) => {
		// get the notifications state and, if response is success, update in localStorage
		let { notifications } = getState();
		if (response.success) {
			setNotifications(notifications.updateWaiting);
		} else { //if error, show error
			if (notifications.showErrors) {
				let error = {
					errorCode: `notification-${response.reason || 'not-subscribed'}`
				};
				dispatch(showError(error));
			}
		}

		// send to action to handle, whether successful (updates state)	or not
		dispatch({
			response,
			type: HAVE_NATIVE_RESPONSE
		});
	}
};

/**
 * Sends the notifications message to native apps
 * @param {Object} message
 * @returns {function} thunk
 */
export function sendNotificationsToNative(message) {
	return dispatch => postMessage(message);
};

/**
 * Toggles a notification for a product
 * @param {string} productID product ID to toggle notification for
 * @param {string} name name of product
 * @param {string} launchDate product launch date in ISO string format
 * @returns {function} thunk
 */
export function toggleNotification(productID, name, launchDate) {
	return dispatch => {
		if (hasNotification(productID)) {
			dispatch(removeNotifiedProduct(productID, name, launchDate));
		} else {
			dispatch(addNotifiedProduct(productID, name, launchDate));
		}
	}
};

/**
 * Triggers a notifications rebuild
 * @param {Array.<Object>} [products] array of products to update launchDates on notifications
 */
export function triggerRebuildNotifications(products) {
	let notifications = rebuildNotifications(products);
	return dispatch => {
		dispatch({
			notifications,
			type: REBUILD_NOTIFICATIONS
		});
	};
};

/**
 * Adds a notification for a product
 * @param {string} productID product ID for which to notify
 * @param {string} name name of product
 * @param {string} launchDate product launch date in ISO string format
 * @returns {Object}
 */
export function addNotifiedProduct(productID, name, launchDate, launchType) {
	// trackEvent(EVTS.CAT.PRODUCT, EVTS.ACT.NOTIFY_ADD, productID); //not yet been asked for

	let notifications = addNotification(productID, name, launchDate, launchType);
	return {
		notifications,
		type: ADD_NOTIFICATION_PRODUCT
	};
}



//////////////////
// private

/**
 * Removes a notification for a product
 * @param {string} productID product ID for which to remove notification
 * @returns {Object}
 */
export function removeNotifiedProduct(productID) {
	// trackEvent(EVTS.CAT.PRODUCT, EVTS.ACT.NOTIFY_REMOVE, productID); //not yet been asked for

	let notifications = removeNotification(productID);
	return {
		notifications,
		type: REMOVE_NOTIFICATION_PRODUCT
	};
}
