// Container component for the checkout page
import { connect } from 'react-redux';
import {
    clearSelectedStore,
    completeOrder,
    loadDataForPreAuthCheckout,
    loadStores,
    selectDeliveryMethod,
    selectPaymentMethod,
    selectStore,
    toggleUseBillingAsDelivery,
    toggleCheckoutConfirmation,
    updateBillingAddress,
    updateCustomer,
    updateDelivery,
    updateLocale,
    updateLocales,
    updateSearch,
    updateStores,
    createPreAuthOrder,
    completeTDS,
    enablePaymentMethods,
    disableKlarna
} from '../actions/checkout';
import { updateDeliveryTypes } from "../actions/deliveryTypes";
import { clearSession } from '../actions/session';
import { removeItem, selectQuantityOfEntries } from '../actions/product';
import PreAuth from '../components/checkout/PreAuth';


//////////////////


/**
 * Maps the checkout state from the application state to props for the checkout component
 * @param {Object} state
 * @return {Object} checkout state
 */
const mapStateToProps = (state) => {
    const { checkout, loading, deliveryTypes, session, content, product, user } = state;
    return { checkout, loading, deliveryTypes, session, theme: content.theme, products: content.products, product, loyalty: user.preferences.loyalty || {isPremium: false} };
};

/**
 * Maps the checkout actions to props for the checkout component
 * @param {function} dispatch
 * @return {Object} checkout props
 */
const mapDispatchToProps = (dispatch) => {
    return {
        billingAddressChanged: (address, product, locale, useAs) => {
            if (address.locale) {
                dispatch(enablePaymentMethods(product, locale, address, useAs))
            }
            dispatch(updateBillingAddress(address))
        },
        clearSelectedStore: () => dispatch(clearSelectedStore()),
        clearSession: () => dispatch(clearSession()),
        completeOrder: (checkout, session) => dispatch(completeOrder(checkout, session)),
        customerDetailsChanged: (details) => dispatch(updateCustomer(details)),
        deliveryAddressChanged: (address) => dispatch(updateDelivery(address)),
        enablePaymentMethods: (product, locale, billing, useAs) => dispatch(enablePaymentMethods(product, locale, billing, useAs)),
        disableKlarna: () => dispatch(disableKlarna()),
        filterStores: (stores) => dispatch(updateStores(stores)),
        loadDataForPreAuthCheckout: (sku) => dispatch(loadDataForPreAuthCheckout(sku)),
        loadStores: () => dispatch(loadStores()),
        localeChange: (locale, product, billing, useAs) => {
            dispatch(enablePaymentMethods(product, locale, billing, useAs))
            dispatch(updateLocale(locale))
        },
        receivedDeliverytypes: (deliveryTypes) => dispatch(updateDeliveryTypes(deliveryTypes)),
        receivedLocales: (locales) => dispatch(updateLocales(locales)),
        selectDeliveryMethod: (deliveryMethod) => dispatch(selectDeliveryMethod(deliveryMethod)),
        selectPaymentMethod: (method) => dispatch(selectPaymentMethod(method)),
        selectStore: (store) => dispatch(selectStore(store)),
        toggleUseBillingAsDelivery: (product, locale, address, useAs) => {
            dispatch(enablePaymentMethods(product, locale, address, !useAs))
            dispatch(toggleUseBillingAsDelivery())
        },
        toggleCheckoutConfirmation: () => dispatch(toggleCheckoutConfirmation()),
        updateSearch: (value) => dispatch(updateSearch(value)),
        removeItem: (stockID) => dispatch(removeItem(stockID)),
        preAuthoriseOrder: ({ checkout, deliveryTypes, optionID, verification, answer }) => dispatch(createPreAuthOrder(checkout, deliveryTypes, optionID, verification, answer)),
        complete3DS: () => dispatch(completeTDS()),
        selectQuantityOfEntries: (quantity) => dispatch(selectQuantityOfEntries(quantity))
    }
};

// map state and dispatch methods to props for checkout
const PreAuthPage = connect(
    mapStateToProps,
    mapDispatchToProps
)(PreAuth);

export default PreAuthPage;
