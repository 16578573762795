import { connect } from 'react-redux';
import React from 'react';
import PageWarpper from './pageTypes/PageWrapper'
import { visitPage } from '../../actions/content'

import './Splash.scss'

const Splash = React.createClass({
	render() {
		let { campaigns, campaign,pageVisted } = this.props;
		let content = campaigns.pages.find((page) => (page.id === campaign.homePage))
		return (
			<div className="Splash">
				<span onClick={()=>pageVisted(content)} className="close"/>
				<PageWarpper page={content} />
			</div>
		);
	}
});

const mapStateToProps = state => {
	const { content } = state;
	return { campaigns: content.campaigns };
};

const mapDispatchToProps = (dispatch) => {
    return {
        pageVisted: (page) => dispatch(visitPage(page))
    }
}


//////////////////


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Splash);
