import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { getQueryParam, addQueryToURL, getQueryStringAsObject } from '../../services/url';
import config from '../../services/config/config'
import { UAT, DEV, LIVE, } from '../../services/config/config'


import './Debug.scss'
const Debug = React.createClass({
    gotoStage(stageUrl) {
        let queryParams = getQueryStringAsObject();
        queryParams.debug = 1;
        stageUrl = addQueryToURL(stageUrl, queryParams)
        window.location = stageUrl;
    },
    toggleBypassCache() {
        let queryParams = getQueryStringAsObject();
        queryParams.debug = 1;
        if (getQueryParam('bypassCache')) {
            delete queryParams.bypassCache;
        }
        else {
            queryParams.bypassCache = 1;
        }
        window.location = addQueryToURL(window.location.origin, queryParams);

    },
    reload() {
        let queryParams = getQueryStringAsObject();
        queryParams.debug = 1;
        window.location = addQueryToURL(window.location.origin, queryParams);

    },
    render() {
        let { show, hideDebug } = this.props
        return (<ReactCSSTransitionGroup
            transitionName="debug-bar"
            transitionEnterTimeout={200}
            transitionLeaveTimeout={200}
        >
            {
                show ?
                    <div className="debugMenu">
                        <h1> Debug Menu</h1>
                        <button className="action" hidden={config.env === DEV} onClick={() => this.gotoStage(`https://dev-${config.store}-mosaic-webapp.nonprod.jdmesh.co`)}>
                            <div className="button-contents">Switch to DEV</div>
                        </button>
                        <button className="action" hidden={config.env === UAT} onClick={() => this.gotoStage(`https://uat-${config.store}-mosaic-webapp.nonprod.jdmesh.co`)}>
                            <div className="button-contents">Switch to UAT</div>
                        </button>
                        <button className="action" hidden={config.env === LIVE} onClick={() => this.gotoStage(`https://${config.store}-mosaic-webapp.jdmesh.co`)}>
                            <div className="button-contents" >Switch to PROD</div>
                        </button>
                        <div className="check-option" onClick={() => this.toggleBypassCache()}>
                            <input type="checkbox" id="bypassCache" checked={getQueryParam('bypassCache')} />
                            <label htmlFor="bypassCache">
                                <span className="check-box tick bypassCache"></span>
                                <span className="name">Bypass on device cache</span>
                            </label>
                        </div>
                        <button className="action" onClick={() => window.location= 'https://mosaic-bridge-tester.nonprod.jdmesh.co/'}>
                            <div className="button-contents" >Bridge Tester</div>
                        </button>
                        <button className="action" onClick={() => this.reload()}>
                            <div className="button-contents">Reload</div>
                        </button>
                        <button className="action close-button" onClick={() => hideDebug()}>
                            <div className="button-contents">Close</div>
                        </button>
                    </div > : null}
        </ReactCSSTransitionGroup >
        )
    }
});

export default Debug;