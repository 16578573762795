// Container component for the header
import { connect } from 'react-redux';
import { toggleMiniCart } from '../actions/header';
import { retreiveSession } from '../actions/session';
import Header from '../components/header/Header';
import DesktopHeader from '../components/headerDesktop/Header';
import NativeAppHeader from '../components/headerNativeApp/Header';

import config, { isInNativeApp } from '../services/config/config'
import {
	clearSession
} from '../actions/session';

/**
 * mapStateToProps
 *
 * @description maps the state from the application state to props for the
 * Header component
 *
 * @param [object] state
 * @return [object] order state
 */
const mapStateToProps = (state, ownProps) => {
	const { session, header, content, checkout, user, auth } = state;
	const { appHeader, showBackButton, backAlign, solid, showLogo } = ownProps;
	return { session, header, content, checkout, appHeader, showBackButton, backAlign, solid, showLogo, preferences: user.preferences, auth };
}

/**
 * mapDispatchToProps
 *
 * @description maps actions to props for the Header component
 *
 * @param [function] dispatch
 * @return [object] checkout props
 */
const mapDispatchToProps = (dispatch) => {
	return {
		toggleMiniCart: (display) => dispatch(toggleMiniCart(display)),
		getSession: (sessionID) => dispatch(retreiveSession(sessionID)),
		clearSession: () => dispatch(clearSession())
	}
}

let HeaderComponent = config.layout.useDesktopHeader ? isInNativeApp() ? NativeAppHeader : DesktopHeader : Header

// map state and dispatch methods to props for Header
const HeaderContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(HeaderComponent);

export default HeaderContainer;