import { connect } from 'react-redux';
import { clearCalendar, loadProducts } from '../actions/pastLaunches';
import Calendar from '../components/pastLaunches/PastLaunches';

const mapStateToProps = state => {
	const { pastLaunches, loading, content } = state;
	return { productsByMonth: pastLaunches.productsByMonth, loading, theme: content.theme };
};

const mapDispatchToProps = dispatch => {
	return {
		clearCalendar: () => dispatch(clearCalendar()),
		getPastLaunches: () => dispatch(loadProducts())
	};
};

const PastLaunchesPage = connect(
	mapStateToProps,
	mapDispatchToProps
)(Calendar);

export default PastLaunchesPage;
