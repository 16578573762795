import React from 'react';
import { connect } from 'react-redux';

import './LoyaltyShare.scss';

import shoebox from './images/shoebox.jpg'
import birthday from './images/birthday.png'
import birthdayShare from './images/share/BirthdaySocial.jpg'
import MoreInfoPopup from '../moreInfoPopup/MoreInfoPopup';
import { shareImage } from '../../services/share/share';

export const BOOST_COPY = "size?access+ boosts reward you with temporary size?access+ status. This means, that each draw you enter - with a boost – you get five entries, giving you a far greater shot of buying your latest must-haves. What’s more,if you don’t win, your boosts are returned for you to use again. So keep going! Please note that you are only eligible to win once per draw and all charity draws are excluded."
export const DATE_BOOST_COPY = "For your birthday and membership anniversary you will gain temporary size?access+ status, during this period, each draw that you enter will have five entries! Enter as many draws as you want with a greater shot at buying your latest must-haves. Good luck! Please note that you are only eligible to win once per draw and all charity draws are excluded."

function importAll(r) {
	let images = {};
	r.keys().forEach((item, index) => { images[item.replace('./', '').replace(/\.(png|jpe?g|svg)$/, '')] = r(item); });
	return images
}

const Loyalty = React.createClass({
	getInitialState() {
		return { showLearnMore: false }
	},
	showLearnMore() {
		this.setState({ showLearnMore: true })
	},
	hideLearnMore() {
		this.setState({ showLearnMore: false })
	},
	render() {

		const shareImages = importAll(require.context('./images/share/achievements', false, /\.(png|jpe?g|svg)$/));
		const achievements = importAll(require.context('../preferences/membership/achievements/assets', false, /\.(png|jpe?g|svg)$/));
		const anniversary = importAll(require.context('./images/anniversary', false, /\.(png|jpe?g|svg)$/));

		let { type, achievement, badges, onDismiss } = this.props;
		let { showLearnMore } = this.state;

		let config = {};
		switch (type) {
			case 'birthday':
				config = {
					background: shoebox,
					heroImage: birthday,
					shareImage: window.location.origin + birthdayShare,
					title: "Happy Birthday",
					description: "We've made you a size?access+ member for your special week",
					line3: '',
					learnMorePopup: DATE_BOOST_COPY
				}
				break;
			case 'achievement':
				config = {
					background: shoebox,
					heroImage: achievements[`${achievement}Y`],
					shareImage: window.location.origin + shareImages[`Achievement${achievement}`],
					title: "Achievement Unlocked!",
					description: `Won ${achievement} draws.`,
					line3: 'Gained 1 size?access+ boost!',
					learnMorePopup: BOOST_COPY
				}
				break;
			case 'sticker':
				let badge = badges.find((badge) => badge.id === achievement)
				config = {
					background: badge.background,
					heroImage: badge.foreground,
					shareImage: badge.shareImage,
					title: "Sticker Collection Complete!",
					description: `Collected 5 ${badge.name}${badge.name.endsWith("s") ? "." : "'s."}`,
					line3: 'Gained 1 size?access+ boost!',
					learnMorePopup: BOOST_COPY
				}
				break;
			case 'anniversary':
				config = {
					background: anniversary[`BG${achievement || 1}`],
					heroImage: anniversary[`I${achievement || 1}`],
					shareImage: window.location.origin + anniversary[`S${achievement || 1}`],
					title: "Happy Anniversary!",
					description: "We've made you a size?access+ member for your special week",
					line3: '',
					learnMorePopup: DATE_BOOST_COPY
				}
				break;
			default:
				break;


		}
		return (<div className={`loyaltyNotification notification-${type}`} style={{ backgroundImage: `url(${config.background})` }}>
			<style>{`.header,.sidebar {display: none;}`}</style>
			<div>
				<img src={config.heroImage} alt={config.title} />
			</div>
			<h1>{config.title}</h1>
			<p>{config.description}<br />{config.line3}</p>
			<u onClick={() => this.showLearnMore()}>learn more</u>
			<div onClick={() => shareImage(config.title, config.description, config.shareImage)} className="shareButton">
				<div>share now</div>
			</div>
			<div className="form-action">
				<button onClick={onDismiss} className="action">
					<div className="button-contents">Done</div>
				</button>
			</div>
			<MoreInfoPopup showLearnMore={showLearnMore} hideLearnMore={this.hideLearnMore} text={config.learnMorePopup} />
		</div>);
	}
});

const mapStateToProps = state => {
	const { content } = state;
	return { badges: content.badges }
};

const mapDispatchToProps = dispatch => {
	return {}
};

//////////////////

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Loyalty);
