import { getUserData } from '../services/user';
import { prefillCheckout } from './checkout';
import { saveEncryptedCardData, getSavedCards, disablePaymentDetails, updateMarketingPreferences, getLoyalty, getMarketing, activateLoyaltyInMesh, getHomeContent, getCanReview, deleteUser } from '../services/api';
import { loading, loaded } from './loading';
import { showError } from './error';
import { EVTS, trackEvent } from '../services/analytics/analytics';
import storage from '../services/storage/storage';
import { showReviewLightbox } from '../services/showReviewBox/showReviewLightbox';
import { hashHistory } from 'react-router';


//////////////////
// public


export const CLEAR_PREFERENCES = 'CLEAR_PREFERENCES';
export const CHANGE_PREFERENCES = 'CHANGE_PREFERENCES';
export const GET_PREFERENCES = 'GET_PREFERENCES';
export const SAVE_USER_TO_DEVICE = 'SAVE_USER_TO_DEVICE';
export const SAVED_CARD_UPDATE = 'SAVED_CARD_UPDATE';
export const UPDATE_PREFERENCES = 'UPDATE_PREFERENCES';
export const LOYALTY_UPDATE = 'LOYALTY_UPDATE';
export const MARKETING_UPDATE = 'MARKETING_UPDATE';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';


/**
 * Clears the preferences from the state, so we can go back to dashboard, then back to a preferences page and
 * not have the fields showing whatever data was entered but not saved previously
 * @param {string} key which preferences object to clear (e.g. 'notifications')
 * @returns {Object}
 */
export function clearPreferencesFromState(key) {
    return {
        type: CLEAR_PREFERENCES
    };
};

export function hideLoyaltyNotification(index) {
    return {
        type: REMOVE_NOTIFICATION,
        index
    };
};

/**
 *
 */
export function deletePaymentCard() {
    return (dispatch, getState) => {
        let userData = getUserData();
        let userID;

        if (userData && userData.userID) userID = userData.userID;
        dispatch(loading('Deleting payment details'));
        if (userID) {
            return disablePaymentDetails(userID)
                .then((cards) => {
                    dispatch(savedCardUpdate(cards));
                })
                .catch((error) => {
                    dispatch(showError({ errorInfo: 'Unable to save payment details' }))
                })
                .then(() => {
                    dispatch(loaded());
                });
        }
    }
};

/**
 *
 */
export function getCard() {
    return (dispatch, getState) => {
        let userData = getUserData(),
            userID;

        if (userData && userData.userID) userID = userData.userID;
        if (userID) {
            dispatch(loading('Retrieving latest payment details'));
            return getSavedCards(userID)
                .then((cards) => {
                    dispatch(savedCardUpdate(cards));
                })
                .catch((error) => {
                    dispatch(showError({ errorInfo: 'Unable to load payment details' }))
                })
                .then(() => {
                    dispatch(loaded());
                })
        }
    }
};

/**
 *
 */
export function updateLoyalty(achievements) {
    return (dispatch, getState) => {
        let userData = getUserData(),
            userID;

        if (userData && userData.userID) userID = userData.userID;
        if (userID) {
            return getLoyalty(userID)
                .then((loyalty) => {
                    dispatch({
                        type: LOYALTY_UPDATE,
                        loyalty,
                        achievements
                    });
                })
        }
    }
};

/**
 * update marketing object
 */
export function updateMarketing() {

    return (dispatch, getState) => {
        let userData = getUserData(),
            userID;

        if (userData && userData.userID) userID = userData.userID;
        if (userID) {
            return getMarketing(userID)
                .then((marketing) => {

                    const birthday = marketing.birthday;

                    if (typeof birthday === 'string') {
                        const [day, month, year] = birthday.split('/');

                        dispatch({
                            type: UPDATE_PREFERENCES,
                            key: "marketing",
                            value: {
                                birthday: {
                                    day: parseInt(day),
                                    month: parseInt(month),
                                    year: parseInt(year)
                                },
                                favoriteBrand: marketing.favoriteBrand,
                                gender: marketing.gender,
                                optedIn: marketing.optedIn,
                                shoeSize: marketing.shoeSize,
                            }
                        });

                    } else {
                        dispatch({
                            type: UPDATE_PREFERENCES,
                            key: "marketing",
                            value: {
                                birthday: {
                                    day: '',
                                    month: '',
                                    year: ''
                                },
                                favoriteBrand: marketing.favoriteBrand,
                                gender: marketing.gender,
                                optedIn: marketing.optedIn,
                                shoeSize: marketing.shoeSize,
                            }
                        });
                    }
                })
        }
    }
};

/**
 *
 */
export function showReviewModal() {
    if (storage.getItem('shownReviewModal')) {
        return
    }
    let userData = getUserData(),
        userID;

    if (userData && userData.userID) userID = userData.userID;
    if (userID) {
        return getCanReview(userID)
            .then((data) => {
                if (data.canReview) {
                    storage.setItem('shownReviewModal', 'true', 60 * 60 * 24 * 30 * 6);
                    showReviewLightbox();
                }
            })
    }
};

/**
 *
 */
export function deleteAccount() {

    return dispatch => {
        let userData = getUserData(),
            userID;

        if (userData && userData.userID) userID = userData.userID;
        if (userID) {
            dispatch(loading('Making Request'));
            return deleteUser(userID)
                .then((data) => {
                    dispatch(loaded())
                    hashHistory.push('/preferences/delete/confirm');
                }).catch((error) => {
                    dispatch(loaded());
                    if (error.response) {
                        error.response.json().then((error) => {
                            dispatch(showError(error))
                        })
                    } else if (error.message) {
                        error.errorInfo = error.message;
                        dispatch(showError(error));
                    } else {
                        dispatch(showError(error));
                    }
                });
        }
    }
};


/**
 * Gets a specific set of user preferences, based on the key passed
 * @param {string} key which preferences object to get (e.g. 'notifications')
 * @returns {function} thunk
 */
export function getUserPreferences(key) {
    return dispatch => {
        dispatch({
            type: GET_PREFERENCES
        });
    };
};

/**
 * Called when a property of the preferences is changed. To keep things simple, this only updates the top
 * level of properties within a preferences object. So, if we're updating a property that is an array, or an
 * object, we must pass the full array/object.
 *
 * e.g. key = notifications, propertyKey = notifyTimeBefore, data = [ ... ]
 * @param {string} key which preferences object to update (e.g. notifications)
 * @param {string} propertyKey which property within the preferences[key] to update
 * @param {any} data the updated data of the property of preferences[key] to update
 * @returns {Object}
 */
export function onUserPreferencesChange(key, propertyKey, data) {
    return {
        data,
        key,
        propertyKey,
        type: CHANGE_PREFERENCES
    };
};

/**
 * Saves preferences to state for a newly logged in/signed up user`
 * @param {Object} preferences
 * @returns {function} thunk
 */
export function saveNewUser(preferences) {
    return (dispatch, getState) => {
        dispatch({
            preferences,
            type: SAVE_USER_TO_DEVICE
        });

        let { checkout } = getState();
        dispatch(prefillCheckout(checkout, preferences));
    }
};

/**
 * Called when a card is saved or deleted
 * @param {Boolean} card
 * @returns {Object}
 */
export function savedCardUpdate(cards) {
    return {
        type: SAVED_CARD_UPDATE,
        cards: cards
    };
}

/**
 * Saves card for user
 * @param {Object} data
 * @returns {function} thunk
 */
export function savePaymentCard(data) {
    return (dispatch, getState) => {
        let userData = getUserData(),
            userID;

        trackEvent(EVTS.CAT.ACCOUNT, EVTS.ACT.PAYMENT_DETAILS_SAVE);

        if (userData && userData.userID) userID = userData.userID;

        dispatch(loading('Saving payment details'));
        if (userID) {
            return saveEncryptedCardData(userID, data)
                .then((response) => getSavedCards(userID))
                .then((cards) => {
                    dispatch(savedCardUpdate(cards));
                })
                .catch((error) => {
                    dispatch(showError({ errorInfo: 'Unable to save payment details' }))
                })
                .then(() => {
                    dispatch(loaded());
                });
        }
    }
};

/**
 * Updates a user preferences object on local storage
 * @param {*} key which preferences object to update (e.g. 'notifications')
 * @param {Object} value the updated preferences object
 * @returns {function} thunk
 */
export function updateUserPreferences(key, value) {
    return (dispatch, getState) => {
        dispatch({
            key,
            value,
            type: UPDATE_PREFERENCES
        });

        let { checkout, user } = getState();
        dispatch(prefillCheckout(checkout, user.preferences));
    };
};

/**
 * Saves card for user
 * @param {Object} data
 * @returns {function} thunk
 */
export function updateMarketingPreferencesPlatform(data, hideLoade = false) {
    let newData = { ...data }
    return (dispatch, getState) => {
        let userData = getUserData(),
            userID;
        if (userData && userData.userID) {
            userID = userData.userID;
            newData.email = userData.preferences.personal.email;
            newData.phone = userData.preferences.personal.phone;
        }
        if (!hideLoade) {
            dispatch(loading('Updating marketing preferences'));
        }
        if (userID) {
            return updateMarketingPreferences(userID, newData)
                .then(response => {
                })
                .then(() => {
                    dispatch(loaded());
                });
        }
    }
};

/**
 * Saves card for user
 * @param {Object} data
 * @returns {function} thunk
 */
export function activateLoyalty(data, hideLoade = false) {
    let newData = { ...data }
    return (dispatch, getState) => {
        let userData = getUserData(),
            userID;

        let { user } = getState();
        if (userData && userData.userID) {
            userID = userData.userID;
            newData.email = user.preferences.personal.email;
            newData.phone = user.preferences.personal.phone;
        }
        if (!hideLoade) {
            dispatch(loading('Activating membership'));
        }
        if (userID) {
            let homeContent;
            return updateMarketingPreferences(userID, newData)
                .then(response => activateLoyaltyInMesh(userID, newData))
                .then(() => getHomeContent())
                .then((content) => { homeContent = content })
                .then(() => getLoyalty(userID))
                .then((loyalty) => {
                    dispatch({
                        type: LOYALTY_UPDATE,
                        loyalty,
                        achievements: homeContent.achievements
                    });
                })
                .catch((error) => {
                    dispatch(showError({ errorInfo: 'Unable to activate membership' }))
                })
                .then(() => {
                    dispatch(loaded());
                });
        }
    }
};