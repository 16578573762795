/*
	What should happen:
		- Splash screen captures the current URL
		- User chooses to login/signup
		- On success, they are redirected to a page that shows the login/signup journey
			- this journey is a series of preferences pages
		- This component will act as a container for the process
			- pass in an array of screens to journey through, and the key of the corresponding preferences object
			- keeps track of index in the array of screens
				- increment on "NEXT"
			- loads the screens, overrides the Cancel/Save buttons with Skip/(Next/Finish)
				- Next should do same as the "save" button, but then transition to next screen
				- If index is last in array, show "Finish" on button
		- When "Skip" or "Finish", redirect back to the URL captured by splash screen
			- Usually '/', but would be a product if opened from share URL

	Things TODO:
		- action that is triggered on backToApp(), which clears the onFinish value from the content state
*/

import React from 'react';
import { connect } from 'react-redux';
import { hashHistory } from 'react-router';
import { updateUserPreferences } from '../../actions/preferences';
import { shouldShowHoldingPage } from '../../services/holdingPage';
import config from "../../services/config/config";

import './LoginSignupJourney.scss';


//////////////////


const JourneyButtons = ({ isLast, onNext, onSkip, preferencesKey, preferences, theme, isNextDisabled }) => (
	<div className="form-action">
		<div className="inline split">
			<button className="cancel" onClick={onSkip}>Skip</button>
			<button className="action next" onClick={() => onNext(preferencesKey, preferences)} style={theme.primaryButton} disabled={isNextDisabled}>
				<div className="button-contents">
					{isLast ? 'Finish' : 'Next'}
				</div>
			</button>
		</div>
	</div>
);

const LoginSignupJourney = React.createClass({
	backToApp() {
		if (shouldShowHoldingPage(this.props.overrideMessage)) {
			hashHistory.push('/');
			return;
		}

		if (config.layout.useDesktopHeader) {
			hashHistory.push(this.props.onFinish);
			return;
		}
		hashHistory.push(this.props.onFinish === '/' ? '/onboarding/tutorial' : this.props.onFinish);
	},
	onNext(preferencesKey, preferences) {
		let key = this.props.screens[this.props.step - 1].preferencesKey;
		// some preferences don't save the page contents (e.g. payments), so don't have a key
		if (key) {
			this.props.savePreferences(key, preferences);
		}
		let onNext = this.props.screens[this.props.step - 1].onNext;
		if (onNext) {
			onNext(preferences);
		}

		this.nextPage();
	},
	onSkip(skipNumber = 1) {
		this.nextPage(skipNumber);
	},
	nextPage(skipNumber = 1) {
		// handle next/finish based on screen index
		if (+this.props.step + skipNumber - 1 === this.props.screens.length) {
			this.backToApp();
		} else {
			hashHistory.push(`/journey/${+this.props.step + skipNumber}`);
			this.forceUpdate();
		}
	},
	render() {
		let { screens, theme, preferences } = this.props;

		return (
			<div className="Account-Journey">
				{
					screens.map((el, i) => (
						<div key={i} className={'journeyScreen' + (i === this.props.step - 1 ? ' active' : '')}>
							{i === this.props.step - 1 ? <div>
								{el.markup}
								{el.hideJorneyButtons ? null :
									<JourneyButtons
										isLast={i === screens.length - 1}
										onNext={this.onNext}
										onSkip={() => this.onSkip(el.skipNumber)}
										theme={theme}
										preferences={preferences}
										preferencesKey={el.preferencesKey}
										isNextDisabled={this.props.isNextDisabled}
									/>
								}
							</div> :
								null
							}
						</div>
					))
				}
			</div>
		);
	}
});

const mapStateToProps = (state, currentProps) => {
	const { content } = state;
	let onFinish = content.onFinish ? content.onFinish : '/';
	return { onFinish, theme: content.theme, step: currentProps.step, overrideMessage: content.overrideMessage };
};

const mapDispatchToProps = dispatch => {
	return {
		savePreferences: (key, data) => dispatch(updateUserPreferences(key, data)),
	};
};


//////////////////


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(LoginSignupJourney);
