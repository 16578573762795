import {
	APPLE_PAY_ENABLED,
	APPLE_PAY_START,
	APPLE_PAY_EVENT,
	CALENDAR_EVENT,
	NOTIFICATION_SUBSCRIBE,
	PUSH_ENABLED,
	HAWK_SIGN,
	OAUTH_GET,
	OAUTH_REFRESH
} from '../services/nativeBridge/messageTypes';

import { enableApplePay, applePayEventHandler } from './applePay';
import { haveCalendarResponse } from './calendarEvents';
import { haveNativeResponse } from './notifications';
import { pushNativeResponse } from './push';
import { showError } from './error';
import { doOauthLogin, doRefreshOauth, receiveHawk } from './auth';

/**
 * @description This function returns a dispatcher function which can
 * be dispatchs using the redux thunk middleware. From here we can run business
 * logic or dispatch actions based on the messaged we recieve.
 *
 * @param {object} payload
 */
export function nativeBridgeMessageHandler(payload) {
	return (dispatch, getState) => {
		//dispatch(showError({ errorInfo: `Received message from native bridge ${JSON.stringify(payload)}` }));
		switch (typeof payload.messageType === 'string' ? payload.messageType.toLowerCase() : payload.messageType) {
			case APPLE_PAY_ENABLED.toLowerCase():
				if (payload.data && payload.data.success) dispatch(enableApplePay());
				break;

			case APPLE_PAY_START.toLowerCase():
				if (payload.data && !payload.data.success) dispatch(showError("Unable to start apple pay payment."))
				break;

			case APPLE_PAY_EVENT.toLowerCase():
				applePayEventHandler(getState, payload, dispatch);
				break;

			case CALENDAR_EVENT.toLowerCase():
				if (payload.data) dispatch(haveCalendarResponse(payload.data));
				break;

			case NOTIFICATION_SUBSCRIBE.toLowerCase():
				if (payload.data) dispatch(haveNativeResponse(payload.data));
				break;

			case PUSH_ENABLED.toLowerCase():
				if (payload.data) dispatch(pushNativeResponse(payload.data));
				break;

			case HAWK_SIGN.toLowerCase():
				if (payload.data) dispatch(receiveHawk(payload.data));
				break;

			case OAUTH_GET.toLowerCase():
				if (payload.data) dispatch(doOauthLogin(payload.data));
				break;
			
			case OAUTH_REFRESH.toLowerCase():
				if (payload.data) dispatch(doRefreshOauth(payload.data));
				break;

			default:
				console.log('Message type not known', payload);
				break;
		}
	}
};
