import React, { Component, PropTypes } from 'react';
import AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';
import config from '../../services/config/config';
import TitleBlock from '../titleBlock/TitleBlock';

class AdyenPaymentForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            adyenCard: {},
            holderName: '',
            adyenStatus: 'idle',
            nameValid: false,
            browserInfo: {},
        };
        this.validateName = this.validateName.bind(this);
        this.handlePayment = this.handlePayment.bind(this);
        this.handleFormChange = this.handleFormChange.bind(this);
    }

    componentDidMount() {
        /* 
            REQUIRED DELAY DUE TO RE-RENDER THAT HAPPENS ON PAYMENT PREFERENCES PAGE,
            FORM WILL NOT INITIALISE PROPERLY WITHOUT.
        */
        this.delayedInitialize = setTimeout(() => {
            this.initialiseAdyenCheckout();
        }, 800);
    }

    componentWillUnmount() {
        /* 
            REQUIRED DELAY DUE TO RE-RENDER THAT HAPPENS ON PAYMENT PREFERENCES PAGE,
            FORM WILL NOT INITIALISE PROPERLY WITHOUT.
        */
        if (this.delayedInitialize) {
            clearTimeout(this.delayedInitialize);
        }
    }

    handleFormChange(state) {
        this.setState({
            browserInfo: state.data.browserInfo
        });
    };

    async initialiseAdyenCheckout() {

        this.setState({ adyenStatus: 'loading' });

        const configuration = {
            locale: config.adyen.locale,
            environment: config.adyen.environment,
            clientKey: config.adyen.adyenClientKey,
            onChange: this.handleFormChange,
        };

        const adyenCardInstance = await AdyenCheckout(configuration);

        const adyenCard = adyenCardInstance.create('securedfields', {
            type: 'card',
            styles: {
                base: {
                    fontFamily: 'inherit',
                    fontSize: '13px',
                    color: '#000000'
                },
                error: {
                    fontFamily: 'inherit',
                    fontSize: '13px',
                    color: '#C91515'
                },
                validated: {
                    fontFamily: 'inherit',
                    fontSize: '13px',
                    color: '#1a7d4d',
                },
                placeholder: {
                    fontFamily: 'inherit',
                    fontSize: '13px',
                    color: '#afaeae',
                }
            }
        }).mount('#customCard-container');

        this.setState({ adyenCard, adyenStatus: 'loaded' });
    }

    validateName(event) {

        // Check if the input has any text in it
        if (event && event.target && typeof event.target.value !== undefined) {
            const name = event.target.value;

            this.setState({ holderName: name }, () => {
                const { holderName } = this.state;
                /*
                    matches strings that contain only uppercase and lowercase alphabetical characters
                    (A-Z, a-z), apostrophes and spaces, with no other symbols or numbers allowed.
                */
                const pattern = /^[a-zA-Z ']+$/;
                const isValid = pattern.test(holderName) && holderName.length >= 4;

                // Update state.nameValid with the validation result
                this.setState({ nameValid: isValid });
            });
        }
    }

    handlePayment() {
        const { storePaymentMethod, user } = this.props;
        const { adyenCard, holderName, browserInfo } = this.state;
        const { isValid, data } = adyenCard ? adyenCard.state : {};

        if (isValid) {
            const {
                encryptedCardNumber,
                encryptedExpiryMonth,
                encryptedExpiryYear,
                encryptedSecurityCode
            } = data;

            let encryptedData;

            // We need to pass the user's details to the new payment service if they want to store their card
            if (storePaymentMethod) {
                encryptedData = {
                    encryptedCardNumber,
                    encryptedExpiryMonth,
                    encryptedExpiryYear,
                    encryptedSecurityCode,
                    holderName,
                    customer: {
                        firstName: user.preferences.personal.firstName,
                        lastName: user.preferences.personal.lastName,
                        phone: user.preferences.personal.phone,
                        email: user.preferences.personal.email
                    },
                    billingAddress: {
                        city: user.preferences.billing.town,
                        country: 'GB',
                        houseNumberOrName: user.preferences.billing.address1,
                        postalCode: user.preferences.billing.postcode,
                        stateOrProvince: '-',
                        street: '-'
                    },
                    deliveryAddress: {
                        city: user.preferences.billing.town,
                        country: 'GB',
                        houseNumberOrName: user.preferences.billing.address1,
                        postalCode: user.preferences.billing.postcode,
                        stateOrProvince: '-',
                        street: '-'
                    },
                    deliveryMethod: {
                        clientID: "UKSTD",
                        price: {
                            amount: "0.00",
                            currency: "GBP"
                        },
                        metapackServiceCode: null,
                        name: "UK Click & Collect",
                        leadTime: 0,
                        ID: "9ccf492b-e009-4968-8847-9be4ac99a223",
                        launchDay: false,
                        type: 'delivery'
                    },
                    storePaymentMethod: true,
                };

            } else {
                encryptedData = {
                    encryptedCardNumber,
                    encryptedExpiryMonth,
                    encryptedExpiryYear,
                    encryptedSecurityCode,
                    holderName,
                    browserInfo
                };
            }

            this.props.onPaymentSubmission(encryptedData);
        }
    }

    render() {
        const { adyenStatus } = this.state;
        const { title } = this.props;
        return (
            <div className='content-scroll'>
                {title ? <TitleBlock title={title} /> : null}
                <div className="payment-marks"></div>
                <div id="customCard-container">
                    {adyenStatus === 'loaded' &&
                        <div>
                            <label>
                                <span className="customCard_title">Name on Card:</span>
                                <input type="text" name="holderName" placeholder="John Doe" onChange={this.validateName} required />
                            </label>
                            <label>
                                <span className="customCard_title">Card Number:</span>
                                <span data-cse="encryptedCardNumber"></span>
                            </label>
                            <div className="customCard_exp">
                                <label>
                                    <span className="customCard_title">Expiry Date:</span>
                                    <span data-cse="encryptedExpiryDate"></span>
                                </label>
                                <label>
                                    <span className="customCard_title">CVC:</span>
                                    <span data-cse="encryptedSecurityCode"></span>
                                </label>
                            </div>
                        </div>
                    }
                </div>
                {/* SUBMIT BUTTON */
                    adyenStatus === 'loaded' &&
                    <div className="form-action fixed">
                        <button type="submit" className="submit action" onClick={this.handlePayment}>
                            <div className="button-contents">
                                {this.props.submitText}
                            </div>
                        </button>
                    </div>
                }
            </div>
        );
    }
}

AdyenPaymentForm.propTypes = {
    title: PropTypes.string,
    onPaymentSubmission: PropTypes.func.isRequired,
    submitText: PropTypes.string.isRequired,
    storePaymentMethod: PropTypes.bool.isRequired
};

export default AdyenPaymentForm;
