import { HAVE_PRODUCTS_FOR_BOOKMARKS } from '../actions/bookmarks';
import { RECEIVED_PRODUCTS_BY_MONTH } from '../actions/calendar';
import { LOAD_STORES } from '../actions/checkout';
import { LOAD_CONTENT, RECIEVED_CONTENT, LOAD_RAFFLE_ENTRIES,  RECIEVED_RAFFLE_ENTRIES } from '../actions/content';
import { LOAD_DELIVERY_METHODS } from '../actions/deliveryTypes';
import { LOADING, LOADED, LOADED_3DS, LOADING_3DS } from '../actions/loading';
import { LOGIN, LOGIN_RESPONSE, SIGNUP, SIGNUP_RESPONSE } from '../actions/auth';
import { LOAD_PRODUCT, RECIEVED_PRODUCT } from '../actions/product';
import { GET_PREFERENCES } from '../actions/preferences';

export const loading = (state = { loading: true, message: "" }, action) => {
	switch (action.type) {
		// start loading
		case LOADING:
		case GET_PREFERENCES:
		case LOAD_CONTENT:
		case LOAD_RAFFLE_ENTRIES:
		case LOAD_DELIVERY_METHODS:
		case LOAD_PRODUCT:
		case LOAD_STORES:
		case LOGIN:
		case SIGNUP:
			return Object.assign({}, state, { loading: true, message: action.message });

		// finish loading
		case LOADED:
		case HAVE_PRODUCTS_FOR_BOOKMARKS:
		case LOGIN_RESPONSE:
		case RECEIVED_PRODUCTS_BY_MONTH:
		case RECIEVED_CONTENT:
		case RECIEVED_RAFFLE_ENTRIES:
		case RECIEVED_PRODUCT:
		case SIGNUP_RESPONSE:
			return Object.assign({}, state, { loading: false });

		case LOADING_3DS:
			return Object.assign({}, state, { loading3DS: true, message: action.message });

		case LOADED_3DS:
			return Object.assign({}, state, { loading3DS: false });

		default:
			return state;
	}
}

export default loading;
