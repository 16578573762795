import { connect } from 'react-redux';
import React from 'react';
import PageWarpper from './pageTypes/PageWrapper'
import config from '../../services/config/config'

const Feature = React.createClass({
	render() {
		let { homepage, campaigns } = this.props;
		let content = campaigns.pages.find((page) => (page.id === homepage))
		return (
			<div className={`Campaign ${config.layout.nav.position}`}>
				<PageWarpper page={content} />
			</div>
		);
	}
});

const mapStateToProps = state => {
	const { content } = state;
	return { campaigns: content.campaigns };
};

const mapDispatchToProps = dispatch => {
	return {
	}
};


//////////////////


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Feature);
