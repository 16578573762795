import { connect } from 'react-redux';
import BlogPage from '../components/blog/Blog';

const mapStateToProps = state => {
	const { content } = state;
	return { posts :content.blog || [] };
};

const mapDispatchToProps = dispatch => {
	return {};
};

const Blog = connect(
	mapStateToProps,
	mapDispatchToProps
)(BlogPage);

export default Blog;
