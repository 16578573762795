import React from 'react';


const HTML = React.createClass({
  async componentDidMount() {
    let { content } = this.props;
    //We let the content team inject external scripts. 
    //These are appened onto the body one after the other once the perevious script has loaded.
    for (let i = 0; i < content.scripts.length; i++) {
      await setExternalScript(content.scripts[i]);
    }
  },
  componentWillUnmount() {
    //Remove all the injected scriptrs from the page
    var scripts = document.getElementsByClassName('injected-script');
    while (scripts[0]) {
      scripts[0].parentNode.removeChild(scripts[0]);
    }
  },
	render() {
    let { content, name } = this.props
    return (<div
      className={`html-wrapper ${content.fullwidth ? 'full' : 'borders'} ${name}`}
      dangerouslySetInnerHTML={createMarkup(content.data)} />)
  }
})

function setExternalScript(src) {
  return new Promise((resolve, reject) => {
    const scriptTag = document.createElement('script');
    scriptTag.type = 'text/javascript';
    scriptTag.className = 'injected-script'
    scriptTag.src = src;
    scriptTag.onload = () => resolve();
    document.body.appendChild(scriptTag);
  });
}

function createMarkup(html) {
  return { __html: html };
}

export default HTML;