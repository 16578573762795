import { SHOW_ERROR, CLEAR_ERROR } from '../actions/error';

const initialState = () => ({
	title: "",
	message: "",
	show: false,
	clearCallback: null
});

export const error = (state = initialState(), action) => {

	switch (action.type) {
		case SHOW_ERROR:
			let err = {
				title: action.title,
				message: action.message,
				dismissButton: action.dismissButton,
				show: true
			};
			if (action.clearCallback) err.clearCallback = action.clearCallback;
			return Object.assign({}, state, err);

		case CLEAR_ERROR:
			return Object.assign({}, initialState());

		default:
			return state;
	}
}

export default error;