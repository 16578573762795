/**
 * Payment presentational component
 *
 * This is a presentational component which wraps the payment page
 *
 * Receives props from the container component in containers/paymentPage
 *
 */
import React from 'react';
import AdyenPaymentForm from '../adyenPaymentForm/AdyenPaymentForm';

import { trackPage } from '../../services/analytics/analytics';

import './Payment.scss';


const Payment = ({ session, order, sendEncryptedPaymentData, clearSession }) => {

    const makePayment = (data) => {
        sendEncryptedPaymentData(session.ID, data);
    };

    trackPage('payment');

    return (
        <div className="Checkout Payment">
            <AdyenPaymentForm generatedTime={order.serverDate} onPaymentSubmission={makePayment} submitText="Place Order & Pay" title="Secure Payment" storePaymentMethod={false} />
        </div>
    )
};

export default Payment
