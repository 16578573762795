import React from 'react';

import { hashHistory } from 'react-router';

import './MiniCart.scss';
import basketIcon from '../../assets/img/nav/icn-basket.png';
import loadingSpinner from './preload-white.gif';
import config, { isInNativeApp } from '../../services/config/config';

const MiniCart = React.createClass({
    getInitialState() {
        return {
            showInfoPopup: false
        }
    },
    navigateTo(e) {
        e.stopPropagation();
        if (this.props.stock.length) {
            hashHistory.push('/checkout');
        } else {
            this.setState({ showInfoPopup: true })
        }
    },

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    },

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    },

    handleClickOutside(event) {
        if (this.state.showInfoPopup && this.refs.missingPopup && !this.refs.missingPopup.contains(event.target)) {
            this.setState({ showInfoPopup: false })
        }
    },

    render() {

        let { stock, className, loading, forceShow } = this.props;

        return (
            stock.length || forceShow ? <div> <span className={`${className} ${stock.length ? '' : 'disabled'}`}
                onClick={this.navigateTo}
            >
                {loading ? <img className="loader"
                    alt="loading"
                    src={loadingSpinner}
                />
                    : <img className="icon icon-basket" alt="basket icon" src={basketIcon}
                        role="presentation" />
                }
                {stock.length ? <span className="count">{stock.length}</span> : null}
            </span>
                {this.state.showInfoPopup ? <div className="missingPopup" ref="missingPopup">
                    <h2>Your basket is empty</h2>
                    <h5>{isInNativeApp() ? null : <a target="_blank" rel="noopener noreferrer" href={`${config.storeUrl}cart`}>Something missing?</a>}</h5>
                </div> : null}
            </div> : null
        )

    }

});

export default MiniCart;