/**
 * extractOrderFromState
 *
 * @description this takes the applications checkout state and mutates it to the correct schema for
 * creating an order.
 *
 * @param [object] checkout state
 * @return [object] order details
 */
export function extractOrderFromState(checkout){

	// deep clone
	// checkout contains references to multiple objects
	let order = JSON.parse(JSON.stringify(checkout));

	let {customer, delivery, billing, deliveryMethod, locale, useBillingAsDelivery, optionID, productID, userID} = order;
	delivery.locale = locale;
	if(useBillingAsDelivery && deliveryMethod.type !== "clickAndCollect"){
		billing = delivery;
	}
	
	return {
		customer,
		delivery,
		billing,
		deliveryMethod,
		optionID, 
		productID, 
		userID
	};
}