/**
 * Checkout header
 *
 * Purely presentational component for displaying a seperating title block on the checkout page.
 */

import React, {PropTypes} from 'react';
import './TitleBlock.scss';

const TitleBlock = ({title}) => (
	<div className="form-header">
		<h3>{title}</h3>
	</div>
);

TitleBlock.propTypes = {
	title: PropTypes.string.isRequired
}

export default TitleBlock;