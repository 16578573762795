// Container component for the Home page
import { connect } from 'react-redux';
import { signupDetailsChange, doSignup } from '../actions/auth';
import SignUp from '../components/signUp/SignUp';

/**
 * Maps the state from the application state to props
 * @param {Object} state
 * @return {Object}
 */
const mapStateToProps = (state) => {
	const { loading } = state; //using the login state
	return { loading };
}

/**
 * Maps the checkout actions to props
 * @param {function} dispatch
 * @return {Object}
 */
const mapDispatchToProps = (dispatch) => {
	return {
		updateCallback: formData => dispatch(signupDetailsChange(formData)),
		userSignup: (formData, verification) => dispatch(doSignup(formData, verification))
	}
}

// map state and dispatch methods to props
const SignUpPage = connect(
	mapStateToProps,
	mapDispatchToProps
)(SignUp);

export default SignUpPage;
