/**
 * Payment presentational component
 *
 * This is a presentational component which wraps the payment page
 *
 * Receives props from the container component in containers/paymentPage
 *
 */
import React from 'react';

import KlarnaPaymentForm from '../klarnaPaymentForm/KlarnaPaymentForm';
import { trackPage } from '../../services/analytics/analytics';

import './Klarna.scss';


const Klarna = ({ session, order, sendKlarnaPaymentData, clearSession, paymentMethod, checkout, hideKlarna, showError, updateSession }) => {
    const makePayment = (data) => {
        sendKlarnaPaymentData(session.ID, data);
    };
    const setPaymentButtonSate = (data) => {
        let klarna = Object.assign({}, session.klarna);
        klarna.enablePaymentButton = data
        updateSession(Object.assign({}, session, { klarna: klarna }))
    };
    trackPage('klarna');

    return (
        session.klarna ?
            <div className="Checkout Klarna Payment">
                <KlarnaPaymentForm token={session.klarna.token} setPaymentButtonSate={setPaymentButtonSate} disabledPaymentButton={!session.klarna.enablePaymentButton} enablePaymentButton={session.klarna.enablePaymentButton} updateSession={updateSession} hideKlarna={hideKlarna} paymentMethod={checkout.paymentMethod.klarnaType} onPaymentSubmission={makePayment} showError={showError} />
            </div>
            : null
    )
};

export default Klarna
