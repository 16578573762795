import React from 'react';
import LoginSignUpForm from '../loginSignUpForm/LoginSignUpForm';

import { trackPage } from '../../services/analytics/analytics';

const Login = ({ updateCallback, userLogin }) => {

	trackPage('login');

	return (
		<div className="Checkout">
			<LoginSignUpForm isSignup={false} submitForm={userLogin} updateCallback={updateCallback} />
		</div>
	)
};

export default Login;
