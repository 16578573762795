import {SESSION_EXPIRED, PAYMENT_FAILED, UNKNOWN_ERROR} from './paymentStatuses';

function checkPaymentStatus(payment){
    let error;
    switch(payment.status){
        case UNKNOWN_ERROR:
        case SESSION_EXPIRED:
        case PAYMENT_FAILED:
            error = new Error(payment.refusalReason);
            Object.assign(error, payment);
            throw error;
        default: 
            return payment;
    }
}

export default checkPaymentStatus;