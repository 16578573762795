import React from 'react';
import Countdown from '../countdownTimer/Countdown';

import { hasDatePassed } from '../../services/time/time';


const PreLaunchInformation = ({ launchType, launchStartDate, launchDate, dateFormat, launchProduct }) => {

	// raffles have a raffle start date and a launch start date
	const startDate = launchType === 'raffle' ? launchStartDate : launchDate;

	return (
		<div className="releaseSoon">
			{
				launchDate ? launchType === 'monthOnly' ? null : <Countdown end={startDate} countdownEnded={launchProduct} />: null
			}
		</div>
	)
}

const LaunchedInformation = ({ launchType, launchDate, launchStartDate, launchProduct, dateFormat }) => {
	// raffles have a raffle start date and a launch date
	const startDate = launchType === 'raffle' ? launchStartDate : launchDate;

	return (
		<div className="releaseLive">
			{
				launchType === 'raffle' && launchDate
					? <span className="launchDate">
							{hasDatePassed(startDate) ?
								<span>
									{hasDatePassed(launchDate) ?
										null :
										<span>
											<Countdown end={launchDate} countdownEnded={launchProduct} />
										</span>
									}
								</span>
								:
								<span>
									{launchType === 'monthOnly' ? null : <Countdown end={startDate} countdownEnded={launchProduct} />}
								</span>
							}
						</span>
					: null
			}
		</div>
	)
}

const ProductLaunchCountDown = ({ isAvailable, launchDate, launchProduct, dateFormat, launchType, launchStartDate }) => {
	// raffles have a raffle start date and a launch date
	const startDate = launchType === 'raffle' ? launchStartDate : launchDate;

	let notLaunchedInformation = false;
	let launchInformation = true;

	if (!hasDatePassed(startDate) && isAvailable) {
		notLaunchedInformation = true;
		launchInformation = false;
	}


	return <div className="launchInformation">

		{launchInformation ? <LaunchedInformation
			launchType={launchType}
			launchStartDate={launchStartDate}
			launchDate={launchDate}
			launchProduct={launchProduct}
			dateFormat={dateFormat}
		/> : null
		}

		{notLaunchedInformation ?
			<PreLaunchInformation
				launchType={launchType}
				launchStartDate={launchStartDate}
				launchDate={launchDate}
				launchProduct={launchProduct}
				dateFormat={dateFormat}
				isAvailable={isAvailable}
			/>
			: null
		}
	</div>;
};


export default ProductLaunchCountDown;
