import { getProducts } from '../services/api';
import { showErrorFromResponse } from './error';

export const CLEAR_PAST_LAUNCHES = 'CLEAR_PAST_LAUNCHES';
export const LOAD_PAST_LAUNCHES_PRODUCTS = 'LOAD_PAST_LAUNCHES_PRODUCTS';
export const PROCESS_PAST_LAUNCHES_PRODUCTS = 'PROCESS_PAST_LAUNCHES_PRODUCTS';
export const RECEIVED_PRODUCTS_BY_MONTH_PAST_LAUNCH = 'RECEIVED_PRODUCTS_BY_MONTH_PAST_LAUNCH';

/**
 * Removes everything from the calendar
 * @returns {Object}
 */
export function clearCalendar() {
	return {
		type: CLEAR_PAST_LAUNCHES
	}
};

/**
 * Loads all products in, orders them by date, and groups them by month
 * @returns {function} thunk
 */
export function loadProducts() {
	return dispatch => {
		dispatch({
			type: LOAD_PAST_LAUNCHES_PRODUCTS
		})
		getProducts()
			.then(data => dispatch(processProducts(data)))
			.then(data => dispatch(receivedProductsByMonth()))
			.catch(error => dispatch(showErrorFromResponse(error)));
	};
};


//////////////////


/**
 * Processes the array of products from mosaic
 * @param {Array.<Object>} products
 * @returns {Object}
 */
export function processProducts(products) {
	return {
		type: PROCESS_PAST_LAUNCHES_PRODUCTS,
		products,
	}
};

/**
 * Called when we have the products grouped by release month
 * @returns {Object}
 */
export function receivedProductsByMonth() {
	return {
		type: RECEIVED_PRODUCTS_BY_MONTH_PAST_LAUNCH
	}
};
