import React, { PropTypes } from 'react';
import Price from '../price/Price';

const DeliveryOption = ({ option, updateDeliveryMethod, methodType, selectedDeliveryMethod }) => {
	return (
		<div className="check-option deliveryOption" data-id={option.ID}>
			<input id={`deliveryOption${option.ID}`} type="radio" name="deliveryOption" data-type={methodType} data-price={JSON.stringify(option.price)} value={option.ID} onChange={(e) => updateDeliveryMethod(option)} checked={selectedDeliveryMethod.ID === option.ID} />
			<label htmlFor={`deliveryOption${option.ID}`}>
				<span className="check-box"></span>
				<span className="name">
					{option.launchDay ? <span className="new">New</span> : null} {option.name}
					<span className="description">
						{option.description}
					</span>
				</span>
				<Price className="price" amount={option.price.amount} currency={option.price.currency} />
			</label>
		</div>
	);
}

DeliveryOption.defaultProps = {
	option: {
		id: "",
		name: "",
		selected: false
	},
	methodType: ""
}

DeliveryOption.propTypes = {
	option: PropTypes.shape({
		ID: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired,
		selected: PropTypes.bool
	}),
	methodType: PropTypes.string
}

export default DeliveryOption;