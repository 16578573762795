import React from 'react';
import preOrderStatus from './PreOrderStatus'
import orderStatus from './OrderStatus'

import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import { hashHistory } from 'react-router';


const PreOrder = ({ PreOrder, index, cancel, withdraw }) => {
	// Switch statement calls tier levels from boostLevel and shows customer how many entries they have into the raffle.
	// let tierLevel = "";
	// switch (PreOrder.boostLevel) {
	// 	case "tier-1":
	// 		tierLevel = "x5";
	// 		break;
	// 	case "tier-2":
	// 		tierLevel = "x3";
	// 		break;
	// 	default:
	// 		break;
	// }
	return (
		<li className="preorder-item">

			<div className="topRow">

				<div className="productImage"><img alt={PreOrder.product.title} src={PreOrder.product.mainImage.original}></img></div>

				<div className="title">
					<div>{PreOrder.product.name}</div>
					<div>{PreOrder.product.subTitle}</div>
					<div>Size: {PreOrder.product.option}</div>
				</div>
				{/* {tierLevel ? <div className="tierLevel"> <p> {tierLevel} </p> </div> : null} */}
			</div>

			<div className="bottomRow">
				<div className="progressBar">
					<ProgressBar
						percent={preOrderStatus(PreOrder.status).progress}
						filledBackground={preOrderStatus(PreOrder.status).barBackground}
					>
						<Step transition="scale">
							{({ accomplished, index }) => (
								<div className="step">
									<div className={"indexedStep"} style={{ backgroundColor: accomplished ? preOrderStatus(PreOrder.status).barBackground : null }}>
										{index + 1}
									</div>
									<div>You're in!</div>
								</div>
							)}
						</Step>
						<Step transition="scale">
							{({ accomplished, index }) => (
								<div className="step">
									<div className={"indexedStep"} style={{ backgroundColor: accomplished ? preOrderStatus(PreOrder.status).barBackground : null }}>
										{index + 1}
									</div>
									<div>Processing</div>
								</div>
							)}
						</Step>
						<Step transition="scale">
							{({ accomplished, index }) => (
								<div className="step">
									<div className={"indexedStep"} style={{ backgroundColor: accomplished ? preOrderStatus(PreOrder.status).barBackground : null }}>
										{index + 1}
									</div>
									<div>{preOrderStatus(PreOrder.status).finalStepName}</div>
								</div>
							)}
						</Step>
					</ProgressBar>
				</div>
				{
					preOrderStatus(PreOrder.status).firstDescription && index === 0 ?
						<b>{preOrderStatus(PreOrder.status).firstDescription}</b> : null
				}
			</div>
			{PreOrder.orderStatus && PreOrder.orderStatus !== "ISSUE" ?
				<div className="bottomRow orderInfo">
					< hr />
					<h3>Order: {PreOrder.orderID}</h3>
					<div className="progressBar">
						<ProgressBar
							percent={orderStatus(PreOrder.orderStatus).progress}
							filledBackground={orderStatus(PreOrder.orderStatus).barBackground}
						>
							<Step transition="scale">
								{({ accomplished, index }) => (
									<div className="step">
										<div className={"indexedStep"} style={{ backgroundColor: accomplished ? orderStatus(PreOrder.orderStatus).barBackground : null }}>
											{index + 1}
										</div>
										<div>Placed</div>
									</div>
								)}
							</Step>
							<Step transition="scale">
								{({ accomplished, index }) => (
									<div className="step">
										<div className={"indexedStep"} style={{ backgroundColor: accomplished ? orderStatus(PreOrder.orderStatus).barBackground : null }}>
											{index + 1}
										</div>
										<div>Warehouse</div>
									</div>
								)}
							</Step>
							<Step transition="scale">
								{({ accomplished, index }) => (
									<div className="step">
										<div className={"indexedStep"} style={{ backgroundColor: accomplished ? orderStatus(PreOrder.orderStatus).barBackground : null }}>
											{index + 1}
										</div>
										<div>{PreOrder.deliveryMethodType === "delivery" ? "On its way" : "Collected"}</div>
									</div>
								)}
							</Step>
						</ProgressBar>
					</div>
					<b>Order Status: {orderStatus(PreOrder.orderStatus).name}</b>
					<p>{orderStatus(PreOrder.orderStatus).description}</p>
				</div>
				: null}
			{
				PreOrder.canWithdraw && !cancel ? <div>
					<button className="action level-2" onClick={() => hashHistory.push(`/preferences/raffle/${PreOrder.id}/withdraw`)}>
						<div className="button-contents">Cancel Entry</div></button></div> : null
			}
			{
				cancel ? <div>
					<button className="action" onClick={withdraw}>
						<div className="button-contents">Cancel Entry</div></button>
					<button className="action level-2" onClick={() => hashHistory.goBack()}>
						<div className="button-contents">Go Back</div></button></div> : null
			}
		</li>
	);
};

export default PreOrder;