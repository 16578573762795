// Container component for the order confirmation page
import { connect } from 'react-redux';
import Order from '../components/order/Order';
import { clearSession } from '../actions/session';

/**
 * mapStateToProps
 * 
 * @description maps the order state from the application state to props for the 
 * Order component
 * 
 * @param [object] state
 * @return [object] order state
 */
const mapStateToProps = (state) => {
  const { order, session } = state;
  return { order, session };
}

/**
 * mapDispatchToProps
 * 
 * @description maps the order actions to props for the Order component
 * 
 * @param [function] dispatch
 * @return [object] checkout props
 */
const mapDispatchToProps = (dispatch) => {
  return {
    clearSession: () => dispatch(clearSession())
  }
}

// map state and dispatch methods to props for Order
const OrderPage = connect(
    mapStateToProps,
    mapDispatchToProps
)(Order);

export default OrderPage;