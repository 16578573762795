import React from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "./Audio.scss";

export default class Audio extends React.Component {
	componentDidMount() {
		this.player = videojs(this.videoNode, this.props);
		const player = this.player;
		player.isAudio(true);
		player.controls(true);
		player.on("touchstart", function (e) {
			console.log(e)
			if (e.target.nodeName === "DIV") {
				if (player.paused()) {
					player.play();
				}
			}
		});
		console.log(player.controls())
	}
	componentWillUnmount() {
		if (this.player) {
			this.player.dispose();
		}
	}

	render() {
		let content = this.props || {};
		let { autoPlay, thumbnail, src, preload } = content;
		return (
			<div className="vjs-audio">
				<div data-vjs-player className="vjs-layout-tiny">
					<audio
						ref={node => (this.videoNode = node)}
						autoPlay={autoPlay || false}
						muted={autoPlay || false}
						poster={thumbnail}
						preload={preload || "none"}
						className="video-js vjs-default-skin"
						data-setup='{"fullscreenToggle": false}'
					>
						<source src={src} type="audio/mp3" />
					</audio>
				</div>
			</div>
		);
	}
}
