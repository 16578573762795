import React from 'react';
import DateTime from '../dateTime/DateTime';

import { hasDatePassed } from '../../services/time/time';


const PreLaunchInformation = ({ launchType, launchStartDate, launchDate, dateFormat, launchProduct }) => {

	// raffles have a raffle start date and a launch start date
	const startDate = launchType === 'raffle' ? launchStartDate : launchDate;

	return (
		<div className="releaseSoon">
			{
				launchDate
					? <div className="countdown-date">
						<span className="launchDate">
							<strong>{launchType === 'raffle' ? 'Draw opening' :'Launching'} <DateTime date={startDate} format={dateFormat} /></strong>
						</span>
					</div>
					: null
			}
		</div>
	)
}

const LaunchedInformation = ({ launchType, launchDate, launchStartDate, launchProduct, dateFormat }) => {
	// raffles have a raffle start date and a launch date
	const startDate = launchType === 'raffle' ? launchStartDate : launchDate;

	return (
		<div className="releaseLive">
			{
				launchType === 'raffle' && launchDate
					? <div className="countdown-date">
						<span className="launchDate">
							{hasDatePassed(startDate) ?
								<span>
									{hasDatePassed(launchDate) ?
										<span>
											<strong>Draw Closed <DateTime date={launchDate} format={dateFormat} /></strong>
										</span> :
										<span>
											<strong>Draw Closes <DateTime date={launchDate} format={dateFormat} /></strong>
										</span>
									}

								</span>

								:
								<span>
									<strong>Draw Opens <DateTime date={startDate} format={dateFormat} /></strong>
								</span>
							}
						</span>

					</div>
					: null
			}
		</div>
	)
}

const ProductLaunchInformation = ({ isAvailable, launchDate, launchProduct, dateFormat, launchType, launchStartDate }) => {
	// raffles have a raffle start date and a launch date
	const startDate = launchType === 'raffle' ? launchStartDate : launchDate;

	let notLaunchedInformation = false;
	let launchInformation = true;

	if (!hasDatePassed(startDate) && isAvailable) {
		notLaunchedInformation = true;
		launchInformation = false;
	}


	return <div className="launchInformation">

		{launchInformation ? <LaunchedInformation
			launchType={launchType}
			launchStartDate={launchStartDate}
			launchDate={launchDate}
			launchProduct={launchProduct}
			dateFormat={dateFormat}
		/> : null
		}

		{notLaunchedInformation ?
			<PreLaunchInformation
				launchType={launchType}
				launchStartDate={launchStartDate}
				launchDate={launchDate}
				launchProduct={launchProduct}
				dateFormat={dateFormat}
				isAvailable={isAvailable}
			/>
			: null
		}
	</div>;
};


export default ProductLaunchInformation;
