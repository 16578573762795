/**
 * Error Actions
 *
 * Here you'll find all the action creators & actions for managing error messages. Async
 * actions (using redux-thunk) are at the bottom.
 */
import { getErrorByCode } from '../services/error';


//////////////////


export const CLEAR_ERROR = 'CLEAR_ERROR';
export const SHOW_ERROR = 'SHOW_ERROR';

/**
 * Action creator for removing the error
 * @return {function} thunk
 */
export function clearError() {
	return (dispatch, getState) => {
		let { error } = getState();
		dispatch({
			type: CLEAR_ERROR
		});
		if (error.clearCallback) error.clearCallback();
	}
};

/**
 * Action creator for showing a new error, the code will be mapped to a message by the error services
 * @param {string} errorCode
 * @param {function} [clearCallback] callback to be called when the error is cleared
 * @param {string} title - errorCode for a title or string to use as title
 * @return {Object}
 */
export function showError(error, clearCallback, title) {
	let message = getErrorByCode(error.errorCode);
	// If no title in error mapping then use string passed
	title = getErrorByCode(title) === 'Error' ? title : getErrorByCode(title);
	if ((!message || message === 'Error') && error.errorInfo) message = error.errorInfo;
	let dismissButton =  error.dismissButton || 'Try Again'
	return {
		message: `${message}${error.paymentErrorCode ? ` - ${error.paymentErrorCode}` : ""}`,
		clearCallback,
		title,
		dismissButton,
		type: SHOW_ERROR
	};
};

/**
 * Action creator for showing a new error using the response of an asynchronous function e.g. http call
 * @param {Promise} error
 * @returns {function} thunk
 */
export function showErrorFromResponse(error) {
	return (dispatch) => {
		if (error.response) {
			error.response.json()
				.then((json) => {
					dispatch(showError(error));
				})
		}
		else {
			dispatch(showError(error));
		}
	}
};
