import React from 'react';

import { trackPage } from '../services/analytics/analytics';
import Tutorial from '../components/tutorial/Tutorial';


//////////////////


export default () => {

	trackPage('onboarding/tutorial');

	return (
		<div className="Onboarding Tutorial">
			<Tutorial onboarding={true} />
		</div>
	)
};

