import { postMessage } from '../nativeBridge';
import { SHOW_REVIEW_LIGHTBOX } from '../nativeBridge/messageTypes';

/**
 * Show Review Lightbox
 * 
 */
export const showReviewLightbox = () => {
	let message = {
		messageType: SHOW_REVIEW_LIGHTBOX,
		data: {}
	};
	postMessage(message);
};

