// Container component for the Home page
import { connect } from 'react-redux';
import { loginDetailsChange, doLogin } from '../actions/auth';
import Login from '../components/login/Login';

/**
 * Maps the state from the application state to props
 * @param {Object} state
 * @return {Object}
 */
const mapStateToProps = (state) => {
	const { loading } = state;
	return { loading };
};

/**
 * Maps the checkout actions to props
 * @param {function} dispatch
 * @return {Object}
 */
const mapDispatchToProps = (dispatch) => {
	return {
		updateCallback: formData => dispatch(loginDetailsChange(formData)),
		userLogin: (formData, verification) => dispatch(doLogin(formData, verification))
	}
};

// map state and dispatch methods to props
const LoginPage = connect(
	mapStateToProps,
	mapDispatchToProps
)(Login);

export default LoginPage;
