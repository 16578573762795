import { clearUserData, getUserData, setUserData } from './userData';
import { notifyLogin } from '../userActions/login';

/**
 * Removes the user from the device entirely
 */
export function clearUserFromDevice() {
	clearUserData();
};

/**
 * Determines whether the user is logged in
 * @returns {boolean}
 */
export function isUserLoggedIn() {
	let userData;
	try {
		userData = getUserData();
	} catch (err) {
		userData = null;
	}
	return (userData && userData.userID);
};

/**
 * Saves the user to the device
 * @param {Object} customer the mosaic customer object
 */
export function saveUserToDevice(customer) {
	let userData = getUserData() || { preferences: {} };
	userData.userID = customer.userID;
	notifyLogin(customer.userID);
	userData.preferences = Object.assign({}, userData.preferences, customer.preferences);
	setUserData(userData);
};
