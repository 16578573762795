import { UPDATED_SEARCH_RESULTS, UPDATE_SEARCH_TEAM, UPDATE_SEARCH_RESULTS, LOADING_SEARCH_RESULTS, TRACK_SEARCH_SCROLL } from '../actions/search';

const initialState = () => ({
	term: '',
	products: [],
	page: 1,
	loading: false,
	max: 0,
	scrollTop: 0
});

export const search = (state = initialState(), action) => {
	switch (action.type) {
		case UPDATE_SEARCH_TEAM:
			return Object.assign({}, state, { term: action.term, loading: action.term !== '', products: [], max: 0, page: 1, scrollTop: 0 });
		case UPDATED_SEARCH_RESULTS:
			return Object.assign({}, state, { loading: false });
		case UPDATE_SEARCH_RESULTS:
			return Object.assign({}, state, { products: [...state.products, ...action.products.results], max: action.products.total, page: state.page + 1 });
		case LOADING_SEARCH_RESULTS:
			return Object.assign({}, state, { loading: true });
		case TRACK_SEARCH_SCROLL:
			return Object.assign({}, state, { scrollTop: action.scrollTop });
		default:
			return state;
	}
};
