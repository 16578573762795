/**
 * Session Actions
 * 
 * Here you'll find all the action creators & actions for managing session state. Async
 * actions (using redux-thunk) are at the bottom. 
 * 
 */
import storage from '../services/storage/storage';
import {getSession} from '../services/api';

export const UPDATE_SESSION = 'UPDATE_SESSION';

/**
 * updateSession
 * 
 * @param [object] session
 * @return [object] action
 */
export function updateSession(session){
    return {
        type: UPDATE_SESSION, 
        session
    }
}

export const SET_SESSION = 'SET_SESSION';
/**
 * updateSession
 * 
 * @param [string] sessionID
 * @return [object] action
 */
export function setSession(sessionID){
    return {
        type: SET_SESSION, 
        sessionID
    }
}


export const CLEAR_SESSION = 'CLEAR_SESSION';
/**
 * clearSession
 * 
 * @return [object] action
 */
export function clearSession(){
    return {
        type: CLEAR_SESSION
    }
}


export const LOADING_SESSION = 'LOADING_SESSION';
/**
 * loadingSession
 * 
 * @return [object] action
 */
export function loadingSession(){
    return {
        type: LOADING_SESSION
    }
}


/****************************************
 * ASYNC ACTION CREATORS 
 * (dependent on redux thunk middleware)
 * 
 ***************************************/

/**
 * retreiveSession
 * 
 * @param [string] sessionID
 * @return [Promise]
 */
export function retreiveSession(sessionID){

    return (dispatch) => {
        if(sessionID){
            dispatch(loadingSession());
            return getSession(sessionID)
            .then((session) => dispatch(updateSession(session)))
            .catch(() => dispatch(clearSession()))
        } else{
            // nothing to do
            return Promise.resolve()
        }
    }
    
}


/**
 * restoreSession
 * 
 * @return [Promise]
 */
export function restoreSession(){

    return (dispatch) => {
        let sessionID = storage.getItem('session');
        if(sessionID){
            dispatch(loadingSession());
            dispatch(setSession(sessionID));
            return getSession(sessionID)
            .then((session) => dispatch(updateSession(session)))
        } else{
            // nothing to do
            return Promise.resolve()
        }
    }
    
}