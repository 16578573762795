// Container component for the product page
import { connect } from 'react-redux';
import { search, updateSearchTerm, lazyLoad, trackScroll } from '../actions/search';
import Search from '../components/search/Search';



/**
 * mapStateToProps
 *
 * @description maps the product state from the application state to props for the
 * product component
 *
 * @param [object] state
 * @return [object] product state
 */
const mapStateToProps = (state) => {
  const { search } = state;
  return { search };
}

/**
 * mapDispatchToProps
 *
 * @description maps the product actions to props for the Product component
 *
 * @param [function] dispatch
 * @return [object] product props
 */
const mapDispatchToProps = (dispatch) => {
  return {
    updateSearchTerm: (term) => dispatch(updateSearchTerm(term)),
    doSearch: (term) => dispatch(search(term)),
    lazyLoad: () => dispatch(lazyLoad()),
    trackScroll: (scrollTop) => dispatch(trackScroll(scrollTop)),

  }
}

// map state and dispatch methods to props for Product
const SearchPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(Search);

export default SearchPage;
