import React, {PropTypes} from 'react';
import DeliveryMethod from './DeliveryMethod';

import './DeliveryTypes.scss';

const DeliveryTypes = ({deliveryTypes, updateDeliveryMethod, deliveryMethod}) => {
	let deliveryMethods;
	const handleDeliveryOptionSelection = (deliveryOption) => {
		updateDeliveryMethod(deliveryOption);
	}
	
	if(deliveryTypes.length && deliveryTypes.filter((option)=> option.options.length !== 0).length){
		deliveryMethods = deliveryTypes.map((el, i) => <DeliveryMethod key={i} displayName={el.name} methodType={el.type} options={el.options} updateDeliveryMethod={handleDeliveryOptionSelection} selectedDeliveryMethod={deliveryMethod}/>)
	} else{
		deliveryMethods = <p>No delivery methods found</p>;
	}
	
	
	return (
		<div className="form-horizontal form-options">
            <ul>
			     {deliveryMethods}
            </ul>
		</div>
	);
}

DeliveryTypes.defaultProps = {
	deliveryTypes : []
}

DeliveryTypes.propTypes = {
	deliveryTypes : PropTypes.array.isRequired,
	updateDeliveryMethod: PropTypes.func
}

export default DeliveryTypes;