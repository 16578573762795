import React from 'react';
import './Achievement.scss'


function importAll(r) {
    let images = {};
    r.keys().forEach((item, index) => { images[item.replace('./', '').replace(/\.(png|jpe?g|svg)$/, '')] = r(item); });
    return images
}

//////////////////


export default ({ level, wins, onClick }) => {
    const images = importAll(require.context('./assets', false, /\.(png|jpe?g|svg)$/));
    let imageKey = `${level}${level <= wins ? 'Y' : 'N'}`

    let click = level <= wins ? onClick : function () { }
    return (
        (imageKey in images) ?
            <div className="achievement" onClick={() => click({ type: 'achievement', achievement: level })}>
                <img src={images[imageKey]} alt={imageKey} />
            </div> : null
    )
};