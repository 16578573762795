/**
 * Home Content presentational component
 *
 * This is a presentational component which wraps the home page.
 *
 * Receives props from the container component in containers/HomePage
 *
 */
import React from 'react';
import { connect } from 'react-redux';

import SwipableViews from 'react-swipeable-views';
import Bookmarks from '../bookmarks/Bookmarks';
import CalendarPage from '../../containers/CalendarPage';
import PastLaunches from '../../containers/PastLaunches';
import UpcomingLaunches from '../../containers/UpcomingLaunches';
import Blog from '../../containers/Blog';
import Campagin from '../../components/contentService/Campagin';
import Splash from '../../components/contentService/Splash';

import SidebarWrapper from '../sidebar/SidebarWrapper';

import './Content.scss';
import { shouldShowHoldingPage } from '../../services/holdingPage';
import HoldingPage from '../holdingPage/HoldingPage';
import LoyaltyShare from '../loyaltyShare/LoyaltyShare';
import { hideLoyaltyNotification } from '../../actions/preferences';
import { filterCampaigns } from '../../actions/content';

const Content = React.createClass({
	componentWillMount() {
		this.props.loadContent();
	},
	render() {
		const trackScroll = el => {
			let element = document.getElementsByClassName(el)[0].getElementsByClassName('content-scroll')[0];
			this.props.trackScroll(element.scrollTop);
		};
		let { sliderIndex, scrollTop, visitedPages, overrideMessage } = this.props.content;
		let { campaigns, loyaltyNotification, hideNotification, loyalty } = this.props;
		let content = campaigns;

		//Do we have any splash screens on the go that a user has not yet seen
		let splashCampaign = filterCampaigns(content.campaigns, loyalty, 'splash').find((campaign) => !Array.isArray(visitedPages) || !(visitedPages.includes(campaign.homePage)))

		//Inject Campaign Content
		let campaignPages = []
		filterCampaigns(content.campaigns || [], loyalty).forEach(campaign => {
			campaignPages.push(<Campagin key={campaign.id} homepage={campaign.homePage} />);
		});

		if (shouldShowHoldingPage(overrideMessage)) return <HoldingPage />
		if (splashCampaign) return <div> <style>{`.header{display: none;} #hero-iframe-container{ display: initial !important; }`}</style> <Splash campaign={splashCampaign} /> </div>
		if (loyaltyNotification.length) return <div>{loyaltyNotification.map((loyaltyNotification, index) => <LoyaltyShare key={index} {...loyaltyNotification} onDismiss={() => { hideNotification(index) }} />)}</div>

		return (
			<div className="Home">
				<style>{`#hero-iframe-container{ display: initial !important; }`}</style>
				<SidebarWrapper trackPageChanges={true} />
				<SwipableViews className="swipable-container"
					enableMouseEvents={true}
					index={sliderIndex}
					disabled={true}
					axis="y"
					animateTransitions={false}
					onChangeIndex={this.handleChangeIndex}>
					<CalendarPage
						scrollTop={sliderIndex === 0 ? scrollTop : 0}
						trackScroll={() => trackScroll('Calendar')} />
					<Bookmarks
						scrollTop={sliderIndex === 1 ? scrollTop : 0}
						trackScroll={() => trackScroll('Bookmarks')} />
					<PastLaunches
						scrollTop={sliderIndex === 2 ? scrollTop : 0}
						trackScroll={() => trackScroll('PastLaunches')} />
					<UpcomingLaunches
						scrollTop={sliderIndex === 3 ? scrollTop : 0}
						trackScroll={() => trackScroll('UpcomingLaunches')} />
					<Blog
						scrollTop={sliderIndex === 4 ? scrollTop : 0}
						trackScroll={() => trackScroll('Blog')} />
					{campaignPages}
				</SwipableViews>
			</div>
		);
	}
});

const mapStateToProps = state => {
	const { content, user } = state;
	return { campaigns: content.campaigns, visitedPages: content.visitedPages || [], loyalty: user.preferences.loyalty || { isPremium: false }, loyaltyNotification: user.preferences.loyalty ? user.preferences.loyalty.notifications || [] : [] }
};

const mapDispatchToProps = dispatch => {
	return {
		hideNotification: (index) => dispatch(hideLoyaltyNotification(index))
	}
};

//////////////////

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Content);
