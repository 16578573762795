import { hashHistory } from 'react-router';
import { showError } from './error';
import { saveNewUser } from './preferences';
import { clearSession } from '../actions/session';
import { createAccount, login, loginFromOauth, loginFromSession } from '../services/api';
import { clearUserFromDevice, saveUserToDevice } from '../services/user/index';
import storage, { getUserID } from '../services/storage/storage';
import { EVTS, endSessionOnNextPageview, trackEvent } from '../services/analytics/analytics';
import { shouldShowHoldingPage } from '../services/holdingPage';
import config from "../services/config/config";
import { loaded3DS, loading3DS } from './loading';
import { oauthError, oauthRefresh } from '../services/oauth/oauth';
import { startupComplete } from '../modules/startup';
import { loadContent } from './content';

const redirectOnAuthPath = 'journey/1';


//////////////////
// public

export const LOGIN = 'LOGIN';
export const LOGIN_DETAILS_CHANGE = 'LOGIN_DETAILS_CHANGE';
export const LOGIN_RESPONSE = 'LOGIN_RESPONSE';
export const LOGOUT = 'LOGOUT';
export const SIGNUP = 'SIGNUP';
export const SIGNUP_DETAILS_CHANGE = 'SIGNUP_DETAILS_CHANGE';
export const SIGNUP_RESPONSE = 'SIGNUP_RESPONSE';

/**
 * Does the user login
 * @param {Object} formData
 * @returns {function} thunk
 */
export function doLogin(formData, verification) {
	return dispatch => {
		login(formData, verification)
			.then(response => dispatch(loginResponse(null, response)))
			.catch(error => dispatch(loginResponse(error)));

		dispatch({
			type: LOGIN,
			action: "Logging in"
		});
	};
};

/**
 * Does the user login from session
 * @param {Object} sessionID
 * @returns {function} thunk
 */
export function doSessionLogin(sessionID) {
	return dispatch => {
		dispatch(loading3DS('Loading'));
		loginFromSession(sessionID)
			.then(response => dispatch(loginResponse(null, response)))
			.catch((error) => {
				dispatch(logout(false))
			}).finally(() => {
				dispatch(loaded3DS())
			});

		dispatch({
			type: LOGIN,
			action: "Logging in"
		});

	};
};

/**
 * Does the user login an oauth token
 * @param {Object} formData
 * @returns {function} thunk
 */
export function doOauthLogin(data) {
	return dispatch => {
		validateOauthResponse(data)
			.then(() => loginFromOauth(data.payload.token))
			.then(response => dispatch(loginResponse(null, response)))
			.then(() => {
				localStorage.setItem('oauthToken', data.payload.token)
				dispatch(loadContent(false, true));
				dispatch(startupComplete())
			})
			.catch((error) => {
				window.addEventListener('oauthRefreshed', function oauthRefreshLogin() {
					window.removeEventListener('oauthRefreshed', oauthRefreshLogin);
					loginFromOauth(localStorage.getItem('oauthToken'))
						.then(response => dispatch(loginResponse(null, response)))
						.then(() => {
							dispatch(loadContent(false, true));
							dispatch(startupComplete())
						}).catch((error) => {
							dispatch(oauthError())
						})
				});
				oauthRefresh();
				return;
			})

		dispatch({
			type: LOGIN,
			action: "Logging in"
		});

	};
};

function validateOauthResponse(data) {
	if (data.success && data.payload && data.payload.token) {
		return Promise.resolve(data)
	} else {
		console.log('oauth-reject')
		return Promise.reject('get-failed')
	}
}

/**
 * Does the user login an oauth token
 * @param {Object} formData
 * @returns {function} thunk
 */
export function doRefreshOauth(data) {
	return dispatch => {
		localStorage.removeItem('oauthRefresh')
		if (data.success) {
			localStorage.setItem('oauthToken', data.payload.token)
			var event = new CustomEvent('oauthRefreshed', {});
			window.dispatchEvent(event);
		} else {
			dispatch(oauthError());
			return;
		}
	};
};


/**
 * Does the user signup
 * @param {Object} formData
 * @returns {function} thunk
 */
export function doSignup(formData, verification) {
	let useAsBilling = formData.delivery.useAsBilling;
	let delivery = { ...formData.delivery }
	delete delivery.useAsBilling;
	delivery.isPrimaryAddress = true;
	let addresses = [delivery];
	if (useAsBilling) {
		addresses[0].isPrimaryBillingAddress = true;
	} else {
		let billing = { ...formData.billing }
		billing.isPrimaryBillingAddress = true;
		addresses.push(billing)
	}

	delete formData.billing;
	delete formData.delivery;

	return dispatch => {
		if (formData.password2 !== formData.password) {
			dispatch(signupResponse({ errorCode: 'signup-password-mismatch' }));
			return;
		}

		createAccount({ ...formData, addresses }, verification)
			.then(response => dispatch(signupResponse(null, response)))
			.catch(error => dispatch(signupResponse(error)));

		dispatch({
			type: SIGNUP
		});
	};
};

/**
 * Callback called on every update in the login form
 * @param {Object} formData
 * @returns {function} thunk
 */
export function loginDetailsChange(formData) {
	return dispatch => {
		dispatch({
			formData,
			type: LOGIN_DETAILS_CHANGE
		});
	};
};

/**
 * Logs the user out from the device
 * @returns {function} thunk
 */
export function logout(sendEvent = true) {
	if (sendEvent) {
		trackEvent(EVTS.CAT.ACCOUNT, EVTS.ACT.LOGOUT);
	}
	endSessionOnNextPageview();

	clearUserFromDevice();
	return dispatch => {
		dispatch({
			type: LOGOUT
		});
		dispatch(clearSession());
	};
};

/**
 * Callback called on every update in the signup form
 * @param {Object} formData
 * @returns {function} thunk
 */
export function signupDetailsChange(formData) {
	return dispatch => {
		dispatch({
			formData,
			type: SIGNUP_DETAILS_CHANGE
		});
	};
};


//////////////////
// private

/**
 * Err first callback that handles the login response
 * @param {Object} error
 * @param {Object} success
 * @returns {function} thunk
 */
function loginResponse(error, success) {
	return dispatch => {
		let hasUserChanged = false;
		if (!error) {
			let currentUserID = getUserID();
			if (currentUserID && currentUserID !== success.customer.userID) {
				dispatch(logout(true))
				hasUserChanged = true;
			}
		}
		dispatch({
			type: LOGIN_RESPONSE,
			success: !error
		});

		if (error) {
			if (error.response) { //handle api error
				error.response.json()
					.then(error => {
						dispatch(showError(error));
					});
			} else if (error.message) {
				error.errorInfo = error.message;
				dispatch(showError(error));
			} else {
				dispatch(showError(error));
			}
			return;
		}

		trackEvent(EVTS.CAT.ACCOUNT, EVTS.ACT.LOGIN, success.customer.userID, undefined, undefined, success);

		// get user data from local storage
		// (if previously logged in) or from Commerce
		let userObj = {};

		// set userID so we can access stored user data
		storage.setUserID(success.customer.userID);
		// TODO: This should be using an improved sessions service
		// access session directly
		let session = storage.getItem('session');
		let user = (session) ? JSON.parse(session).user : null;

		if (user) {
			userObj = {
				userID: success.customer.userID,
				preferences: user.preferences
			}
		} else {
			userObj = {
				userID: success.customer.userID,
				preferences: {
					personal: success.customer.personal,
					delivery: success.customer.delivery,
					billing: success.customer.billing
				}
			};
		}

		dispatch(saveNewUser(userObj.preferences));
		saveUserToDevice(userObj);

		storage.setItem('shouldIgnoreMembershipNotifications', 'true');
		storage.removeItem('wasSignup');

		//MOSAIC-120 - new customers should go to the journey
		// exising should go straight to home
		//If were an app, then we need to redirect on login
		if (config.is_app) {
			if (success.customer.isNew) {
				hashHistory.push(redirectOnAuthPath);
			} else {
				if (shouldShowHoldingPage(window.store.getState().content.overrideMessage)) {
					hashHistory.push('/');
					return;
				} else {
					hashHistory.push('/onboarding/tutorial');
					return;
				}
			}
		} else if (hasUserChanged) {
			hashHistory.push('/');
			return;
		}
	};
}

/**
 * Err first callback that handles the signup response
 * @param {Object} error
 * @param {Object} success
 * @returns {function} thunk
 */
function signupResponse(error, success) {
	return dispatch => {
		dispatch({
			type: SIGNUP_RESPONSE,
			success: !error
		});

		if (error) {
			if (error.response) { //handle api error
				error.response.json()
					.then(error => {
						dispatch(showError(error));
					});
			} else if (error.message) {
				error.errorInfo = error.message;
				dispatch(showError(error));
			} else {
				dispatch(showError(error));
			}
			return;
		}

		trackEvent(EVTS.CAT.ACCOUNT, EVTS.ACT.SIGNUP, success.customer.userID);
		let userObj = {
			userID: success.customer.userID,
			preferences: {
				personal: success.customer.personal,
				delivery: success.customer.delivery,
				billing: success.customer.billing
			}
		};
		dispatch(saveNewUser(userObj.preferences));
		saveUserToDevice(userObj);

		storage.setItem('wasSignup', 'true');
		hashHistory.push(redirectOnAuthPath);
	};
}

export const HAVE_HAWK_NATIVE_RESPONSE = 'HAVE_HAWK_NATIVE_RESPONSE';

export function receiveHawk(response) {
	// if successful, and we've not got this token, register it
	var event = new CustomEvent('hawkReceived', { detail: response });
	window.dispatchEvent(event);
	return {
		type: HAVE_HAWK_NATIVE_RESPONSE
	}
};

