import { navigateToExternal } from '../../services/navigate/navigate';
import { sendPageShare, sendProductShare } from '../../services/share/share';
import { setContentIndex, visitPage } from '../../actions/content';


import { hashHistory } from 'react-router';

export const SHOW_PRODUCT = 'SHOW_PRODUCT';

export default function handelAction(dispatch, action) {
    if (!action) {
        return;
    }
    switch (action.type) {
        case 'navigate-to-internal':
            hashHistory.push(action.value);
            break;
        case 'url':
            navigateToExternal(action.value);
            break;
        case 'share-product':
            sendProductShare(action.value, "Check this out", "", "");
            break;
        case 'share-page':
            sendPageShare(action.value, "Check this out", action.title);
            break;
        case 'product':
            hashHistory.push(`/product/${action.value}`);
            break;
        case 'page':
            if (action.index !== undefined) {
                dispatch(setContentIndex(action.value, action.index))
            }
            hashHistory.push(`/page/${action.value}`);
            break;
        case 'show-product':
            dispatch({
                type: SHOW_PRODUCT,
                productID: action.value
            })
            break;
        case 'hide-splash':
            dispatch(visitPage({id: action.value}))
            break;
        default:
            console.log("unknow action");
    }
}