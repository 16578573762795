import UpcomingLaunchItem from './UpcomingLaunchItem';
import React, { PropTypes } from 'react';
import config from '../../services/config/config'

import './UpcomingLaunches.scss';


//////////////////


const UpcomingLaunches = React.createClass({
	componentDidMount() {
		let el = document.getElementsByClassName('UpcomingLaunches')[0].getElementsByClassName('content-scroll')[0];
		el.scrollTop = this.props.scrollTop || 0;
	},
	render() {
		let { products, brands } = this.props;
		// build productList list
		let list = products.slice().sort((a, b) => (a.launchDate > b.launchDate) ? 1 : -1).map((product, i) =>
			!product.hideFromLaunchesTable ? (<UpcomingLaunchItem
				key={product.sku || i}
				launchDate={product.launchDate}
				launchType={product.launchType}
				plu={product.PLU}
				title={product.name}
				brand={product.brand}
				subtitle={product.subTitle}
				price={product.price}
				maxPrice={product.maxPrice}
				launchesOn={product.launchesOn}
				sizeBreakdown={product.sizeBreakdown}
				brands={brands}
				trackScroll={this.props.trackScroll} />) : null

		);

		// return full calendar markup
		return (
			<div className={`UpcomingLaunches nav-${config.layout.nav.position}`}>
				<div className="content-scroll">
					<h2>Calendar</h2>
					<ul>
						{list}
					</ul>
				</div>
			</div>
		);
	}
});

UpcomingLaunches.defaultProps = {
	products: [],
	barnds: [],
};

UpcomingLaunches.propTypes = {
	products: PropTypes.array.isRequired,
	brands: PropTypes.array.isRequired
};


//////////////////


export default UpcomingLaunches;
