// Apple pay messages
export const APPLE_PAY_ENABLED = 'applePay-enabled';
export const APPLE_PAY_EVENT = 'applePay-event';
export const APPLE_PAY_START = 'applePay-start';

// calendar event
export const CALENDAR_EVENT = 'calendar-event';

// navigation
export const NAVIGATE_TO = 'navigate-to';
export const NAVIGATE_TO_EXTERNAL = 'navigate-to-external';

// notifications
export const NOTIFICATION_SUBSCRIBE = 'notification-subscribe';

// push
export const PUSH_ENABLED = 'push-enabled';

// sharing
export const SHARE_PRODUCT = 'share';
export const SHARE_IMAGE = 'share-image';


// hawk
export const HAWK_SIGN = 'hawk-sign';

export const SHOW_REVIEW_LIGHTBOX = 'request-review';

// login
export const LOGIN = 'login';
export const LOGOUT = 'logout';
// tracking
export const APPSFLYER_EVENT = 'appsflyer-event';

//oauth for native integration
export const OAUTH_GET = 'oauth-get'
export const OAUTH_REFRESH = 'oauth-refresh'
export const CLOSE_NATIVE = 'close'